import {
  supplementsFromTwoMonths,
  supplementsLittleImprovements,
  supplementsLotsOfImprovements,
  supplementsNoConcernInImprovements,
  supplementsNoImprovements,
  supplementsSoftness,
  supplementsSomeImprovements,
} from 'Apps/Feedback/constants/feedbackSlugs';

const supplementsSoftnessScreen = {
  name: supplementsSoftness,
  route: `/feedback/supplements/${supplementsSoftness}`,
  shouldBeIncluded: ({ feedbackAnswers }) =>
    feedbackAnswers &&
    supplementsFromTwoMonths.includes(feedbackAnswers.product_supplements_usage_duration),
  title: 'Does your hair feel softer?',
  subtitle: 'You might notice hair that’s softer when handling.',
  options: [
    { value: supplementsLotsOfImprovements, label: 'Yes, lots of improvement' },
    { value: supplementsSomeImprovements, label: 'Yes, some improvement' },
    { value: supplementsLittleImprovements, label: 'Yes, maybe a little' },
    { value: supplementsNoImprovements, label: 'Not yet' },
    { value: supplementsNoConcernInImprovements, label: 'No, but it wasn’t a concern' },
  ],
  getInitialValue: answers => answers?.product_supplements_softness,
  getAnswers: ({ selected }) => ({ product_supplements_softness: selected }),
};

export default supplementsSoftnessScreen;
