import { useCallback, useEffect, useState } from 'react';

import * as APIClient from 'Services/APIClient';

const useGetApi = url => {
  const [state, setState] = useState({
    data: null,
    error: null,
    isLoading: true,
  });

  const callApi = useCallback(async () => {
    setState(prevState => ({
      ...prevState,
      error: null,
      isLoading: true,
    }));

    try {
      const data = await APIClient.getJson(url);

      setState(prevState => ({
        ...prevState,
        data,
        isLoading: false,
      }));
    } catch (error) {
      setState(prevState => ({
        ...prevState,
        error,
        isLoading: false,
      }));
    }
  }, [url]);

  useEffect(() => {
    callApi();
  }, [callApi]);

  return [state, callApi];
};

export default useGetApi;
