import { createSlice } from '@reduxjs/toolkit';

import AuthStatus from './constants/authStatuses';
import { fetchAuth, userSignout } from './thunks';

/*
 * INFO: using 'as' here because otherwise TS tightens the type to 'idle', which most of the time is fine
 * but not hte behavior we wants here.
 * see https://github.com/reduxjs/redux-toolkit/pull/827 for more informations
 */
const initialState = AuthStatus.IDLE as AuthStatus;

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // Kept for backward compat reasons
    authRequest: () => AuthStatus.LOADING,
    authSuccess: () => AuthStatus.AUTHENTICATED,
    authError: () => AuthStatus.ERROR,
    signupSuccess: () => AuthStatus.AUTHENTICATED,
    authFailure: () => AuthStatus.NOT_AUTHENTICATED,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAuth.pending, () => AuthStatus.LOADING)
      .addCase(fetchAuth.fulfilled, () => AuthStatus.AUTHENTICATED)
      .addCase(fetchAuth.rejected, (_, action) => {
        if (action?.payload === 'signout') {
          return AuthStatus.NOT_AUTHENTICATED;
        }
        return AuthStatus.ERROR;
      })

      .addCase(userSignout, () => AuthStatus.NOT_AUTHENTICATED);
  },
});

export const { reducer, actions } = authSlice;
