import { SKIN } from 'Apps/Feedback/constants/feedbackSections';
import {
  skincareImprovements,
  skincareNoConcernInImprovements,
  skincareNoImprovements,
  skincareRescue,
  skincareSomeImprovements,
} from 'Apps/Feedback/constants/feedbackSlugs';

import { feedbackCategories } from 'constants/feedback';

const skinRescue = {
  name: skincareRescue,
  route: '/feedback/skincare/skin-rescue',
  category: SKIN,
  feedbackCategory: feedbackCategories.SKINCARE,
  shouldBeIncluded: () => true,
  title: 'Is your skin less sensitive or reactive?',
  options: [
    { value: skincareImprovements, label: "Yes, it's balanced now" },
    { value: skincareSomeImprovements, label: 'Some, but could be better' },
    { value: skincareNoImprovements, label: "No, it's just as sensitive" },
    { value: skincareNoConcernInImprovements, label: "It wasn't a concern" },
  ],
  getInitialValue: answers => answers?.skin_rescue,
  getAnswers: ({ selected }) => ({ skin_rescue: selected }),
};

export default skinRescue;
