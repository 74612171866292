import { createSlice } from '@reduxjs/toolkit';

import type AuthStatus from '../auth/constants/authStatuses';
import Status from '../auth/constants/authStatuses';
import { guestUserSignup } from './thunks';

const guestAuthSlice = createSlice({
  name: 'guestAuth',
  initialState: Status.IDLE as AuthStatus,
  reducers: {
    setGuestAuthenticated: () => Status.AUTHENTICATED,
  },
  extraReducers: (builder) => {
    builder
      .addCase(guestUserSignup.pending, () => Status.LOADING)
      .addCase(guestUserSignup.fulfilled, () => Status.AUTHENTICATED)
      .addCase(guestUserSignup.rejected, (_, action) => {
        if (action?.payload === 'signout') {
          return Status.NOT_AUTHENTICATED;
        }
        return Status.ERROR;
      });
  },
});

export const { reducer, actions } = guestAuthSlice;
