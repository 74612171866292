import { createSlice } from '@reduxjs/toolkit';

import { userSignout } from 'dux/auth/actions';

import { fetchGiftWithPurchaseList } from './thunks';

export type GiftWithPurchase = {
  pubkey: string;
  name: string;
  offered_variant_type: string;
  label: string;
  picture_name: string;
  context_description: string;
  promotion_title: string;
  promotion_description: string;
  banner_text: string;
  cart_banner_text: string;
  cart_condition_text: string;
  banner_color: string | null;
  banner_redirection_url: string;
  lp_title: string;
  lp_url: string;
  lp_legal_text: string;
  lp_gift_description: string;
  lp_cta_text: string;
  lp_cta_url: string;
  lp_images: { mobile: string; tablet: string; desktop: string } | null;
  lp_copy_color: string | null;
  should_show_banner: boolean;
};

type GiftWithPurchaseState = {
  list: Array<GiftWithPurchase> | null;
  isLoading: boolean;
  error: boolean;
};

const initialState: GiftWithPurchaseState = {
  list: null,
  isLoading: false,
  error: false,
};

const giftWithPurchaseSlice = createSlice({
  name: 'giftWithPurchase',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // reinitialize state upon signout
      .addCase(userSignout, () => initialState)

      .addCase(fetchGiftWithPurchaseList.pending, (draft) => {
        draft.isLoading = true;
        draft.error = false;
      })
      .addCase(fetchGiftWithPurchaseList.fulfilled, (draft, { payload }) => {
        draft.isLoading = false;
        draft.error = false;
        draft.list = payload;
      })
      .addCase(fetchGiftWithPurchaseList.rejected, (draft) => {
        draft.error = true;
      });
  },
});

export const { reducer } = giftWithPurchaseSlice;
