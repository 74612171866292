import { combineReducers, createSlice } from '@reduxjs/toolkit';

import { initialApiState } from 'utils/reduxUtils';

import { userSignout } from 'dux/auth/thunks';

import {
  buffer,
  fetchAggressors,
  fetchFragrances,
  fetchHaircareIncis,
  fetchHaircareIngredients,
  fetchHairProfile,
  fetchHairProfileProductVariantBySlug,
  fetchRecommendationBySlug,
  fetchScoring,
  fetchSkincareFragrances,
  fetchSkincareIncis,
  fetchSkincareIngredients,
  fetchSkincareScoring,
  fetchSkincareTextures,
  initializeConsultation,
  saveAnswer,
} from './thunks';

const initialAnwersState = {
  data: null,
  didInvalidate: false,
  isSaving: false,
  isLoading: false,
  error: null,
  emailCaptureExitConsultationModalOpened: false,
  firstHaircareQuestion: null,
};

const answersSlice = createSlice({
  name: 'answers',
  initialState: initialAnwersState,
  reducers: {
    invalidateConsultation(draft) {
      draft.didInvalidate = true;
    },
    setEmailCaptureExitConsultationModalOpened(draft, action) {
      draft.emailCaptureExitConsultationModalOpened = action.payload;
    },
  },
  extraReducers: builder => {
    // reset state on user signout
    builder.addCase(userSignout, () => initialAnwersState);

    builder.addCase(saveAnswer.pending, draft => {
      draft.isSaving = true;
      draft.error = null;
    });
    builder.addCase(saveAnswer.fulfilled, (draft, action) => {
      draft.isSaving = false;
      draft.error = null;
      draft.data = {
        ...draft.data,
        ...action.payload,
      };
    });

    builder.addCase(initializeConsultation.pending, draft => ({
      ...initialAnwersState,
      emailCaptureExitConsultationModalOpened: draft.emailCaptureExitConsultationModalOpened,
      isLoading: true,
    }));
    builder.addCase(initializeConsultation.fulfilled, (draft, action) => {
      draft.isLoading = false;
      draft.data = action.payload;
    });
    builder.addCase(initializeConsultation.rejected, (draft, action) => {
      draft.isLoading = false;
      draft.isSaving = false;
      draft.error = action.error;
    });

    builder.addCase(fetchHairProfile.pending, draft => ({
      ...initialAnwersState,
      emailCaptureExitConsultationModalOpened: draft.emailCaptureExitConsultationModalOpened,
      isLoading: true,
    }));
    builder.addCase(fetchHairProfile.fulfilled, (draft, action) => {
      draft.isLoading = false;
      draft.data = action.payload;
    });
    builder.addCase(fetchHairProfile.rejected, (draft, action) => {
      draft.isLoading = false;
      draft.isSaving = false;
      draft.error = action.error;
    });
  },
});

const { reducer: answers, actions: answersSliceActions } = answersSlice;

export const answersActions = answersSliceActions;

const bufferedAnswersSlice = createSlice({
  name: 'bufferedAnswers',
  initialState: {},
  reducers: {
    clearAnswerBuffer(draft) {
      draft.bufferedData = null;
    },
  },
  extraReducers: builder => {
    // reset state on user signout
    builder.addCase(userSignout, () => ({}));

    builder.addCase(buffer.fulfilled, (draft, action) => {
      draft.bufferedData = { ...draft.bufferedData, ...action.payload };
    });

    builder.addCase(initializeConsultation.fulfilled, draft => {
      if (draft.bufferedData) {
        return {};
      }
      return draft;
    });
  },
});

const { reducer: bufferedAnswers, actions: bufferedAnswersSliceActions } = bufferedAnswersSlice;

export const bufferedAnswersActions = bufferedAnswersSliceActions;

const aggressorsSlice = createSlice({
  name: 'aggressors',
  initialState: initialApiState,
  reducers: {},
  extraReducers: builder => {
    // reset state on user signout
    builder.addCase(userSignout, () => initialApiState);

    builder.addCase(initializeConsultation.pending, () => initialApiState);
    builder.addCase(fetchHairProfile.pending, () => initialApiState);
    builder.addCase(saveAnswer.fulfilled, () => initialApiState);

    builder.addCase(fetchAggressors.pending, draft => {
      draft.status = 'loading';
      draft.error = null;
    });
    builder.addCase(fetchAggressors.fulfilled, (draft, action) => {
      draft.status = 'success';
      draft.data = action.payload;
    });
    builder.addCase(fetchAggressors.rejected, (draft, action) => {
      draft.status = 'error';
      draft.error = { code: action.payload.code, detail: action.payload.detail };
    });
  },
});

const { reducer: aggressors } = aggressorsSlice;

const fragrancesSlice = createSlice({
  name: 'fragrances',
  initialState: initialApiState,
  reducers: {},
  extraReducers: builder => {
    // reset state on user signout
    builder.addCase(userSignout, () => initialApiState);

    builder.addCase(initializeConsultation.pending, () => initialApiState);
    builder.addCase(fetchHairProfile.pending, () => initialApiState);
    builder.addCase(saveAnswer.fulfilled, (draft, action) => {
      if (
        action?.meta?.arg?.payload?.pref_fragrance ||
        action?.meta?.arg?.payload?.pref_fragrance_free
      ) {
        return draft;
      }
      return initialApiState;
    });

    builder.addCase(fetchFragrances.pending, draft => {
      draft.status = 'loading';
      draft.error = null;
    });
    builder.addCase(fetchFragrances.fulfilled, (draft, action) => {
      draft.status = 'success';
      draft.data = action.payload;
    });
    builder.addCase(fetchFragrances.rejected, (draft, action) => {
      draft.status = 'error';
      draft.error = action.error;
    });
  },
});

const { reducer: fragrances } = fragrancesSlice;

const skincareFragrancesSlice = createSlice({
  name: 'skincareFragrances',
  initialState: initialApiState,
  reducers: {},
  extraReducers: builder => {
    // reset state on user signout
    builder.addCase(userSignout, () => initialApiState);

    builder.addCase(initializeConsultation.pending, () => {
      return initialApiState;
    });

    builder.addCase(fetchSkincareFragrances.pending, draft => {
      draft.status = 'loading';
      draft.error = null;
    });
    builder.addCase(fetchSkincareFragrances.fulfilled, (draft, action) => {
      draft.status = 'success';
      draft.data = action.payload;
    });
    builder.addCase(fetchSkincareFragrances.rejected, (draft, action) => {
      draft.status = 'error';
      draft.error = action.error;
    });
  },
});

const { reducer: skincareFragrances } = skincareFragrancesSlice;

const skincareTexturesSlice = createSlice({
  name: 'skincareTextures',
  initialState: initialApiState,
  reducers: {},
  extraReducers: builder => {
    // reset state on user signout
    builder.addCase(userSignout, () => initialApiState);

    builder.addCase(initializeConsultation.pending, () => {
      return initialApiState;
    });

    builder.addCase(fetchSkincareTextures.pending, draft => {
      draft.status = 'loading';
      draft.error = null;
    });
    builder.addCase(fetchSkincareTextures.fulfilled, (draft, action) => {
      draft.status = 'success';
      draft.data = action.payload;
    });
    builder.addCase(fetchSkincareTextures.rejected, (draft, action) => {
      draft.status = 'error';
      draft.error = action.error;
    });
  },
});

const { reducer: skincareTextures } = skincareTexturesSlice;

const ingredientsSlice = createSlice({
  name: 'ingredients',
  initialState: initialApiState,
  reducers: {},
  extraReducers: builder => {
    // reset state on user signout
    builder.addCase(userSignout, () => initialApiState);

    builder.addCase(initializeConsultation.pending, () => initialApiState);
    builder.addCase(fetchHairProfile.pending, () => initialApiState);
    builder.addCase(saveAnswer.fulfilled, () => initialApiState);

    builder.addCase(fetchHaircareIngredients.pending, draft => {
      draft.status = 'loading';
      draft.error = null;
    });
    builder.addCase(fetchHaircareIngredients.fulfilled, (draft, action) => {
      draft.status = 'success';
      draft.data = action.payload;
    });
    builder.addCase(fetchHaircareIngredients.rejected, (draft, action) => {
      draft.status = 'error';
      draft.error = action.error;
    });
  },
});

const { reducer: ingredients } = ingredientsSlice;

const skincareIngredientsSlice = createSlice({
  name: 'skincareIngredients',
  initialState: initialApiState,
  reducers: {},
  extraReducers: builder => {
    // reset state on user signout
    builder.addCase(userSignout, () => initialApiState);

    builder.addCase(initializeConsultation.pending, () => initialApiState);
    builder.addCase(fetchHairProfile.pending, () => initialApiState);
    builder.addCase(saveAnswer.fulfilled, () => initialApiState);

    builder.addCase(fetchSkincareIngredients.pending, draft => {
      draft.status = 'loading';
      draft.error = null;
    });
    builder.addCase(fetchSkincareIngredients.fulfilled, (draft, action) => {
      draft.status = 'success';
      draft.data = action.payload;
    });
    builder.addCase(fetchSkincareIngredients.rejected, (draft, action) => {
      draft.status = 'error';
      draft.error = action.error;
    });
  },
});

const { reducer: skincareIngredients } = skincareIngredientsSlice;

const skincareIncisSlice = createSlice({
  name: 'skincareIncis',
  initialState: initialApiState,
  reducers: {},
  extraReducers: builder => {
    // reset state on user signout
    builder.addCase(userSignout, () => initialApiState);

    builder.addCase(initializeConsultation.pending, () => initialApiState);
    builder.addCase(fetchHairProfile.pending, () => initialApiState);
    builder.addCase(saveAnswer.fulfilled, () => initialApiState);

    builder.addCase(fetchSkincareIncis.pending, draft => {
      draft.status = 'loading';
      draft.error = null;
    });
    builder.addCase(fetchSkincareIncis.fulfilled, (draft, action) => {
      draft.status = 'success';
      draft.data = action.payload;
    });
    builder.addCase(fetchSkincareIncis.rejected, (draft, action) => {
      draft.status = 'error';
      draft.error = action.error;
    });
  },
});

const { reducer: skincareIncis } = skincareIncisSlice;

const haircareIncisSlice = createSlice({
  name: 'haircareIncis',
  initialState: initialApiState,
  reducers: {},
  extraReducers: builder => {
    // reset state on user signout
    builder.addCase(userSignout, () => initialApiState);

    builder.addCase(initializeConsultation.pending, () => initialApiState);
    builder.addCase(fetchHairProfile.pending, () => initialApiState);
    builder.addCase(saveAnswer.fulfilled, () => initialApiState);

    builder.addCase(fetchHaircareIncis.pending, draft => {
      draft.status = 'loading';
      draft.error = null;
    });
    builder.addCase(fetchHaircareIncis.fulfilled, (draft, action) => {
      draft.status = 'success';
      draft.data = action.payload;
    });
    builder.addCase(fetchHaircareIncis.rejected, (draft, action) => {
      draft.status = 'error';
      draft.error = action.error;
    });
  },
});

const { reducer: haircareIncis } = haircareIncisSlice;

const scoringSlice = createSlice({
  name: 'scoring',
  initialState: initialApiState,
  reducers: {},
  extraReducers: builder => {
    // reset state on user signout
    builder.addCase(userSignout, () => initialApiState);

    builder.addCase(initializeConsultation.pending, () => initialApiState);
    builder.addCase(fetchHairProfile.pending, () => initialApiState);
    builder.addCase(saveAnswer.fulfilled, () => initialApiState);

    builder.addCase(fetchScoring.pending, draft => {
      draft.status = 'loading';
      draft.error = null;
    });
    builder.addCase(fetchScoring.fulfilled, (draft, action) => {
      draft.status = 'success';
      draft.data = action.payload;
    });
    builder.addCase(fetchScoring.rejected, (draft, action) => {
      draft.status = 'error';
      draft.error = action.error;
    });
  },
});

const { reducer: scoring } = scoringSlice;

const skincareScoringSlice = createSlice({
  name: 'skincareScoring',
  initialState: initialApiState,
  reducers: {},
  extraReducers: builder => {
    // reset state on user signout
    builder.addCase(userSignout, () => initialApiState);

    builder.addCase(initializeConsultation.pending, () => initialApiState);
    builder.addCase(fetchHairProfile.pending, () => initialApiState);
    builder.addCase(saveAnswer.fulfilled, () => initialApiState);

    builder.addCase(fetchSkincareScoring.pending, draft => {
      draft.status = 'loading';
      draft.error = null;
    });
    builder.addCase(fetchSkincareScoring.fulfilled, (draft, action) => {
      draft.status = 'success';
      draft.data = action.payload;
    });
    builder.addCase(fetchSkincareScoring.rejected, (draft, action) => {
      draft.status = 'error';
      draft.error = action.error;
    });
  },
});

const { reducer: skincareScoring } = skincareScoringSlice;

const productsVariantsSlice = createSlice({
  name: 'productsVariants',
  initialState: initialApiState,
  reducers: {},
  extraReducers: builder => {
    // reset state on user signout
    builder.addCase(userSignout, () => initialApiState);

    builder.addCase(fetchHairProfileProductVariantBySlug.pending, draft => {
      draft.status = 'loading';
      draft.error = null;
    });
    builder.addCase(fetchHairProfileProductVariantBySlug.fulfilled, (draft, action) => {
      draft.status = 'success';
      draft.data = { ...draft.data, [action.meta.arg]: action.payload };
    });
    builder.addCase(fetchHairProfileProductVariantBySlug.rejected, (draft, action) => {
      draft.status = 'error';
      draft.error = action.error;
    });

    builder.addCase(initializeConsultation.pending, () => initialApiState);
    builder.addCase(fetchHairProfile.pending, () => initialApiState);
    builder.addCase(saveAnswer.fulfilled, () => initialApiState);
  },
});

const { reducer: productsVariants } = productsVariantsSlice;

const recommendationsSlice = createSlice({
  name: 'recommendations',
  initialState: initialApiState,
  reducers: {},
  extraReducers: builder => {
    // reset state on user signout
    builder.addCase(userSignout, () => initialApiState);

    builder.addCase(initializeConsultation.pending, () => initialApiState);
    builder.addCase(fetchHairProfile.pending, () => initialApiState);
    builder.addCase(saveAnswer.fulfilled, () => initialApiState);

    builder.addCase(fetchRecommendationBySlug.pending, draft => {
      draft.status = 'loading';
      draft.error = null;
    });
    builder.addCase(fetchRecommendationBySlug.fulfilled, (draft, action) => {
      draft.status = 'success';
      draft.data = { ...draft.data, [action.meta.arg]: action.payload };
    });
    builder.addCase(fetchRecommendationBySlug.rejected, (draft, action) => {
      draft.status = 'error';
      draft.error = action.error;
    });
  },
});

const { reducer: recommendations } = recommendationsSlice;

const originSlice = createSlice({
  name: 'origin',
  initialState: { startedFrom: null },
  reducers: {},
  extraReducers: builder => {
    // reset state on user signout
    builder.addCase(userSignout, () => ({ startedFrom: null }));

    builder.addCase(initializeConsultation.pending, (draft, action) => {
      draft.startedFrom = action.meta.arg.dataFrom;
    });
  },
});

const { reducer: origin } = originSlice;

const consultationRoutesSlice = createSlice({
  name: 'consultationRoutes',
  initialState: initialAnwersState,
  reducers: {
    setFirstHaircareConsultationQuestionRoute: (draft, action) => {
      draft.firstHaircareQuestion = action.payload;
    },
    setFirstSkincareConsultationQuestionRoute: (draft, action) => {
      draft.firstSkincareQuestion = action.payload;
    },
  },
});

const { actions: consultationRoutesActionsSlice, reducer: consultationRoutes } =
  consultationRoutesSlice;

export const consultationRoutesActions = consultationRoutesActionsSlice;

export default combineReducers({
  answers,
  bufferedAnswers,
  consultationRoutes,
  aggressors,
  fragrances,
  skincareTextures,
  skincareFragrances,
  ingredients,
  skincareIngredients,
  skincareIncis,
  haircareIncis,
  scoring,
  skincareScoring,
  productsVariants,
  recommendations,
  origin,
});
