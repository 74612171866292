import { createSlice } from '@reduxjs/toolkit';

import * as Status from 'constants/statuses';

import * as forecastStatuses from './constants/forecastStatuses';
import * as snoozeStatuses from './constants/snoozeStatuses';
import {
  cancel,
  create,
  createPreviewByPubkey,
  createShipNowOrder,
  createSubscriptionPreview,
  fetchSubscriptionForecast,
  fetchSubscriptionLogReasons,
  fetchSubscriptions,
  fetchSubscriptionsRetentionInfos,
  snooze,
  updateSubscription,
} from './thunks';

const initialState = {
  status: Status.IDLE,
  snoozeError: null,
  subscriptions: [],
  subscriptionLogReasons: [],
  subscriptionLogReasonStatus: Status.IDLE,
  subscriptionForecast: [],
  subscriptionForecastStatus: forecastStatuses.NOT_AVAILABLE,
  subscriptionTicket: null,
  subscriptionCategoryWithMissingConsultation: null,
  cancelSubscriptionStatus: Status.IDLE,
  retention: null,
  retentionStatus: Status.IDLE,
  retentionError: null,
  shipNowOrder: {
    status: Status.IDLE,
    pubkey: null,
    error: null,
  },
};

const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    /**
     * Redirection
     * If the user hasn't finished the consultation of a specific category
     * We redirect him to this consultation.
     * At the end of this consultation, he'll be redirected to the subscription page.
     */
    onUpsellSubscriptionClick(draft, action) {
      if (!action.payload.hasAnsweredConsultationQuestions) {
        draft.subscriptionCategoryWithMissingConsultation = action.payload.category;
      }
    },
    resetShipNowOrderState(draft) {
      draft.shipNowOrder.status = Status.IDLE;
      draft.shipNowOrder.pubkey = null;
      draft.shipNowOrder.error = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(cancel.pending, draft => {
        draft.cancelSubscriptionStatus = Status.LOADING;
      })
      .addCase(cancel.fulfilled, (draft, { payload }) => {
        draft.cancelSubscriptionStatus = Status.SUCCESS;
        const index = draft.subscriptions.findIndex(sub => sub.pubkey === payload?.pubkey);
        if (index !== -1) {
          draft.subscriptions[index] = payload;
        }
      })
      .addCase(cancel.rejected, draft => {
        draft.cancelSubscriptionStatus = Status.ERROR;
      })

      .addCase(create.pending, draft => {
        draft.status = Status.LOADING;
      })
      .addCase(create.fulfilled, (draft, { payload }) => {
        draft.status = Status.SUCCESS;
        draft.subscriptions.unshift(payload);
      })
      .addCase(create.rejected, draft => {
        draft.status = Status.ERROR;
      })

      .addCase(createSubscriptionPreview.fulfilled, (draft, { payload }) => {
        draft.subscriptionTicket = payload.ticket;
        draft.subscriptionForecast = payload.forecast;
        draft.subscriptionForecastStatus = forecastStatuses.AVAILABLE;
      })

      .addCase(createPreviewByPubkey.fulfilled, (draft, { payload }) => {
        draft.subscriptionTicket = payload.ticket;
        draft.subscriptionForecast = payload.forecast;
        draft.subscriptionForecastStatus = forecastStatuses.AVAILABLE;
      })

      .addCase(fetchSubscriptionForecast.pending, draft => {
        draft.subscriptionForecastStatus = forecastStatuses.NOT_AVAILABLE;
        draft.subscriptionForecast = [];
      })
      .addCase(fetchSubscriptionForecast.fulfilled, (draft, { payload }) => {
        draft.subscriptionForecastStatus = forecastStatuses.AVAILABLE;
        draft.subscriptionForecast = payload;
      })
      .addCase(fetchSubscriptionForecast.rejected, draft => {
        draft.subscriptionForecastStatus = forecastStatuses.NOT_AVAILABLE;
      })

      .addCase(fetchSubscriptionLogReasons.pending, draft => {
        draft.subscriptionLogReasonStatus = Status.LOADING;
      })
      .addCase(fetchSubscriptionLogReasons.fulfilled, (draft, { payload }) => {
        draft.subscriptionLogReasonStatus = Status.SUCCESS;
        draft.subscriptionLogReasons = payload;
      })
      .addCase(fetchSubscriptionLogReasons.rejected, draft => {
        draft.subscriptionLogReasonStatus = Status.ERROR;
      })

      .addCase(fetchSubscriptions.pending, draft => {
        draft.status = Status.LOADING;
        // Clear the current ticket values updated by the preview mode
        draft.subscriptionTicket = null;
      })
      .addCase(fetchSubscriptions.fulfilled, (draft, { payload }) => {
        draft.status = Status.SUCCESS;
        draft.subscriptions = payload;
      })
      .addCase(fetchSubscriptions.rejected, draft => {
        draft.status = Status.ERROR;
      })

      .addCase(snooze.pending, draft => {
        draft.status = snoozeStatuses.SNOOZING;
        draft.snoozeError = null;
      })
      .addCase(snooze.fulfilled, (draft, { payload }) => {
        draft.status = Status.SUCCESS;
        const index = draft.subscriptions.findIndex(sub => sub.pubkey === payload?.pubkey);
        if (index !== -1) {
          draft.subscriptions[index] = payload;
        }
      })
      .addCase(snooze.rejected, (draft, action) => {
        if (!action?.payload) {
          // Snooze failure don't invalidate subscription data
          draft.status = Status.SUCCESS;
          // Message to show in the UI
          draft.snoozeError = action.error.message;
        } else {
          draft.status = Status.ERROR;
        }
      })

      .addCase(updateSubscription.fulfilled, (draft, action) => {
        draft.status = Status.SUCCESS;
        const index = draft.subscriptions.findIndex(sub => sub.pubkey === action.payload.pubkey);
        if (index !== -1) {
          draft.subscriptions[index] = action.payload;
        }
      })

      .addCase(fetchSubscriptionsRetentionInfos.pending, draft => {
        draft.retentionStatus = Status.LOADING;
      })
      .addCase(fetchSubscriptionsRetentionInfos.fulfilled, (draft, action) => {
        draft.retentionStatus = Status.SUCCESS;
        draft.retention = action.payload;
        draft.retentionError = null;
      })
      .addCase(fetchSubscriptionsRetentionInfos.rejected, (draft, action) => {
        draft.retentionStatus = Status.ERROR;
        draft.retentionError = action.error;
      })

      .addCase(createShipNowOrder.pending, draft => {
        draft.shipNowOrder.status = Status.LOADING;
        draft.shipNowOrder.pubkey = null;
        draft.shipNowOrder.error = null;
      })
      .addCase(createShipNowOrder.fulfilled, (draft, action) => {
        draft.shipNowOrder.status = Status.SUCCESS;
        draft.shipNowOrder.pubkey = action.payload.pubkey;
        draft.shipNowOrder.error = null;
      })
      .addCase(createShipNowOrder.rejected, (draft, action) => {
        draft.shipNowOrder.status = Status.ERROR;
        draft.shipNowOrder.pubkey = null;
        draft.shipNowOrder.error = action.payload;
      });
  },
});

export const { reducer, actions } = subscriptionsSlice;
