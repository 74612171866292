import { useAppSelector } from 'dux/app/hooks';

import { theme } from '@prose-ui';
import { useTrialOfferPromotionModalContent } from 'hooks/useRedeemCouponForTrialOffer';

import OfferCheckoutCommunication from 'Apps/Checkout/Blocks/OfferCheckoutCommunication';

import Spacer from 'Components/Spacer';

import { useSssShippingFeeAbTest } from 'abTesting/sssShippingFee/hook';

import { type Currency } from 'constants/currencies';
import { productsCategories } from 'constants/products';

import {
  skincareMinisWithoutShippingFee,
  skincareMinisWithShippingFee,
} from 'assets/content/skincareMinisModal';

import { getHasSkincareMinisInCatalog } from 'dux/checkoutCart/selectors';
import * as featureFlagSelectors from 'dux/featureFlags/selectors';
import {
  getHasHaircareSubscriptionInAnyState,
  getHasSkincareSubscriptionInAnyState,
  getUserCurrency,
} from 'dux/user/selectors';
import { VARIANT1 } from 'dux/featureFlags/constants/flagVariants';

import { useCheckoutCategory } from '../hooks/useCheckoutCategory';

const CartPromoCommunication = () => {
  const checkoutCategory = useCheckoutCategory();

  const { variantName: sssShippingFeeAbTestVariantName } = useSssShippingFeeAbTest();

  const skincareMinisContent =
    sssShippingFeeAbTestVariantName === VARIANT1
      ? skincareMinisWithShippingFee
      : skincareMinisWithoutShippingFee;

  const userCurrency: Currency = useAppSelector(getUserCurrency);

  const hasSkincareSubscriptionInAnyState = useAppSelector(getHasSkincareSubscriptionInAnyState);
  const hasSkincareMinisInCatalog = useAppSelector(getHasSkincareMinisInCatalog);
  const hasHaircareSubscriptionInAnyState = useAppSelector(getHasHaircareSubscriptionInAnyState);

  const showTrialOffer = useAppSelector(featureFlagSelectors.shouldShowTrialOffer);
  const { content: trialOfferContent, variant } = useTrialOfferPromotionModalContent();
  const show50DiscountMessage =
    showTrialOffer &&
    ((checkoutCategory === productsCategories.HAIRCARE && !hasHaircareSubscriptionInAnyState) ||
      (checkoutCategory === productsCategories.SKINCARE && !hasSkincareSubscriptionInAnyState));

  return (
    <>
      {hasSkincareMinisInCatalog && (
        <>
          <Spacer size={14} />
          <OfferCheckoutCommunication
            backgroundColor={theme.colors.highlight[200]}
            checkoutCommunicationContent={skincareMinisContent.checkoutCommunication[userCurrency]}
            dataTestId="checkout-communication-skincare-minis"
            modalContent={skincareMinisContent.bottomModal[userCurrency]}
            padding="32px 32px"
            testPrefix="bottom-modal-skincare-minis"
          />
        </>
      )}
      {!hasSkincareMinisInCatalog && show50DiscountMessage && (
        <>
          <Spacer size={14} />
          <OfferCheckoutCommunication
            checkoutCommunicationContent={trialOfferContent.checkoutCommunication}
            dataTestId={`checkout-communication-trial-offer-${variant}`}
            modalContent={trialOfferContent.bottomModal}
            padding="32px 32px"
            testPrefix={`bottom-modal-trial-offer-${variant}`}
          />
        </>
      )}
    </>
  );
};

export default CartPromoCommunication;
