import {
  ADDRESS_VALIDATION_CHOICE,
  ADDRESS_VALIDATION_CHOICE_RESET,
  ADDRESS_VALIDATION_INVALID_FIELDS,
  ADDRESS_VALIDATION_RECOMMENDED_FIELDS,
} from 'dux/checkoutAddressesValidation/actionTypes';

const addressValidationInitialState = {
  addressChoice: null,
  addressInvalidFields: null,
  addressRecommendedFields: null,
};

export const addressValidation = (state = addressValidationInitialState, action) => {
  switch (action.type) {
    case ADDRESS_VALIDATION_CHOICE:
      return {
        ...state,
        addressChoice: action.data,
      };
    case ADDRESS_VALIDATION_INVALID_FIELDS:
      return {
        ...state,
        addressInvalidFields: action.data,
      };
    case ADDRESS_VALIDATION_RECOMMENDED_FIELDS:
      return {
        ...state,
        addressRecommendedFields: action.data,
      };
    case ADDRESS_VALIDATION_CHOICE_RESET:
      return {
        ...addressValidationInitialState,
      };
    default:
      return state;
  }
};
