import {
  supplementsFromTwoMonths,
  supplementsLessShedding,
  supplementsLittleImprovements,
  supplementsLotsOfImprovements,
  supplementsNoConcernInImprovements,
  supplementsNoImprovements,
  supplementsSomeImprovements,
} from 'Apps/Feedback/constants/feedbackSlugs';

const supplementsLessSheddingScreen = {
  name: supplementsLessShedding,
  route: `/feedback/supplements/${supplementsLessShedding}`,
  shouldBeIncluded: ({ feedbackAnswers }) =>
    feedbackAnswers &&
    supplementsFromTwoMonths.includes(feedbackAnswers.product_supplements_usage_duration),
  title: 'Have you noticed less hair shedding?',
  subtitle:
    'Do you notice you’re losing less hair when washing, brushing, or styling? You can check your brush (or drain) for reference.',
  options: [
    { value: supplementsLotsOfImprovements, label: 'Yes, lots of improvement' },
    { value: supplementsSomeImprovements, label: 'Yes, some improvement' },
    { value: supplementsLittleImprovements, label: 'Yes, maybe a little' },
    { value: supplementsNoImprovements, label: 'Not yet' },
    { value: supplementsNoConcernInImprovements, label: 'No, but it wasn’t a concern' },
  ],
  getInitialValue: answers => answers?.product_supplements_less_shedding,
  getAnswers: ({ selected }) => ({ product_supplements_less_shedding: selected }),
};

export default supplementsLessSheddingScreen;
