import { PRODUCTS } from 'Apps/Feedback/constants/feedbackSections';
import {
  haircareHairOilFrizz,
  haircareImprovements,
  haircareNoConcernInImprovements,
  haircareNoImprovements,
  haircareSomeImprovements,
} from 'Apps/Feedback/constants/feedbackSlugs';

import { productsSlugs } from 'constants/products';

const hairOilFrizz = {
  name: haircareHairOilFrizz,
  route: `/feedback/haircare/${haircareHairOilFrizz}`,
  category: PRODUCTS,
  shouldBeIncluded: ({ formulas, feedbackAnswers }) =>
    formulas?.find(f => f.variant.product.type === productsSlugs.OIL) &&
    feedbackAnswers?.product_hair_oil_satisfaction,
  title: 'Did you see less frizz after using your hair oil?',
  subtitle: 'You can also count flyaways and static',
  options: [
    { value: haircareImprovements, label: 'Yes, my hair is frizz-free & smooth' },
    { value: haircareSomeImprovements, label: 'Sort of, but not enough' },
    { value: haircareNoImprovements, label: 'No, still too much frizz' },
    { value: haircareNoConcernInImprovements, label: "It wasn't a concern" },
  ],
  getInitialValue: answers => answers?.product_hair_oil_frizz,
  getAnswers: ({ selected }) => ({ product_hair_oil_frizz: selected }),
};

export default hairOilFrizz;
