type PerksContentTitleProps = {
  title: string;
  variant:
    | 'onlyHaircareSubscription'
    | 'onlySkincareSubscription'
    | 'noSubscription'
    | 'bothSubscription';
  discount: number;
};

export const PerksContentTitle = ({ title, variant, discount }: PerksContentTitleProps) => {
  if (
    variant === 'noSubscription' ||
    variant === 'onlyHaircareSubscription' ||
    variant === 'onlySkincareSubscription'
  ) {
    const discountLabel = `${title[0]}${discount * 100}%`;
    return (
      <>
        <b>{discountLabel}</b> {title[1]}
      </>
    );
  }

  const discountLabel = `${title[1]}${discount * 100}%`;
  return (
    <>
      {title[0]} <b>{discountLabel}</b> {title[2]}
    </>
  );
};
