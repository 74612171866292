import { HAIR } from 'Apps/Feedback/constants/feedbackSections';
import {
  haircareHairDryness,
  haircareImprovements,
  haircareNoConcernInImprovements,
  haircareNoImprovements,
  haircareSomeImprovements,
} from 'Apps/Feedback/constants/feedbackSlugs';

import { productsSlugs } from 'constants/products';

const hairDryness = {
  name: haircareHairDryness,
  route: `/feedback/haircare/${haircareHairDryness}`,
  category: HAIR,
  shouldBeIncluded: ({ formulas }) =>
    formulas?.some(f =>
      [productsSlugs.HAIR_MASK, productsSlugs.SHAMPOO, productsSlugs.CONDITIONER].includes(
        f.variant.product.type
      )
    ),
  title: "Did you see improvements in your hair's dryness?",
  subtitle: 'Hair should feel perfectly moisturized',
  options: [
    { value: haircareImprovements, label: "Yes, it's well-hydrated" },
    { value: haircareSomeImprovements, label: 'Some, but could be better' },
    { value: haircareNoImprovements, label: "No, it's just as dry" },
    { value: haircareNoConcernInImprovements, label: "It wasn't a concern" },
  ],
  getInitialValue: answers => answers?.hair_dryness,
  getAnswers: ({ selected }) => ({ hair_dryness: selected }),
};

export default hairDryness;
