import { useState } from 'react';

import type { MouseEventHandler } from 'react';

import { Box, theme } from '@prose-ui';
import { styled } from '@prose-ui/legacy';
import { mediaMinWidth } from '@prose-ui/utils/media';
import type { ImageProps } from 'next/image';
import Image from 'next/image';

import HorizontalSlider from 'Components/HorizontalSlider';
import Spacer from 'Components/Spacer';

import navArrow from 'assets/images/digitalLeaflet/nav_arrow.svg';

import type { EnrichedOrderItem } from 'dux/digitalLeaflet/types';

import ProductDetails from './ProductDetails';

/**
 * Linearly interpolate/re-map a value between ranges (in px)
 * (Lerp = Linear Interpolation)
 * @example
 * // interpolate the value 25 from the range [0,50] to [100,200] === 150
 * cssLerp('25px', [0,50], [100,200]); // will return a css `calc(..)` that displays 150px to the user
 */
const cssLerp = (
  inputValue: string, // any valid css size value
  inputRange: [number, number], // the range associated with the INput value (only in `px` as a number for now)
  outputRange: [number, number], // the range associated with the OUTput value (only in `px` as a number for now)
  options: { clamped: boolean } = { clamped: false },
) => {
  const [inputStart, inputEnd] = inputRange;
  const [outputStart, outputEnd] = outputRange;

  const remappedValue = `${outputStart}px +
  ((${inputValue} - ${inputStart}px) * (${outputEnd} - ${outputStart})) /
    (${inputEnd} - ${inputStart}))`; // linear interpolation maths

  if (options.clamped) {
    const [smallerValue, biggerValue] =
      outputStart < outputEnd ? [outputStart, outputEnd] : [outputEnd, outputStart];
    const clampedValue = `min(${biggerValue}px, max(${smallerValue}px, ${remappedValue})`; // could not make css 'clamp' function work here for some reason
    return `calc(${clampedValue})`;
  }
  return `calc(${remappedValue})`;
};

const cssResponsiveLerp = (
  {
    mobile: mobileValue,
    desktop: desktopValue,
  }: {
    mobile: number;
    desktop: number;
  },
  options: { clamped: boolean } = { clamped: true },
) => {
  const mobileMockWidth = 375; // mobile frame width in Figma
  const desktopMockWidth = 1440; // desktop frame width in Figma

  return cssLerp('100vw', [mobileMockWidth, desktopMockWidth], [mobileValue, desktopValue], {
    clamped: options.clamped,
  });
};

const SliderContainer = styled.div`
  border-block: 1px solid ${theme.colors.neutral[900]};
`;

const ProductButtonContainer = styled.div<{ isSelected: boolean }>`
  width: ${cssResponsiveLerp({ mobile: 170, desktop: 226 })};
  height: ${cssResponsiveLerp({ mobile: 170, desktop: 284 })};
  display: grid;
  grid-template-rows: 1fr 0.45fr;
  grid-gap: ${theme.spacing['1x']};

  justify-items: center;

  --border-line-vertical: 0.5px solid ${theme.colors.neutral[700]};

  border-left: var(--border-line-vertical);
  &:last-of-type {
    border-right: var(--border-line-vertical);
  }

  transition: background 100ms;

  ${({ isSelected }) =>
    isSelected
      ? `
  cursor: unset;
  background: ${theme.colors.neutral[300]};`
      : `
  cursor: pointer;`}
`;

const StyledImage = styled(Image)`
  width: ${cssResponsiveLerp({ mobile: 96, desktop: 120 })};
  height: ${cssResponsiveLerp({ mobile: 104, desktop: 130 })};
  object-fit: contain;

  align-self: end;
`;

const ProductNameContainer = styled.div<{ isSelected: boolean }>`
  width: min-content;

  align-self: center;

  text-align: center;
  text-transform: uppercase;

  font-family: ${theme.typography.fontFamily.label};
  font-size: ${theme.typography.fontSize.xs};
  line-height: 1.5;
  transform: translateY(-0.15em); /* slightly bring up the text on mobile */

  ${mediaMinWidth('sm')} {
    transform: unset;
    font-size: ${theme.typography.fontSize.sm};
  }

  ${mediaMinWidth('md')} {
    font-size: ${theme.typography.fontSize.md};
  }

  transition: border 100ms;

  ${({ isSelected }) =>
    isSelected
      ? `
  border-bottom: 2px solid ${theme.colors.neutral[900]};`
      : `
  border-bottom: 2px solid transparent;`}
`;

const Arrow = styled.div<{ isActive: boolean }>`
  width: 44px;
  height: 44px;
  border-radius: 100vmax;

  background-image: url(${navArrow});
  background-repeat: no-repeat;
  background-size: 30px;
  background-position: center;

  position: absolute;
  top: 50%;

  cursor: pointer;

  ${({ isActive }) =>
    isActive
      ? `
  opacity: 1;
  transition: all 150ms;`
      : `
  opacity: 0.25;
  transition: none;
  pointer-events: none;`}

  &:hover {
    transition: all 150ms;
  }
`;

const ArrowPrevious = styled(Arrow)`
  left: 1.5vw;
  transform: translateY(-50%);

  &:hover {
    transform: translateY(-50%) scale(1.2);
  }
`;

const ArrowNext = styled(Arrow)`
  right: 1.5vw;
  transform: translateY(-50%) scale(-1);

  &:hover {
    transform: translateY(-50%) scale(-1.2);
  }
`;

type ProductsButtonProps = {
  displayName: string;
  image: ImageProps;
  isSelected: boolean;
  onClick: MouseEventHandler;
  slug: string;
};

const ProductButton = ({ displayName, image, isSelected, onClick, slug }: ProductsButtonProps) => (
  <ProductButtonContainer
    aria-label={displayName}
    data-testid={`${slug}-product-nav-button`}
    isSelected={isSelected}
    onClick={onClick}
    role="button"
  >
    <StyledImage alt="" src={image.src} />
    <ProductNameContainer isSelected={isSelected} role="presentation">
      {displayName}
    </ProductNameContainer>
  </ProductButtonContainer>
);

type ProductsSectionProps = {
  customerFirstName: string;
  orderItems: Array<EnrichedOrderItem>;
};

const ProductsSection = ({ customerFirstName, orderItems }: ProductsSectionProps) => {
  const [focusedIndex, setFocusedIndex] = useState(0);

  const focusedItem = orderItems.find((_v, i) => i === focusedIndex)!;

  return (
    <Box width="full">
      {orderItems.length > 1 /* no need for the navigation if only one product */ && (
        <>
          {/* navigation */}
          <SliderContainer aria-label="select a product from your order" role="tablist">
            <HorizontalSlider
              ArrowNext={ArrowNext}
              ArrowPrevious={ArrowPrevious}
              arrowsForceScroll
              forcedFocus={focusedIndex}
              slides={orderItems.map((orderItem, index) => (
                <ProductButton
                  key={orderItem.formula.type}
                  displayName={orderItem.navLabel}
                  image={orderItem.productImage}
                  isSelected={index === focusedIndex}
                  onClick={() => setFocusedIndex(index)}
                  slug={orderItem.formula.type}
                />
              ))}
            />
          </SliderContainer>
          <Spacer size={56} />
        </>
      )}
      {/* content */}
      <ProductDetails customerFirstName={customerFirstName} product={focusedItem} />
    </Box>
  );
};

export default ProductsSection;
