import { theme } from '@prose-ui';
import { styled } from '@prose-ui/legacy';

import { countriesFlags } from 'constants/countries';

import Typography from './Typography';

type RedeemedGiftInfoProps = {
  currency: 'USD' | 'CAD';
};

const RedeemedInfoWrapper = styled.div`
  display: flex;
  align-items: baseline;
  grid-column-gap: ${theme.spacing['2x']};
`;

const Flag = styled.img`
  width: ${theme.spacing['3x']};
  height: fit-content;
`;

const currencyTocountries = {
  USD: 'USA',
  CAD: 'Canada',
};

const redeemedDisclaimer = {
  USD: { flag: countriesFlags.US, country: currencyTocountries.USD },
  CAD: { flag: countriesFlags.CA, country: currencyTocountries.CAD },
};

export const RedeemedGiftInfo = ({ currency }: RedeemedGiftInfoProps) => (
  <RedeemedInfoWrapper>
    {/* @ts-expect-error - Typography has no TS types for now */}
    <Typography color="grey" variant="p4">
      Redeem in {redeemedDisclaimer[currency].country}
    </Typography>
    <Flag alt="redeemed-flag" src={redeemedDisclaimer[currency].flag.src} />
  </RedeemedInfoWrapper>
);
