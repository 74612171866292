import { PRODUCTS } from 'Apps/Feedback/constants/feedbackSections';
import {
  haircareCurlCreamFrizz,
  haircareImprovements,
  haircareNoConcernInImprovements,
  haircareNoImprovements,
  haircareSomeImprovements,
} from 'Apps/Feedback/constants/feedbackSlugs';

import { productsSlugs } from 'constants/products';

const curlCreamFrizz = {
  name: haircareCurlCreamFrizz,
  route: `/feedback/haircare/${haircareCurlCreamFrizz}`,
  category: PRODUCTS,
  shouldBeIncluded: ({ formulas, feedbackAnswers }) =>
    formulas?.find(f => f.variant.product.type === productsSlugs.CURL_CREAM) &&
    feedbackAnswers?.product_curl_cream_satisfaction,
  title: 'Did you see less frizz after using curl cream?',
  options: [
    { value: haircareImprovements, label: "Yes, I'm frizz-free" },
    { value: haircareSomeImprovements, label: 'Sort of, but could be better' },
    { value: haircareNoImprovements, label: 'No, still too much frizz' },
    { value: haircareNoConcernInImprovements, label: "It wasn't a concern" },
  ],
  getInitialValue: answers => answers?.product_curl_cream_frizz,
  getAnswers: ({ selected }) => ({ product_curl_cream_frizz: selected }),
};

export default curlCreamFrizz;
