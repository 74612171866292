import { createApi } from '@reduxjs/toolkit/query/react';

import customBaseQuery from 'Services/customBaseQuery';

type SkincareGoals = {
  goals: Array<string>;
};

const userApiSlice = createApi({
  reducerPath: 'userApi',
  baseQuery: customBaseQuery,
  endpoints: (build) => ({
    skincareGoalsQuery: build.query<SkincareGoals, void>({
      query: () => ({
        url: `v1/account/hair_profile/goals?category=skincare`,
        endpointName: 'user/skincareGoalsQuery',
      }),
      keepUnusedDataFor: 0, // no caching for now
    }),
  }),
});

export default userApiSlice;

export const { skincareGoalsQuery } = userApiSlice.endpoints;
