import { HAIR } from 'Apps/Feedback/constants/feedbackSections';
import {
  haircareHairFrizziness,
  haircareImprovements,
  haircareNoConcernInImprovements,
  haircareNoImprovements,
  haircareSomeImprovements,
} from 'Apps/Feedback/constants/feedbackSlugs';

import { productsSlugs } from 'constants/products';

const hairFrizziness = {
  name: haircareHairFrizziness,
  route: `/feedback/haircare/${haircareHairFrizziness}`,
  category: HAIR,
  shouldBeIncluded: ({ formulas }) =>
    formulas?.some(f =>
      [productsSlugs.HAIR_MASK, productsSlugs.SHAMPOO, productsSlugs.CONDITIONER].includes(
        f.variant.product.type
      )
    ),
  title: "Did you see improvements in your hair's frizziness?",
  subtitle: 'Flyaways and static count too',
  options: [
    { value: haircareImprovements, label: "Yes, I'm frizz-free" },
    { value: haircareSomeImprovements, label: 'Some, but could be better' },
    { value: haircareNoImprovements, label: 'No, I still have frizz' },
    { value: haircareNoConcernInImprovements, label: "It wasn't a concern" },
  ],
  getInitialValue: answers => answers?.hair_frizziness,
  getAnswers: ({ selected }) => ({ hair_frizziness: selected }),
};

export default hairFrizziness;
