import PropTypes from 'prop-types';

import { theme } from '@prose-ui';

const BackIcon = ({ className }) => (
  <svg
    aria-label="A simple left-facing arrow"
    className={className}
    height={14}
    role="img"
    width={17}
  >
    <title>back</title>
    <description>A simple left-facing arrow</description>
    <g fill="none" fillRule="evenodd" stroke={theme.colors.neutral['500']} strokeLinecap="square">
      <path d="M1.479 7.421l6.62-6.62M1.479 7.421l6.432 6.432M1.92 7.485h13.636" />
    </g>
  </svg>
);

BackIcon.propTypes = {
  className: PropTypes.string,
};

export default BackIcon;
