import { results } from 'Apps/Feedback/constants/feedbackSlugs';

import { feedbackCategories } from 'constants/feedback';

const skinResults = {
  name: results,
  shouldBeIncluded: () => true,
  route: `/feedback/${feedbackCategories.SKINCARE}/${results}`,
  component: 'SkincareFeedbackResults',
  skipSave: true,
  getAnswers: () => ({ status: 'completed' }),
};

export default skinResults;
