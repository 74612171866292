import { createABTestHook } from 'abTesting/hooks';
import { GrowthbookFakeExperimentExperiment } from 'abTesting/testConfigs';

import {
  getActiveOrVariantForGrowthbookFakeExperimentAbTest,
  isGrowthbookFakeExperimentAbTestInExperiment,
} from 'dux/featureFlags/selectors';

export const useGrowthbookFakeExperimentAbTest = createABTestHook({
  isInExperimentSelector: isGrowthbookFakeExperimentAbTestInExperiment,
  variantNameSelector: getActiveOrVariantForGrowthbookFakeExperimentAbTest,
  experimentConfig: GrowthbookFakeExperimentExperiment,
});
