import dayjs from 'dayjs';
import { getIn, setIn } from 'formik';

import { countriesCode } from 'constants/countries';

import { ADDRESS_REGEX, NAME_REGEX } from './validatorRegex';

// Regex from https://stackoverflow.com/questions/46155/whats-the-best-way-to-validate-an-email-address-in-javascript
const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isNotEmpty = value => value?.trim() !== '';
export const isValidEmail = email => EMAIL_REGEX.test(email);

export const isValidName = name => NAME_REGEX.test(name);
const isValidFullName = name => NAME_REGEX.test(name) || !isNotEmpty(name);
export const isValidAddress1 = address => ADDRESS_REGEX.test(address);
const isValidAddress2 = address => ADDRESS_REGEX.test(address) || !isNotEmpty(address);

const isValidGiftMessage = message => {
  const explodedMessage = message.split(/\r\n|\r|\n/);
  const lineNumbers = explodedMessage.reduce((acc, line) => {
    return acc + Math.ceil(line.length / 90);
  }, 0);
  return lineNumbers <= 4;
};
export const isValidPhone = phone => /^[(][0-9]{3}[)]\s[0-9]{3}[-][0-9]{4}$/.test(phone);
export const isValidState = state => /^[A-Z]{2}$/.test(state);
const isValidCountry = country => /^[A-Z]{2}$/.test(country);
export const isValidZipCode = zipcode => /^[0-9]{5}$/.test(zipcode);
export const isValidZipCodeByCountry = zipcode => ({
  [countriesCode.US]: /^[0-9]{5}$/.test(zipcode),
  [countriesCode.CA]: /^[A-Z][0-9][A-Z]\s?[0-9][A-Z][0-9]$/.test(zipcode),
});
const isNotInPast = date => {
  const now = dayjs();
  return date >= now.format('YYYY-MM-DD');
};

const isSameEmail = (email, object) => {
  return email !== object.email;
};

// add isValid name or firstname
const validationMessages = {
  email: 'Invalid email address',
  firstName: 'Invalid first name format',
  lastName: 'Invalid last name format',
  name: 'Invalid name format',
  giftMessage: 'You have exceeded the character count. Please shorten your gift message.',
  phone: 'Invalid phone number',
  required: 'Required',
  state: 'Must be two capital letters',
  zipcode: 'Must be 5 digits',
  dateIsNotInPast: 'Date must be in the future or today',
  notEmailGifter: "Sorry, you can't send gifts to yourself!",
};

const validationMessagesByCountry = {
  email: 'Invalid email address',
  address1: 'Invalid format address',
  address2: 'Invalid format address',
  fullName: 'Invalid name format',
  firstName: 'Invalid first name format',
  lastName: 'Invalid last name format',
  name: 'Invalid name format',
  giftMessage: 'You have exceeded the character count. Please shorten your gift message.',
  phone: 'Invalid phone number',
  required: 'Required',
  state: 'Must be two capital letters',
  zipcode: { US: 'Must be 5 digits', CA: 'Must be 6 digits' },
  dateIsNotInPast: 'Date must be in the future or today',
  notEmailGifter: "Sorry, you can't send gifts to yourself!",
};

const validatorByType = {
  email: isValidEmail,
  firstName: isValidName,
  lastName: isValidName,
  giftMessage: isValidGiftMessage,
  name: isValidName,
  phone: isValidPhone,
  required: isNotEmpty,
  state: isValidState,
  zipcode: isValidZipCode,
  dateIsNotInPast: isNotInPast,
  notEmailGifter: isSameEmail,
};

const validatorByTypeByCountry = {
  email: isValidEmail,
  firstName: isValidName,
  lastName: isValidName,
  name: isValidName,
  fullName: isValidFullName,
  giftMessage: isValidGiftMessage,
  phone: isValidPhone,
  required: isNotEmpty,
  state: isValidState,
  country: isValidCountry,
  zipcode: isValidZipCodeByCountry,
  dateIsNotInPast: isNotInPast,
  notEmailGifter: isSameEmail,
  address1: isValidAddress1,
  address2: isValidAddress2,
};

// validates an object and returns an error object for formik
//
// validationSteps = array of shape [path, validatorType]
export const makeValidations = (
  formValues,
  validationSteps = [],
  messages = validationMessages
) => {
  const stepReducer = (error, [path, validatorType]) => {
    const checkValidity = validatorByType[validatorType];
    const msg = messages[validatorType];
    // if field has error from previous text entry, bypass
    if (getIn(error, path) || checkValidity(getIn(formValues, path), formValues)) {
      return error;
    }
    return setIn(error, path, msg);
  };

  return validationSteps.reduce(stepReducer, {});
};

export const makeValidationsByCountry = (
  object,
  steps = [],
  messages = validationMessagesByCountry
) => {
  const stepReducer = (error, [path, validatorType]) => {
    // The path is different between shipping address in checkout and shipping address in account
    const countryValue = object.newAddress?.country || object.country;
    // We have to use a condition here because of the country zipcode difference
    const isZipCodeStep =
      (path === 'newAddress.zipcode' || path === 'zipcode') && validatorType === 'zipcode';
    const checkValidity = validatorByTypeByCountry[validatorType];

    const msg = isZipCodeStep ? messages[validatorType][countryValue] : messages[validatorType];

    // if field already with error, bypass
    if (
      getIn(error, path) ||
      (isZipCodeStep
        ? checkValidity(getIn(object, path), object)[countryValue]
        : checkValidity(getIn(object, path), object))
    ) {
      return error;
    }
    return setIn(error, path, msg);
  };

  return steps.reduce(stepReducer, {});
};
