import { createAsyncThunk } from '@reduxjs/toolkit';

import * as APIClient from 'Services/APIClient';

import logSentryError from 'utils/logSentry';

import * as accountCardsSelectors from './selectors';

export const fetchAccountCards = createAsyncThunk(
  'accountCards/fetch',
  async () => {
    try {
      const response = await APIClient.get('/v1/account/cards');
      return response.json();
    } catch (error) {
      logSentryError('[dux/accountCards] fetchAccoundCards', error);

      throw error;
    }
  },
  {
    condition: (_, { getState }) => {
      if (accountCardsSelectors.isLoading(getState())) {
        return false;
      }
      return true;
    },
  }
);

export const deleteCard = createAsyncThunk(
  'accountCards/deleteCard',
  async (/** @type {string} */ cardId) => {
    try {
      const response = await APIClient.del(`/v1/account/cards/${cardId}`);
      return response.json();
    } catch (error) {
      logSentryError('[dux/accountCards] deleteCard', error);

      throw error;
    }
  }
);

export const makeDefaultCard = createAsyncThunk(
  'accountCards/makeDefaultCard',
  async (/** @type {string} */ cardId) => {
    try {
      const response = await APIClient.put(`/v1/account/cards/${cardId}`, { default: true });
      return await response.json();
    } catch (error) {
      logSentryError('[dux/accountCards] makeDefaultCard', error);

      throw error;
    }
  }
);
