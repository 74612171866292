import { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'dux/app/hooks';

import { theme } from '@prose-ui';
import { legacyTheme, styled } from '@prose-ui/legacy';
import { Formik } from 'formik';

import { BasicModal } from 'Components/BasicModal';
import Button from 'Components/LegacyButton';
import Spacer from 'Components/Spacer';
import TextOptinField from 'Components/TextOptinComponents';
import Typography from 'Components/Typography';

import {
  CONSENT_COOKIE_DURATION,
  CONSENT_COOKIE_NAME,
  CONSENT_DENIED,
  CONSENT_GRANTED,
  CONSENT_PARAMETERS_GRANTED,
  CONSENT_UPDATED_EVENT,
} from 'constants/consent';

import { ReactComponent as Cross } from 'assets/images/cross_icon.svg';

import { getConsentValuesClientSide } from 'utils/consentClientSide';
import { getCookie, setCookie } from 'utils/cookies';
import useResponsiveVariant from 'utils/useResponsiveVariant';

import { onConsentBannerToggled } from 'dux/consentManagement/actions';
import { trackHeapEvent } from 'dux/tracking/actions';
import { getShowConsentManagementBanner } from 'dux/consentManagement/selectors';
import { getUserGeolocationGuessedCountry } from 'dux/user/selectors';

const StyledModal = styled(BasicModal)`
  position: relative;

  width: 100%;
  margin-top: auto;
  padding: ${legacyTheme.spacing.s24};

  background-color: ${({ backgroundColor }) => backgroundColor};

  text-align: center;

  ${legacyTheme.breakpoints.up('sm')} {
    width: auto;
    min-width: 400px;
    max-width: 375px;
    padding-top: ${legacyTheme.spacing.s32};

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
`;

const CloseModalButton = styled.button`
  position: absolute;
  top: ${legacyTheme.spacing.s24};
  right: ${legacyTheme.spacing.s24};

  display: flex;
  align-items: center;
  justify-content: center;
  width: ${legacyTheme.spacing.s24};
  height: ${legacyTheme.spacing.s24};
  margin-top: -4px;
  appearance: none;

  background: none;
  border: none;

  cursor: pointer;
`;

const Title = styled(Typography)`
  font-weight: normal;
  color: ${theme.colors.primary[400]};
`;

const FieldsContainer = styled.div`
  position: relative;
  overflow-y: scroll;

  max-height: 200px;
`;

type ConsentParameters = {
  ad_personalization: typeof CONSENT_DENIED | typeof CONSENT_GRANTED;
  ad_storage: typeof CONSENT_DENIED | typeof CONSENT_GRANTED;
  ad_user_data: typeof CONSENT_DENIED | typeof CONSENT_GRANTED;
  analytics_storage: typeof CONSENT_DENIED | typeof CONSENT_GRANTED;
  functionality_storage: typeof CONSENT_DENIED | typeof CONSENT_GRANTED;
  personalization_storage: typeof CONSENT_DENIED | typeof CONSENT_GRANTED;
  security_storage: typeof CONSENT_DENIED | typeof CONSENT_GRANTED;
};

export const ConsentBanner = () => {
  const geolocatedCountry = useAppSelector<string>(getUserGeolocationGuessedCountry);
  const geolocatedRegion = useAppSelector<string>(getUserGeolocationGuessedCountry);
  const showConsentBanner = useAppSelector<boolean>(getShowConsentManagementBanner);
  const dispatch = useAppDispatch();
  const { isMobile } = useResponsiveVariant();
  const [isManagePreferencesOpen, setIsManagePreferencesOpen] = useState(false);
  const [managePreferencesValues, setManagePreferencesValues] = useState<ConsentParameters | null>(
    null,
  );

  const formatConsentBannerFormConsentValueToGtm = (value: boolean) =>
    value ? CONSENT_GRANTED : CONSENT_DENIED;
  const formatGtmValueToConsentBannerForm = (
    value: typeof CONSENT_DENIED | typeof CONSENT_GRANTED,
  ) => value === CONSENT_GRANTED;

  const onClose = () => {
    dispatch(onConsentBannerToggled(false));
  };

  const updateConsent = (values: ConsentParameters) => {
    const consentBannerClosedEvent = new CustomEvent(CONSENT_UPDATED_EVENT, {
      detail: {
        newConsentParams: values,
        oldConsentParams: getConsentValuesClientSide({
          country: geolocatedCountry,
          region: geolocatedRegion,
        }),
      },
    });
    setCookie(CONSENT_COOKIE_NAME, JSON.stringify(values), CONSENT_COOKIE_DURATION);
    window.dispatchEvent(consentBannerClosedEvent);
    onClose();
  };

  const onDismissConsent = () => {
    updateConsent(CONSENT_PARAMETERS_GRANTED);
    dispatch(
      trackHeapEvent('Consent Banner Dismissed', { consentParams: CONSENT_PARAMETERS_GRANTED }),
    );
    onClose();
  };

  const onGrantAllConsent = (values: ConsentParameters) => {
    updateConsent(values);
    dispatch(
      trackHeapEvent('Consent Banner All Granted', { consentParams: CONSENT_PARAMETERS_GRANTED }),
    );
  };

  const onUpdateConsentPreferences = (values: ConsentParameters) => {
    updateConsent(values);
    dispatch(trackHeapEvent('Consent Banner Preferences Updated', { consentParams: values }));
  };

  useEffect(() => {
    if (!getCookie(CONSENT_COOKIE_NAME)) {
      dispatch(onConsentBannerToggled(true));
      dispatch(trackHeapEvent('Consent Banner Viewed - Pop-up'));
    } else {
      // If the consent banner was seen before, we initialize the preferences form with the consent params previously set
      setManagePreferencesValues(
        getConsentValuesClientSide({
          country: geolocatedCountry,
          region: geolocatedRegion,
        }),
      );
    }
  }, []);

  useEffect(() => {
    if (showConsentBanner) {
      dispatch(onConsentBannerToggled(true));
      dispatch(trackHeapEvent('Consent Banner Viewed - Footer'));
    }
  }, [showConsentBanner]);

  return (
    <StyledModal
      backgroundColor={
        isManagePreferencesOpen ? theme.colors.neutral[100] : theme.colors.primary[100]
      }
      dataFrom="consent-banner"
      dataTestId="consent-banner"
      hideClose
      horizontalPosition={isMobile ? 'center' : 'end'}
      isBackdropVisible={false}
      isFullWidth={isMobile}
      isOpen={showConsentBanner}
      onClose={onDismissConsent}
      zIndexValue={1501}
    >
      <CloseModalButton
        data-click="consent-banner-dismiss"
        data-from="consent-banner"
        data-testid="consent-banner-close"
        onClick={onDismissConsent}
      >
        <Cross height="13" width="13" />
      </CloseModalButton>

      {!isManagePreferencesOpen && (
        <div>
          {/* @ts-expect-error - Typography has no TS types yet */}
          <Title
            align="left"
            aria-label="Your Privacy"
            data-testid="consent-banner-title"
            id={showConsentBanner ? 'modal-title' : ''}
            markupName="h2"
            medium
            variant="p3"
          >
            Your Privacy
          </Title>
          <Spacer axis="vertical" size={16} />
          {/* @ts-expect-error - Typography has no TS types yet */}
          <Typography
            align="left"
            data-testid="consent-banner-description"
            id={showConsentBanner ? 'modal-description' : ''}
            variant="p3"
          >
            We use cookies and similar tracking technologies, including those provided by third
            parties, to view and retain your interactions on the site, help us improve our site,
            services, and marketing campaigns, and to serve you ads. For more information, view our{' '}
            <Link to="/privacy">Privacy Policy</Link>.
          </Typography>
          <div>
            <Spacer axis="vertical" size={16} />
            {/* @ts-expect-error - Button has no TS types yet */}
            <Button
              data-click="consent-banner-accept-all"
              data-from="consent-banner"
              data-testid="consent-banner-submit"
              fullWidth
              onClick={() => onGrantAllConsent(CONSENT_PARAMETERS_GRANTED)}
              variant="vert"
            >
              Accept
            </Button>
            {/* @ts-expect-error - Button has no TS types yet */}
            <Button
              data-click="consent-banner-open-my-preferences"
              data-from="consent-banner"
              onClick={() => setIsManagePreferencesOpen(true)}
              variant="underlinedBlack"
            >
              Manage Preferences
            </Button>
          </div>
        </div>
      )}

      {isManagePreferencesOpen && (
        <div>
          {/* @ts-expect-error - Typography has no TS types yet */}
          <Title
            align="left"
            aria-label="We care about your privacy"
            data-testid="consent-banner-title"
            id={showConsentBanner ? 'modal-title' : ''}
            markupName="h2"
            medium
            variant="h3"
          >
            We care about your privacy
          </Title>
          <Spacer axis="vertical" size={16} />
          {/* @ts-expect-error - Typography has no TS types yet */}
          <Typography
            align="left"
            data-testid="consent-banner-description"
            id={showConsentBanner ? 'modal-description' : ''}
            variant="p3"
          >
            Below you’ll find detailed information about each cookie category and the possibility to
            customize your settings. You can choose which cookie categories to accept or not. Please
            keep in mind that blocking some types of cookies may impact how we can deliver or
            improve our services to you. When you’re ready, click on Confirm My Choices and
            remember, you can always change your mind later. If you want to learn more about cookies
            and why we use them, you can visit our <Link to="/privacy">Privacy Policy</Link> at any
            time.
          </Typography>
          <Formik
            initialValues={{
              advertisingTracking: managePreferencesValues?.ad_storage
                ? formatGtmValueToConsentBannerForm(managePreferencesValues.ad_storage)
                : true,
              analyticsTracking: managePreferencesValues?.analytics_storage
                ? formatGtmValueToConsentBannerForm(managePreferencesValues.analytics_storage)
                : true,
              essentialTracking: managePreferencesValues?.functionality_storage
                ? formatGtmValueToConsentBannerForm(managePreferencesValues.functionality_storage)
                : true,
            }}
            onSubmit={() => {}}
          >
            {({ handleSubmit, setFieldValue: _setField, values }) => (
              <form onSubmit={handleSubmit}>
                <FieldsContainer>
                  <Spacer axis="vertical" size={16} />
                  <TextOptinField
                    content="Essential cookies"
                    dataPrefix="consent-essential-cookies"
                    fieldName="essentialTracking"
                  />
                  {/* @ts-expect-error - Typography has no TS types yet */}
                  <Typography align="left" variant="p4">
                    This website uses essential cookies and services to enable core website features
                    and provide a seamless user experience. These cookies and services are used to
                    facilitate features such as navigation, remember user preferences, and ensure
                    the security of the website.
                  </Typography>
                  <Spacer axis="vertical" size={16} />
                  <TextOptinField
                    content="Analytics cookies"
                    dataPrefix="consent-analytics-cookies"
                    fieldName="analyticsTracking"
                    isEditable
                  />
                  {/* @ts-expect-error - Typography has no TS types yet */}
                  <Typography align="left" variant="p4">
                    This website uses analytics cookies and services for collecting aggregated data
                    and statistical information. This data helps us analyze and optimize site
                    performance, identify popular content, detect navigation issues, and make
                    informed decisions to enhance the user experience.
                  </Typography>
                  <Spacer axis="vertical" size={16} />
                  <TextOptinField
                    content="Advertising cookies"
                    dataPrefix="consent-advertising-cookies"
                    fieldName="advertisingTracking"
                    isEditable
                  />
                  {/* @ts-expect-error - Typography has no TS types yet */}
                  <Typography align="left" variant="p4">
                    This website uses advertising cookies and services to deliver personalized
                    advertisements, promotions, and offers that are relevant to you. They help
                    optimize ad delivery, measure campaign effectiveness, and enable advertisers to
                    reach specific audiences.
                  </Typography>
                </FieldsContainer>
                <Spacer axis="vertical" size={16} />
                {/* @ts-expect-error - Button has no TS types yet */}
                <Button
                  data-click="consent-banner-confirm-my-preferences"
                  data-from="consent-banner"
                  data-testid="consent-banner-submit"
                  fullWidth
                  onClick={() =>
                    onUpdateConsentPreferences({
                      ad_personalization: formatConsentBannerFormConsentValueToGtm(
                        values.advertisingTracking,
                      ),
                      ad_storage: formatConsentBannerFormConsentValueToGtm(
                        values.advertisingTracking,
                      ),
                      ad_user_data: formatConsentBannerFormConsentValueToGtm(
                        values.advertisingTracking,
                      ),
                      analytics_storage: formatConsentBannerFormConsentValueToGtm(
                        values.analyticsTracking,
                      ),
                      functionality_storage: formatConsentBannerFormConsentValueToGtm(
                        values.essentialTracking,
                      ),
                      personalization_storage: formatConsentBannerFormConsentValueToGtm(
                        values.essentialTracking,
                      ),
                      security_storage: formatConsentBannerFormConsentValueToGtm(
                        values.essentialTracking,
                      ),
                    })
                  }
                  variant="vert"
                >
                  Confirm my choices
                </Button>
              </form>
            )}
          </Formik>
        </div>
      )}
    </StyledModal>
  );
};
