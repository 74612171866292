import {
  supplementsFiveMonths,
  supplementsFourMonths,
  supplementsFromSixMonths,
  supplementsNotStarted,
  supplementsOneMonthOrLess,
  supplementsThreeMonths,
  supplementsTwoMonths,
  supplementsUsageDuration,
} from 'Apps/Feedback/constants/feedbackSlugs';

const supplementsUsageDurationScreen = {
  name: supplementsUsageDuration,
  route: `/feedback/supplements/${supplementsUsageDuration}`,
  shouldBeIncluded: () => true,
  title: 'How long have you been taking your supplements?',
  subtitle: (
    <>
      If you’re not sure on timing, think of how many <b>Root Source</b>
      <sup>®</sup>orders you’ve gone through already. One order = one month.
    </>
  ),
  options: [
    { value: supplementsNotStarted, label: 'I haven’t started yet' },
    { value: supplementsOneMonthOrLess, label: '1 month or less' },
    { value: supplementsTwoMonths, label: '2 months' },
    { value: supplementsThreeMonths, label: '3 months' },
    { value: supplementsFourMonths, label: '4 months' },
    { value: supplementsFiveMonths, label: '5 months' },
    { value: supplementsFromSixMonths, label: '6 months or more' },
  ],
  getInitialValue: answers => answers?.product_supplements_usage_duration,
  getAnswers: ({ selected }) => ({ product_supplements_usage_duration: selected }),
};

export default supplementsUsageDurationScreen;
