import * as APIClient from './APIClient';

const fetchOrders = async params => {
  const url = '/v2/orders';
  const searchParams = params && new URLSearchParams(params);
  const response = await APIClient.get(params ? `${url}/?${searchParams}` : url);
  return response.json();
};

export const getCompletedOrders = async () => {
  const [orders, freeOrders] = await Promise.all([
    fetchOrders({ is_paid: true }),
    fetchOrders({ is_gift: true }),
  ]);

  return orders.concat(freeOrders).sort((a, b) => {
    if (a.created_at < b.created_at) {
      return 1;
    }
    if (a.created_at > b.created_at) {
      return -1;
    }
    return 0;
  });
};
