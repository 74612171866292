import createLoadablePage from 'utils/createLoadablePage';

const AccountLoadable = createLoadablePage(
  () => import(/* webpackChunkName: "app-account" */ './Routes'),
  {
    LoadingSceneProps: { header: false },
  }
);

const AccountApp = () => <AccountLoadable />;

export default AccountApp;
