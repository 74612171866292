import publicReviewScreen from './questions/shared/publicReview';
import cleanserFeel from './questions/skin/cleanserFeel';
import cleanserFragranceIntensitySatisfaction from './questions/skin/cleanserFragrance';
import cleanserSatisfaction from './questions/skin/cleanserSatisfaction';
import cleanserTexture from './questions/skin/cleanserTexture';
import efficiencyTransitionScreen from './questions/skin/efficiencyTransitionScreen';
import fragranceTransitionScreen from './questions/skin/fragranceTransitionScreen';
import moisturizerFeel from './questions/skin/moisturizerFeel';
import moisturizerFragranceIntensitySatisfaction from './questions/skin/moisturizerFragrance';
import moisturizerSatisfaction from './questions/skin/moisturizerSatisfaction';
import moisturizerTexture from './questions/skin/moisturizerTexture';
import overallExperience from './questions/skin/overallExperience';
import productsTransitionScreen from './questions/skin/productsTransitionScreen';
import serumFeel from './questions/skin/serumFeel';
import serumFragranceIntensitySatisfaction from './questions/skin/serumFragrance';
import serumSatisfaction from './questions/skin/serumSatisfaction';
import skinAntiBlemish from './questions/skin/skinBlemish';
import skincareFragranceNewChoice from './questions/skin/skincareFragranceNewChoice';
import skincareTransitionScreen from './questions/skin/skincareTransitionScreen';
import skinEventone from './questions/skin/skinEventone';
import skinExfoliation from './questions/skin/skinExfoliation';
import skinFirming from './questions/skin/skinFirming';
import skinHydration from './questions/skin/skinHydration';
import skinAntiRedness from './questions/skin/skinRedness';
import skinRescue from './questions/skin/skinRescue';
import skinResults from './questions/skin/skinResults';
import skinSatisfaction from './questions/skin/skinSatisfaction';
import skinAntiShine from './questions/skin/skinShine';
import skinAntiWrinkles from './questions/skin/skinWrinkles';

export default [
  // INTRO
  skincareTransitionScreen,
  overallExperience,

  // QUESTIONS
  efficiencyTransitionScreen,
  skinSatisfaction,
  skinHydration,
  skinExfoliation,
  skinEventone,
  skinRescue,
  skinAntiRedness,
  skinAntiShine,
  skinAntiBlemish,
  skinAntiWrinkles,
  skinFirming,

  // PRODUCTS
  productsTransitionScreen,

  // CLEANSER
  cleanserSatisfaction,
  cleanserFeel,
  cleanserTexture,

  // SERUM
  serumSatisfaction,
  serumFeel,

  // MOISTURIZER
  moisturizerSatisfaction,
  moisturizerFeel,
  moisturizerTexture,

  // FRAGRANCE
  fragranceTransitionScreen,
  cleanserFragranceIntensitySatisfaction,
  serumFragranceIntensitySatisfaction,
  moisturizerFragranceIntensitySatisfaction,
  skincareFragranceNewChoice,

  // RESULTS
  skinResults,

  // PUBLIC REVIEW
  { ...publicReviewScreen, route: `/feedback/skincare/public-review` },
];
