import { PRODUCTS } from 'Apps/Feedback/constants/feedbackSections';
import { haircareStylingGelSatisfaction } from 'Apps/Feedback/constants/feedbackSlugs';

import { productsSlugs } from 'constants/products';

const stylingGelSatisfaction = {
  name: haircareStylingGelSatisfaction,
  route: `/feedback/haircare/${haircareStylingGelSatisfaction}`,
  category: PRODUCTS,
  shouldBeIncluded: ({ formulas }) =>
    formulas?.find(f => f.variant.product.type === productsSlugs.STYLING_GEL),
  component: 'Rating',
  title: 'How are you satisfied with your styling gel?',
  options: [
    { value: 1, label: 'Very Unsatisfied' },
    { value: 2, label: 'Unsatisfied' },
    { value: 3, label: 'Neutral' },
    { value: 4, label: 'Satisfied' },
    { value: 5, label: 'Very Satisfied' },
  ],
  skippable: 'I haven’t used it enough',
  skippableValue: 0,
  hasOpenText: true,
  getInitialValue: answers => answers?.product_styling_gel_satisfaction,
  getInitialProductFeedback: productsAnswers => {
    return productsAnswers.find(answer => answer.product === productsSlugs.STYLING_GEL)
      ?.open_text_feedback;
  },
  getAnswers: ({ selected }) => {
    if (selected === 0) {
      return {
        product_styling_gel_satisfaction: selected,
        product_styling_gel_flexible: null,
        product_styling_gel_hold: null,
      };
    }
    return { product_styling_gel_satisfaction: selected };
  },
  getFeedbackAnswer: ({ openText }) => ({
    product: productsSlugs.STYLING_GEL,
    open_text_feedback: openText?.feedback_text,
  }),
};

export default stylingGelSatisfaction;
