import { Elements as ElementsOG } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import getEnv from 'utils/getEnv';

const stripeKey = getEnv('REACT_APP_STRIPE_KEY');

const stripePromise = loadStripe(stripeKey);

const Elements = props => {
  return <ElementsOG stripe={stripePromise} {...props} />;
};

export default Elements;
