import { createAsyncThunk } from '@reduxjs/toolkit';

import type { RootState } from 'store';

import * as APIClient from 'Services/APIClient';

import logSentryError from 'utils/logSentry';

import { getGiftWithPurchaseListLoading } from './selectors';
import type { GiftWithPurchase } from './slice';

export const fetchGiftWithPurchaseList = createAsyncThunk<
  GiftWithPurchase[],
  void,
  { state: RootState }
>(
  'giftWithPurchase/fetch',
  async () => {
    try {
      const response = await APIClient.get('/v1/promotional_gift');
      const giftWithPurchaseList = await response.json<GiftWithPurchase[]>();
      return giftWithPurchaseList;
    } catch (error) {
      logSentryError(`[GiftWithPurchase Actions] fetch`, error);

      throw error;
    }
  },
  {
    condition(_, { getState }) {
      return !getGiftWithPurchaseListLoading(getState());
    },
  },
);
