import { useAppSelector } from 'dux/app/hooks';

import { productsCategories } from 'constants/products';

import {
  getHasCompletedShippingAddressSectionV2,
  getSelectedAddressV2,
} from 'dux/cartV2/selectors';
import * as checkoutSelectors from 'dux/checkout/selectors';
import * as checkoutCartSelectors from 'dux/checkoutCart/selectors';

import { useCheckoutCategory } from './useCheckoutCategory';

const useCheckoutAddressInfosByCartType = () => {
  const checkoutCategory = useCheckoutCategory();

  // Cart V1
  const savedSelectedAddress = useAppSelector(checkoutSelectors.getSelectedAddress);
  const cartHasSubscription = useAppSelector(checkoutCartSelectors.cartHasSubscription);
  const hasCompletedShippingAddressSection = useAppSelector(
    checkoutSelectors.getHasCompletedShippingAddressSection
  );

  // Cart V2
  const savedSelectedAddressV2 = useAppSelector(getSelectedAddressV2);
  const hasCompletedShippingAddressSectionV2 = useAppSelector(
    getHasCompletedShippingAddressSectionV2
  );

  if (checkoutCategory === productsCategories.ACCESSORIES) {
    return {
      savedSelectedAddress: savedSelectedAddressV2,
      hasCompletedShippingAddressSection: hasCompletedShippingAddressSectionV2,
      isCartV2: true,
    };
  }

  return {
    savedSelectedAddress,
    cartHasSubscription,
    hasCompletedShippingAddressSection,
  };
};

export default useCheckoutAddressInfosByCartType;
