import { createAsyncThunk } from '@reduxjs/toolkit';

import * as APIClient from 'Services/APIClient';

import logSentryError from 'utils/logSentry';

import { getIsFlagsLoading } from './selectors';

export const fetchFeatureFlags = createAsyncThunk(
  'featureFlags/fetch',
  async () => {
    try {
      const response = await APIClient.get('/flags');
      return await response.json();
    } catch (error) {
      logSentryError(`[dux/featureFlags] fetchFeatureFlags`, error);

      throw error;
    }
  },
  {
    condition(_, { getState }) {
      return !getIsFlagsLoading(getState());
    },
  }
);
