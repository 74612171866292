import { createSlice } from '@reduxjs/toolkit';

import { userSignout } from 'dux/auth/actions';

import { saveSelfies } from './thunks';

const initialState = {
  selfies: [],
  isLoading: false,
  error: null,
  saved: false,
};

const selfiesSlice = createSlice({
  name: 'selfies',
  initialState,
  reducers: {
    setSelfie(draft, { payload }) {
      const newSelfie = { filename: payload.filename, content: payload.file };
      draft.selfies.push(newSelfie);
    },
    removeSelfie(draft, { payload }) {
      const filteredSelfies = draft.selfies.filter(selfie => selfie.filename !== payload.filename);
      draft.selfies = filteredSelfies;
    },
    clearSelfiesError(draft) {
      draft.error = false;
    },
  },
  extraReducers: builder => {
    builder
      // reinitialize state upon signout
      .addCase(userSignout, () => initialState)

      .addCase(saveSelfies.pending, draft => {
        draft.isLoading = true;
        draft.error = false;
      })
      .addCase(saveSelfies.fulfilled, draft => {
        draft.isLoading = false;
        draft.error = false;
        draft.saved = true;
      })
      .addCase(saveSelfies.rejected, draft => {
        draft.isLoading = false;
        draft.error = true;
      });
  },
});

export const { reducer, actions } = selfiesSlice;
