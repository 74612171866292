import { PRODUCTS } from 'Apps/Feedback/constants/feedbackSections';
import {
  haircareDryShampooVolume,
  haircareImprovements,
  haircareNoConcernInImprovements,
  haircareNoImprovements,
  haircareSomeImprovements,
} from 'Apps/Feedback/constants/feedbackSlugs';

import { productsSlugs } from 'constants/products';

const dryShampooVolume = {
  name: haircareDryShampooVolume,
  route: `/feedback/haircare/${haircareDryShampooVolume}`,
  category: PRODUCTS,
  title: 'Did you feel your dry shampoo gave your hair volume?',
  shouldBeIncluded: ({ formulas, feedbackAnswers }) =>
    formulas &&
    formulas.find(f => f.variant.product.type === productsSlugs.DRY_SHAMPOO) &&
    feedbackAnswers?.product_dry_shampoo_satisfaction,
  options: [
    { value: haircareImprovements, label: 'Yes, it give a boost of volume and texture' },
    { value: haircareSomeImprovements, label: 'Some, but not enough' },
    { value: haircareNoImprovements, label: "No, it's just as flat" },
    { value: haircareNoConcernInImprovements, label: "It wasn't a concern" },
  ],
  getInitialValue: answers => answers?.product_dry_shampoo_volume,
  getAnswers: ({ selected }) => ({ product_dry_shampoo_volume: selected }),
};

export default dryShampooVolume;
