import PropTypes from 'prop-types';
import { forbidExtraProps } from 'airbnb-prop-types';

import { connect } from 'react-redux';

import ErrorScene from 'Scenes/ErrorScene';
import LoadingScene from 'Scenes/LoadingScene';

import * as featureFlagSelectors from 'dux/featureFlags/selectors';

const RequireFeatureFlags = ({
  shouldShow,
  children,
  isFlagsDoneFetching,
  fallbackRender,
  header,
  ...props
}) => {
  if (!isFlagsDoneFetching) {
    // We are still on static values
    return shouldShow ? children : <LoadingScene header={header} {...props} />;
  }
  // We are on dynamic values (or fetch failed)
  return shouldShow ? children : fallbackRender();
};

RequireFeatureFlags.propTypes = forbidExtraProps({
  children: PropTypes.node.isRequired,
  shouldShow: PropTypes.bool.isRequired,
  isFlagsDoneFetching: PropTypes.bool.isRequired,
  fallbackRender: PropTypes.func,
  header: PropTypes.bool,
});

RequireFeatureFlags.defaultProps = {
  fallbackRender: () => <ErrorScene />,
  header: true,
};

export default connect(
  state => ({
    isFlagsDoneFetching: featureFlagSelectors.isFlagsDoneFetching(state),
  }),
  {}
)(RequireFeatureFlags);
