import { PRODUCTS } from 'Apps/Feedback/constants/feedbackSections';
import {
  haircareCurlCreamMoisture,
  haircareImprovements,
  haircareNoConcernInImprovements,
  haircareNoImprovements,
  haircareSomeImprovements,
  haircareTooHeavy,
} from 'Apps/Feedback/constants/feedbackSlugs';

import { productsSlugs } from 'constants/products';

const curlCreamMoisture = {
  name: haircareCurlCreamMoisture,
  route: `/feedback/haircare/${haircareCurlCreamMoisture}`,
  category: PRODUCTS,
  shouldBeIncluded: ({ formulas, feedbackAnswers }) =>
    formulas?.find(f => f.variant.product.type === productsSlugs.CURL_CREAM) &&
    feedbackAnswers?.product_curl_cream_satisfaction,
  title: 'Did your hair feel moisturized after using your curl cream?',
  options: [
    { value: haircareImprovements, label: "Yes, it's well-hydrated & soft" },
    { value: haircareSomeImprovements, label: 'Some, but not enough' },
    { value: haircareNoImprovements, label: "No, it's just as dry & rough" },
    { value: haircareTooHeavy, label: 'It feels heavy and weighed down' },
    { value: haircareNoConcernInImprovements, label: "It wasn't a concern" },
  ],
  getInitialValue: answers => answers?.product_curl_cream_moisture,
  getAnswers: ({ selected }) => ({ product_curl_cream_moisture: selected }),
};

export default curlCreamMoisture;
