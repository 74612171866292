import { SCALP } from 'Apps/Feedback/constants/feedbackSections';
import {
  haircareImprovements,
  haircareNoConcernInImprovements,
  haircareNoImprovements,
  haircareScalpFlakiness,
  haircareSomeImprovements,
} from 'Apps/Feedback/constants/feedbackSlugs';

import { productsSlugs } from 'constants/products';

const scalpFlakiness = {
  name: haircareScalpFlakiness,
  route: `/feedback/haircare/${haircareScalpFlakiness}`,
  category: SCALP,
  shouldBeIncluded: ({ formulas }) =>
    formulas?.some(f =>
      [productsSlugs.SCALP_MASK, productsSlugs.SHAMPOO].includes(f.variant.product.type)
    ),
  title: "Did you see improvements in your scalp's flakiness?",
  subtitle: 'Recurring dryness and visible flakes',
  options: [
    { value: haircareImprovements, label: 'Yes, I see improvement' },
    { value: haircareSomeImprovements, label: 'Some, but could be better' },
    { value: haircareNoImprovements, label: 'No, I still have issues' },
    { value: haircareNoConcernInImprovements, label: "It wasn't a concern" },
  ],
  getInitialValue: answers => answers?.scalp_flakiness,
  getAnswers: ({ selected }) => ({ scalp_flakiness: selected }),
};

export default scalpFlakiness;
