import { createAsyncThunk } from '@reduxjs/toolkit';

import * as APIClient from 'Services/APIClient';

import logSentryError from 'utils/logSentry';

import {
  getHasActiveHaircareSubscription,
  getHasActiveSkincareSubscription,
} from 'dux/user/selectors';

import type {
  CreateSubscriptionPayload,
  UpsellPayload,
  UpsellPreviewPayload,
} from './overview.types';

export const fetchHaircareAccountOverviewData = createAsyncThunk<
  UpsellPayload | null,
  undefined,
  { rejectValue: { message: string } }
>(
  'haircareUpsell/fetch',
  async (_, { rejectWithValue }) => {
    try {
      const response = await APIClient.get('/v1/account/upsell/haircare');
      const data = (await response.json()) as UpsellPayload;

      return 'transaction_id' in data ? data : null;
    } catch (error) {
      const err: { message: string } = error as { message: string };
      if (err instanceof Error) {
        // 404 is sometimes expected
        if (err.message === 'not_found') {
          return null;
        }
        if ('message' in err) {
          if (err instanceof Error) {
            logSentryError('[dux/accountOverview] fetchHaircareAccountOverviewData', err);
          }
          return rejectWithValue({ message: err.message });
        }
      }
      return rejectWithValue({ message: 'An unexpected error occurred.' });
    }
  },
  {
    condition(_arg, { getState }) {
      const hasActiveHaircareSub = getHasActiveHaircareSubscription(getState());

      return hasActiveHaircareSub;
    },
    dispatchConditionRejection: true,
  },
);

export const fetchSkincareAccountOverviewData = createAsyncThunk<
  UpsellPayload | null,
  undefined,
  { rejectValue: { message: string } }
>(
  'skincareUpsell/fetch',
  async (_, { rejectWithValue }) => {
    try {
      const response = await APIClient.get('/v1/account/upsell/skincare');
      const data = (await response.json()) as UpsellPayload;

      return 'transaction_id' in data ? data : null;
    } catch (error) {
      const err: { message: string } = error as { message: string };
      if (err instanceof Error) {
        // 404 is sometimes expected
        if (err.message === 'not_found') {
          return null;
        }
        if ('message' in err) {
          if (err instanceof Error) {
            logSentryError('[dux/accountOverview] fetchSkincareAccountOverviewData', err);
          }
          return rejectWithValue({ message: err.message });
        }
      }
      return rejectWithValue({ message: 'An unexpected error occurred.' });
    }
  },
  {
    condition(_arg, { getState }) {
      const hasActiveSkincareSub = getHasActiveSkincareSubscription(getState());

      return hasActiveSkincareSub;
    },
    dispatchConditionRejection: true,
  },
);

export const createUpsellSubscription = createAsyncThunk<
  CreateSubscriptionPayload,
  {
    transactionId: string;
    quantity: number;
    frequency: number;
    type: string;
    shippingAddress: string;
    paymentSource: string;
    nextStart: string;
  }
>(
  'haircareUpsell/createSubscription',
  async ({
    transactionId,
    quantity,
    frequency,
    type,
    shippingAddress,
    paymentSource,
    nextStart,
  }) => {
    try {
      const response = await APIClient.post('/v2/subscriptions/add-products', {
        transaction_id: transactionId,
        regular_products: [
          {
            type,
            quantity,
            frequency,
          },
        ],
        payment_source: paymentSource,
        next_start: nextStart,
        shipping_address: shippingAddress,
      });
      return (await response.json()) as CreateSubscriptionPayload;
    } catch (error) {
      if (error instanceof Error) {
        logSentryError('[dux/accountOverview] createSubscription', error);
      }

      throw error;
    }
  },
);

export const getUpsellSubscriptionPreview = createAsyncThunk<
  UpsellPreviewPayload,
  {
    nextStart: string;
    regularProducts: {
      type: string;
      quantity: number;
      frequency: number;
    }[];
  }
>('upsell/getSubscriptionPreview', async ({ nextStart, regularProducts }) => {
  try {
    const response = await APIClient.post('/v2/subscriptions/preview-upsell', {
      regular_products: regularProducts,
      next_start: nextStart,
    });
    return (await response.json()) as UpsellPreviewPayload;
  } catch (error) {
    if (error instanceof Error) {
      logSentryError(`[dux/accountOverview] getUpsellSubscriptionPreview `, error);
    }
    throw error;
  }
});
