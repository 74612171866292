import { Component } from 'react';
import PropTypes from 'prop-types';

import * as Sentry from '@sentry/nextjs';

import ErrorScene from 'Scenes/ErrorScene';

import * as errorContent from 'assets/content/error';

import logSentryError from 'utils/logSentry';

// Bundle loading errors occour when we make a new release
// and an user asks for a bundle of a previous release.
// We log them as info to watch for any spilke but they are
// inherent to our deployment architecture.
const isBundleLoadingError = error => /^Loading chunk \d+ failed./.test(error.message);

const getLogErrorLevel = error => {
  // An error can define a custom severity level.
  if (error.level) {
    return error.level;
  }

  if (isBundleLoadingError(error)) {
    return 'info';
  }

  // By default set high severity
  return 'error';
};

const getErrorMessage = error => {
  // An error can define a custom message to be presented to the user.
  if (error.publicMessage) {
    return error.publicMessage;
  }

  if (isBundleLoadingError(error)) {
    return errorContent.newBundle;
  }

  return errorContent.oops;
};

const getRetryCTALabel = error => {
  // An error can define a custom label to the try again button.
  if (error.labelRetryCTA) {
    return error.labelRetryCTA;
  }

  if (isBundleLoadingError(error)) {
    return errorContent.pageRefresh;
  }

  return errorContent.tryAgain;
};

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
      hideRetryCTA: error.hideRetryCTA,
      labelRetryCTA: getRetryCTALabel(error),
      message: getErrorMessage(error),
    };
  }

  componentDidCatch(error, errorInfo) {
    // send the error to the error tracking service
    Sentry.addBreadcrumb({ message: 'Captured by error boundary' });
    Sentry.withScope(scope => {
      scope.setExtra('errorInfo', errorInfo);
      scope.setLevel(getLogErrorLevel(error));
      logSentryError('[Error Boundary] didCatch', error);
    });

    // IMPORTANT: in development mode React will rethrow the error and it will generate a second
    // call to the Sentry service. This will allow React displaying the error stack on the browser.
  }

  render() {
    const { hasError, message, hideRetryCTA, labelRetryCTA } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <ErrorScene hideCTA={hideRetryCTA} labelCTA={labelRetryCTA} title={message} />;
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
