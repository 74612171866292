import { PRODUCTS } from 'Apps/Feedback/constants/feedbackSections';
import {
  haircareClean,
  haircareOverstrippedDry,
  haircareShampooImpact,
  haircareUncleansedGreasy,
} from 'Apps/Feedback/constants/feedbackSlugs';

import { productsSlugs } from 'constants/products';

const shampooImpact = {
  name: haircareShampooImpact,
  route: `/feedback/haircare/${haircareShampooImpact}`,
  category: PRODUCTS,
  shouldBeIncluded: ({ formulas, feedbackAnswers }) =>
    formulas?.find(f => f.variant.product.type === productsSlugs.SHAMPOO) &&
    feedbackAnswers?.product_shampoo_satisfaction,
  title: 'How did your hair and scalp feel after washing?',
  options: [
    { value: haircareUncleansedGreasy, label: 'Uncleansed or greasy' },
    { value: haircareClean, label: 'Perfectly clean' },
    { value: haircareOverstrippedDry, label: 'Overstripped or dry' },
  ],
  getInitialValue: answers => answers?.product_shampoo_impact,
  getAnswers: ({ selected }) => ({ product_shampoo_impact: selected }),
};

export default shampooImpact;
