import '@prose-ui/legacy/legacyVars.css';

import { useRouteError } from 'react-router-dom';

import type { SeverityLevel } from '@sentry/nextjs';

import ErrorScene from 'Scenes/ErrorScene';

import * as errorContent from 'assets/content/error';

import getEnv from 'utils/getEnv';
import logSentryError from 'utils/logSentry';

// Bundle loading errors occour when we make a new release
// and an user asks for a bundle of a previous release.
// We log them as info to watch for any spilke but they are
// inherent to our deployment architecture.
const isBundleLoadingError = (error: Error) => /^Loading chunk \d+ failed./.test(error.message);

const getLogErrorLevel = (error: Error) => {
  // An error can define a custom severity level.
  if ('level' in error) {
    return error.level as SeverityLevel;
  }

  if (isBundleLoadingError(error)) {
    return 'info';
  }

  // By default set high severity
  return 'error';
};

const getErrorMessage = (error: Error) => {
  // An error can define a custom message to be presented to the user.
  if ('publicMessage' in error) {
    return error.publicMessage;
  }

  if (isBundleLoadingError(error)) {
    return errorContent.newBundle;
  }

  return errorContent.oops;
};

const getRetryCTALabel = (error: Error) => {
  // An error can define a custom label to the try again button.
  if ('labelRetryCTA' in error) {
    return error.labelRetryCTA as string;
  }

  if (isBundleLoadingError(error)) {
    return errorContent.pageRefresh;
  }

  return errorContent.tryAgain;
};

export const RootErrorBoundary = () => {
  const error = useRouteError();
  if (!(error instanceof Error)) {
    return null;
  }

  // send the error to the error tracking service
  logSentryError('[RootErrorBoundary] captured', error, {
    logLevel: getLogErrorLevel(error),
  });

  if (getEnv('REACT_APP_ENV') !== 'production') console.error(error);

  let hideRetryCTA = false;
  if ('hideRetryCTA' in error) {
    hideRetryCTA = error.hideRetryCTA as boolean;
  }
  const labelRetryCTA = getRetryCTALabel(error);
  const message = getErrorMessage(error) as string;

  return (
    <ErrorScene banner={false} hideCTA={hideRetryCTA} labelCTA={labelRetryCTA} title={message} />
  );
};
