import { createSlice } from '@reduxjs/toolkit';

import findIndex from 'lodash/findIndex';

import { ERROR, IDLE, LOADING, SUCCESS } from 'constants/statuses';

import { CLEAR_CHECKOUT } from 'dux/checkout/actionTypes';

import {
  createCartV2,
  getCartCustomizationV2,
  getCartRecommendationV2,
  getCartV2,
  patchCartV2,
  updateCartCustomizationV2,
} from './thunks';

const initialState = {
  data: null,
  error: null,
  status: IDLE,
  isUpdating: false,
  customization: {
    data: null,
    error: null,
    status: IDLE,
  },
  recommendation: {
    data: null,
    error: null,
    status: IDLE,
  },
};

const cartV2Slice = createSlice({
  name: 'cartV2',
  initialState,
  extraReducers: builder => {
    builder.addCase(getCartV2.pending, draft => {
      draft.status = LOADING;
      draft.error = null;
    });
    builder.addCase(getCartV2.fulfilled, (draft, action) => {
      draft.status = SUCCESS;
      draft.data = action.payload;
      draft.error = null;
    });

    builder.addCase(updateCartCustomizationV2.pending, (draft, action) => {
      const index = findIndex(draft.customization?.data, {
        pubkey: action?.meta?.arg?.item?.customization?.pubkey,
      });
      if (index !== -1) {
        draft.customization.data[index] = {
          ...draft.customization.data[index],
          data: {
            ...draft.customization.data[index]?.data,
            ...action?.meta?.arg?.data,
          },
        };
      }
    });
    builder.addCase(updateCartCustomizationV2.fulfilled, (draft, action) => {
      const index = findIndex(draft.customization?.data, {
        pubkey: action?.meta?.arg?.item?.customization?.pubkey,
      });
      if (index !== -1) {
        draft.customization.data[index] = action.payload;
      }
    });
    builder.addCase(updateCartCustomizationV2.rejected, (draft, action) => {
      const index = findIndex(draft.customization?.data, {
        pubkey: action?.meta?.arg?.item?.customization?.pubkey,
      });
      if (index !== -1) {
        draft.customization.data[index] = action.meta.arg.item.customization;
      }
      draft.customization.status = ERROR;
      draft.customization.error = action.error;
    });

    builder.addCase(getCartCustomizationV2.pending, draft => {
      draft.customization.status = LOADING;
      draft.customization.error = null;
    });
    builder.addCase(getCartCustomizationV2.fulfilled, (draft, action) => {
      draft.customization.status = SUCCESS;
      draft.customization.data = action.payload;
      draft.customization.error = null;
    });
    builder.addCase(getCartCustomizationV2.rejected, (draft, action) => {
      draft.customization.status = ERROR;
      draft.customization.error = action.error;
    });

    builder.addCase(getCartRecommendationV2.pending, draft => {
      draft.recommendation.status = LOADING;
      draft.recommendation.error = null;
    });
    builder.addCase(getCartRecommendationV2.fulfilled, (draft, action) => {
      draft.recommendation.status = SUCCESS;
      draft.recommendation.data = action.payload;
      draft.recommendation.error = null;
    });
    builder.addCase(getCartRecommendationV2.rejected, (draft, action) => {
      draft.recommendation.status = ERROR;
      draft.recommendation.error = action.error;
    });

    builder.addCase(patchCartV2.pending, draft => {
      draft.isUpdating = true;
    });
    builder.addCase(patchCartV2.fulfilled, (draft, action) => {
      draft.status = SUCCESS;
      draft.data = action.payload;
      draft.error = null;
      draft.isUpdating = false;
    });
    builder.addCase(patchCartV2.rejected, (draft, action) => {
      draft.status = ERROR;
      draft.error = action.error;
      draft.isUpdating = false;
    });

    builder.addCase(createCartV2.fulfilled, (draft, action) => {
      draft.status = SUCCESS;
      draft.data = action.payload;
      draft.error = null;
    });
    builder.addCase(getCartV2.rejected, (draft, action) => {
      draft.status = ERROR;
      draft.error = action.error;
    });
    // --- external duxes ---
    builder.addCase(CLEAR_CHECKOUT, () => initialState);
  },
});

export const { reducer } = cartV2Slice;
