import { SKIN } from 'Apps/Feedback/constants/feedbackSections';
import {
  skincareFirming,
  skincareImprovements,
  skincareNoConcernInImprovements,
  skincareNoImprovements,
  skincareSomeImprovements,
} from 'Apps/Feedback/constants/feedbackSlugs';

import { feedbackCategories } from 'constants/feedback';

const skinFirming = {
  name: skincareFirming,
  route: '/feedback/skincare/skin-firming',
  category: SKIN,
  feedbackCategory: feedbackCategories.SKINCARE,
  shouldBeIncluded: () => true,
  title: 'Did you notice an improvement in elasticity?',
  options: [
    { value: skincareImprovements, label: "Yes, it's better now" },
    { value: skincareSomeImprovements, label: 'Some, but could be better' },
    { value: skincareNoImprovements, label: 'No, I still feel a lack in firmness' },
    { value: skincareNoConcernInImprovements, label: "It wasn't a concern" },
  ],
  getInitialValue: answers => answers?.skin_firming,
  getAnswers: ({ selected }) => ({ skin_firming: selected }),
};

export default skinFirming;
