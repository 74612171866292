import isEmpty from 'lodash/fp/isEmpty';

import dayjs from 'dayjs';

import { PRODUCTS } from 'Apps/Feedback/constants/feedbackSections';
import {
  haircareConditionerContinueAntibrass,
  haircareImprovements,
  haircareNoImprovements,
} from 'Apps/Feedback/constants/feedbackSlugs';

import { productsSlugs } from 'constants/products';

const conditionerContinueAntiBrass = {
  name: haircareConditionerContinueAntibrass,
  route: `/feedback/haircare/${haircareConditionerContinueAntibrass}`,
  category: PRODUCTS,
  shouldBeIncluded: ({ formulas, feedbackAnswers }) => {
    const antiBrassIngredients = ['purple_v2', 'anti_brassines'];
    return (
      formulas?.find(
        f =>
          f.variant.product.type === productsSlugs.CONDITIONER &&
          !isEmpty(f.item_object.ingredients?.find(i => antiBrassIngredients.includes(i.slug))) &&
          dayjs().diff(f.created_at, 'day') > 21
      ) && feedbackAnswers?.product_conditioner_satisfaction
    );
  },
  title: 'Would you like to keep anti-brass ingredients in your next conditioner?',
  options: [
    { value: haircareImprovements, label: 'Yes, please keep them' },
    { value: haircareNoImprovements, label: 'No, please remove them' },
  ],
  getInitialValue: answers => answers?.product_conditioner_continue_antibrass,
  getAnswers: ({ selected }) => ({ product_conditioner_continue_antibrass: selected }),
};

export default conditionerContinueAntiBrass;
