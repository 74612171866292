import * as Auth from 'Services/Auth';

import { formDataFromObject, getApiUrlFromRoute } from './utils';

export const upload = async files => {
  const requests = files.map(file => {
    const accessToken = Auth.getAccessToken();
    const options = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      referrerPolicy: 'unsafe-url',
      body: formDataFromObject({
        type: 'image',
        filename: file.filename,
        file: file.content,
      }),
    };
    return fetch(getApiUrlFromRoute('/v1/account/documents/'), options);
  });

  const responses = await Promise.all(requests);

  const hasError = responses.some(response => !response.ok);
  if (hasError) {
    throw new Error('Document Upload Error');
  }
};
