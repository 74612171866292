import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Link, useNavigate } from 'react-router-dom';

import { connect } from 'react-redux';
import { useAppDispatch as useDispatch } from 'dux/app/hooks';

import { legacyTheme, styled } from '@prose-ui/legacy';
import { Formik } from 'formik';

import {
  formatSignupUserFromFormikToApi,
  setSignupFormikFieldError,
} from 'Apps/Consultation/utils';

import { BasicModal } from 'Components/BasicModal';
import Button from 'Components/LegacyButton';
import TextField from 'Components/TextField';
import Typography from 'Components/Typography';

import consultationCategoryProp from 'PropTypes/consultationProps';

import * as captureEmailExitModalContent from 'assets/content/consultation/captureEmailExitModal';

import { makeValidations } from 'utils/validators';

import * as trackingActions from 'dux/tracking/actions';
import * as authSelectors from 'dux/auth/selectors';
import * as signupSelectors from 'dux/signup/selectors';
import { signupUser } from 'dux/signup/thunks';

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${legacyTheme.spacing.s36};
`;

const ExitButton = styled(Link)`
  margin-top: 24px;
  color: ${legacyTheme.palette.common.grey.dark};
  border-bottom: 1px solid ${legacyTheme.palette.common.grey.dark};
`;

const StyledTextField = styled(TextField)`
  width: 300px;
`;

const Terms = styled(Typography)`
  color: ${legacyTheme.palette.common.grey.dark};
  margin-top: ${legacyTheme.spacing.s24};
`;

const StyledBasicModal = styled(BasicModal)`
  max-height: 738px;
`;

const EmailCaptureExitModal = ({
  consultationCategory,
  isOpen,
  onClose,
  isAuthenticated,
  isMagicLinkSent,
  signupError,
  signupIsLoading,
  trackIterableEvent,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // It can be one of request | success | magicLinkSent
  const [status, setStatus] = useState('request');

  useEffect(() => {
    if (isMagicLinkSent && !signupIsLoading) {
      setStatus('magicLinkSent');
    }
  }, [isMagicLinkSent, signupIsLoading]);

  useEffect(() => {
    if (isAuthenticated) {
      setStatus('success');
    } else {
      setStatus('request');
    }
  }, [isAuthenticated]);

  const handleSubmit = (values, actions) =>
    dispatch(
      signupUser({
        // fist param: user object with the backend shape
        user: formatSignupUserFromFormikToApi(values),
        // second param: callbacks to react and formik
        actions: {
          ...actions,
          setFieldError: setSignupFormikFieldError(actions),
          onSuccess: () =>
            trackIterableEvent('Started Consultation Exit', { category: consultationCategory }),
        },
        consultationCategory,
      })
    );

  const onClickFirstCta = () => {
    if (status !== 'request') {
      onClose();
      navigate(captureEmailExitModalContent.firstCTA.link(consultationCategory)[status]);
    }
  };

  return (
    <StyledBasicModal
      dataClick="exit"
      dataFrom={captureEmailExitModalContent.modal.dataFrom[status]}
      dataTestId="email-capture-exit-modal"
      isOpen={isOpen}
      onClose={onClose}
    >
      {signupError ? (
        <>
          <Typography align="center" id={isOpen ? 'modal-title' : ''} paragraph variant="h2">
            Oops, something went wrong...
          </Typography>
          <ButtonsContainer>
            <Button onClick={() => window.location.reload()} variant="underlined">
              Please reload
            </Button>
          </ButtonsContainer>
        </>
      ) : (
        <>
          <Typography align="center" id={isOpen ? 'modal-title' : ''} paragraph variant="h2">
            {captureEmailExitModalContent.title[status]}
          </Typography>
          <Typography align="center" id={isOpen ? 'modal-description' : ''} variant="p2">
            {captureEmailExitModalContent.body[status]}
          </Typography>
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
            }}
            onSubmit={handleSubmit}
            validate={values =>
              makeValidations(values, [
                ['firstName', 'required'],
                ['lastName', 'required'],
                ['email', 'required'],
                ['email', 'email'],
              ])
            }
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit: handleSubmitFormik,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmitFormik}>
                <ButtonsContainer>
                  {status === 'request' && (
                    <>
                      <StyledTextField
                        autoCorrect={false}
                        disabled={isSubmitting}
                        error={touched.firstName && errors.firstName}
                        id="firstName"
                        label="First name*"
                        margin
                        name="firstName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.firstName}
                      />
                      <StyledTextField
                        autoCorrect={false}
                        disabled={isSubmitting}
                        error={touched.lastName && errors.lastName}
                        id="lastName"
                        label="Last name*"
                        margin
                        name="lastName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.lastName}
                      />
                      <StyledTextField
                        autoCorrect={false}
                        disabled={isSubmitting}
                        error={touched.email && errors.email}
                        id="email"
                        label="Email address*"
                        margin
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="email"
                        value={values.email}
                      />
                    </>
                  )}
                  <Button
                    buttontype={status === 'request' ? 'submit' : undefined}
                    data-click={captureEmailExitModalContent.firstCTA.dataClick[status]}
                    data-from={captureEmailExitModalContent.firstCTA.dataFrom[status]}
                    data-testid="email-capture-first-cta-modal"
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                    noMargin
                    onClick={onClickFirstCta}
                    variant="vert"
                  >
                    {captureEmailExitModalContent.firstCTA.label[status]}
                  </Button>
                  <ExitButton
                    data-click={captureEmailExitModalContent.secondCTA.dataClick[status]}
                    data-from={captureEmailExitModalContent.secondCTA.dataFrom[status]}
                    onClick={onClose}
                    to={captureEmailExitModalContent.secondCTA.link[status]}
                  >
                    <Typography variant="p2">
                      {captureEmailExitModalContent.secondCTA.label[status]}
                    </Typography>
                  </ExitButton>
                  {status === 'request' && (
                    <Terms align="center" id={isOpen ? 'modal-conditions' : ''} variant="p2">
                      By clicking ‘Save my progress’ I acknowledge I have read and agree to the
                      Prose{' '}
                      <Link target="_blank" to="/terms">
                        End User License and Terms of Service
                      </Link>{' '}
                      and{' '}
                      <Link target="_blank" to="/privacy">
                        Privacy Policy
                      </Link>
                      .
                    </Terms>
                  )}
                </ButtonsContainer>
              </form>
            )}
          </Formik>
        </>
      )}
    </StyledBasicModal>
  );
};

EmailCaptureExitModal.propTypes = {
  consultationCategory: consultationCategoryProp.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isMagicLinkSent: PropTypes.bool.isRequired,
  signupError: PropTypes.bool.isRequired,
  signupIsLoading: PropTypes.bool.isRequired,
  trackIterableEvent: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    isAuthenticated: authSelectors.getIsAuthenticated(state),
    isMagicLinkSent: signupSelectors.getIsMagicLinkSent(state),
    signupError: signupSelectors.getHasError(state),
    signupIsLoading: signupSelectors.getIsLoading(state),
  }),
  {
    trackIterableEvent: trackingActions.trackIterableEvent,
  }
)(EmailCaptureExitModal);
