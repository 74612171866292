import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'dux/app/hooks';

import isEmpty from 'lodash/fp/isEmpty';

import { theme } from '@prose-ui';
import { legacyTheme, styled } from '@prose-ui/legacy';
import type { ShippingAddress } from 'types/shippingAddress';

import { CHECKOUT_ADDRESS_ENTERED } from 'Apps/Checkout/constants/checkoutAddressValidationStatus';
import * as checkoutSteps from 'Apps/Checkout/constants/checkoutSteps';

import { BasicModal } from 'Components/BasicModal';
import Button from 'Components/LegacyButton';
import Spacer from 'Components/Spacer';
import Typography from 'Components/Typography';

import {
  resetAddressChoice,
  resetAddressInvalidFields,
  validateAddressChoice,
} from 'dux/checkoutAddressesValidation/actions';
import { trackHeapEvent } from 'dux/tracking/actions';
import { getAddressValidationInvalidFields } from 'dux/checkoutAddressesValidation/selectors';
import * as featureFlagsSelectors from 'dux/featureFlags/selectors';

type CheckoutSteps = typeof import('Apps/Checkout/constants/checkoutSteps');

const StyledModal = styled(BasicModal)`
  border-radius: 4px;
`;

const InvalidAddress = styled(Typography)`
  padding: ${legacyTheme.spacing.s24};

  border: 1px solid ${theme.colors.neutral[600]};
  border-radius: 3px;
`;

const InvalidAddressFields = styled(Typography)`
  display: inline;
`;

const FlexBox = styled.div`
  ${legacyTheme.breakpoints.up('sm')} {
    display: flex;
  }
`;

type CheckoutStep = CheckoutSteps[keyof CheckoutSteps];

type AddressValidationModalProps = {
  address: ShippingAddress;
  isSubmitting: boolean;
  step: CheckoutStep;
  submit: Function;
};

const AddressValidationModal = ({
  address,
  isSubmitting,
  step,
  submit,
}: AddressValidationModalProps) => {
  const dispatch = useAppDispatch();
  const addressInvalidFields = useAppSelector(getAddressValidationInvalidFields) as string[];
  const shouldShowAddressValidation = useAppSelector(
    // @ts-expect-error - Selectors has no TS types for now
    featureFlagsSelectors.shouldShowAddressValidation,
  );
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = () => {
    dispatch(resetAddressInvalidFields());
    setIsOpen(false);
  };

  const confirmAddressChoice = async () => {
    // Store user choice to omit the address validation on next submit
    await dispatch(validateAddressChoice(CHECKOUT_ADDRESS_ENTERED));
    submit();
    closeModal();
  };

  useEffect(() => {
    if (
      step === checkoutSteps.SHIPPING_ADDRESS &&
      shouldShowAddressValidation &&
      !isEmpty(address) &&
      !isEmpty(addressInvalidFields)
    ) {
      setIsOpen(true);
      dispatch(trackHeapEvent('Invalid address modal viewed'));
    }
    if (step !== checkoutSteps.SHIPPING_ADDRESS) {
      /**
       * Every time we reach the shipping address step
       * we want to run the address validation
       */
      dispatch(resetAddressChoice());
    }
  }, [addressInvalidFields, step, shouldShowAddressValidation]);

  return (
    isOpen && (
      <StyledModal isOpen={isOpen} onClose={closeModal}>
        {/* @ts-expect-error - Typography has no TS types for now */}
        <Typography
          data-testid="address-validation-modal-title"
          id={isOpen ? 'modal-title' : ''}
          markupName="h2"
          variant="h2"
        >
          Please check your address
        </Typography>
        <Spacer size={16} />

        <div id={isOpen ? 'modal-description' : ''}>
          {/* @ts-expect-error - Typography has no TS types for now */}
          <InvalidAddressFields markupName="span" variant="p1">
            We noticed this:{' '}
            {addressInvalidFields?.map((field, index) => (
              <span key={field}>
                <strong data-testid="address-validation-modal-invalid-field-name">
                  {field} not found
                </strong>
                {addressInvalidFields.length > 1 &&
                  index !== addressInvalidFields.length - 1 &&
                  ', '}
              </span>
            ))}
          </InvalidAddressFields>
          <Spacer size={16} />
          {/* @ts-expect-error - Typography has no TS types for now */}
          <InvalidAddress markupName="p" variant="p1">
            {address.address1}
            <br />
            {address.city}, {address.state}, {address.zipcode}
          </InvalidAddress>
        </div>
        <Spacer size={16} />

        <FlexBox>
          {/* @ts-expect-error - Button has no TS types for now */}
          <Button
            data-testid="address-validation-modal-button-entered"
            disabled={isSubmitting}
            fullWidth
            isLoading={isSubmitting}
            noMargin
            onClick={() => confirmAddressChoice()}
            variant="noir"
          >
            Looks fine
          </Button>
          <Spacer size={22} />
          {/* @ts-expect-error - Button has no TS types for now */}
          <Button
            data-testid="address-validation-modal-button-changes"
            fullWidth
            noMargin
            onClick={() => closeModal()}
            variant="vert"
          >
            Make a change
          </Button>
        </FlexBox>
      </StyledModal>
    )
  );
};

export default AddressValidationModal;
