import { PRODUCTS } from 'Apps/Feedback/constants/feedbackSections';
import {
  haircareImprovements,
  haircareLeaveinConditionerFrizz,
  haircareNoConcernInImprovements,
  haircareNoImprovements,
  haircareSomeImprovements,
} from 'Apps/Feedback/constants/feedbackSlugs';

import { productsSlugs } from 'constants/products';

const leaveInConditionerFrizz = {
  name: haircareLeaveinConditionerFrizz,
  route: `/feedback/haircare/${haircareLeaveinConditionerFrizz}`,
  category: PRODUCTS,
  shouldBeIncluded: ({ formulas, feedbackAnswers }) =>
    formulas?.find(f => f.variant.product.type === productsSlugs.LEAVEIN_CONDITIONER) &&
    feedbackAnswers?.product_leavein_conditioner_satisfaction,
  title: 'Did you see less frizz after using your leave-in conditioner?',
  options: [
    { value: haircareImprovements, label: 'Yes, my hair is frizz-free & smooth' },
    { value: haircareSomeImprovements, label: 'Sort of, but could be better' },
    { value: haircareNoImprovements, label: 'No, still too much frizz' },
    { value: haircareNoConcernInImprovements, label: "It wasn't a concern" },
  ],
  getInitialValue: answers => answers?.product_leavein_conditioner_frizz,
  getAnswers: ({ selected }) => ({ product_leavein_conditioner_frizz: selected }),
};

export default leaveInConditionerFrizz;
