import * as feedbackComponents from 'Apps/Feedback/constants/feedbackComponents';
import { PRODUCTS } from 'Apps/Feedback/constants/feedbackSections';
import { skincareMoisturizerTexture } from 'Apps/Feedback/constants/feedbackSlugs';

import { feedbackCategories } from 'constants/feedback';
import { productsSlugs } from 'constants/products';

const moisturizerTexture = {
  name: skincareMoisturizerTexture,
  route: `/feedback/${feedbackCategories.SKINCARE}/moisturizer-texture`,
  category: PRODUCTS,
  productSlug: productsSlugs.MOISTURIZER, // this assumes that the textures for the Minis are the same as the Full-size
  feedbackCategory: feedbackCategories.SKINCARE,
  shouldBeIncluded: ({ formulas, feedbackAnswers }) =>
    formulas?.find(f => f.variant.product.type === productsSlugs.MOISTURIZER_MINI) &&
    feedbackAnswers?.moisturizer_satisfaction,
  title: 'Which moisturizer texture do you want for your next order?',
  getInitialValue: answers => ({
    pref_texture_moisturizer: answers?.pref_texture_moisturizer,
  }),
  getAnswers: ({ selected }) => ({
    pref_texture_moisturizer: selected,
  }),
  component: feedbackComponents.TextureContainer,
};

export default moisturizerTexture;
