import PropTypes from 'prop-types';

import { Field, getIn } from 'formik';

import SelectField from 'Components/SelectField';

import { countriesCode, stateLabelByCountries } from 'constants/countries';

const StateInput = ({ countriesList, name, testId, ariaRequired, ...props }) => {
  return (
    <Field name={name}>
      {({ field, form }) => {
        const selectedCountry = countriesList?.find(
          country => country.code === form.values.newAddress.country
        );
        const isInputTouched = getIn(form.touched, name);
        const inputError = isInputTouched ? getIn(form.errors, name) : '';

        return (
          <SelectField
            aria-invalid={inputError ? 'true' : 'false'}
            aria-required={ariaRequired ? 'true' : 'false'}
            data-testid={testId}
            error={isInputTouched && inputError}
            id={name}
            label={`${
              stateLabelByCountries[selectedCountry?.code] ||
              stateLabelByCountries[countriesCode.US]
            }*`}
            list={
              selectedCountry?.states.map(state => ({ label: state[0], value: state[0] })) || []
            }
            onBlur={field.handleBlur}
            onChange={event => form.setFieldValue(name, event.target.value)}
            value={field.value}
            {...props}
          />
        );
      }}
    </Field>
  );
};

StateInput.propTypes = {
  // Metadata for screen readers
  ariaRequired: PropTypes.bool,
  // Name can be a nested path like newAddress.address1
  name: PropTypes.string.isRequired,
  // Test id for cypress
  testId: PropTypes.string,
  countriesList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      code: PropTypes.oneOf([countriesCode.US, countriesCode.CA, countriesCode.GB]),
      states: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
    })
  ),
};

StateInput.defaultProps = {
  ariaRequired: false,
  testId: null,
  countriesList: null,
};

export default StateInput;
