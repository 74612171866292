import { PRODUCTS } from 'Apps/Feedback/constants/feedbackSections';
import {
  skincareOkay,
  skincareSerumFeel,
  skincareSoftHydrating,
  skincareTooSticky,
} from 'Apps/Feedback/constants/feedbackSlugs';

import { feedbackCategories } from 'constants/feedback';
import { productsSlugs } from 'constants/products';

const serumFeel = {
  name: skincareSerumFeel,
  route: '/feedback/skincare/serum-product-feel',
  category: PRODUCTS,
  feedbackCategory: feedbackCategories.SKINCARE,
  shouldBeIncluded: ({ formulas, feedbackAnswers }) =>
    formulas?.find(f => f.variant.product.type === productsSlugs.SERUM) &&
    feedbackAnswers?.serum_satisfaction,
  title: 'How does the serum feel on your skin?',
  options: [
    { value: skincareTooSticky, label: 'Too sticky' },
    { value: skincareOkay, label: 'Just okay' },
    { value: skincareSoftHydrating, label: 'Soft and hydrating' },
  ],
  getInitialValue: answers => answers?.serum_product_feel,
  getAnswers: ({ selected }) => ({ serum_product_feel: selected }),
};

export default serumFeel;
