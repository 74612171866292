import { productsCategories } from 'constants/products';

import { get, patchJson, postJson } from './APIClient';

const ensureRecommendationitem = item => ({
  ...item,
  recommendation: item.recommendation || {
    quantity: item.quantity,
    section: item.quantity ? 'main' : 'upsale',
  },
});

const normalizeCart = cart => ({
  ...cart,
  catalog: cart.catalog.map(item =>
    item.category === productsCategories.FORMULA ? ensureRecommendationitem(item) : item
  ),
});

const originMap = {
  piFQI: 'trial_kit_a',
  pvJDz: 'trial_kit_b',
  yjfwm: 'trial_kit_c',
};

// params argument is a URLSearchParams object
export const post = async (params, category) => {
  const payload = { origin: category };

  Array.from(params.entries()).forEach(([key, value]) => {
    if (key === 'items') {
      payload.items = JSON.parse(value);
    } else if (key === 'origin') {
      payload.origin = originMap[value] || value;
    } else {
      payload[key] = value;
    }
  });

  // 1st part for perf tracking
  const startTime = performance.now();

  const cart = await postJson('/v1/account/cart/', payload);

  // 2nd part for perf tracking
  const endTime = performance.now();
  if (window.heap && window.heap.track) {
    window.heap.track('cart load time', {
      ms: Math.round(endTime - startTime), // in miliseconds
    });
  }

  return normalizeCart(cart);
};

export const patch = async data => {
  const cart = await patchJson('/v1/account/cart/', data);
  return normalizeCart(cart);
};

export const patchItems = async items => {
  const cart = await patchJson('/v1/account/cart/', { items });
  return normalizeCart(cart);
};

// Initialized to resolved promise this way we can await it
let patchPromise = Promise.resolve();

export const queuePatchItems = async items => {
  // Always await on-going promise first (most of the time already resolved so should return directly)
  await patchPromise;
  // create the new promise and save it in patchPromise for next call
  patchPromise = patchItems(items);

  // return the saved promise
  return patchPromise;
};

export const fetchCartSectionsTitle = async () => {
  const response = await get('/v1/cart_sections/');
  return response.json();
};

export const fetchCartV2 = async () => {
  const response = await get('/v2/cart/');
  return response.json();
};

export const fetchCartCustomizationV2 = async () => {
  const response = await get('/v2/cart_item_customization/');
  return response.json();
};

export const postCartCustomizationV2 = async payload => {
  const response = await postJson('/v2/cart_item_customization/', payload);
  return response;
};

export const fetchCartRecommendationV2 = async () => {
  const response = await get('v1/recommendation/');
  return response.json();
};

export const patchCartCustomizationV2 = async payload => {
  const response = await patchJson(`/v2/cart_item_customization/${payload.pubkey}`, payload);
  return response;
};

export const patchCartV2 = async payload => {
  const response = await patchJson('/v2/cart/', payload);
  return response;
};

export const postCartV2 = async payload => {
  const response = await postJson('/v2/cart/', payload);
  return response;
};
