import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'dux/app/hooks';

import toLower from 'lodash/fp/toLower';

import * as Sentry from '@sentry/nextjs';
import { useStripe } from '@stripe/react-stripe-js';

import { useCheckoutCategory } from 'Apps/Checkout/hooks/useCheckoutCategory';

import { countriesCodeCurrency } from 'constants/currencies';
import { APPLE_PAY, GOOGLE_PAY, OTHER_PAYMENT } from 'constants/paymentMethods';
import { productsCategories } from 'constants/products';

import {
  expressCheckoutSubmit as expressCheckoutSubmitAction,
  expressCheckoutSubmitV2,
} from 'dux/checkout/actions';
import { trackEvent } from 'dux/tracking/actions';
import { getCartV2Currency, getTotalOrderAmountV2, isPayableV2 } from 'dux/cartV2/selectors';
import * as checkoutCartSelectors from 'dux/checkoutCart/selectors';
import * as userSelectors from 'dux/user/selectors';
import { payWithButton as payWithButtonAction, payWithButtonV2 } from 'dux/checkoutPayment/thunks';

const useStripePaymentInfosByCartType = ({ expressCheckout }) => {
  const checkoutCategory = useCheckoutCategory();
  // Cart V1
  const currency = useSelector(checkoutCartSelectors.getCartCurrency);
  const isPayable = useSelector(state =>
    checkoutCartSelectors.isPayable(state, { expressCheckout })
  );
  const totalOrderPaymentFromCustomer = useSelector(
    checkoutCartSelectors.getTotalOrderPaymentFromCustomer
  );

  // Cart V2
  const currencyV2 = useSelector(getCartV2Currency);

  const isPayableV2Selector = useSelector(state => isPayableV2(state, { expressCheckout }));
  const totalOrderAmountV2 = useSelector(getTotalOrderAmountV2);

  if (checkoutCategory === productsCategories.ACCESSORIES) {
    return {
      currency: currencyV2,
      isPayable: isPayableV2Selector,
      totalOrderAmount: totalOrderAmountV2,
      expressCheckoutSubmit: expressCheckoutSubmitV2,
      payWithButton: payWithButtonV2,
    };
  }

  return {
    currency,
    isPayable,
    totalOrderAmount: totalOrderPaymentFromCustomer,
    expressCheckoutSubmit: expressCheckoutSubmitAction,
    payWithButton: payWithButtonAction,
  };
};

export const useStripePayment = ({ expressCheckout, saveCard }) => {
  const { currency, isPayable, totalOrderAmount, expressCheckoutSubmit, payWithButton } =
    useStripePaymentInfosByCartType({
      expressCheckout,
    });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const stripe = useStripe();

  const [canMakePayment, setCanMakePayment] = useState(false);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [walletType, setWalletType] = useState(null);

  const country = useSelector(checkoutCartSelectors.getConsultationProfileCountryInCartSurvey);
  const guessedCountry = useSelector(userSelectors.getUserGeolocationGuessedCountry);

  useEffect(() => {
    // If no Payment Request
    if (isPayable && stripe && !paymentRequest) {
      Sentry.addBreadcrumb({ message: 'Setup payment button request' });
      setCanMakePayment(false);

      // See all params: https://stripe.com/docs/js/payment_request/create
      const newPaymentRequest = stripe.paymentRequest({
        // we prioritize the country coming from the Cart, otherwise the geolocation by default for other flows (gift, brush...)
        country: country || guessedCountry,
        currency: toLower(currency || countriesCodeCurrency[guessedCountry]),
        /*
         * Collect user's name, will appear in the PaymentRequestEvent object.
         * Needed for https://app.shortcut.com/prose/story/52056/collect-zip-code-for-apple-pay
         */
        requestPayerName: true,
        // Collect user's phone, appears in the PaymentResponse (token callback)
        requestPayerPhone: true,
        // Collect user's shippingAddress, appears in the PaymentResponse (token callback)
        requestShipping: true,
        // Necessary to collect user's shippingAddress
        shippingOptions: [
          /**
           * At least one shipping option must be supplied to collect the shipping address
           * But, in our case, we don't want to let the user choose the shippingOption since we only use UPS. That's why a fake id is used here.
           * In case we want to handle this in the future:
           * - The shippingOption property: https://stripe.com/docs/js/appendix/shipping_option
           * - The shippingoptionchange listener: https://stripe.com/docs/js/payment_request/events/on_shipping_address_change
           * - The UpdateDetails object: https://stripe.com/docs/js/appendix/update_details
           */
          {
            // A unique ID that we create to keep track of this shipping option
            id: 'someUniqueID',
            label: 'Ground',
            detail: 'Standard ground shipping (free)',
            amount: 0,
          },
        ],
        total: {
          label: 'Prose Hair',
          amount: totalOrderAmount,
        },
      });

      let wType;
      // canMakePayment tells if an enabled wallet is ready to pay
      newPaymentRequest.canMakePayment().then(result => {
        if (result) {
          wType = result.applePay ? APPLE_PAY : result.googlePay ? GOOGLE_PAY : OTHER_PAYMENT;
          dispatch(
            trackEvent('payment_button_view', {
              payment_button_type: wType,
            })
          );
          setWalletType(wType);
          setCanMakePayment(true);
        }
      });

      // See all returned properties: https://stripe.com/docs/js/appendix/payment_response
      newPaymentRequest.on(
        'token',
        async ({ complete, payerPhone, shippingAddress, shippingOption, token }) => {
          let result = null;
          if (expressCheckout) {
            result = await dispatch(
              expressCheckoutSubmit({
                payerPhone,
                paymentType: wType,
                shippingAddress,
                shippingOption,
                token,
              })
            );
          } else {
            result = await dispatch(
              payWithButton({
                paymentType: wType,
                saveCard: expressCheckout ? false : saveCard,
                token,
              })
            ).unwrap();
          }

          const order = result?.order;
          const status = result?.status;

          if (status === 'success') {
            dispatch(
              trackEvent('payment_button_paid', {
                payment_button_type: wType,
              })
            );

            /**
             * Report to the browser that the payment was successful,
             * and that it can close any active payment interface.
             */
            complete('success');

            navigate(`/checkout/success?order-pubkey=${order.pubkey}`);
          } else {
            /**
             * Report to the browser that you were unable to process the customer‘s payment.
             * Browsers may re-show the payment interface, or simply show a message and close.
             * The error is also handled on redux, so severe errors generate an oops page.
             */
            complete('fail');
          }
        }
      );

      setPaymentRequest(newPaymentRequest);
    }
  }, [stripe]);

  return { canMakePayment, walletType, paymentRequest };
};
