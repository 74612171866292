import { FRAGRANCE } from 'Apps/Feedback/constants/feedbackSections';
import { serumFragranceSatisfactionIntensity } from 'Apps/Feedback/constants/feedbackSlugs';

import { feedbackCategories } from 'constants/feedback';
import { productsSlugs } from 'constants/products';

const serumFragranceIntensitySatisfaction = {
  name: serumFragranceSatisfactionIntensity,
  route: `/feedback/skincare/fragrance-serum`,
  category: FRAGRANCE,
  feedbackCategory: feedbackCategories.SKINCARE,
  shouldBeIncluded: ({ formulas, skinFragrance, consultationAnswers }) =>
    !consultationAnswers?.pref_fragrance_free_skin &&
    formulas?.some(
      f => [productsSlugs.SERUM].includes(f.variant.product.type) && skinFragrance !== null
    ),
  component: 'FragranceSatisfactionIntensity',
  surtitle: {
    showFormulas: 'serum',
    showFragrance: true,
  },
  title: 'How satisfied are you with the scent of your serum?',
  getInitialValue: answers => ({
    fragrance_satisfaction: answers?.fragrance_serum_satisfaction,
    fragrance_intensity: answers?.fragrance_serum_intensity,
  }),
  getAnswers: ({ selected }) => ({
    fragrance_serum_satisfaction: selected?.fragrance_satisfaction,
    fragrance_serum_intensity: selected?.fragrance_intensity,
  }),
};

export default serumFragranceIntensitySatisfaction;
