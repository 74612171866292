import { createApi } from '@reduxjs/toolkit/query/react';

import customBaseQuery from 'Services/customBaseQuery';

type SubscriptionPreview = Array<{
  frequency: number;
  next_start_date: string;
  product: string;
  quantity: number;
  total_price: number;
  total_price_display: number;
}>;

const checkoutApiSlice = createApi({
  reducerPath: 'checkoutApi',
  baseQuery: customBaseQuery,
  endpoints: (build) => ({
    checkoutSubscriptionPreview: build.query<SubscriptionPreview, void>({
      query: () => ({
        url: `v1/account/cart/subscription_preview`,
        endpointName: 'checkout/subscriptionPreview',
      }),
      keepUnusedDataFor: 0, // no caching for now
    }),

    checkoutOrder: build.query<any, { pubkey: string }>({
      query: (data) => ({
        url: `v1/checkout/${data.pubkey}`,
        endpointName: 'checkout/checkoutOrder',
      }),
      keepUnusedDataFor: 0, // no caching for now
    }),
  }),
});

export default checkoutApiSlice;

export const { useCheckoutSubscriptionPreviewQuery, useCheckoutOrderQuery } = checkoutApiSlice;
