import { PRODUCTS } from 'Apps/Feedback/constants/feedbackSections';
import {
  haircareImprovements,
  haircareLeaveinConditionerTangles,
  haircareNoConcernInImprovements,
  haircareNoImprovements,
  haircareSomeImprovements,
} from 'Apps/Feedback/constants/feedbackSlugs';

import { productsSlugs } from 'constants/products';

const leaveInConditionerTangles = {
  name: haircareLeaveinConditionerTangles,
  route: `/feedback/haircare/${haircareLeaveinConditionerTangles}`,
  category: PRODUCTS,
  shouldBeIncluded: ({ formulas, feedbackAnswers }) =>
    formulas?.find(f => f.variant.product.type === productsSlugs.LEAVEIN_CONDITIONER) &&
    feedbackAnswers?.product_leavein_conditioner_satisfaction,
  title: 'Did you see an improvement in detangling?',
  subtitle: 'How easy is your hair to comb through?',
  options: [
    { value: haircareImprovements, label: 'Yes, detangling is a breeze now' },
    { value: haircareSomeImprovements, label: 'Some, but could be better' },
    { value: haircareNoImprovements, label: "No, my hair's still knotty" },
    { value: haircareNoConcernInImprovements, label: "It wasn't a concern" },
  ],
  getInitialValue: answers => answers?.product_leavein_conditioner_tangles,
  getAnswers: ({ selected }) => ({ product_leavein_conditioner_tangles: selected }),
};

export default leaveInConditionerTangles;
