import { createSlice } from '@reduxjs/toolkit';

import { userSignout } from 'dux/auth/actions';
import { CLEAR_CHECKOUT } from 'dux/checkout/actionTypes';

import initialState from './initialState';
import {
  goToCheckout,
  loadCartSectionsTitle,
  loadCartThunk,
  updateCart,
  updateCartItems,
  updateMonogram,
} from './thunks';

const formatMonogram = (action, items) => {
  return items.map(item => {
    if (
      item.customization &&
      item.category === action.item.category &&
      item.product === action.item.product &&
      item.type === action.item.type
    ) {
      return {
        ...item,
        customization: {
          ...item.customization,
          monograms: action.monogram,
        },
      };
    }
    return item;
  });
};

const checkoutCartSlice = createSlice({
  name: 'checkoutCart',
  initialState,
  reducers: {
    resetCartStatus: draft => {
      draft.status = 'idle';
    },
  },
  extraReducers: builder => {
    // reinitialize state upon signout
    builder.addCase(userSignout, () => initialState);
    builder.addCase(updateCartItems.pending, (draft, action) => {
      draft.status = 'saving';
      // We need to add this previousData object for optimistic data logic
      draft.previousData = draft.data;
      draft.data = action.meta;
      draft.error = null;
    });
    builder.addCase(updateCartItems.fulfilled, (draft, { payload }) => {
      draft.status = 'success';
      draft.data = payload;
      draft.previousData = null;
    });
    builder.addCase(updateCartItems.rejected, draft => {
      draft.status = 'success';
      // if two actions are rejected one after the other, previousData will be null for the second rejected action
      if (draft.previousData) {
        draft.data = draft.previousData;
        draft.previousData = null;
      }
    });
    builder.addCase(loadCartThunk.pending, draft => {
      draft.status = 'loading';
      draft.error = null;
    });
    builder.addCase(loadCartThunk.fulfilled, (draft, { payload }) => {
      draft.status = 'success';
      draft.data = payload;
    });
    builder.addCase(loadCartThunk.rejected, (draft, { error }) => {
      draft.status = 'error';
      draft.error = error;
    });

    builder.addCase(goToCheckout.pending, (draft, action) => {
      draft.status = 'saving';
      draft.previousData = draft.data;
      draft.data = action.meta;
      draft.error = null;
    });
    builder.addCase(goToCheckout.fulfilled, (draft, { payload }) => {
      draft.status = 'success';
      draft.data = payload;
      draft.previousData = null;
    });
    builder.addCase(goToCheckout.rejected, draft => {
      draft.status = 'success';
      // if two actions are rejected one after the other, previousData will be null for the second rejected action
      if (draft.previousData) {
        draft.data = draft.previousData;
        draft.previousData = null;
      }
    });

    builder.addCase(updateMonogram.pending, (draft, action) => {
      draft.data.items = formatMonogram(action.meta.arg, draft.data.items);
      draft.data.catalog = formatMonogram(action.meta.arg, draft.data.catalog);
    });
    builder.addCase(updateMonogram.rejected, (draft, action) => {
      draft.data.items = formatMonogram(action.payload, draft.data.items);
      draft.data.catalog = formatMonogram(action.payload, draft.data.catalog);
    });

    builder.addCase(updateCart.pending, draft => {
      draft.updateStatus = 'loading';
    });
    builder.addCase(updateCart.fulfilled, (draft, { payload }) => {
      draft.updateStatus = 'success';
      draft.status = 'success';
      draft.data = payload;
      draft.previousData = null;
    });

    builder.addCase(loadCartSectionsTitle.pending, draft => {
      draft.cartSectionTitle.status = 'loading';
      draft.cartSectionTitle.error = null;
    });
    builder.addCase(loadCartSectionsTitle.fulfilled, (draft, { payload }) => {
      draft.cartSectionTitle.status = 'success';
      draft.cartSectionTitle.data = payload;
    });
    builder.addCase(loadCartSectionsTitle.rejected, (draft, { error }) => {
      draft.cartSectionTitle.status = 'error';
      draft.cartSectionTitle.error = error;
    });

    // --- external duxes ---
    builder.addCase(CLEAR_CHECKOUT, () => initialState);
  },
});

export const { actions, reducer } = checkoutCartSlice;
