import * as feedbackComponents from 'Apps/Feedback/constants/feedbackComponents';
import { OVERALL } from 'Apps/Feedback/constants/feedbackSections';

import { feedbackCategories } from 'constants/feedback';

import skincareTransitionImgDesktop from 'assets/images/reviewAndRefine/skincare-transition_desktop.jpg';
import skincareTransitionImgMobile from 'assets/images/reviewAndRefine/skincare-transition_mobile.jpg';
import skincareTransitionImgTablet from 'assets/images/reviewAndRefine/skincare-transition_tablet.jpg';

const skincareTransitionScreen = {
  name: '',
  route: `/feedback/${feedbackCategories.SKINCARE}`,
  shouldBeIncluded: () => true,
  category: OVERALL,
  component: feedbackComponents.SkincareTransitionScreen,
  skipSave: true,
  title: 'Give us feedback on your Skincare Starter Set',
  subtitle:
    'Answer a few questions about your product texture + fragrance so we can refine your full-size routine. Keep up consistent use and expect visible results after week 4. Your Review & Refine process will be more in-depth when you review your full-size products.',
  paragraphs: [],
  legalNotice: '',
  ctaNextText: "Let's get started",
  feedbackCategory: feedbackCategories.SKINCARE,
  style: {
    ctaTheme: 'vert',
    theme: 'white',
    backgroundImages: {
      desktop: skincareTransitionImgDesktop,
      mobile: skincareTransitionImgMobile,
      tablet: skincareTransitionImgTablet,
    },
  },
};

export default skincareTransitionScreen;
