import { results } from 'Apps/Feedback/constants/feedbackSlugs';

const hairResults = {
  name: results,
  shouldBeIncluded: () => true,
  route: `/feedback/haircare/${results}`,
  component: 'HaircareFeedbackResults',
  skipSave: true,
  getAnswers: () => ({ status: 'completed' }),
};

export default hairResults;
