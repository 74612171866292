import { createSlice } from '@reduxjs/toolkit';

import { userSignout } from 'dux/auth/actions';

import { type Country, fetchCountries } from './thunks';

type CountriesState = {
  isLoading: boolean;
  list: Country[];
  error: boolean;
};

const initialState: CountriesState = {
  isLoading: false,
  list: [],
  error: false,
};

const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // reinitialize state upon signout
      .addCase(userSignout, () => initialState)

      .addCase(fetchCountries.pending, (draft) => {
        draft.isLoading = true;
        draft.error = false;
      })
      .addCase(fetchCountries.fulfilled, (draft, action) => {
        draft.isLoading = false;
        draft.list = action.payload.countries;
        draft.error = false;
      })
      .addCase(fetchCountries.rejected, (draft) => {
        draft.isLoading = false;
        draft.error = true;
      });
  },
});

export const { reducer } = countriesSlice;
