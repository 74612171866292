import { createAsyncThunk } from '@reduxjs/toolkit';

import * as OrderService from 'Services/OrderService';

import logSentryError from 'utils/logSentry';

import { enrichDigitalLeaflet } from './transforms';
import type { DigitalLeafletRawData, EnrichedData } from './types';

export const fetchDigitalLeaflet = createAsyncThunk<
  EnrichedData,
  | { orderBoxPubKey: string; orderPubKey?: undefined }
  | { orderBoxPubKey?: undefined; orderPubKey: string }
>('digitalLeaflet/fetchDigitalLeaflet', async ({ orderBoxPubKey, orderPubKey }) => {
  try {
    if (orderBoxPubKey) {
      return enrichDigitalLeaflet(
        (await OrderService.fetchDigitalLeafletWithOrderBoxPubkey(
          orderBoxPubKey,
        )) as DigitalLeafletRawData,
      );
    }
    /* else orderPubKey is truthy */
    return enrichDigitalLeaflet(
      (await OrderService.fetchDigitalLeafletWithOrderPubkey(orderPubKey)) as DigitalLeafletRawData,
    );
  } catch (error) {
    logSentryError('[dux/digitalLeaflet] fetchDigitalLeaflet', error);

    throw error;
  }
});
