import { createApi } from '@reduxjs/toolkit/query/react';

import customBaseQuery from 'Services/customBaseQuery';

import { feedbackCategories } from 'constants/feedback';

type FeedbackStatuses = Array<{
  category: 'haircare' | 'skincare';
  sub_category: 'supplements' | 'topicals';
  feedback_pubkey?: string;
  order_pubkey: string;
  status: 'not_started' | 'created' | 'completed' | 'disabled';
}>;

type Fragrance = {
  excluded: boolean;
  limited: boolean;
  name: string; // Maybe more of a 'slug' than a 'name'.
  rank: number;
  reason_why_slug: null | string;
  recommended: boolean;
  visible: boolean;
  fragrance_group: 'hairoil' | 'global'; // To check with API if/when we can have 'haircare' | 'skincare'
};
type Fragrances = Array<Fragrance>;

const feedbackApiSlice = createApi({
  reducerPath: 'feedbackApi',
  baseQuery: customBaseQuery,
  endpoints: (build) => ({
    feedbackStatusesQuery: build.query<FeedbackStatuses, void>({
      query: () => ({
        url: `v1/account/feedback`,
        endpointName: 'feedback/feedbackStatusesQuery',
      }),
      keepUnusedDataFor: 0, // no caching for now
    }),
    fragrancesUsingFeedbackQuery: build.query<
      Fragrances,
      (typeof feedbackCategories)[keyof typeof feedbackCategories]
    >({
      query: (feedbackCategory: (typeof feedbackCategories)[keyof typeof feedbackCategories]) => {
        const allParam = feedbackCategory === feedbackCategories.HAIRCARE ? `&all=true` : ''; // to include hairoil fragrances in case of haircare

        return {
          url: `v1/account/hair_profile/fragrances?use_feedback=true${allParam}`,
          endpointName: 'feedback/fragrancesUsingFeedbackQuery',
        };
      },
      transformResponse: (response: { fragrances: Fragrances }) => response.fragrances,
      keepUnusedDataFor: 0, // no caching for now
    }),
  }),
});

export default feedbackApiSlice;

export const { feedbackStatusesQuery, fragrancesUsingFeedbackQuery } = feedbackApiSlice.endpoints;
