import { PRODUCTS } from 'Apps/Feedback/constants/feedbackSections';
import {
  haircareImprovements,
  haircareNoConcernInImprovements,
  haircareNoImprovements,
  haircareSomeImprovements,
  haircareStylingGelHold,
} from 'Apps/Feedback/constants/feedbackSlugs';

import { productsSlugs } from 'constants/products';

const stylingGelHold = {
  name: haircareStylingGelHold,
  route: `/feedback/haircare/${haircareStylingGelHold}`,
  category: PRODUCTS,
  shouldBeIncluded: ({ formulas, feedbackAnswers }) =>
    formulas?.find(f => f.variant.product.type === productsSlugs.STYLING_GEL) &&
    feedbackAnswers?.product_styling_gel_satisfaction,
  title: 'Did your styling gel provide enough hold?',
  options: [
    { value: haircareImprovements, label: 'Yes, it held my hairstyle very well' },
    { value: haircareSomeImprovements, label: 'A little, but not as much as expected' },
    { value: haircareNoImprovements, label: 'No, it was not strong enough' },
    { value: haircareNoConcernInImprovements, label: "It wasn't a concern" },
  ],
  getInitialValue: answers => answers?.product_styling_gel_hold,
  getAnswers: ({ selected }) => ({ product_styling_gel_hold: selected }),
};

export default stylingGelHold;
