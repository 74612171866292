import Height from '@prose-ui/foundations/height';
import Spacing, { type SpacingToken } from '@prose-ui/foundations/spacing';
import { isKey } from 'types/predicates';

// padding
export const cssPadding = (spacingProp: PaddingProp) =>
  spacingProp
    .split(' ')
    .reduce(
      (acc, spacingToken) => `${acc} ${Spacing[spacingToken as SpacingToken]}`,
      '',
    ) as PaddingCss;

export type PaddingProp =
  | SpacingToken
  | `${SpacingToken} ${SpacingToken}`
  | `${SpacingToken} ${SpacingToken} ${SpacingToken} ${SpacingToken}`;

type PaddingCssSingle = (typeof Spacing)[SpacingToken];
export type PaddingCss =
  | PaddingCssSingle
  | `${PaddingCssSingle} ${PaddingCssSingle}`
  | `${PaddingCssSingle} ${PaddingCssSingle} ${PaddingCssSingle} ${PaddingCssSingle}`;

// height
export const cssHeight = (heightPropValue: HeightProp) =>
  isKey(Height, heightPropValue) ? Height[heightPropValue] : heightPropValue;

export type HeightCSSValue = (typeof Height)[keyof typeof Height] | `${number}px` | `${number}vh`;
export type HeightProp = keyof typeof Height | HeightCSSValue;
