import { useAppSelector } from 'dux/app/hooks';

import { styled } from '@prose-ui/legacy';

import ScaleLoader from 'Components/ScaleLoader';
import Spacer from 'Components/Spacer';
import Typography from 'Components/Typography';

import * as Status from 'constants/statuses';

import { getPayPalPaymentStatus } from 'dux/checkoutPayment/selectors';

const PaymentLoader = styled(ScaleLoader)`
  text-align: center;
`;

const CardErrorMessage = styled(Typography)`
  margin-left: 20px;
`;

const PayPalPayment = () => {
  const paypalPaymentStatus = useAppSelector(getPayPalPaymentStatus);

  return (
    <>
      {paypalPaymentStatus !== Status.LOADING && <div id="paypal-button" />}

      {paypalPaymentStatus === Status.LOADING && <PaymentLoader id="paypal-loader" />}

      {paypalPaymentStatus === Status.ERROR && (
        <>
          {/* @ts-expect-error Typography */}
          <CardErrorMessage align="left" color="rouge" markupName="p" variant="p3">
            We were unable to complete payment with PayPal. Please try another payment method.
          </CardErrorMessage>
          <Spacer size={6} />
        </>
      )}
    </>
  );
};

export default PayPalPayment;
