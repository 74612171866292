import PropTypes from 'prop-types';

import { Rifm } from 'rifm';

import { countriesFlags } from 'constants/countries';

import { formatPhoneInput } from 'utils/textUtils';

import TextField from './TextField';

const PhoneTextField = ({
  onBlur,
  onChange,
  value,
  selectedCountry,
  ariaInvalid,
  ariaRequired,
  ...props
}) => (
  <Rifm
    accept={/\d/g}
    format={formatPhoneInput}
    mask={value?.length >= 14}
    onChange={onChange}
    value={value}
  >
    {({ value, onChange }) => (
      <TextField
        {...props}
        aria-invalid={ariaInvalid ? 'true' : 'false'}
        aria-required={ariaRequired ? 'true' : 'false'}
        image={
          selectedCountry
            ? { src: countriesFlags[selectedCountry], alt: `${selectedCountry} flag` }
            : undefined
        }
        onBlur={onBlur}
        onChange={onChange}
        type="tel"
        value={value}
      />
    )}
  </Rifm>
);

PhoneTextField.defaultProps = {
  ariaInvalid: false,
  ariaRequired: false,
  selectedCountry: '',
  value: '',
};

PhoneTextField.propTypes = {
  // Metadata for screen readers
  ariaInvalid: PropTypes.bool,
  ariaRequired: PropTypes.bool,
  // Others
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedCountry: PropTypes.string,
  value: PropTypes.string,
};

export default PhoneTextField;
