import * as APIClient from './APIClient';

export const cancelSubscription = (pubkey, data) => {
  return APIClient.postJson(`/v2/subscriptions/${pubkey}/cancel`, data);
};

export const createSubscription = data => {
  return APIClient.postJson('/v2/subscriptions', data);
};

export const createSubscriptionPreview = data => {
  return APIClient.postJson('/v2/subscriptions/preview', data);
};

export const createSubscriptionPreviewByPubkey = (pubkey, data) => {
  return APIClient.postJson(`/v2/subscriptions/${pubkey}/preview`, data);
};

export const fetchSubscriptions = () => {
  return APIClient.getJson('/v2/subscriptions');
};

export const fetchSubscriptionLogReasons = () => {
  return APIClient.getJson('/v1/subscription_log_reasons');
};

export const updateSubscription = (pubkey, data) => {
  return APIClient.patchJson(`/v2/subscriptions/${pubkey}`, data);
};

export const snoozeSubscription = (pubkey, days) => {
  return APIClient.postJson(`/v2/subscriptions/${pubkey}/snooze/`, { days });
};

export const fetchForecast = pubkey => {
  return APIClient.postJson(`/v2/subscriptions/${pubkey}/forecast`);
};

export const fetchRetention = pubkey => {
  return APIClient.getJson(`/v2/subscriptions/${pubkey}/retention`);
};
