import PropTypes from 'prop-types';

import { productsCategories } from 'constants/products';

const consultationCategories = [
  productsCategories.AGNOSTIC,
  productsCategories.HAIRCARE,
  productsCategories.SKINCARE,
];

const consultationCategoryProp = PropTypes.oneOf(consultationCategories);

export type ConsultationCategoryProp = (typeof consultationCategories)[number];

export default consultationCategoryProp;
