import isEmpty from 'lodash/fp/isEmpty';

import dayjs from 'dayjs';

import { PRODUCTS } from 'Apps/Feedback/constants/feedbackSections';
import {
  haircareConditionerImpact,
  haircareOverconditionedHeavy,
  haircareSoftManageable,
  haircareUnderconditionedDry,
} from 'Apps/Feedback/constants/feedbackSlugs';

import { productsSlugs } from 'constants/products';

const conditionerImpact = {
  name: haircareConditionerImpact,
  route: `/feedback/haircare/${haircareConditionerImpact}`,
  category: PRODUCTS,
  shouldBeIncluded: ({ formulas, feedbackAnswers }) =>
    formulas?.find(f => f.variant.product.type === productsSlugs.CONDITIONER) &&
    feedbackAnswers?.product_conditioner_satisfaction,
  title: 'How did your hair feel after using your conditioner?',
  options: [
    { value: haircareUnderconditionedDry, label: 'Under-conditioned and dry' },
    { value: haircareSoftManageable, label: 'Soft and manageable' },
    { value: haircareOverconditionedHeavy, label: 'Over-conditioned and heavy' },
  ],
  getInitialValue: answers => answers?.product_conditioner_impact,
  modalVariant: ({ formulas, antiBrassIngredients }) => {
    const formula = formulas?.find(
      f =>
        f.variant.product.type === productsSlugs.CONDITIONER &&
        !isEmpty(f.item_object.ingredients?.find(i => antiBrassIngredients.includes(i.slug)))
    );
    return formula?.created_at && dayjs().diff(formula.created_at, 'day') <= 21
      ? 'AntibrassModal'
      : null;
  },
  getAnswers: ({ selected }) => ({ product_conditioner_impact: selected }),
};

export default conditionerImpact;
