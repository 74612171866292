import { Navigate, useParams } from 'react-router-dom';

import { isInvalidOrderFilter } from 'Services/HTTPError';

import ErrorContainer from 'Containers/ErrorContainer';

import LoadingScene from 'Scenes/LoadingScene';

import useGetApi from 'utils/useGetApi';

// Our customers can scan a QR on their product an be redirected to this component.
// The QR refers to a formula pubkey. We need to fetch the orders to find

const ProdItemLookUp = () => {
  const { productionItemPubkey } = useParams();
  const [{ isLoading, data = [], error }, callApi] = useGetApi(
    `/v2/orders/?formula=${productionItemPubkey}`
  );

  if (error) {
    if (isInvalidOrderFilter(error)) {
      return <Navigate replace to="/account/history" />;
    }

    return (
      <ErrorContainer
        error={error}
        retry={callApi}
        sentryMessage="Unhandled API error formula lookup"
      />
    );
  }

  if (isLoading) {
    return <LoadingScene />;
  }

  const foundOrder = data.find(order =>
    order.proditems.some(item => item.pubkey === productionItemPubkey)
  );

  return (
    <Navigate
      replace
      to={foundOrder ? `/account/history/${foundOrder.pubkey}` : '/account/history'}
    />
  );
};

ProdItemLookUp.propTypes = {};

export default ProdItemLookUp;
