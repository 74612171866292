import publicReviewScreen from './questions/shared/publicReview';
import cleanserFeel from './questions/skinMinis/cleanserFeel';
import cleanserFragranceIntensitySatisfaction from './questions/skinMinis/cleanserFragrance';
import cleanserSatisfaction from './questions/skinMinis/cleanserSatisfaction';
import cleanserTexture from './questions/skinMinis/cleanserTexture';
import efficiencyTransitionScreen from './questions/skinMinis/efficiencyTransitionScreen';
import fragranceTransitionScreen from './questions/skinMinis/fragranceTransitionScreen';
import moisturizerFeel from './questions/skinMinis/moisturizerFeel';
import moisturizerFragranceIntensitySatisfaction from './questions/skinMinis/moisturizerFragrance';
import moisturizerSatisfaction from './questions/skinMinis/moisturizerSatisfaction';
import moisturizerTexture from './questions/skinMinis/moisturizerTexture';
import overallExperience from './questions/skinMinis/overallExperience';
import productsTransitionScreen from './questions/skinMinis/productsTransitionScreen';
import serumFeel from './questions/skinMinis/serumFeel';
import serumFragranceIntensitySatisfaction from './questions/skinMinis/serumFragrance';
import serumSatisfaction from './questions/skinMinis/serumSatisfaction';
import skincareFragranceNewChoice from './questions/skinMinis/skincareFragranceNewChoice';
import skincareTransitionScreen from './questions/skinMinis/skincareTransitionScreen';
import skinResults from './questions/skinMinis/skinResults';
import skinSatisfaction from './questions/skinMinis/skinSatisfaction';

export default [
  // INTRO
  skincareTransitionScreen,
  overallExperience,

  // QUESTIONS
  efficiencyTransitionScreen,
  skinSatisfaction,

  // PRODUCTS
  productsTransitionScreen,

  // CLEANSER
  cleanserSatisfaction,
  cleanserFeel,
  cleanserTexture,

  // SERUM
  serumSatisfaction,
  serumFeel,

  // MOISTURIZER
  moisturizerSatisfaction,
  moisturizerFeel,
  moisturizerTexture,

  // FRAGRANCE
  fragranceTransitionScreen,
  cleanserFragranceIntensitySatisfaction,
  serumFragranceIntensitySatisfaction,
  moisturizerFragranceIntensitySatisfaction,
  skincareFragranceNewChoice,

  // RESULTS
  skinResults,

  // PUBLIC REVIEW
  { ...publicReviewScreen, route: `/feedback/skincare/public-review` },
];
