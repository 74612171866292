import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useAppDispatch, useAppSelector } from 'dux/app/hooks';

import LoadingScene from 'Scenes/LoadingScene';

import * as accountCardsActions from 'dux/accountCards/actions';
import { loadAddresses } from 'dux/checkoutAddresses/actions';
import { getIsAuthenticated } from 'dux/auth/selectors';

const CheckoutIdleSceneV2 = () => {
  const isAuthenticated = useAppSelector(getIsAuthenticated);
  const dispatch = useAppDispatch();

  // On the idle scene, load checkout data
  useEffect(() => {
    if (isAuthenticated) {
      dispatch(loadAddresses());
      dispatch(accountCardsActions.fetchAccountCards());
    }
  }, [isAuthenticated]);

  // Render the loading scene
  return <LoadingScene />;
};

CheckoutIdleSceneV2.propTypes = {
  params: PropTypes.shape({}).isRequired,
};

export default CheckoutIdleSceneV2;
