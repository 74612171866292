import { SKIN } from 'Apps/Feedback/constants/feedbackSections';
import {
  skincareAntiWrinkles,
  skincareImprovements,
  skincareNoConcernInImprovements,
  skincareNoImprovements,
  skincareSomeImprovements,
} from 'Apps/Feedback/constants/feedbackSlugs';

import { feedbackCategories } from 'constants/feedback';

const skinAntiShine = {
  name: skincareAntiWrinkles,
  route: '/feedback/skincare/skin-anti-wrinkles',
  category: SKIN,
  feedbackCategory: feedbackCategories.SKINCARE,
  shouldBeIncluded: () => true,
  title: 'Did you notice an improvement in your wrinkles or fine lines?',
  options: [
    { value: skincareImprovements, label: 'Yes, they are better now' },
    { value: skincareSomeImprovements, label: 'Some, but could be better' },
    { value: skincareNoImprovements, label: 'No, they are exactly the same' },
    { value: skincareNoConcernInImprovements, label: "It wasn't a concern" },
  ],
  getInitialValue: answers => answers?.skin_anti_wrinkles,
  getAnswers: ({ selected }) => ({ skin_anti_wrinkles: selected }),
};

export default skinAntiShine;
