import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'dux/app/hooks';

import { getGuestTokens } from 'Services/Auth';

import { getIsAuthenticated, getIsAuthenticating } from 'dux/auth/selectors';
import { actions } from 'dux/guestAuth/slice';

import { guestUserSignup } from './thunks';

export const useFetchGuestAuthIfNeeded = () => {
  const dispatch = useAppDispatch();
  const isGuestAuthenticated = getGuestTokens();
  const isAuthenticating = useAppSelector(getIsAuthenticating);
  const isAuthenticated = useAppSelector(getIsAuthenticated);

  useEffect(() => {
    if (!isAuthenticated && !isAuthenticating) {
      if (!isGuestAuthenticated) {
        dispatch(guestUserSignup());
      } else {
        dispatch(actions.setGuestAuthenticated());
      }
    }
  }, [isGuestAuthenticated, isAuthenticating, isAuthenticated]);
};
