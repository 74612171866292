import * as APIClient from './APIClient';

export const fetchQuestionSets = async payload => {
  const response = await APIClient.get(
    `/v1/question_sets${
      payload?.consultationCategory ? `?category=${payload?.consultationCategory}` : ''
    }`
  );
  return response.json();
};
