import { createSlice } from '@reduxjs/toolkit';

import * as Status from 'constants/statuses';

import { userSignout } from 'dux/auth/actions';

import initialState from './initialState';
import { fetchGiftsSets, fetchProducts } from './thunks';

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      // reinitialize state upon signout
      .addCase(userSignout, () => initialState)

      .addCase(fetchProducts.pending, draft => {
        draft.productsStatus = Status.LOADING;
      })
      .addCase(fetchProducts.fulfilled, (draft, { payload }) => {
        draft.productsStatus = Status.SUCCESS;
        draft.fetchedProducts = payload;
      })
      .addCase(fetchProducts.rejected, draft => {
        draft.productsStatus = Status.ERROR;
      })
      .addCase(fetchGiftsSets.pending, draft => {
        draft.giftsSetsStatus = Status.LOADING;
      })
      .addCase(fetchGiftsSets.fulfilled, (draft, { payload }) => {
        draft.giftsSetsStatus = Status.SUCCESS;
        draft.giftsSets = payload;
      })
      .addCase(fetchGiftsSets.rejected, draft => {
        draft.giftsSetsStatus = Status.ERROR;
      });
  },
});

export const { reducer } = productsSlice;
