import { createSlice } from '@reduxjs/toolkit';

import * as Status from 'constants/statuses';

import { userSignout } from 'dux/auth/actions';
import { CLEAR_CHECKOUT } from 'dux/checkout/actionTypes';

import { payWithPayPalButton, savePayment, saveStripePayment } from './thunks';

const paymentInitialState = {
  isPaymentReady: false,
};

const stripeInitialState = { token: null, cardPubkey: null };

const paypalInitialState = {
  status: Status.IDLE,
};

const paymentSlice = createSlice({
  name: 'payment',
  initialState: paymentInitialState,
  reducers: {
    setIsPaymentReady: (draft, action) => {
      draft.isPaymentReady = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      // reinitialize state upon signout
      .addCase(userSignout, () => paymentInitialState);
  },
});

const paypalSlice = createSlice({
  name: 'paypal',
  initialState: paypalInitialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(payWithPayPalButton.pending, draft => {
        draft.status = Status.LOADING;
      })
      .addCase(payWithPayPalButton.fulfilled, draft => {
        draft.status = Status.SUCCESS;
      })
      .addCase(payWithPayPalButton.rejected, draft => {
        draft.status = Status.ERROR;
      });
  },
});

const stripeSlice = createSlice({
  name: 'stripe',
  initialState: stripeInitialState,
  reducers: {
    saveSelectedCardPubkey: (draft, action) => {
      draft.cardPubkey = action.payload.cardPubkey;
    },
  },
  extraReducers: builder => {
    builder
      // reinitialize state upon signout
      .addCase(userSignout, () => stripeInitialState);

    builder.addCase(savePayment.fulfilled, (draft, action) => {
      if (action.payload.token) {
        draft.token = action.payload.token;
      } else {
        draft.cardPubkey = action.payload.cardPubkey;
      }
    });
    builder.addCase(saveStripePayment.fulfilled, (draft, action) => {
      draft.token = action.payload.token;
    });
    // --- external duxes ---
    builder.addCase(CLEAR_CHECKOUT, () => stripeInitialState);
  },
});

export const { reducer: payment, actions: paymentActions } = paymentSlice;
export const { reducer: stripe, actions: stripeActions } = stripeSlice;
export const { reducer: paypal } = paypalSlice;
