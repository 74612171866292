import * as Sentry from '@sentry/nextjs';

import getEnv from 'utils/getEnv';

import HTTPError from './HTTPError';

const CLOUD_FUNCTIONS_URL = getEnv('REACT_APP_CLOUD_FUNCTIONS_URL');

const compact = arr => arr.filter(o => o);

const fetchCloudFunction = async ({ url, body, signal = null }) => {
  try {
    const response = await fetch(new URL(url, CLOUD_FUNCTIONS_URL), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      referrerPolicy: 'unsafe-url',
      body: JSON.stringify(body),
      signal,
    });

    if (!response.ok) {
      Sentry.addBreadcrumb({ message: `Places error: ${response.status} ${response.statusText}` });
      throw new HTTPError({ code: 'fetch_places_error' });
    }

    return await response.json();
  } catch (error) {
    if (error.name === 'AbortError') {
      throw new HTTPError(
        {
          code: 'request_arborted',
          detail: error.message,
        },
        { cause: error }
      );
    }
    if (error.code !== 'fetch_places_error') {
      Sentry.addBreadcrumb({ message: `Fetch error: ${error.message}` });

      throw new HTTPError(
        {
          code: 'failed_to_fetch',
          detail: 'Failed to fetch',
        },
        { cause: error }
      );
    }

    throw error;
  }
};

export const fetchPlaces = async (input, country, options) => {
  const response = await fetchCloudFunction({
    url: '/suggestPlaces',
    body: { input: encodeURIComponent(input), country: encodeURIComponent(country || '') },
    signal: options?.signal,
  });

  return response.payload.predictions.map(prediction => ({
    id: prediction.place_id,
    label: prediction.description,
    address1: prediction.structured_formatting.main_text,
    matchedSubstrings: prediction.matched_substrings,
  }));
};

export const fetchSelectedPlace = async placeId => {
  const response = await fetchCloudFunction({
    url: '/placeDetails',
    body: { place_id: placeId },
  });

  const typeMap = {
    locality: 'long_name',
    sublocality: 'long_name',
    administrative_area_level_1: 'short_name',
    postal_code: 'long_name',
    street_number: 'long_name',
    route: 'long_name',
    street_address: 'long_name',
    country: 'short_name',
  };

  const o = response.payload.result.address_components.reduce(
    (acc, component) =>
      Object.assign(
        acc,
        ...component.types.map(type =>
          type in typeMap ? { [type]: component[typeMap[type]] } : {}
        )
      ),
    {}
  );

  return {
    address1: compact([o.street_number, o.route, o.street_address]).join(' '),
    city: o.locality || o.sublocality || '',
    state: o.administrative_area_level_1 || '',
    zipcode: o.postal_code || '',
    country: o.country || '',
  };
};

export const validateAddress = async address => {
  const response = await fetchCloudFunction({
    url: '/validateAddress',
    body: {
      address,
    },
  });
  return response?.payload?.result;
};
