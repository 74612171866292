import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

// this name to disambiguate from react-router own RouterState
type RouterReduxState = { location: { pathname: string; search: string } };

const initialState: RouterReduxState = {
  location: {
    pathname: '',
    search: '',
  },
};
const routerSlice = createSlice({
  name: 'router',
  initialState,
  reducers: {
    locationChanged: (draft, action: PayloadAction<RouterReduxState>) => {
      draft.location = action.payload.location;
    },
  },
});

const { reducer, actions } = routerSlice;

export const { locationChanged } = actions;

export default reducer;
