import { isAction, type Middleware } from '@reduxjs/toolkit';

import * as Sentry from '@sentry/nextjs';
import type { RootState } from 'store';

import { fetchUser } from 'dux/user/thunks';

// send each action type as Sentry breadcrumb
// get and send state and lastAction on exception

const sentry: Middleware<unknown, RootState> = (store) => {
  Sentry.configureScope((scope) => {
    scope.addEventProcessor((event) => {
      return {
        ...event,
        extra: {
          ...event.extra,
          'redux:state': store.getState(),
        },
      };
    });
  });

  return (next) => (action) => {
    if (fetchUser.fulfilled.match(action)) {
      Sentry.configureScope((scope) => {
        scope.setUser({
          id: action.payload?.pubkey,
          email: action.payload?.email,
        });
      });
    }

    Sentry.addBreadcrumb({
      category: 'redux-action',
      message: isAction(action) ? action.type : 'Not a redux action',
    });

    Sentry.configureScope((scope) => {
      scope.setExtra('redux:lastAction', action);
    });

    return next(action);
  };
};

export default sentry;
