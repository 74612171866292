import { PRODUCTS } from 'Apps/Feedback/constants/feedbackSections';
import {
  haircareHairOilNourishment,
  haircareImprovements,
  haircareNoConcernInImprovements,
  haircareNoImprovements,
  haircareSomeImprovements,
  haircareTooHeavy,
} from 'Apps/Feedback/constants/feedbackSlugs';

import { productsSlugs } from 'constants/products';

const hairOilNourishment = {
  name: haircareHairOilNourishment,
  route: `/feedback/haircare/${haircareHairOilNourishment}`,
  category: PRODUCTS,
  shouldBeIncluded: ({ formulas, feedbackAnswers }) =>
    formulas?.find(f => f.variant.product.type === productsSlugs.OIL) &&
    feedbackAnswers?.product_hair_oil_satisfaction,
  title: 'Did you hair feel nourished after using your hair oil?',
  options: [
    { value: haircareImprovements, label: 'Yes, my hair is well-nourished and soft' },
    { value: haircareSomeImprovements, label: 'A little, but it could feel more nourished' },
    { value: haircareTooHeavy, label: 'Sort of, but too heavy and weighed down' },
    { value: haircareNoImprovements, label: "No, it's just as dry & rough" },
    { value: haircareNoConcernInImprovements, label: "It wasn't a concern" },
  ],
  getInitialValue: answers => answers?.product_hair_oil_nourishment,
  getAnswers: ({ selected }) => ({ product_hair_oil_nourishment: selected }),
};

export default hairOilNourishment;
