import { PRODUCTS } from 'Apps/Feedback/constants/feedbackSections';
import {
  haircareHairMaskImpact,
  haircareImprovements,
  haircareNoImprovements,
  haircareSomeImprovements,
  haircareTooHeavy,
} from 'Apps/Feedback/constants/feedbackSlugs';

import { productsSlugs } from 'constants/products';

const hairMaskImpact = {
  name: haircareHairMaskImpact,
  route: `/feedback/haircare/${haircareHairMaskImpact}`,
  category: PRODUCTS,
  shouldBeIncluded: ({ formulas, feedbackAnswers }) =>
    formulas?.find(f => f.variant.product.type === productsSlugs.HAIR_MASK) &&
    feedbackAnswers?.product_hair_mask_satisfaction,
  title: 'Did you feel that the mask nourished your hair?',
  options: [
    { value: haircareImprovements, label: 'Yes, just the right amount' },
    { value: haircareSomeImprovements, label: 'Some, but not enough' },
    { value: haircareTooHeavy, label: "Yes, but it's way too heavy" },
    { value: haircareNoImprovements, label: 'No, I see no change' },
  ],
  getInitialValue: answers => answers?.product_hair_mask_impact,
  getAnswers: ({ selected }) => ({ product_hair_mask_impact: selected }),
};

export default hairMaskImpact;
