import { PRODUCTS } from 'Apps/Feedback/constants/feedbackSections';
import {
  haircareImprovements,
  haircareLeaveinConditionerManageable,
  haircareNoConcernInImprovements,
  haircareNoImprovements,
  haircareSomeImprovements,
} from 'Apps/Feedback/constants/feedbackSlugs';

import { productsSlugs } from 'constants/products';

const leaveInConditionerManageable = {
  name: haircareLeaveinConditionerManageable,
  route: `/feedback/haircare/${haircareLeaveinConditionerManageable}`,
  category: PRODUCTS,
  shouldBeIncluded: ({ formulas, feedbackAnswers }) =>
    formulas?.find(f => f.variant.product.type === productsSlugs.LEAVEIN_CONDITIONER) &&
    feedbackAnswers?.product_leavein_conditioner_satisfaction,
  title: 'Was your hair manageable after using your leave-in conditioner?',
  options: [
    { value: haircareImprovements, label: 'Yes, it is more manageable' },
    { value: haircareSomeImprovements, label: 'A little, but could be better' },
    { value: haircareNoImprovements, label: "No, it's just the same" },
    { value: haircareNoConcernInImprovements, label: "It wasn't a concern" },
  ],
  getInitialValue: answers => answers?.product_leavein_conditioner_manageable,
  getAnswers: ({ selected }) => ({ product_leavein_conditioner_manageable: selected }),
};

export default leaveInConditionerManageable;
