import { PRODUCTS } from 'Apps/Feedback/constants/feedbackSections';
import {
  haircareImprovements,
  haircareNoConcernInImprovements,
  haircareNoImprovements,
  haircareSomeImprovements,
  haircareStylingGelFlexible,
} from 'Apps/Feedback/constants/feedbackSlugs';

import { productsSlugs } from 'constants/products';

const stylingGelFlexible = {
  name: haircareStylingGelFlexible,
  route: `/feedback/haircare/${haircareStylingGelFlexible}`,
  category: PRODUCTS,
  shouldBeIncluded: ({ formulas, feedbackAnswers }) =>
    formulas?.find(f => f.variant.product.type === productsSlugs.STYLING_GEL) &&
    feedbackAnswers?.product_styling_gel_satisfaction,
  title: 'Did your hair feel flexible after using your styling gel?',
  options: [
    { value: haircareImprovements, label: 'Yes, it was soft and flexible' },
    { value: haircareSomeImprovements, label: 'A little, but not enough' },
    { value: haircareNoImprovements, label: 'No, it was too crunchy' },
    { value: haircareNoConcernInImprovements, label: "It wasn't a concern" },
  ],
  getInitialValue: answers => answers?.product_styling_gel_flexible,
  getAnswers: ({ selected }) => ({ product_styling_gel_flexible: selected }),
};

export default stylingGelFlexible;
