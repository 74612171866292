import { useLocation, useNavigate } from 'react-router-dom';

import { useCheckoutCategory } from 'Apps/Checkout/hooks/useCheckoutCategory';

import addOriginParam from 'utils/addOriginParam';

export const useGoToCart = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const checkoutCategory = useCheckoutCategory();

  const params = new URLSearchParams(location.search);
  const withOrigin = addOriginParam(params);

  if (params.get('origin') === 'gift') {
    return () => navigate('/gift');
  }

  return () => navigate(withOrigin(`/checkout/${checkoutCategory}`));
};
