import { type ReactNode, useEffect, useRef, useState } from 'react';

import isString from 'lodash/fp/isString';

import { styled } from '@prose-ui/legacy';
import Image, { type StaticImageData } from 'next/image';

import { handleKeyPress } from 'utils/a11y';
import getEnv from 'utils/getEnv';
import usePrefersReducedMotion from 'utils/usePrefersReducedMotion';

const videoRoot = getEnv('REACT_APP_MEDIA_ROOT_URL');

const getVideoUrl = (videoName: string) =>
  isString(videoRoot) && Boolean(videoRoot) ? videoRoot + videoName : undefined;

const VideoWrapper = styled.div`
  position: relative;
  cursor: pointer;
`;

const StyledVideo = styled.video<{ fullWidth: boolean; fullHeight: boolean }>`
  width: ${({ fullWidth }) => fullWidth && '100%'};
  height: ${({ fullHeight }) => fullHeight && '100%'};
  display: block;
`;

const OverlayWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
`;

type VideoProps = {
  autoplay?: boolean;
  videoSource: string;
  posterImage: StaticImageData;
  posterImageAlt: string;
  loop?: boolean;
  forcePause?: boolean;
  preload?: 'metadata' | 'none' | 'auto';
  onPlay?: () => void;
  onPause?: () => void;
  muted?: boolean;
  defaultControls?: boolean;
  fullWidth?: boolean;
  fullHeight?: boolean;
  overlay?: {
    playing?: ReactNode;
    paused?: ReactNode;
  };
  autoplayDelay?: number;
};

export const Video = ({
  videoSource: videoSrc,
  posterImage,
  fullWidth = false,
  fullHeight = false,
  muted = false,
  forcePause = false,
  posterImageAlt,
  loop = false,
  autoplay = false,
  preload = 'metadata',
  onPlay,
  onPause,
  defaultControls = false,
  overlay,
  autoplayDelay = 0,
}: VideoProps) => {
  const isReducedMotion = usePrefersReducedMotion();

  const videoUrl = getVideoUrl(videoSrc);

  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleOnPlay = () => {
    setIsPlaying(true);
    if (onPlay) {
      onPlay();
    }
  };

  const handleOnPause = () => {
    setIsPlaying(false);
    if (onPause) {
      onPause();
    }
  };

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> | null = null;

    if (autoplay) {
      timeoutId = setTimeout(() => videoRef.current?.play(), autoplayDelay);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [autoplay, autoplayDelay]);

  useEffect(() => {
    videoRef?.current?.load();
  }, [videoSrc]);

  useEffect(() => {
    if (forcePause && !(videoRef.current?.paused || videoRef.current?.ended)) {
      videoRef?.current?.pause();
    }
  }, [forcePause]);

  return (
    <VideoWrapper>
      <StyledVideo
        ref={videoRef}
        autoPlay={!isReducedMotion && autoplay && autoplayDelay <= 0}
        controls={defaultControls ? true : undefined}
        disablePictureInPicture
        fullHeight={fullHeight}
        fullWidth={fullWidth}
        loop={loop}
        muted={muted}
        onKeyPress={handleKeyPress(handleOnPlay)}
        onPause={handleOnPause}
        onPlay={handleOnPlay}
        playsInline
        poster={posterImage.src}
        preload={preload}
        tabIndex={isPlaying ? 0 : -1}
      >
        <source src={videoUrl && `${videoUrl}.mp4`} type="video/mp4" />
        <source src={videoUrl && `${videoUrl}.webm`} type="video/webm" />

        <Image alt={posterImageAlt} src={posterImage} />
        {posterImageAlt}
      </StyledVideo>

      {overlay?.playing && isPlaying && (
        <OverlayWrapper
          onClick={() => videoRef?.current?.pause()}
          onKeyPress={handleKeyPress(() => videoRef.current?.pause())}
        >
          {overlay.playing}
        </OverlayWrapper>
      )}
      {overlay?.paused && !isPlaying && !videoRef.current?.seeking && (
        <OverlayWrapper
          onClick={() => videoRef?.current?.play()}
          onKeyPress={handleKeyPress(() => videoRef.current?.play())}
        >
          {overlay.paused}
        </OverlayWrapper>
      )}
    </VideoWrapper>
  );
};
