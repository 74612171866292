import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'dux/app/hooks';

import { productsCategories } from 'constants/products';

import { shouldShowDataDrivenConsultation } from 'dux/featureFlags/selectors';

import { getHaircareQuestionSetForConsultation, getNormalizedHaicareQuestions } from './selectors';
import { fetchQuestionSets } from './thunks';

export const useQuestionSets = () => {
  const dispatch = useAppDispatch();
  const haircareQuestionSet = useAppSelector(getHaircareQuestionSetForConsultation);
  const normalizedHairQuestions = useAppSelector(getNormalizedHaicareQuestions);
  const showDataDrivenConsultation = useAppSelector(shouldShowDataDrivenConsultation);

  useEffect(() => {
    if (showDataDrivenConsultation) {
      dispatch(fetchQuestionSets({ consultationCategory: productsCategories.HAIRCARE }));
    }
  }, [showDataDrivenConsultation]);

  return { haircareQuestionSet, normalizedHairQuestions };
};
