import { createSlice } from '@reduxjs/toolkit';

import { userSignout } from 'dux/auth/thunks';

import {
  fetchConsultationSurvey,
  fetchFeedbackEndOfTheRoad,
  fetchFeedbackHaircareOrder,
  fetchFeedbackOrder,
  fetchFeedbackSkincareOrder,
  fetchFeedbackSupplementsOrder,
  getProductsFeedback,
  loadFeedback,
  patchHaircareFeedback,
  patchProductsFeedback,
  patchSkincareFeedback,
  patchSupplementsFeedback,
  saveFeedbackAnswers,
} from './thunks';

const initialState = {
  consultationSurvey: {},
  data: null,
  productSatisfactionData: null,
  endOfTheRoadMessage: null,
  error: null,
  haircareFeedbackCompleted: false,
  haircareOrder: {},
  status: 'idle',
  supplementsOrder: {},
  supplementsFeedbackCompleted: false,
  skincareOrder: {},
  skincareFeedbackCompleted: false,
};

const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      // reset state on user signout action
      .addCase(userSignout, () => initialState)

      // fetchFeedbackEndOfTheRoad
      .addCase(fetchFeedbackEndOfTheRoad.fulfilled, (draft, { payload }) => {
        draft.endOfTheRoadMessage = payload;
      })

      // fetchFeedback{Category}Order
      .addCase(fetchFeedbackHaircareOrder.fulfilled, (draft, { payload }) => {
        const { order, haircareOrderPubkey, supplementsOrderPubkey, withFeedback } = payload;
        draft.haircareOrder = order;
        if (withFeedback) {
          draft.data = order.feedback;
        }
        // Store haircare feedback status
        if (order?.feedback?.status) {
          draft.haircareFeedbackCompleted = order.feedback.status === 'completed';
        }

        if (haircareOrderPubkey === supplementsOrderPubkey) {
          draft.supplementsOrder = order;
          // Store supplements feedback status
          if (order?.supplements_feedback?.status) {
            draft.supplementsFeedbackCompleted = order.supplements_feedback.status === 'completed';
          }
        }
      })
      .addCase(fetchFeedbackSupplementsOrder.fulfilled, (draft, { payload }) => {
        const { order, withFeedback } = payload;
        draft.supplementsOrder = order;
        if (withFeedback) {
          draft.data = order.supplements_feedback;
        }
        // Store supplements feedback status
        if (order?.supplements_feedback?.status) {
          draft.supplementsFeedbackCompleted = order.supplements_feedback.status === 'completed';
        }
      })
      .addCase(fetchFeedbackSkincareOrder.fulfilled, (draft, { payload }) => {
        const { order, withFeedback } = payload;
        draft.skincareOrder = order;
        if (withFeedback) {
          draft.data = order.skincare_feedback;
        }
        // Store skincare feedback status
        if (order?.skincare_feedback?.status) {
          draft.skincareFeedbackCompleted = order.skincare_feedback.status === 'completed';
        }
      })

      // fetchFeedbackOrder error
      .addCase(fetchFeedbackOrder.rejected, (draft, { error }) => {
        draft.error = error;
        draft.status = 'error';
      })

      // loadFeedback
      .addCase(loadFeedback.pending, draft => {
        draft.status = 'loading';
      })
      .addCase(loadFeedback.fulfilled, draft => {
        draft.status = 'success';
      })
      .addCase(loadFeedback.rejected, (draft, { error }) => {
        draft.error = error;
        draft.status = 'error';
      })

      // fetchConsultationSurvey
      .addCase(fetchConsultationSurvey.fulfilled, (draft, { payload }) => {
        draft.consultationSurvey = payload;
      })
      .addCase(fetchConsultationSurvey.rejected, (draft, { error }) => {
        draft.error = error;
        draft.status = 'error';
      })

      // patch{Category}Feedback
      .addCase(patchHaircareFeedback.fulfilled, (draft, { payload }) => {
        draft.data = payload;
        draft.status = 'success';
      })
      .addCase(patchHaircareFeedback.rejected, (draft, { error }) => {
        draft.error = error;
        draft.status = 'error';
      })
      .addCase(patchSupplementsFeedback.fulfilled, (draft, { payload }) => {
        draft.data = payload;
        draft.status = 'success';
      })
      .addCase(patchSupplementsFeedback.rejected, (draft, { error }) => {
        draft.error = error;
        draft.status = 'error';
      })
      .addCase(patchSkincareFeedback.fulfilled, (draft, { payload }) => {
        draft.data = payload;
        draft.status = 'success';
      })
      .addCase(patchSkincareFeedback.rejected, (draft, { error }) => {
        draft.error = error;
        draft.status = 'error';
      })
      // patchProductsFeedback
      .addCase(patchProductsFeedback.fulfilled, (draft, { payload }) => {
        draft.productSatisfactionData = payload;
        draft.status = 'success';
      })
      .addCase(patchProductsFeedback.rejected, (draft, { error }) => {
        draft.error = error;
        draft.status = 'error';
      })
      // getProductsFeedback
      .addCase(getProductsFeedback.fulfilled, (draft, { payload }) => {
        draft.productSatisfactionData = payload;
        draft.status = 'success';
      })
      .addCase(getProductsFeedback.rejected, (draft, { error }) => {
        draft.error = error;
        draft.status = 'error';
      })

      // saveFeedbackAnswers
      .addCase(saveFeedbackAnswers.pending, draft => {
        draft.status = 'saving';
      })
      .addCase(saveFeedbackAnswers.fulfilled, draft => {
        draft.status = 'success';
      })
      .addCase(saveFeedbackAnswers.rejected, (draft, { error }) => {
        draft.error = error;
        draft.status = 'error';
      });
  },
});

export const { reducer } = feedbackSlice;
