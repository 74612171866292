import { useAppSelector } from 'dux/app/hooks';

import { productsCategories } from 'constants/products';

import * as checkoutCartSelectors from 'dux/checkoutCart/selectors';

import { useCheckoutCategory } from './useCheckoutCategory';

export const useCheckoutLegalBlockInfosByCartType = () => {
  const checkoutCategory = useCheckoutCategory();

  // Cart V1
  const cartHasSubscription = useAppSelector(checkoutCartSelectors.cartHasSubscription);

  if (checkoutCategory === productsCategories.ACCESSORIES) {
    return {
      isCartV2: true,
    };
  }

  return {
    cartHasSubscription,
  };
};
