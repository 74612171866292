import { PRODUCTS } from 'Apps/Feedback/constants/feedbackSections';
import { haircareHairMaskSatisfaction } from 'Apps/Feedback/constants/feedbackSlugs';

import { productsSlugs } from 'constants/products';

const hairMaskSatisfaction = {
  name: haircareHairMaskSatisfaction,
  route: `/feedback/haircare/${haircareHairMaskSatisfaction}`,
  category: PRODUCTS,
  shouldBeIncluded: ({ formulas }) =>
    formulas?.find(f => f.variant.product.type === productsSlugs.HAIR_MASK),
  component: 'Rating',
  title: 'How satisfied are you with your hair mask?',
  options: [
    { value: 1, label: 'Very Unsatisfied' },
    { value: 2, label: 'Unsatisfied' },
    { value: 3, label: 'Neutral' },
    { value: 4, label: 'Satisfied' },
    { value: 5, label: 'Very Satisfied' },
  ],
  skippable: "I haven't used it enough",
  skippableValue: 0,
  hasOpenText: true,
  getInitialValue: answers => answers?.product_hair_mask_satisfaction,
  getInitialProductFeedback: productsAnswers => {
    return productsAnswers.find(answer => answer.product === productsSlugs.HAIR_MASK)
      ?.open_text_feedback;
  },
  getAnswers: ({ selected }) => {
    if (selected === 0) {
      return { product_hair_mask_satisfaction: selected, product_hair_mask_impact: null };
    }
    return { product_hair_mask_satisfaction: selected };
  },
  getFeedbackAnswer: ({ openText }) => ({
    product: productsSlugs.HAIR_MASK,
    open_text_feedback: openText?.feedback_text,
  }),
};

export default hairMaskSatisfaction;
