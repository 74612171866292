import assign from 'lodash/fp/assign';
import compact from 'lodash/fp/compact';
import curry from 'lodash/fp/curry';
import find from 'lodash/fp/find';
import flow from 'lodash/fp/flow';
import join from 'lodash/fp/join';
import map from 'lodash/fp/map';
import set from 'lodash/fp/set';
import update from 'lodash/fp/update';

import * as Sentry from '@sentry/nextjs';

import logSentryError from './logSentry';

const getItemUniqueId = flow(item => [item.category, item.type, item.product], compact, join('_'));

const itemIdMatches = curry((item1, item2) => getItemUniqueId(item1) === getItemUniqueId(item2));

const updateCatalog = itemsToUpdate =>
  update(
    'catalog',
    map(currentCatalogItem => {
      const foundItemToUpdate = find(itemIdMatches(currentCatalogItem))(itemsToUpdate);
      return foundItemToUpdate
        ? flow(
            set('quantity', foundItemToUpdate?.quantity),
            update('subscription', subscription =>
              assign(subscription, {
                active: foundItemToUpdate?.subscription?.active,
                frequency: foundItemToUpdate?.subscription?.frequency || subscription?.frequency,
              })
            )
          )(currentCatalogItem)
        : currentCatalogItem;
    })
  );

const optimisticCartUpdate = (currentCart, itemsToUpdate) => {
  try {
    return updateCatalog(itemsToUpdate)(currentCart);
  } catch (error) {
    Sentry.withScope(scope => {
      scope.setExtra('cart', currentCart);
      scope.setExtra('items', itemsToUpdate);
      logSentryError('[optimisticCartUpdate]', error);
    });

    return currentCart;
  }
};

export default optimisticCartUpdate;
