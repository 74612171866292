import * as supportStatuses from './constants/supportStatuses';

export default {
  fetchedCategories: [],
  categoriesStatus: supportStatuses.IDLE,
  fetchedArticles: [],
  articlesStatus: supportStatuses.IDLE,
  fetchedSearch: [],
  searchStatus: supportStatuses.IDLE,
  fetchedTags: [],
  fetchedTagsStatus: supportStatuses.IDLE,
  inquiryStatus: supportStatuses.IDLE,
};
