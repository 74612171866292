import * as feedbackComponents from 'Apps/Feedback/constants/feedbackComponents';
import { PRODUCTS } from 'Apps/Feedback/constants/feedbackSections';
import { skincareCleanserSatisfaction } from 'Apps/Feedback/constants/feedbackSlugs';

import { feedbackCategories } from 'constants/feedback';
import { productsSlugs } from 'constants/products';

const cleanserSatisfaction = {
  name: skincareCleanserSatisfaction,
  route: '/feedback/skincare/cleanser-satisfaction',
  category: PRODUCTS,
  feedbackCategory: feedbackCategories.SKINCARE,
  shouldBeIncluded: ({ formulas }) =>
    formulas?.find(f => f.variant.product.type === productsSlugs.CLEANSER),
  component: feedbackComponents.Rating,
  title: 'How satisfied are you with your cleanser?',
  subtitle: '',
  skippable: 'I haven’t used it enough',
  options: [
    { value: 1, label: 'Very Unsatisfied', slug: 'very_unsatisfied' },
    { value: 2, label: 'Unsatisfied', slug: 'unsatisfied' },
    { value: 3, label: 'Neutral', slug: 'neutral' },
    { value: 4, label: 'Satisfied', slug: 'satisfied' },
    { value: 5, label: 'Very Satisfied', slug: 'very_satisfied' },
  ],
  hasOpenText: true,
  getInitialValue: answers => answers?.cleanser_satisfaction,
  getInitialProductFeedback: productsAnswers => {
    return productsAnswers.find(answer => answer.product === productsSlugs.CLEANSER)
      ?.open_text_feedback;
  },
  getAnswers: ({ selected }) => ({ cleanser_satisfaction: selected }),
  getFeedbackAnswer: ({ openText }) => ({
    product: productsSlugs.CLEANSER,
    open_text_feedback: openText?.feedback_text,
  }),
};

export default cleanserSatisfaction;
