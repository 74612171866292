import PropTypes from 'prop-types';

import { styled } from '@prose-ui/legacy';

import AddressAutoCompleteInput from 'Apps/Checkout/Components/AddressAutoCompleteInput';
import PaymentTextField from 'Apps/Checkout/Components/PaymentTextField';
import StateInput from 'Apps/Checkout/Components/StateInput';
import { CHECKOUT_OPTIMISATION_THEME } from 'Apps/Checkout/constants/checkoutInputThemes';

import CountrySelectForm from 'Components/CountrySelectForm';

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const AddressFields = ({ selectedCountry, countriesList }) => {
  return (
    <Root>
      {/* Country field */}
      <CountrySelectForm
        ariaRequired
        autoCorrect={false}
        label="Country*"
        list={countriesList?.map(country => ({ label: country.name, value: country.code }))}
        name="newAddress.country"
        testId="address-form-country"
        theme={CHECKOUT_OPTIMISATION_THEME}
      />

      {/* User name field */}
      <PaymentTextField
        ariaRequired
        autoCorrect={false}
        label="Full name*"
        name="newAddress.name"
        theme={CHECKOUT_OPTIMISATION_THEME}
      />

      {/* Address field */}
      <AddressAutoCompleteInput
        ariaRequired
        autoCorrect={false}
        label="Address*"
        name="newAddress.address1"
        selectedCountry={selectedCountry}
        testId="address-form-1"
        theme={CHECKOUT_OPTIMISATION_THEME}
      />

      {/* Address 2 field */}
      <PaymentTextField
        autoCorrect={false}
        label="Apartment, suite, etc."
        name="newAddress.address2"
        theme={CHECKOUT_OPTIMISATION_THEME}
      />

      {/* Address city field */}
      <PaymentTextField
        ariaRequired
        autoCorrect={false}
        label="City*"
        name="newAddress.city"
        testId="address-form-city"
        theme={CHECKOUT_OPTIMISATION_THEME}
      />

      {/* Address State/Province field */}
      {/* The label is automatically set in the component from the country code */}
      <StateInput
        ariaRequired
        countriesList={countriesList}
        name="newAddress.state"
        testId="address-form-state"
        theme={CHECKOUT_OPTIMISATION_THEME}
      />

      {/* Address zipcode field */}
      <PaymentTextField
        ariaRequired
        autoCorrect={false}
        label="Zip code*"
        name="newAddress.zipcode"
        testId="address-form-zipcode"
        theme={CHECKOUT_OPTIMISATION_THEME}
      />

      {/* Address nickname field */}
      <PaymentTextField
        autoCorrect={false}
        label="Nickname (optional)"
        name="newAddress.label"
        theme={CHECKOUT_OPTIMISATION_THEME}
      />
    </Root>
  );
};

AddressFields.defaultProps = {
  countriesList: null,
  selectedCountry: null,
};

AddressFields.propTypes = {
  countriesList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      code: PropTypes.string,
    })
  ),
  selectedCountry: PropTypes.string,
};

export default AddressFields;
