import PropTypes from 'prop-types';

import { Field, getIn } from 'formik';

import {
  CHECKOUT_DEFAULT_THEME,
  CHECKOUT_OPTIMISATION_THEME,
} from 'Apps/Checkout/constants/checkoutInputThemes';

import { AddressAutoComplete } from './AddressAutoComplete';

/**
 * Formik wrapper for the AddressAutoComplete component
 * Avoids re-rendering while the first address input doesn't change.
 */

// set all fields with the returning address
const setAddress = setFieldValue => address =>
  ['address1', 'address2', 'city', 'country', 'state', 'zipcode'].forEach(field => {
    setFieldValue(`newAddress.${field}`, address[field] || '');
  });

const AddressAutoCompleteInput = ({
  selectedCountry,
  name,
  label,
  textFieldClassName,
  testId,
  theme,
  autoCorrect,
  hideInfo,
  ariaRequired,
}) => (
  <Field name={name}>
    {({ field, form }) => {
      const isInputTouched = getIn(form.touched, name);
      const inputError = isInputTouched ? getIn(form.errors, name) : '';

      const textFieldProps = {
        id: name,
        label,
        onBlur: field.onBlur,
        error: inputError && `Address ${inputError.toLowerCase()}`,
        className: textFieldClassName,
        autoCorrect,
      };

      return (
        <AddressAutoComplete
          ariaInvalid={Boolean(inputError)}
          ariaRequired={ariaRequired}
          hideInfo={hideInfo}
          onAddressAutoComplete={setAddress(form.setFieldValue)}
          onChange={value => form.setFieldValue(name, value)}
          selectedCountry={selectedCountry}
          testId={testId}
          textFieldProps={textFieldProps}
          theme={theme}
          value={field.value || ''}
        />
      );
    }}
  </Field>
);

AddressAutoCompleteInput.propTypes = {
  // Metadata for screen readers
  ariaRequired: PropTypes.bool,
  // Label to describe the input
  label: PropTypes.string.isRequired,
  hideInfo: PropTypes.bool,
  // Name can be a nested path like newAddress.address1
  name: PropTypes.string.isRequired,
  // The className to be passed to the TextFieldComponent
  textFieldClassName: PropTypes.string,
  // Test id for cypress
  testId: PropTypes.string,
  selectedCountry: PropTypes.string,
  autoCorrect: PropTypes.bool,
  theme: PropTypes.oneOf([CHECKOUT_DEFAULT_THEME, CHECKOUT_OPTIMISATION_THEME]),
};

AddressAutoCompleteInput.defaultProps = {
  ariaRequired: false,
  autoCorrect: true,
  theme: null,
  hideInfo: false,
};

export default AddressAutoCompleteInput;
