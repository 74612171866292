import { useEffect, useState } from 'react';

import useEffectOnResize from './useEffectOnResize';

const useRerenderOnResize = () => {
  const [needsRerender, setNeedsRerender] = useState(false);

  useEffectOnResize(
    () => {
      setNeedsRerender(true);
    },
    [],
    { immediate: false },
  );

  useEffect(() => {
    if (needsRerender) setNeedsRerender(false);
  }, [needsRerender]);
};

export default useRerenderOnResize;
