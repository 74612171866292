import { useCallback, useLayoutEffect } from 'react';

type CleanUp = () => void;

const useEffectOnResize = (
  effect: (evt?: UIEvent) => CleanUp | void,
  dependencies: Array<any> = [],
  options: { immediate?: boolean } = {},
) => {
  const { immediate = true } = options;

  const memoizedEffect = useCallback(effect, dependencies);

  useLayoutEffect(() => {
    let cleanUp: CleanUp | void;

    function onResize(evt: UIEvent) {
      cleanUp = memoizedEffect(evt);
    }

    window?.addEventListener('resize', onResize);

    if (immediate) {
      memoizedEffect();
    }

    return () => {
      window?.removeEventListener('resize', onResize);
      if (cleanUp) cleanUp();
    };
  }, [memoizedEffect]);
};

export default useEffectOnResize;
