import PropTypes from 'prop-types';

import { styled } from '@prose-ui/legacy';

import Button from 'Components/LegacyButton';
import Title from 'Components/Title';
import Typography from 'Components/Typography';

import BaseScene from 'Scenes/LegacyBaseScene';

const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const reloadLocation = () => window.location.reload();

const ErrorScene = ({ hideCTA, isOffline, labelCTA, message, onClick, title, banner }) => {
  const defaultTitle = isOffline
    ? 'Sorry, we cannot open the page because the connection was lost.'
    : 'Oops, something went wrong...';

  return (
    <BaseScene banner={banner} header justifyContent="space-between" padding>
      <Text>
        <Title>{title || defaultTitle}</Title>
        {message && (
          <Typography align="center" variant="p2">
            {message}
          </Typography>
        )}
      </Text>

      {!hideCTA && (
        <Button onClick={onClick} variant="underlined">
          {labelCTA}
        </Button>
      )}
    </BaseScene>
  );
};

ErrorScene.propTypes = {
  hideCTA: PropTypes.bool,
  isOffline: PropTypes.bool,
  labelCTA: PropTypes.string,
  message: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string,
  banner: PropTypes.bool,
};

ErrorScene.defaultProps = {
  hideCTA: false,
  isOffline: false,
  labelCTA: "Let's try again",
  message: '',
  onClick: reloadLocation,
  title: '',
  banner: true,
};

export default ErrorScene;
