import PropTypes from 'prop-types';

import {
  CHECKOUT_DEFAULT_THEME,
  CHECKOUT_OPTIMISATION_THEME,
} from 'Apps/Checkout/constants/checkoutInputThemes';

import SelectField from 'Components/SelectField';

const CountrySelect = ({
  value,
  onChange,
  label,
  list,
  error,
  theme,
  testId,
  ariaInvalid,
  ariaRequired,
}) => (
  <SelectField
    aria-invalid={ariaInvalid ? 'true' : 'false'}
    aria-required={ariaRequired ? 'true' : 'false'}
    data-testid={testId}
    error={error}
    id="country"
    label={label}
    list={list}
    onChange={onChange}
    theme={theme}
    value={value}
  />
);

CountrySelect.propTypes = {
  ariaInvalid: PropTypes.bool,
  ariaRequired: PropTypes.bool,
  label: PropTypes.string,
  error: PropTypes.string,
  testId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  value: PropTypes.string,
  theme: PropTypes.oneOf([CHECKOUT_DEFAULT_THEME, CHECKOUT_OPTIMISATION_THEME]),
};
CountrySelect.defaultProps = {
  // Meta data for screen readers
  ariaInvalid: false,
  ariaRequired: false,
  // Others
  label: null,
  testId: '',
  error: '',
  value: null,
  list: [],
  theme: null,
};

export default CountrySelect;
