import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { forbidExtraProps } from 'airbnb-prop-types';

import { connect } from 'react-redux';

import { theme } from '@prose-ui';
import { styled } from '@prose-ui/legacy';

import { closeRootToast as closeRootToastAction } from 'dux/user/actions';

import ToastPortal from './ToastPortal';
import Typography from './Typography';

const DISPLAY_DURATION = 5000;
const ANIMATE_OUT_DURATION = 250;

const ToastContainer = styled.div`
  pointer-events: none;
  position: fixed;
  top: 95px;
  right: 0px;
  overflow: hidden;

  // Originally at 1100 but for the first-trial-offer-promo-ab-test we need to put it to this value
  z-index: 1099;

  width: 100%;

  @media (min-width: 768px) {
    width: ${({ isFullWidth }) => (isFullWidth ? '100%' : 'calc(100vw - 225px)')};
  }

  @media (min-width: 1024px) {
    width: ${({ isFullWidth }) => (isFullWidth ? '100vw' : '80vw')};
  }
`;

const Toast = styled.div`
  pointer-events: auto; /* to help cypress 'see' this element */
  transform: ${({ isOpen }) => (isOpen ? 'translateY(0)' : 'translateY(calc(-100% - 1px))')};
  transition: ${({ isOpen }) =>
    isOpen ? 'transform 500ms' : `transform ${ANIMATE_OUT_DURATION}ms`};
`;

const Text = styled(Typography)`
  padding: 14px 24px;
  background-color: ${({ bgcolor }) => bgcolor};
  color: ${({ color }) => color};
`;

const BasicToast = ({
  children,
  bgcolor,
  textColor,
  dataTestId,
  isFullWidth,
  shouldDisplayToast,
  closeRootToast,
  isPermanent,
}) => {
  useEffect(() => {
    let timeout;
    if (shouldDisplayToast && !isPermanent) {
      timeout = setTimeout(() => {
        closeRootToast();
      }, DISPLAY_DURATION);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [shouldDisplayToast]);

  return (
    <ToastPortal>
      <ToastContainer isFullWidth={isFullWidth}>
        <Toast data-testid={dataTestId} isOpen={shouldDisplayToast}>
          <Text align="center" bgcolor={bgcolor} color={textColor} variant="p3">
            {children}
          </Text>
        </Toast>
      </ToastContainer>
    </ToastPortal>
  );
};

BasicToast.defaultProps = {
  dataTestId: 'toast-banner',
  isFullWidth: false,
  textColor: 'white',
  bgcolor: theme.colors.primary[300],
  shouldDisplayToast: false,
  isPermanent: false,
};
BasicToast.propTypes = forbidExtraProps({
  children: PropTypes.node.isRequired,
  textColor: PropTypes.string,
  bgcolor: PropTypes.string,
  dataTestId: PropTypes.string,
  isFullWidth: PropTypes.bool,
  shouldDisplayToast: PropTypes.bool,
  closeRootToast: PropTypes.func.isRequired,
  isPermanent: PropTypes.bool,
});

export default connect(null, { closeRootToast: closeRootToastAction })(BasicToast);
