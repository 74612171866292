export const CONSENT_COOKIE_NAME = 'prose-consent';
export const CONSENT_COOKIE_DURATION = 730; // Days

export const CONSENT_DENIED = 'denied' as const;
export const CONSENT_GRANTED = 'granted' as const;
export const CONSENT_UPDATED_EVENT = 'onConsentUpdated' as const;

const CONSENT_PARAMETERS_DENIED = {
  ad_personalization: CONSENT_DENIED,
  ad_storage: CONSENT_DENIED,
  ad_user_data: CONSENT_DENIED,
  analytics_storage: CONSENT_DENIED,
  functionality_storage: CONSENT_GRANTED, // Essential tracking (must be enabled for Prose)
  personalization_storage: CONSENT_GRANTED, // Essential tracking (must be enabled for Prose)
  security_storage: CONSENT_GRANTED, // Essential tracking (must be enabled for Prose)
} as const;

export const CONSENT_PARAMETERS_GRANTED = {
  ad_personalization: CONSENT_GRANTED,
  ad_storage: CONSENT_GRANTED,
  ad_user_data: CONSENT_GRANTED,
  analytics_storage: CONSENT_GRANTED,
  functionality_storage: CONSENT_GRANTED,
  personalization_storage: CONSENT_GRANTED,
  security_storage: CONSENT_GRANTED,
} as const;

export const consentDefaultValuesByCountry: any = {
  CA: CONSENT_PARAMETERS_DENIED,
  US: CONSENT_PARAMETERS_GRANTED,
  Default: CONSENT_PARAMETERS_GRANTED,
};
