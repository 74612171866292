import { createSlice } from '@reduxjs/toolkit';

import {
  haircareTopicalProducts,
  productsCategories,
  productsSubCategories,
  skincareProducts,
} from 'constants/products';
import * as Status from 'constants/statuses';

import { userSignout } from 'dux/auth/actions';

import { fetchMembership, setSelectedProduct } from './thunks';

export type FreeProductSlug =
  | (typeof haircareTopicalProducts)[number]
  | (typeof skincareProducts)[number]; // TODO: check with API if we also expect supplements here

type CurrentSelectedFreeProduct = {
  slug: FreeProductSlug | '';
  expired_at: string;
  category: keyof typeof productsCategories | null;
  sub_category: keyof typeof productsSubCategories | null;
};

export type AccountMembershipRawData = {
  member_since: string;
  next_order_date: string;
  number_of_product_bought_since_last_unlock: number;
  number_of_pending_selection_products: number;
  current_selected_free_product: CurrentSelectedFreeProduct | null;
  free_product_catalog: Array<{
    slug: FreeProductSlug;
    recommended: boolean;
  }>;
};

type MembershipState = {
  status: keyof typeof Status;
  data: AccountMembershipRawData | null;
  // TODO: error
  currentSelectedFreeProduct: CurrentSelectedFreeProduct | null;
};

const initialState: MembershipState = {
  status: Status.IDLE,
  data: null,
  currentSelectedFreeProduct: null,
};

const membershipSlice = createSlice({
  name: 'membership',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // reinitialize state upon signout
      .addCase(userSignout, () => initialState)

      .addCase(fetchMembership.pending, (draft) => {
        draft.status = Status.LOADING;
      })
      .addCase(fetchMembership.fulfilled, (draft, { payload }) => {
        draft.status = Status.SUCCESS;
        draft.data = payload;
        draft.currentSelectedFreeProduct = payload.current_selected_free_product;
      })
      .addCase(fetchMembership.rejected, (draft) => {
        draft.status = Status.ERROR;
      })
      .addCase(setSelectedProduct.pending, (draft) => {
        draft.status = Status.LOADING;
      })
      .addCase(setSelectedProduct.fulfilled, (draft, { payload }) => {
        draft.status = Status.SUCCESS;
        draft.currentSelectedFreeProduct = payload.current_selected_free_product;
      })
      .addCase(setSelectedProduct.rejected, (draft) => {
        draft.status = Status.ERROR;
      });
  },
});

export const { reducer } = membershipSlice;
