import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Navigate } from 'react-router-dom';

import { connect } from 'react-redux';

import { Formik } from 'formik';

import Signin from 'Apps/Main/Scenes/Signin';

import * as AuthService from 'Services/AuthService';
import { isFetchError } from 'Services/HTTPError';

import ErrorScene from 'Scenes/ErrorScene';
import LoadingScene from 'Scenes/LoadingScene';
import MagicLinkSent from 'Scenes/MagicLinkSent';

import useSearchParams from 'utils/useQueryParams';
import { isValidEmail } from 'utils/validators';

import { fetchAuth as fetchAuthAction } from 'dux/auth/actions';
import { getIsAuthenticated } from 'dux/auth/selectors';
import { getUserError, getUserIsLoading, getUserReferral } from 'dux/user/selectors';

const SigninContainer = ({ isUserLoading, userError, userReferral, fetchAuth, auth }) => {
  const SearchParams = useSearchParams();
  const [magicLinkSent, setMagicLinkSent] = useState(SearchParams.get('magic-link-sent') ?? false);
  const redirectionUrl = SearchParams.get('next') ?? null;
  const fromUrlParam = SearchParams.get('from') ?? null;
  useEffect(() => {
    if (!isUserLoading && userError && isFetchError(userError)) {
      fetchAuth();
    }
  }, [isUserLoading, userError]);

  if (isUserLoading) {
    return <LoadingScene />;
  }

  // user can be redirected to this component if any issue fetching user
  //  in this case we retry
  if (userError && isFetchError(userError)) {
    return <ErrorScene isOffline onClick={fetchAuth} />;
  }

  if (auth) {
    return <Navigate replace to="/account" />;
  }

  if (magicLinkSent) {
    return <MagicLinkSent />;
  }

  return (
    <Formik
      initialValues={{ email: '' }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        const params = { username: values.email };
        if (redirectionUrl) {
          params.next = redirectionUrl;
        }
        if (userReferral?.code) {
          params.coupon = userReferral.code;
        }
        if (userReferral?.source) {
          params.couponSource = userReferral.source;
        }
        if (fromUrlParam) {
          params.from = fromUrlParam;
        }
        const result = await AuthService.requestMagicLink(params);

        switch (result) {
          case 'success':
            setMagicLinkSent(true);
            break;
          case 'unknownUser':
            setErrors({ email: 'Unknown email' });
            setSubmitting(false);
            break;
          case 'error':
          default:
            setErrors({ email: 'Oops, we encountered an error, please try again' });
            setSubmitting(false);
            break;
        }
      }}
      validate={values => {
        // note that formik will not submit if errors is not an empty object.
        const errors = {};
        if (!values.email) {
          errors.email = 'Required';
        } else if (!isValidEmail(values.email)) {
          errors.email = 'Must be a valid email';
        }
        return errors;
      }}
    >
      {({ handleSubmit, handleBlur, isSubmitting, values, touched, handleChange, errors }) => {
        return (
          <Signin
            email={values.email}
            error={touched.email && errors.email ? errors.email : ''}
            handleBlur={handleBlur}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            isValidLogin={values.email ? !errors.email : false}
          />
        );
      }}
    </Formik>
  );
};

SigninContainer.propTypes = {
  auth: PropTypes.bool.isRequired,
  fetchAuth: PropTypes.func.isRequired,
  isUserLoading: PropTypes.bool.isRequired,
  userError: PropTypes.shape({}),
  userReferral: PropTypes.shape({
    code: PropTypes.string,
    source: PropTypes.string,
  }),
};

SigninContainer.defaultProps = {
  userError: null,
  userReferral: null,
};

const mapStateToProps = state => ({
  auth: getIsAuthenticated(state),
  isUserLoading: getUserIsLoading(state),
  userError: getUserError(state),
  userReferral: getUserReferral(state),
});

const mapDispatchToProps = {
  fetchAuth: fetchAuthAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SigninContainer);
