import { createAsyncThunk } from '@reduxjs/toolkit';

import { authClient } from 'Services/AuthClient';
import { isNotAuthenticated } from 'Services/HTTPError';

import logSentryError from 'utils/logSentry';

export type Country = {
  name: string;
  code: string;
  states: string[][];
};

export const fetchCountries = createAsyncThunk('countries/fetchCountries', async () => {
  try {
    const response = await authClient.get('v1/countries/');
    const countries = await response.json<Country[]>();
    return { countries };
  } catch (error) {
    if (isNotAuthenticated(error)) {
      logSentryError('[dux/countries] fetchCountries', error);
    }
    throw error;
  }
});
