import cancel from 'assets/images/membership/cancel.svg';
import freeProduct from 'assets/images/membership/free-product.svg';
import freeShipping from 'assets/images/membership/free-shipping.svg';

export const subscriptionPerksContent = {
  title: {
    haircare: {
      onlySkincareSubscription: ['Save ', 'when you subscribe to haircare'],
      onlyHaircareSubscription: ['Save ', 'when you subscribe to skincare'],
      bothSubscription: ['You’re ', 'saving ', ' as an haircare and skincare subscriber!'],
      noSubscription: ['Save ', ' when you subscribe to haircare'],
    },
    skincare: {
      onlySkincareSubscription: ['Save ', 'when you subscribe to haircare'],
      onlyHaircareSubscription: ['Save ', 'when you subscribe to skincare'],
      bothSubscription: ['You’re ', 'saving ', ' as an haircare and skincare subscriber!'],
      noSubscription: ['Save ', ' when you subscribe to skincare'],
    },
  },
  content: [
    { logo: cancel, text: 'Cancel any time', alt: 'cross logo', width: 14, height: 14 },
    { logo: freeShipping, text: 'Free shipping', alt: 'truck logo', width: 23, height: 12 },
    {
      logo: freeProduct,
      text: 'Buy 10 products, get 1 free',
      alt: 'shampoo bottle logo',
      width: 17,
      height: 19,
    },
  ],
};
