import { PRODUCTS } from 'Apps/Feedback/constants/feedbackSections';
import {
  haircareImprovements,
  haircareLeaveinConditionerMoisture,
  haircareNoConcernInImprovements,
  haircareNoImprovements,
  haircareSomeImprovements,
  haircareTooHeavy,
} from 'Apps/Feedback/constants/feedbackSlugs';

import { productsSlugs } from 'constants/products';

const leaveInConditionerMoisture = {
  name: haircareLeaveinConditionerMoisture,
  route: `/feedback/haircare/${haircareLeaveinConditionerMoisture}`,
  category: PRODUCTS,
  shouldBeIncluded: ({ formulas, feedbackAnswers }) =>
    formulas?.find(f => f.variant.product.type === productsSlugs.LEAVEIN_CONDITIONER) &&
    feedbackAnswers?.product_leavein_conditioner_satisfaction,
  title: 'Did your hair feel moisturized after using your leave-in conditioner?',
  options: [
    { value: haircareImprovements, label: "Yes, it's well-hydrated & soft" },
    { value: haircareTooHeavy, label: 'Sort of, but it feels weighed down' },
    { value: haircareSomeImprovements, label: 'Some, but not enough' },
    { value: haircareNoImprovements, label: "No, it's just as dry & rough" },
    { value: haircareNoConcernInImprovements, label: "It wasn't a concern" },
  ],
  getInitialValue: answers => answers?.product_leavein_conditioner_moisture,
  getAnswers: ({ selected }) => ({ product_leavein_conditioner_moisture: selected }),
};

export default leaveInConditionerMoisture;
