import { SKIN } from 'Apps/Feedback/constants/feedbackSections';
import {
  skincareAntiRedness,
  skincareImprovements,
  skincareNoConcernInImprovements,
  skincareNoImprovements,
  skincareSomeImprovements,
} from 'Apps/Feedback/constants/feedbackSlugs';

import { feedbackCategories } from 'constants/feedback';

const skinAntiRedness = {
  name: skincareAntiRedness,
  route: '/feedback/skincare/skin-anti-redness',
  category: SKIN,
  feedbackCategory: feedbackCategories.SKINCARE,
  shouldBeIncluded: () => true,
  title: 'Did you see a reduction in redness?',
  options: [
    { value: skincareImprovements, label: 'Yes, I see improvement' },
    { value: skincareSomeImprovements, label: 'Some, but could be better' },
    { value: skincareNoImprovements, label: 'No, I still have redness' },
    { value: skincareNoConcernInImprovements, label: "It wasn't a concern" },
  ],
  getInitialValue: answers => answers?.skin_anti_redness,
  getAnswers: ({ selected }) => ({ skin_anti_redness: selected }),
};

export default skinAntiRedness;
