import get from 'lodash/fp/get';

import * as Sentry from '@sentry/nextjs';

import * as haircareFragrances from 'constants/haircareFragrances';
import * as hairOilFragrances from 'constants/hairOilFragrances';
import * as skincareFragrances from 'constants/skincareFragrances';

import * as haircareFragrancesContent from 'assets/content/haircareFragrances';
import {
  ARCADIA,
  AURA,
  BOTANICA,
  CANDELA,
  CORSICA,
  ELIXIR,
  FETE,
  FRAGRANCE_FREE,
  FRUITISSIME,
  GLACIA,
  ISPAHAN,
  MELENI,
  NAPOLI,
  OASIS,
  PALMA,
  PARADISO,
  PERLE,
  PRELUDE,
  RITUEL,
  SIGNATURE,
  VITAE,
} from 'assets/content/haircareFragrances';
import { SOLSTICE } from 'assets/content/hairOilFragrances';
import * as skincareFragrancesContent from 'assets/content/skincareFragrances';
import { BLANCA, PACIFICA } from 'assets/content/skincareFragrances';

export const getFragranceDetails = (fragrance: string) => {
  switch (fragrance) {
    case haircareFragrances.ARCADIA:
      return ARCADIA;
    case haircareFragrances.AURA:
      return AURA;
    case haircareFragrances.BOTANICA:
      return BOTANICA;
    case haircareFragrances.CANDELA:
      return CANDELA;
    case haircareFragrances.CORSICA:
      return CORSICA;
    case haircareFragrances.ELIXIR:
      return ELIXIR;
    case haircareFragrances.FETE:
      return FETE;
    case haircareFragrances.FRUITISSIME:
      return FRUITISSIME;
    case haircareFragrances.ISPAHAN:
      return ISPAHAN;
    case haircareFragrances.MELENI:
      return MELENI;
    case haircareFragrances.NAPOLI:
      return NAPOLI;
    case haircareFragrances.OASIS:
      return OASIS;
    case skincareFragrances.PACIFICA:
      return PACIFICA;
    case skincareFragrances.BLANCA:
      return BLANCA;
    case haircareFragrances.PALMA:
      return PALMA;
    case haircareFragrances.PERLE:
      return PERLE;
    case haircareFragrances.PRELUDE:
      return PRELUDE;
    case haircareFragrances.RITUEL:
      return RITUEL;
    case haircareFragrances.SIGNATURE:
      return SIGNATURE;
    case haircareFragrances.VITAE:
      return VITAE;
    case haircareFragrances.PARADISO:
      return PARADISO;
    case haircareFragrances.GLACIA:
      return GLACIA;
    case hairOilFragrances.SOLSTICE:
      return SOLSTICE;
    case haircareFragrances.FRAGRANCE_FREE: // NOTE: currently same slug for haircare, skincare and hairoil
    case haircareFragrances.NONE:
      return FRAGRANCE_FREE;
    default:
      Sentry.captureMessage(`Unknown fragrance ${fragrance}`);
      return {
        name: fragrance,
      };
  }
};

/**
 * A fragrance-free is named differently by the API (NONE, FREE, fragranceFree)
 * We need a single value for the tracking data
 */
export const streamlineFragranceLabel = (fragrance: string) =>
  !fragrance || fragrance.toLowerCase().includes('free') || fragrance.toLowerCase().includes('none')
    ? FRAGRANCE_FREE.name
    : fragrance;

const fragrances = { ...haircareFragrancesContent, ...skincareFragrancesContent };

export const getFragranceName = (fragranceKey: string) =>
  get(`[${fragranceKey.toUpperCase()}].name`, fragrances);

export const getFragranceIngredients = (fragranceKey: string) =>
  get(`[${fragranceKey.toUpperCase()}].ingredients`, fragrances) ||
  get(`[${fragranceKey.toUpperCase()}].details`, fragrances);
