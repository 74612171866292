import Button from 'Components/LegacyButton';
import Typography from 'Components/Typography';

import BaseScene from 'Scenes/LegacyBaseScene';

const reloadLocation = () => window.location.reload();

const MagicLoadingError = () => (
  <BaseScene header>
    <Typography align="center" paragraph variant="h1r">
      It looks like we are experiencing some difficulties logging you in
    </Typography>
    <Button onClick={reloadLocation} variant="underlined">
      Let's try again
    </Button>
  </BaseScene>
);

export default MagicLoadingError;
