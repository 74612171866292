import { newFragrance } from 'Apps/Feedback/constants/feedbackSlugs';

import { productsWithFragrance } from 'constants/products';

import { capitalizeFirstLetter } from 'utils/textUtils';

const haircareNewFragrance = {
  name: newFragrance,
  category: 'fragrance',
  shouldBeIncluded: ({ formulas, isSoldoutFragrance }) =>
    formulas?.some(f => productsWithFragrance.includes(f.variant.product.type)) &&
    !isSoldoutFragrance,
  surtitle: {
    showFormulas: 'withSurveyFragrance',
    showFragrance: true,
  },
  route: `/feedback/haircare/${newFragrance}`,
  title: 'Would you like to try a new fragrance in your products?',
  options: ({ currentFragrance }) => [
    { value: true, label: "Yes, I'd like to try something new" },
    {
      value: false,
      label: `No, I'd like to keep ${capitalizeFirstLetter(currentFragrance)}`,
    },
  ],
  getInitialValue: answers => answers?.new_fragrance,
  getAnswers: ({ selected }) => {
    return { new_fragrance: selected };
  },
};

export default haircareNewFragrance;
