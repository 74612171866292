import { fragranceNewChoiceHairOil } from 'Apps/Feedback/constants/feedbackSlugs';

import { feedbackCategories } from 'constants/feedback';
import { productsSlugs } from 'constants/products';

const hairOilFragranceNewChoice = {
  name: fragranceNewChoiceHairOil,
  category: 'fragrance',
  feedbackCategory: feedbackCategories.HAIRCARE,
  route: `/feedback/haircare/${fragranceNewChoiceHairOil}`,
  shouldBeIncluded: ({ formulas }) =>
    formulas?.some(f => f.variant.product.type === productsSlugs.OIL),
  component: 'FragranceContainer',
  title: 'Would you like fragrance in your Hair Oil?',
  subtitle:
    'Hair Oil features a unique fragrance, crafted by our French perfumers to complement and enhance the natural scent of its nourishing base oils.',
  surtitle: {
    showFormulas: 'hair_oil',
    showFragrance: false,
  },
  getInitialValue: answers => answers?.fragrance_new_choice_hair_oil,
  getAnswers: ({ selected }) => ({
    fragrance_new_choice_hair_oil: selected === 'NONE' ? 'FRAGRANCE_FREE' : selected,
  }),
};

export default hairOilFragranceNewChoice;
