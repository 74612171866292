import {
  supplementsFromTwoMonths,
  supplementsLittleImprovements,
  supplementsLotsOfImprovements,
  supplementsNoImprovements,
  supplementsOverallImprovement,
  supplementsSomeImprovements,
} from 'Apps/Feedback/constants/feedbackSlugs';

const supplementsOverallImprovementScreen = {
  name: supplementsOverallImprovement,
  route: `/feedback/supplements/${supplementsOverallImprovement}`,
  shouldBeIncluded: ({ feedbackAnswers }) =>
    feedbackAnswers &&
    supplementsFromTwoMonths.includes(feedbackAnswers.product_supplements_usage_duration),
  title: 'Have you noticed any overall improvement in your hair so far?',
  subtitle:
    'This can include an increase in length, strength, shine, and softness and a decrease in shedding.',
  options: [
    { value: supplementsLotsOfImprovements, label: 'Yes, lots of improvement' },
    { value: supplementsSomeImprovements, label: 'Yes, some improvement' },
    { value: supplementsLittleImprovements, label: 'Yes, maybe a little' },
    { value: supplementsNoImprovements, label: 'Not yet' },
  ],
  getInitialValue: answers => answers?.product_supplements_overall_improvement,
  getAnswers: ({ selected }) => ({ product_supplements_overall_improvement: selected }),
};

export default supplementsOverallImprovementScreen;
