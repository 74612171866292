export const APPLE_PAY = 'Apple Pay';
export const CREDIT_CARD = 'Credit Card';
export const GOOGLE_PAY = 'Google Pay';
export const OTHER_PAYMENT = 'Other';
export const PAYPAL = 'PayPal';

export const PAYPAL_ID = 'paypal';

export const paymentMethodNameByServiceId: Record<string, string> = {
  [PAYPAL_ID]: PAYPAL,
};
