import { useState } from 'react';
import PropTypes from 'prop-types';

import { Link, useLocation, useMatch, useNavigate } from 'react-router-dom';

import { connect } from 'react-redux';
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'dux/app/hooks';

import { legacyTheme, styled } from '@prose-ui/legacy';
import Image from 'next/image';

import useConsultationCategory from 'Apps/Consultation/hooks/useConsultationCategory';

import { BasicModal } from 'Components/BasicModal';
import Button from 'Components/LegacyButton';
import Typography from 'Components/Typography';

import { productsCategories } from 'constants/products';

import consultationCategoryProp from 'PropTypes/consultationProps';

import * as exitModalContent from 'assets/content/consultation/exitModal';
import { navbarContent } from 'assets/content/navbar';

import { trackHeapEvent } from 'dux/tracking/actions';
import * as authSelectors from 'dux/auth/selectors';
import * as consultationSelectors from 'dux/consultation/selectors';
import * as userSelectors from 'dux/user/selectors';
import { answersActions } from 'dux/consultation/slice';

import EmailCaptureExitModal from './EmailCaptureExitModal';

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${legacyTheme.spacing.s36};
`;

const ModalExitButton = styled(Link)`
  margin-top: 24px;
  color: ${legacyTheme.palette.common.grey.dark};
  border-bottom: 1px solid ${legacyTheme.palette.common.grey.dark};
`;

const Container = styled.div`
  background: ${legacyTheme.palette.backgrounds.white};
  box-shadow: 0 2px 3px 0 rgba(180, 180, 180, 0.2);
  display: flex;
  height: ${legacyTheme.props.navbarHeight};
  width: 100%;
  overflow-y: hidden;
  align-items: center;
  justify-content: space-between;
  padding: 0px ${legacyTheme.spacing.s32};

  ${legacyTheme.breakpoints.match('md')} {
    padding: 0px 20px;
  }
`;

const StyledButton = styled.button`
  ${{ ...legacyTheme.utils.resetButton }}
  display: flex;
  &:hover {
    text-decoration: underline;
    color: ${legacyTheme.palette.common.noir.medium};
  }
`;

const BackButton = styled(StyledButton)`
  display: flex;
  flex: 1 1 50%;
  flex-direction: row;
`;

const ExitButton = styled(BackButton)`
  justify-content: flex-end;
`;

const ExitModal = ({ consultationCategory, isOpen, onClose, hasCompletedHaircareConsultation }) => {
  return (
    <BasicModal dataFrom="consultation-exit-modal" isOpen={isOpen} onClose={onClose}>
      <Typography align="center" paragraph variant="h2">
        {exitModalContent.title(consultationCategory)}
      </Typography>
      <Typography align="center" variant="p2">
        {exitModalContent.body(consultationCategory)}
      </Typography>
      <ButtonsContainer>
        <Button
          data-click="continue"
          data-from="consultation-exit-modal"
          data-testid="consultation-modal-continue-cta"
          noMargin
          onClick={onClose}
          variant="vert"
        >
          {exitModalContent.continueCTA}
        </Button>
        <ModalExitButton
          data-click="exit"
          data-from="consultation-exit-modal"
          data-testid="consultation-modal-exit-cta"
          to={hasCompletedHaircareConsultation ? '/account' : '/'}
        >
          <Typography variant="p2">{exitModalContent.exitCTA}</Typography>
        </ModalExitButton>
      </ButtonsContainer>
    </BasicModal>
  );
};

ExitModal.propTypes = {
  consultationCategory: consultationCategoryProp.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  hasCompletedHaircareConsultation: PropTypes.bool,
};

ExitModal.defaultProps = {
  hasCompletedHaircareConsultation: false,
};

export const ConsultationNavBar = ({
  isAuthenticated,
  emailCaptureExitConsultationModalOpened,
}) => {
  const consultationCategory = useConsultationCategory();
  const { pathname } = useLocation();
  const isOnHomeConsultationPage = useMatch('/consultation', pathname);
  const isOnConsultationCategoryChoicePage = useMatch('/consultation/category', pathname);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hasCompletedHaircareConsultation = useSelector(
    userSelectors.getHasCompletedHaircareConsultation
  );
  const hasCompletedSkincareConsultation = useSelector(
    userSelectors.getHasCompletedSkincareConsultation
  );
  const [isExitModalOpened, setExitModalOpened] = useState(false);

  const exitConsultation = forcedUrl => {
    if (isOnHomeConsultationPage || isOnConsultationCategoryChoicePage) {
      navigate('/');
    } else if (!isAuthenticated) {
      dispatch(trackHeapEvent('Consultation Email Capture Modal Viewed'));
      dispatch(answersActions.setEmailCaptureExitConsultationModalOpened(true));
    } else if (hasCompletedHaircareConsultation || hasCompletedSkincareConsultation) {
      if (forcedUrl) {
        navigate(forcedUrl);
      } else if (
        (hasCompletedHaircareConsultation &&
          consultationCategory === productsCategories.HAIRCARE) ||
        (hasCompletedSkincareConsultation && consultationCategory === productsCategories.SKINCARE)
      ) {
        navigate('/account');
      } else {
        navigate('/');
      }
    } else {
      dispatch(trackHeapEvent('Consultation Exit Modal Viewed'));
      setExitModalOpened(true);
    }
  };

  return (
    <Container data-testid="consultation-header-nav">
      <BackButton
        aria-label="Navigate to previous screen"
        data-click="back"
        data-from="header-nav"
        data-testid="header-back-button"
        onClick={() => navigate(-1)}
        type="button"
      >
        <Typography color="noir" variant="p2">
          ← Back
        </Typography>
      </BackButton>

      <StyledButton
        aria-label="Return Home"
        data-click="logo"
        data-from="header-nav"
        data-testid="consultation-header-logo"
        onClick={() => exitConsultation('/')}
        type="button"
      >
        <Image alt={navbarContent.logo.alt} height={22} src={navbarContent.logo.image} width={62} />
      </StyledButton>

      <ExitButton
        aria-label="Exit Consultation"
        data-click="exit"
        data-from="header-nav"
        data-testid="consultation-header-exit"
        onClick={() => exitConsultation()}
        type="button"
      >
        <Typography color="noir" variant="p2">
          Exit
        </Typography>
      </ExitButton>
      <EmailCaptureExitModal
        consultationCategory={consultationCategory}
        isOpen={emailCaptureExitConsultationModalOpened}
        onClose={() => dispatch(answersActions.setEmailCaptureExitConsultationModalOpened(false))}
      />
      <ExitModal
        consultationCategory={consultationCategory}
        hasCompletedHaircareConsultation={hasCompletedHaircareConsultation}
        isOpen={isExitModalOpened}
        onClose={() => setExitModalOpened(false)}
      />
    </Container>
  );
};

ConsultationNavBar.propTypes = {
  emailCaptureExitConsultationModalOpened: PropTypes.bool,
  isAuthenticated: PropTypes.bool.isRequired,
};

ConsultationNavBar.defaultProps = {
  emailCaptureExitConsultationModalOpened: false,
};

export default connect(state => ({
  isAuthenticated: authSelectors.getIsAuthenticated(state),
  emailCaptureExitConsultationModalOpened:
    consultationSelectors.getEmailCaptureExitConsultationModalOpened(state),
}))(ConsultationNavBar);
