import { createAsyncThunk } from '@reduxjs/toolkit';

import * as OrderService from 'Services/OrderService';

import { haircareTopicalProducts, productsCategories, productsSlugs } from 'constants/products';

import {
  trackFirstOrder,
  trackGiftPurchase,
  trackSubscriptionStartedFromCart,
} from 'dux/tracking/actions';
import { cartHasSubscription, getIsGivingGift } from 'dux/checkoutCart/selectors';
import { getHaveOrders } from 'dux/user/selectors';
import { fetchUser } from 'dux/user/thunks';

import { getOrderData } from './selectors';

export const loadOrder = createAsyncThunk('checkoutOrder/loadOrder', async pubkey => {
  const data = await OrderService.fetch(pubkey);
  return { data };
});

// isPayment prop enables tracking
export const orderLoadSuccessFromPaymentSuccess = order => (dispatch, getState) => {
  const state = getState();

  if (!getHaveOrders(state)) {
    dispatch(trackFirstOrder(order?.pubkey, order?.formulaset?.categories?.[0]));
  }

  // Loop through card payload to determine which categories are present
  const categories = order?.items.reduce((a, b) => {
    if (
      !a.includes(productsCategories.HAIRCARE) &&
      haircareTopicalProducts.includes(b?.variant?.product?.type)
    ) {
      a.push(productsCategories.HAIRCARE);
    }
    if (
      !a.includes(productsCategories.SUPPLEMENTS) &&
      b?.variant?.product?.type === productsSlugs.SUPPLEMENT_CORE
    ) {
      a.push(productsCategories.SUPPLEMENTS);
    }
    return a;
  }, []);

  if (cartHasSubscription(state)) {
    dispatch(
      trackSubscriptionStartedFromCart({
        categories,
      })
    );
    dispatch(fetchUser()); // to check if user has subscription
  }

  if (getIsGivingGift(state)) {
    dispatch(trackGiftPurchase());
  }

  return dispatch(loadOrder.fulfilled({ data: order, isPayment: true }));
};

export const fetchOrderByPubkeyIfNeeded = pubkey => (dispatch, getState) => {
  const order = getOrderData(getState());

  // skip if order already on state
  if (order && order.pubkey === pubkey) {
    return null;
  }

  return dispatch(loadOrder(pubkey));
};
