import { HAIR } from 'Apps/Feedback/constants/feedbackSections';
import { haircareTransitionHair } from 'Apps/Feedback/constants/feedbackSlugs';

import { productsSlugs } from 'constants/products';

import hairTransitionImgDesktop from 'assets/images/reviewAndRefine/prose-hair-focus-desktop.jpg';
import hairTransitionImgMobile from 'assets/images/reviewAndRefine/prose-hair-focus-mobile.jpg';

import { commasAndListToString } from 'utils/textUtils';

const hairTransitionScreen = {
  name: haircareTransitionHair,
  route: `/feedback/haircare/${haircareTransitionHair}`,
  category: HAIR,
  shouldBeIncluded: ({ formulas }) =>
    formulas?.some(f =>
      [productsSlugs.HAIR_MASK, productsSlugs.SHAMPOO, productsSlugs.CONDITIONER].includes(
        f.variant.product.type
      )
    ),
  component: 'HaircareTransitionScreen',
  skipSave: true,
  title: "Next, we'll ask about your hair texture & condition.",
  subtitle: (date, formulas) =>
    `Think about how your recent
      ${commasAndListToString(
        formulas
          ?.filter(f =>
            [productsSlugs.HAIR_MASK, productsSlugs.SHAMPOO, productsSlugs.CONDITIONER].includes(
              f.variant.product.type
            )
          )
          .map(f => f.variant.label)
      )} ordered on ${date} affected your hair.`,
  style: {
    ctaTheme: 'whiteFull',
    theme: 'white',
    backgroundImages: {
      desktop: hairTransitionImgDesktop,
      mobile: hairTransitionImgMobile,
    },
  },
};

export default hairTransitionScreen;
