import {
  supplementsFromTwoMonths,
  supplementsLittleImprovements,
  supplementsLotsOfImprovements,
  supplementsNoConcernInImprovements,
  supplementsNoImprovements,
  supplementsScalpOil,
  supplementsSomeImprovements,
} from 'Apps/Feedback/constants/feedbackSlugs';

const supplementsScalpOilScreen = {
  name: supplementsScalpOil,
  route: `/feedback/supplements/${supplementsScalpOil}`,
  shouldBeIncluded: ({ feedbackAnswers, consultationAnswers }) =>
    feedbackAnswers &&
    supplementsFromTwoMonths.includes(feedbackAnswers.product_supplements_usage_duration) &&
    consultationAnswers &&
    ['oily', 'oily_dry'].includes(consultationAnswers.diag_hair_type),
  title: 'Have you seen improvement in your scalp oiliness?',
  subtitle: 'You might be seeing less grease at your roots, and more days between washing.',
  options: [
    { value: supplementsLotsOfImprovements, label: 'Yes, lots of improvement' },
    { value: supplementsSomeImprovements, label: 'Yes, some improvement' },
    { value: supplementsLittleImprovements, label: 'Yes, maybe a little' },
    { value: supplementsNoImprovements, label: 'Not yet' },
    { value: supplementsNoConcernInImprovements, label: 'No, but it wasn’t a concern' },
  ],
  getInitialValue: answers => answers?.product_supplements_scalp_oil,
  getAnswers: ({ selected }) => ({ product_supplements_scalp_oil: selected }),
};

export default supplementsScalpOilScreen;
