import { createSlice } from '@reduxjs/toolkit';

import assignWith from 'lodash/fp/assignWith';
import isNull from 'lodash/fp/isNull';

import { userSignout } from 'dux/auth/actions';

import * as flagFetchStatus from './constants/flagFetchStatus';
import initialState from './initialState';
import { fetchFeatureFlags } from './thunks';

const featureFlagsSlice = createSlice({
  name: 'featureFlags',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      // reinitialize state upon signout
      .addCase(userSignout, () => initialState());

    builder.addCase(fetchFeatureFlags.pending, draft => {
      draft.flagFetchStatus = flagFetchStatus.USER_CONTEXT_SPECIFIC_PENDING;
    });
    builder.addCase(fetchFeatureFlags.fulfilled, (draft, { payload }) => {
      draft.flagFetchStatus = flagFetchStatus.USER_CONTEXT_SPECIFIC_SUCCESS;
      draft.flags = assignWith(
        // assignWith signature : customizer, object (destination object = the state), source (source of change = fetched values)
        (stateValue, fetchedValue) => (isNull(fetchedValue) ? stateValue : fetchedValue), // Assign flags to fetchedValue only if not null
        draft.flags,
        payload
      );
    });
    builder.addCase(fetchFeatureFlags.rejected, draft => {
      draft.flagFetchStatus = flagFetchStatus.USER_CONTEXT_SPECIFIC_FAIL;
    });
  },
});

export const { reducer } = featureFlagsSlice;
