import { createAsyncThunk } from '@reduxjs/toolkit';

import * as Sentry from '@sentry/nextjs';

import {
  RETURNING_HAIRCARE_CONSULTATION_ROUTE,
  returningConsultationRouteByCategory,
} from 'Apps/Consultation/constants';

import * as AuthService from 'Services/AuthService';
import * as CouponService from 'Services/CouponService';
import {
  isExistingUser,
  isFetchError,
  isInvalidCoupon,
  isValidationError,
} from 'Services/HTTPError';

import { getVisitorStatus, isReferralCodeValid, sendFriendbuySignupEvent } from 'utils/friendBuy';

import * as authActions from 'dux/auth/actions';
import * as trackingActions from 'dux/tracking/actions';
import * as userActions from 'dux/user/actions';
import { getUserPubkey, getUserReferral } from 'dux/user/selectors';

const signupFields = ['email', 'username', 'first_name', 'last_name'];

const getMagicLinkParams = ({ user, referralCode, couponSource, category }) => {
  const params = {
    next: returningConsultationRouteByCategory[category] || RETURNING_HAIRCARE_CONSULTATION_ROUTE,
    username: user.username,
  };

  if (referralCode) {
    params.coupon = referralCode;
  }
  if (couponSource) {
    params.couponSource = couponSource;
  }

  return params;
};

export const signInMagicLink = createAsyncThunk(
  'signInMagicLink',
  async ({ params, firstName }) => {
    await AuthService.requestMagicLink(params);
    return firstName;
  }
);

/**
 * @typedef signupUserPayload
 * @type {object}
 * @property {any} user
 * @property {any} [actions]
 * @property {any} [extraMagicLinkParams]
 * @property {any} [consultationCategory]
 */
export const signupUser = createAsyncThunk(
  'signup',
  async (
    /** @type {signupUserPayload} */
    { user, actions, extraMagicLinkParams = {}, consultationCategory = null },
    { dispatch, getState, rejectWithValue }
  ) => {
    const state = getState();
    const referral = getUserReferral(state);
    try {
      Sentry.addBreadcrumb({
        message: `Signup ${user.first_name} ${user.last_name} ${user.email}`,
        category: 'view',
      });

      await AuthService.signup(user);

      await dispatch(userActions.fetchUser());

      if (referral?.code) {
        if (referral.source) {
          sendFriendbuySignupEvent(user);
          getVisitorStatus(async status => {
            if (isReferralCodeValid(status)) {
              await CouponService.create({
                code: referral.code,
                source: referral.source,
              });
            }
          });
        } else {
          await CouponService.create({
            code: referral.code,
            source: referral?.source,
          });
        }
      }

      const updatedState = getState();
      const userPubkey = getUserPubkey(updatedState);

      dispatch(authActions.signupSuccess());
      dispatch(
        trackingActions.trackSignup({
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          user_id: userPubkey,
        })
      );

      actions?.onSuccess?.();
      // hack: returns success to parent
      return true;
    } catch (error) {
      if (isExistingUser(error)) {
        const params = {
          ...getMagicLinkParams({
            user,
            ...(referral?.code ? { referralCode: referral.code } : {}),
            ...(referral?.source ? { couponSource: referral.source } : {}),
            consultationCategory,
          }),
          ...extraMagicLinkParams,
        };

        dispatch(signInMagicLink({ params, firstName: user.first_name }));
        actions?.setSubmitting(false);
      } else if (
        actions && // hack: check if actions are available because gifting does not support this
        isValidationError(error) &&
        signupFields.some(field => error.extra[field])
      ) {
        signupFields.forEach(
          field => error.extra[field] && actions.setFieldError(field, error.extra[field].join(' '))
        );
        actions?.setSubmitting(false);
      } else if (isInvalidCoupon(error)) {
        return rejectWithValue({ invalidCoupon: true });
      } else {
        if (!isFetchError(error)) {
          Sentry.captureMessage('API Service (AuthService.signup)');
        }
        actions?.setSubmitting(false);
      }

      // hack: returns failure to parent
      return false;
    }
  }
);
