import * as StylistService from 'Services/StylistService';
import * as UserService from 'Services/UserService';

import stylistPicture from 'assets/images/stylist_faith.jpg';

import { getIsAuthenticated } from 'dux/auth/selectors';
import { getStylist, getStylistLoading } from 'dux/stylist/selectors';
import { getUserStylist } from 'dux/user/selectors';

import { STYLIST_FAILURE, STYLIST_REQUEST, STYLIST_SUCCESS } from './actionTypes';

// default stylist for D2C customers
const d2cStylist = {
  pubkey: null,
  public_name: 'Faith Huffnagle',
  public_slug: null,
  avatar_url: stylistPicture,
  salons: [],
};

const requestStylist = () => ({ type: STYLIST_REQUEST });
const receiveStylist = stylist => ({ type: STYLIST_SUCCESS, stylist });
const receiveStylistError = error => ({ type: STYLIST_FAILURE, error });

export const checkStylistSlug = slug => async (dispatch, getState) => {
  const isAuthenticated = getIsAuthenticated(getState());

  // Clean up to avoid left-overs.
  // We suppose that this funciton is called from a route that keeps the
  //  stylist slug to allow retries.
  window.localStorage.removeItem('stylist');

  dispatch(requestStylist());
  try {
    const stylist = await StylistService.searchBySlug(slug);

    if (isAuthenticated) {
      await UserService.patch({ stylist: stylist.pubkey });
    } else {
      window.localStorage.setItem('stylist', slug);
    }

    dispatch(receiveStylist(stylist));
  } catch (error) {
    dispatch(receiveStylistError(error));
  }
};

export const fetchAndTrySaveStylist = () => async (dispatch, getState) => {
  const stylistSlug = window.localStorage?.getItem('stylist');

  const state = getState();
  const storeStylist = getStylist(state);
  const userStylistPubkey = getUserStylist(state);
  const isLoadingStylist = getStylistLoading(state);
  const isAuthenticated = getIsAuthenticated(state);

  if (storeStylist || isLoadingStylist) {
    return;
  }

  dispatch(requestStylist());
  try {
    let stylist;
    if (stylistSlug) {
      stylist = await StylistService.searchBySlug(stylistSlug);
    } else if (userStylistPubkey) {
      stylist = await StylistService.fetch(userStylistPubkey);
    } else {
      stylist = d2cStylist;
    }

    if (isAuthenticated && stylistSlug) {
      // If there was a slug in local storage, it means that
      //  the user visited a vanity URL and we have to update its stylist
      await UserService.patch({ stylist: stylist.pubkey });
      window.localStorage.removeItem('stylist');
    }

    dispatch(receiveStylist(stylist.public_slug ? stylist : d2cStylist));
  } catch (error) {
    dispatch(receiveStylistError(error));
  }
};
