import { createSelector } from '@reduxjs/toolkit';

import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';

import initialState from './initialState';

const getState = getOr(initialState, 'stylist');
export const getStylist = createSelector(getState, get('stylist'));
export const getStylistLoading = state => state.stylist.isLoading;
export const getStylistError = state => state.stylist.error;
export const getIsProseStylist = createSelector(
  getStylist,
  stylist => !(stylist && stylist.public_slug)
);
export const getStylistPubkey = createSelector(getStylist, stylist => stylist && stylist.pubkey);
export const getStylistSlug = createSelector(getStylist, stylist => stylist && stylist.public_slug);
export const getStylistAvatar = createSelector(
  getStylist,
  stylist => stylist && stylist.avatar_url
);
export const getStylistSalon = createSelector(getStylist, stylist =>
  stylist?.salons?.length ? stylist.salons[0] : null
);
export const getStylistName = createSelector(getStylist, stylist => stylist && stylist.public_name);
