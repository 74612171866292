import { createAsyncThunk } from '@reduxjs/toolkit';

import * as APIClient from 'Services/APIClient';

import logSentryError from 'utils/logSentry';

import type { AccountMembershipRawData } from './slice';

export const fetchMembership = createAsyncThunk('membership/fetch', async () => {
  try {
    const response = await APIClient.get('/v1/account/membership');
    const accountMembershipRawData = (await response.json()) as AccountMembershipRawData;
    return accountMembershipRawData;
  } catch (error) {
    logSentryError(`[dux/membership] fetchMembership`, error);

    throw error;
  }
});

export const setSelectedProduct = createAsyncThunk(
  'membership/setSelectedProduct',
  async (slug) => {
    try {
      const response = await APIClient.put('/v1/account/membership/', { slug });
      const accountMembershipRawData = (await response.json()) as AccountMembershipRawData;
      return accountMembershipRawData;
    } catch (error) {
      logSentryError(`[dux/membership] setSelectedProduct`, error);

      throw error;
    }
  },
);
