import { createSelector } from '@reduxjs/toolkit';

import get from 'lodash/fp/get';

import AuthStatus from 'dux/auth/constants/authStatuses';

const getGuestAuth = get('guestAuth');

export const getIsGuestUserAuthenticated = createSelector(
  getGuestAuth,
  (status) => status === AuthStatus.AUTHENTICATED,
);

export const getIsGuestUserAuthenticating = createSelector(
  getGuestAuth,
  (status) => status === AuthStatus.LOADING,
);
