import { HAIR } from 'Apps/Feedback/constants/feedbackSections';
import {
  haircareHairTangles,
  haircareImprovements,
  haircareNoConcernInImprovements,
  haircareNoImprovements,
  haircareSomeImprovements,
} from 'Apps/Feedback/constants/feedbackSlugs';

import { productsSlugs } from 'constants/products';

const hairTangles = {
  name: haircareHairTangles,
  route: `/feedback/haircare/${haircareHairTangles}`,
  category: HAIR,
  shouldBeIncluded: ({ formulas, consultationAnswers }) =>
    formulas?.some(f =>
      [productsSlugs.HAIR_MASK, productsSlugs.SHAMPOO, productsSlugs.CONDITIONER].includes(
        f.variant.product.type
      )
    ) && ['short', 'shoulder', 'long', 'very_long'].includes(consultationAnswers.diag_hair_length),
  title: "Did you see improvements in your hair's tangles?",
  subtitle: 'How easy is your hair is to comb through?',
  options: [
    { value: haircareImprovements, label: 'Yes, detangling is a breeze now' },
    { value: haircareSomeImprovements, label: 'Some, but could be better' },
    { value: haircareNoImprovements, label: "No, my hair's still knotty" },
    { value: haircareNoConcernInImprovements, label: "It wasn't a concern" },
  ],
  getInitialValue: answers => answers?.hair_tangles,
  getAnswers: ({ selected }) => ({ hair_tangles: selected }),
};

export default hairTangles;
