// Haircare feedback questions slugs
export const haircareConditionerAntibrass = 'product_conditioner_antibrass';
export const haircareConditionerContinueAntibrass = 'product_conditioner_continue_antibrass';
export const haircareConditionerImpact = 'product_conditioner_impact';
export const haircareConditionerSatisfaction = 'product_conditioner_satisfaction';
export const haircareCurlCreamDefinition = 'product_curl_cream_definition';
export const haircareCurlCreamFrizz = 'product_curl_cream_frizz';
export const haircareCurlCreamMoisture = 'product_curl_cream_moisture';
export const haircareCurlCreamSatisfaction = 'product_curl_cream_satisfaction';
export const haircareDryShampooCleansing = 'product_dry_shampoo_cleansing';
export const haircareDryShampooSatisfaction = 'product_dry_shampoo_satisfaction';
export const haircareDryShampooVolume = 'product_dry_shampoo_volume';
export const haircareFragranceSatisfactionIntensity = 'fragrance_satisfaction_intensity';
export const haircareFragranceSatisfactionIntensityHairOil =
  'fragrance_satisfaction_intensity_hair_oil';
export const haircareHairCurlDefinition = 'hair_curl_definition';
export const haircareHairDryness = 'hair_dryness';
export const haircareHairFrizziness = 'hair_frizziness';
export const haircareHairOilFrizz = 'product_hair_oil_frizz';
export const haircareHairOilNourishment = 'product_hair_oil_nourishment';
export const haircareHairOilSatisfaction = 'product_hair_oil_satisfaction';
export const haircareHairShine = 'hair_shine';
export const haircareHairTangles = 'hair_tangles';
export const haircareHairVolume = 'hair_volume';
export const haircareLeaveinConditionerFrizz = 'product_leavein_conditioner_frizz';
export const haircareLeaveinConditionerManageable = 'product_leavein_conditioner_manageable';
export const haircareLeaveinConditionerMoisture = 'product_leavein_conditioner_moisture';
export const haircareLeaveinConditionerSatisfaction = 'product_leavein_conditioner_satisfaction';
export const haircareLeaveinConditionerTangles = 'product_leavein_conditioner_tangles';
export const haircareOverallExperience = 'overall_experience';
export const haircareHairMaskImpact = 'product_hair_mask_impact';
export const haircareHairMaskSatisfaction = 'product_hair_mask_satisfaction';
export const haircareHairSatisfaction = 'hair_satisfaction';
export const haircareFragranceSatisfactionScalpMask = 'fragrance_satisfaction_scalp_mask';
export const haircareScalpFlakiness = 'scalp_flakiness';
export const haircareScalpMaskSatisfaction = 'product_scalp_mask_satisfaction';
export const haircareScalpOiliness = 'scalp_oiliness';
export const haircareScalpSatisfaction = 'scalp_satisfaction';
export const haircareScalpSensitivity = 'scalp_sensitivity';
export const haircareShampooImpact = 'product_shampoo_impact';
export const haircareShampooSatisfaction = 'product_shampoo_satisfaction';
export const haircareStylingGelSatisfaction = 'product_styling_gel_satisfaction';
export const haircareStylingGelFlexible = 'product_styling_gel_flexible';
export const haircareStylingGelHold = 'product_styling_gel_hold';
export const haircareTransitionFragrance = 'transition_fragrance';
export const haircareTransitionHair = 'transition_hair';
export const haircareTransitionScalp = 'transition_scalp';

// Haircare feedback answers slugs
export const haircareClean = 'clean';
export const haircareImprovements = 'yes';
export const haircareNoConcernInImprovements = 'not_a_concern';
export const haircareNoImprovements = 'no';
export const haircareOverconditionedHeavy = 'overconditioned_heavy';
export const haircareOverstrippedDry = 'overstripped_dry';
export const haircareSoftManageable = 'soft_manageable';
export const haircareSomeImprovements = 'some';
export const haircareTooCrunchy = 'too_crunchy';
export const haircareTooHeavy = 'too_heavy';
export const haircareTooMuch = 'too_much';
export const haircareUncleansedGreasy = 'uncleansed_greasy';
export const haircareUnderconditionedDry = 'underconditioned_dry';
export const haircareHasSeenProgressAnswers = [
  haircareImprovements,
  haircareClean,
  haircareSoftManageable,
] as const;
export const haircareHasSeenNoProgressAnswers = [
  haircareSomeImprovements,
  haircareNoImprovements,
] as const;
export const haircareHasSeenNoProgressOtherAnswers = [
  haircareUncleansedGreasy,
  haircareOverstrippedDry,
  haircareUnderconditionedDry,
  haircareOverconditionedHeavy,
  haircareTooCrunchy,
] as const;

// Supplements feedback questions slugs
export const supplementsBabyHairs = 'product_supplements_baby_hairs';
export const supplementsHairGrowth = 'product_supplements_hair_growth';
export const supplementsLessShedding = 'product_supplements_less_shedding';
export const supplementsOverallImprovement = 'product_supplements_overall_improvement';
export const supplementsSatisfaction = 'product_supplements_satisfaction';
export const supplementsScalpComfort = 'product_supplements_scalp_comfort';
export const supplementsScalpFlakes = 'product_supplements_scalp_flakes';
export const supplementsScalpOil = 'product_supplements_scalp_oil';
export const supplementsShine = 'product_supplements_shine';
export const supplementsSoftness = 'product_supplements_softness';
export const supplementsStrength = 'product_supplements_strength';
export const supplementsThickness = 'product_supplements_thickness';
export const supplementsUsageDuration = 'product_supplements_usage_duration';
export const supplementsUsageFrequency = 'product_supplements_usage_frequency';

// Supplements feedback answers slugs
export const supplementsDaily = 'daily';
export const supplementsEveryTwoThreeDays = 'two_three_days';
export const supplementsOnceTwiceAWeek = 'once_twice_a_week';
export const supplementsStoppedUse = 'stopped_use';

export const supplementsNotStarted = 'not_started';
export const supplementsOneMonthOrLess = 'le_one_month';
export const supplementsTwoMonths = 'two_months';
export const supplementsThreeMonths = 'three_months';
export const supplementsFourMonths = 'four_months';
export const supplementsFiveMonths = 'five_months';
export const supplementsFromSixMonths = 'ge_six_months';
export const supplementsLessThanTwoMonths = [
  supplementsNotStarted,
  supplementsOneMonthOrLess,
] as const;
export const supplementsLessThanThreeMonths = [
  supplementsNotStarted,
  supplementsOneMonthOrLess,
  supplementsTwoMonths,
] as const;
export const supplementsLessThanSixMonths = [
  supplementsNotStarted,
  supplementsOneMonthOrLess,
  supplementsTwoMonths,
  supplementsThreeMonths,
  supplementsFourMonths,
  supplementsFiveMonths,
] as const;
export const supplementsFromTwoMonths = [
  supplementsTwoMonths,
  supplementsThreeMonths,
  supplementsFourMonths,
  supplementsFiveMonths,
  supplementsFromSixMonths,
] as const;
export const supplementsFromThreeMonths = [
  supplementsThreeMonths,
  supplementsFourMonths,
  supplementsFiveMonths,
  supplementsFromSixMonths,
] as const;

export const supplementsLittleImprovements = 'yes_a_little';
export const supplementsLotsOfImprovements = 'yes_lots';
export const supplementsNoImprovements = 'not_yet';
export const supplementsNoConcernInImprovements = 'not_a_concern';
export const supplementsSomeImprovements = 'yes_some';
export const supplementsHasSeenProgressAnswers = [
  supplementsLotsOfImprovements,
  supplementsSomeImprovements,
  supplementsLittleImprovements,
] as const;
export const supplementsHasSeenNoProgressAnswers = [supplementsNoImprovements] as const;

// Skincare feedback questions slugs
export const cleanserFragranceSatisfactionIntensity = 'fragrance_cleanser_intensity';
export const moisturizerFragranceSatisfactionIntensity = 'fragrance_moisturizer_intensity';
export const serumFragranceSatisfactionIntensity = 'fragrance_serum_intensity';
export const skincareOverallExperience = 'overall_experience';
export const skincareSatisfaction = 'skin_satisfaction';
export const skincareHydration = 'skin_hydration';
export const skincareExfoliation = 'skin_exfoliation';
export const skincareEventone = 'skin_eventone';
export const skincareRescue = 'skin_rescue';
export const skincareAntiRedness = 'skin_anti_redness';
export const skincareAntiShine = 'skin_anti_shine';
export const skincareAntiBlemish = 'skin_anti_blemish';
export const skincareAntiWrinkles = 'skin_anti_wrinkles';
export const skincareCleanserFeel = 'cleanser_product_feel';
export const skincareCleanserSatisfaction = 'fragrance_cleanser_satisfaction';
export const skincareCleanserTexture = 'pref_texture_cleanser';
export const skincareFirming = 'skin_firming';
export const skincareMoisturizerFeel = 'moisturizer_product_feel';
export const skincareMoisturizerSatisfaction = 'fragrance_moisturizer_satisfaction';
export const skincareMoisturizerTexture = 'pref_texture_moisturizer';
export const skincareSerumSatisfaction = 'fragrance_serum_satisfaction';
export const skincareSerumFeel = 'serum_product_feel';

// Skincare feedback answers slugs
export const skincareImprovements = 'yes';
export const skincareSomeImprovements = 'some';
export const skincareNoImprovements = 'no';
export const skincareNoConcernInImprovements = 'not_a_concern';
export const skincareTooOily = 'too_oily';
export const skincareNicelyHydrating = 'nicely_hydrating';
export const skincareLittleDrying = 'little_drying';
export const skincareTooDrying = 'too_drying';
export const skincareTooSticky = 'too_sticky';
export const skincareNotHydratingEnough = 'not_hydrating_enough';
export const skincareOkay = 'ok';
export const skincareTooHeavy = 'too_heavy';
export const skincareSoftHydrating = 'soft_hydrating';
export const skincareHasSeenProgressAnswers = [
  skincareImprovements,
  skincareSomeImprovements,
  skincareNoImprovements,
] as const;
export const skincareHasSeenNoProgressAnswers = [
  skincareSomeImprovements,
  skincareNoImprovements,
] as const;

// Common
export const fragranceNewChoice = 'fragrance_new_choice';
export const fragranceNewChoiceHairOil = 'fragrance_new_choice_hair_oil';
export const newFragrance = 'new_fragrance';
export const publicReview = 'public_review';
export const results = 'results';
export const transitionProducts = 'transition_products';
export const tooStrong = 'too_strong';
export const tooLight = 'too_light';
export const justRight = 'just_right';
