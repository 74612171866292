import PropTypes from 'prop-types';

import { theme } from '@prose-ui';
import { legacyTheme } from '@prose-ui/legacy';
import { makeStyles } from 'legacyStyles';

import BaseField from './BaseField';

const useStyles = makeStyles(
  {
    root: {
      '&:after': {
        position: 'absolute',
        top: 24,
        right: 20,
        width: 0,
        height: 0,
        padding: 0,
        content: "''",
        borderLeft: '6px solid transparent',
        borderRight: '6px solid transparent',
        borderTop: '6px solid rgba(0, 0, 0, 0.12)',
        pointerEvents: 'none',
      },
    },
    select: {
      appearance: 'none',
      // Make sure the text won't finish within the arrow
      paddingRight: legacyTheme.spacing.s32,
    },
    inputDisabled: {
      backgroundColor: theme.colors.tertiary['100'],
      '&:before': {
        border: 'none',
      },
    },
  },
  { name: 'SelectField' }
);

/**
 * SelectField is also based on BaseField. Here it renders a select input.
 */
const SelectField = ({ list, value, ...props }) => {
  const { classes, cx } = useStyles(undefined, props?.classes ? { props } : undefined);

  return (
    <BaseField
      {...props}
      classes={{
        root: classes.root,
        component: classes.select,
        inputRoot: cx({
          [classes.inputDisabled]: list?.length === 1,
        }),
      }}
      value={value ? `${value}` : ''}
    >
      {props => (
        <select {...props} disabled={Boolean(list?.length === 1)}>
          <option disabled value="" />
          {list &&
            list.map((listItem, i) =>
              typeof listItem === 'object' ? (
                <option
                  key={i}
                  data-click={listItem.dataClick ?? null}
                  data-from={listItem.dataFrom ?? null}
                  data-testid="product-frequency-selected"
                  label={listItem.label}
                  value={listItem.value}
                >
                  {listItem.label}
                </option>
              ) : (
                <option key={i} value={listItem}>
                  {listItem}
                </option>
              )
            )}
        </select>
      )}
    </BaseField>
  );
};

SelectField.defaultProps = {
  classes: null,
};

const ValueType = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);

SelectField.propTypes = {
  /**
   * The list of optons to be rendered.
   * Can be a list of values or objects like { label, value }
   */
  list: PropTypes.arrayOf(
    PropTypes.oneOfType([
      ValueType,
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: ValueType.isRequired,
        dataFrom: PropTypes.string,
        dataClick: PropTypes.string,
      }),
    ])
  ).isRequired,

  value: ValueType,

  classes: PropTypes.objectOf(PropTypes.string),

  /**
   * All remaining props are passed to BaseField
   */
};

export default SelectField;
