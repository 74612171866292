import { PRODUCTS } from 'Apps/Feedback/constants/feedbackSections';
import { haircareConditionerSatisfaction } from 'Apps/Feedback/constants/feedbackSlugs';

import { productsSlugs } from 'constants/products';

const conditionerSatisfaction = {
  name: haircareConditionerSatisfaction,
  route: `/feedback/haircare/${haircareConditionerSatisfaction}`,
  category: PRODUCTS,
  shouldBeIncluded: ({ formulas }) =>
    formulas?.find(f => f.variant.product.type === productsSlugs.CONDITIONER),
  component: 'Rating',
  title: 'How satisfied are you with your conditioner?',
  options: [
    { value: 1, label: 'Very Unsatisfied' },
    { value: 2, label: 'Unsatisfied' },
    { value: 3, label: 'Neutral' },
    { value: 4, label: 'Satisfied' },
    { value: 5, label: 'Very Satisfied' },
  ],
  skippable: "I haven't used it enough",
  skippableValue: 0,
  hasOpenText: true,
  getInitialValue: answers => answers?.product_conditioner_satisfaction,
  getInitialProductFeedback: productsAnswers => {
    return productsAnswers.find(answer => answer.product === productsSlugs.CONDITIONER)
      ?.open_text_feedback;
  },
  getAnswers: ({ selected }) => {
    if (selected === 0) {
      return { product_conditioner_satisfaction: selected, product_conditioner_impact: null };
    }
    return { product_conditioner_satisfaction: selected };
  },
  getFeedbackAnswer: ({ openText }) => ({
    product: productsSlugs.CONDITIONER,
    open_text_feedback: openText?.feedback_text,
  }),
};

export default conditionerSatisfaction;
