import { createSelector } from '@reduxjs/toolkit';

import get from 'lodash/fp/get';

import type { RootState } from 'store';

const getConsentManagementState = (state: RootState) => state.consentManagement;

export const getShowConsentManagementBanner = createSelector(
  getConsentManagementState,
  get('showConsentBanner'),
);
