import * as feedbackComponents from 'Apps/Feedback/constants/feedbackComponents';
import { SKIN } from 'Apps/Feedback/constants/feedbackSections';

import { feedbackCategories } from 'constants/feedback';
import { productsCategories } from 'constants/products';

import efficiencyTransitionImgDesktop from 'assets/images/reviewAndRefine/efficiency-transition_desktop.jpg';
import efficiencyTransitionImgMobile from 'assets/images/reviewAndRefine/efficiency-transition_mobile.jpg';

const efficiencyTransitionScreen = {
  name: 'efficiency',
  route: `/feedback/${feedbackCategories.SKINCARE}/skin`,
  category: SKIN,
  feedbackCategory: productsCategories.SKINCARE,
  shouldBeIncluded: () => true,
  component: feedbackComponents.SkincareTransitionScreen,
  skipSave: true,
  title: "Next, we'll ask how your products are working",
  ctaNextText: 'Refine my formula',
  subtitle: date =>
    `Provide feedback on your Starter Set ordered on ${date} so we can refine your formula for your full-size products in your next order.`,
  style: {
    ctaTheme: 'whiteFull',
    theme: 'white',
    backgroundImages: {
      desktop: efficiencyTransitionImgDesktop,
      mobile: efficiencyTransitionImgMobile,
    },
  },
};

export default efficiencyTransitionScreen;
