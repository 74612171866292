import { useMemo } from 'react';

import { useLocation } from 'react-router-dom';

/**
 * This hooks is complementary with useParam, useNavigate & useLocation
 * hooks from react-router.
 * @return URLSeachParams native object
 */
export default function useQueryParams() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}
