import { CONSENT_COOKIE_NAME, consentDefaultValuesByCountry } from 'constants/consent';

import { getCookie } from 'utils/cookies';

export const getDefaultConsent = ({ country, region = '' }: { country: string; region?: string }) =>
  consentDefaultValuesByCountry[region] ??
  consentDefaultValuesByCountry[country] ??
  consentDefaultValuesByCountry.Default;

export const getConsentValuesClientSide = ({
  country,
  region = '',
}: {
  country: string;
  region?: string;
}) => {
  const proseConsentCookie = getCookie(CONSENT_COOKIE_NAME);

  // Stored values
  if (proseConsentCookie) {
    return JSON.parse(proseConsentCookie);
  }

  // Default values
  return getDefaultConsent({ country, region });
};
