const initialState = {
  previousData: null,
  data: null,
  error: null,
  status: 'idle',
  updateStatus: 'idle',
  cartSectionTitle: {
    status: 'idle',
    data: null,
    error: null,
  },
};

export default initialState;
