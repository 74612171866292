import { createSlice } from '@reduxjs/toolkit';

import * as Status from 'constants/statuses';

import { userSignout } from 'dux/auth/actions';

import initialState from './initialState';
import { deleteCard, fetchAccountCards, makeDefaultCard } from './thunks';

const accountCardsSlice = createSlice({
  name: 'accountCards',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      // reinitialize state upon signout
      .addCase(userSignout, () => initialState)

      .addCase(fetchAccountCards.pending, draft => {
        draft.status = Status.LOADING;
      })
      .addCase(fetchAccountCards.fulfilled, (draft, { payload }) => {
        draft.status = Status.SUCCESS;
        if (payload?.default_source) {
          draft.preferredCard = payload.default_source;
        }
        if (payload?.sources?.data) {
          draft.cards = payload.sources.data;
        }
      })
      .addCase(fetchAccountCards.rejected, draft => {
        draft.status = Status.ERROR;
        // XXX: should we display some error mesagge to the customer ?
      })
      .addCase(deleteCard.fulfilled, (draft, { payload }) => {
        if (payload?.deleted) {
          // If deleted is set to true by back-end
          const idx = draft.cards.findIndex(card => card.id === payload.id);
          if (idx !== -1) draft.cards.splice(idx, 1);
        }
      })
      .addCase(makeDefaultCard.fulfilled, (draft, { payload }) => {
        if (payload?.default) {
          draft.preferredCard = payload?.card.id;
        }
      });
  },
});

export const { reducer } = accountCardsSlice;
