import isEmpty from 'lodash/fp/isEmpty';

import { GOOGLE_ADDRESS_VALIDATION_FIELD_CONFIRMED } from 'Apps/Checkout/constants/checkoutAddressValidationStatus';

import * as AddressService from 'Services/AddressService';

import {
  ADDRESS_VALIDATION_FIELD_ADDRESS_SIMPLIFIED,
  ADDRESS_VALIDATION_FIELD_POSTAL_CODE_SUFFIX,
  address1AdressComponents,
  googleAddressValidationApiFields,
} from 'constants/addresses';
import { countriesCode } from 'constants/countries';

import logSentryError from 'utils/logSentry';

// Relies only on cart actions
import {
  ADDRESS_VALIDATION_CHOICE,
  ADDRESS_VALIDATION_CHOICE_RESET,
  ADDRESS_VALIDATION_INVALID_FIELDS,
  ADDRESS_VALIDATION_RECOMMENDED_FIELDS,
} from 'dux/checkoutAddressesValidation/actionTypes';

/* The google validation api returns many fields, but we are usualy only interested in the Shipping Method form fields
 This function merges all texts from the component types that are included in fieldTypes in a single item */
const mergeAddressFields = (addressComponents, fieldTypes) => {
  let hasComponentInFieldTypes = false;
  const fields = [];
  addressComponents.forEach(addressComponent => {
    if (fieldTypes.includes(addressComponent?.componentType)) {
      hasComponentInFieldTypes = true;
    } else {
      fields.push(googleAddressValidationApiFields[addressComponent?.componentType]);
    }
  });

  if (hasComponentInFieldTypes) {
    fields.push(googleAddressValidationApiFields[ADDRESS_VALIDATION_FIELD_ADDRESS_SIMPLIFIED]);
  }

  return fields;
};

export const validateAddress =
  ({ values }) =>
  async dispatch => {
    try {
      // Run address validation via cloud function
      const addressValidation = await AddressService.validateAddress({
        regionCode: values.newAddress.country,
        locality: values.newAddress.city,
        administrativeArea: values.newAddress.state,
        postalCode: values.newAddress.zipcode,
        addressLines: [values.newAddress.address1],
      });

      /* Recommended flow:
        1. Check if the address is complete
        2. Is validatioinGranularity PREMISE or above? 
          Possible: OTHER, ROUTE, BLOCK, PREMISE_PROXIMITY, PREMISE, SUB_PREMISE, GRANULARITY_UNSPECIFIED
          - ask user to accept corrections
        3. Address Components spellCorrected, Inferred, or Replaced?
          - ask user to accept corrections
          */

      /* An address is valid if:
        - tue addressComplete marker is true
        - the validationGranularity is PREMISE or SUB_PREMISE
        - None of the address component as marked as
          - inferred
          - spellCorrected
          - replaced
          - unexpected
       */

      // 1. Check if the address is complete
      const hasUnconfirmedComponents = addressValidation?.verdict?.hasUnconfirmedComponents;

      if (hasUnconfirmedComponents) {
        const unconfirmedAddressComponents = addressValidation?.address?.addressComponents?.filter(
          addressComponent =>
            addressComponent.confirmationLevel !== GOOGLE_ADDRESS_VALIDATION_FIELD_CONFIRMED
        );

        const fields = mergeAddressFields(unconfirmedAddressComponents, address1AdressComponents);

        dispatch({ type: ADDRESS_VALIDATION_INVALID_FIELDS, data: fields });
        return { status: 'failure', addressError: 'Address invalid' };
      }

      //  2. Is validatioinGranularity PREMISE or above
      const validationGranularity = addressValidation?.verdict?.validationGranularity;
      if (validationGranularity !== 'PREMISE' && validationGranularity !== 'SUB_PREMISE') {
        const missingAddressComponents = addressValidation?.address?.missingComponentTypes?.map(
          missingComponentType => googleAddressValidationApiFields[missingComponentType]
        );

        if (!isEmpty(missingAddressComponents)) {
          const fields = mergeAddressFields(missingAddressComponents, address1AdressComponents);

          dispatch({ type: ADDRESS_VALIDATION_INVALID_FIELDS, data: fields });
          return { status: 'failure', addressError: 'Address granularity not sufficient' };
        }
      }

      // Address Components spellCorrected, Inferred, or Replaced?
      const addressComponentsWithoutPostcodeSuffix =
        addressValidation?.address?.addressComponents.filter(
          addressComponent =>
            addressComponent.componentType !== ADDRESS_VALIDATION_FIELD_POSTAL_CODE_SUFFIX
        );

      const correctedAddresses = addressComponentsWithoutPostcodeSuffix.filter(
        addressComponent =>
          addressComponent.spellCorrected ||
          addressComponent.inferred ||
          addressComponent.replaced ||
          addressComponent.unexpected
      );

      const correctAddressWithoutPostcodeSuffix = correctedAddresses.filter(
        correctedAddress =>
          correctedAddress.componentType !== ADDRESS_VALIDATION_FIELD_POSTAL_CODE_SUFFIX
      );

      if (!isEmpty(correctAddressWithoutPostcodeSuffix)) {
        const postalAddress = addressValidation?.address?.postalAddress;

        const suggestedAddressFields = {
          address1: postalAddress.addressLines.join(' '),
          city: postalAddress.locality,
          zipcode:
            postalAddress.regionCode === countriesCode.US
              ? postalAddress.postalCode.slice(0, 5)
              : postalAddress.postalCode,
          state: postalAddress.administrativeArea,
          country: postalAddress.regionCode,
        };

        dispatch({
          type: ADDRESS_VALIDATION_RECOMMENDED_FIELDS,
          data: suggestedAddressFields,
        });
        return { status: 'failure', addressError: 'Address correction suggested' };
      }

      return { status: 'success' };
    } catch (error) {
      /**
       * If the address validation throws an unexpected error (bad request, network)
       * we want to bypass this validation instead of blocking our users
       */
      logSentryError('[dux/checkoutAddresses] validateAddress', error);
      return { status: 'success' };
    }
  };

export const validateAddressChoice = data => async dispatch =>
  dispatch({ type: ADDRESS_VALIDATION_CHOICE, data });

export const resetAddressInvalidFields = () => async dispatch =>
  dispatch({ type: ADDRESS_VALIDATION_INVALID_FIELDS, data: null });

export const resetAddressChoice = () => async dispatch =>
  dispatch({ type: ADDRESS_VALIDATION_CHOICE_RESET });
