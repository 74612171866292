import { PRODUCTS } from 'Apps/Feedback/constants/feedbackSections';
import {
  skincareMoisturizerFeel,
  skincareNicelyHydrating,
  skincareNotHydratingEnough,
  skincareTooHeavy,
} from 'Apps/Feedback/constants/feedbackSlugs';

import { feedbackCategories } from 'constants/feedback';
import { productsSlugs } from 'constants/products';

const moisturizerFeel = {
  name: skincareMoisturizerFeel,
  route: `/feedback/${feedbackCategories.SKINCARE}/moisturizer-product-feel`,
  category: PRODUCTS,
  feedbackCategory: feedbackCategories.SKINCARE,
  shouldBeIncluded: ({ formulas, feedbackAnswers }) =>
    formulas?.some(f => f.variant.product.type === productsSlugs.MOISTURIZER_MINI) &&
    feedbackAnswers?.moisturizer_satisfaction,
  title: 'How does the moisturizer feel on your skin?',
  options: [
    { value: skincareTooHeavy, label: 'Too heavy' },
    { value: skincareNicelyHydrating, label: 'Nicely hydrating' },
    { value: skincareNotHydratingEnough, label: 'Not hydrating enough' },
  ],
  getInitialValue: answers => answers?.moisturizer_product_feel,
  getAnswers: ({ selected }) => ({ moisturizer_product_feel: selected }),
};

export default moisturizerFeel;
