import { lazy, Suspense } from 'react';

import Delay from 'Components/Delay';

import ErrorBoundary from 'Containers/ErrorBoundary';

import LoadingScene from 'Scenes/LoadingScene';

const createLoadablePage = (loader, { LoadingSceneProps = {} } = {}) => {
  const Component = lazy(loader);

  return props => (
    <ErrorBoundary>
      <Suspense
        fallback={
          <Delay>
            {({ pastDelay }) => <LoadingScene pastDelay={pastDelay} {...LoadingSceneProps} />}
          </Delay>
        }
      >
        <Component {...props} />
      </Suspense>
    </ErrorBoundary>
  );
};

export default createLoadablePage;
