import { createNextState } from '@reduxjs/toolkit';

import partition from 'lodash/fp/partition';

import { isKey } from 'types/predicates';

import { FRAGRANCE_FREE, NO_ADDED_FRAGRANCE } from 'constants/haircareFragrances';
import { productsCategories, productsSlugs } from 'constants/products';

import { supplementsFactsImages } from 'assets/content/cart/supplements';
import * as haircareFragrances from 'assets/content/haircareFragrances';
import * as hairOilFragrances from 'assets/content/hairOilFragrances';
import * as skincareFragrances from 'assets/content/skincareFragrances';

import logSentryError from 'utils/logSentry';
import { capitalizeFirstLetter } from 'utils/textUtils';

import type {
  DigitalLeafletRawData,
  EnrichedData,
  EnrichedOrderItem,
  OrderItem,
  OrderItem as RawOrderItem,
} from 'dux/digitalLeaflet/types';

import {
  fragranceImages,
  headingTexts,
  heroBodyText,
  heroImages,
  productsAdditionalContent,
} from './contents';

const productHasShowcasedFragrance = (item: OrderItem) =>
  !item.formula.type.includes('supplement') &&
  item.formula.fragrance &&
  item.formula.fragrance !== NO_ADDED_FRAGRANCE;

const fragrances = { ...haircareFragrances, ...hairOilFragrances, ...skincareFragrances };

const deriveName = (fragrance: RawOrderItem['formula']['fragrance']) => {
  const key = fragrance.toUpperCase();
  if (isKey(fragrances, key)) return fragrances[key].name;
  return ''; /* not expected. Here we are parsing/validating the data, should we log to Sentry when the data is erroneous? */
};

const deriveFragranceNotes = (fragrance: RawOrderItem['formula']['fragrance']) => {
  const key = fragrance.toUpperCase();
  if (isKey(fragrances, key)) {
    const fragranceContent = fragrances[key];
    if ('ingredients' in fragranceContent) return fragranceContent.ingredients;
    if ('details' in fragranceContent) return fragranceContent.details;
  }
  return ''; /* not expected */
};

const deriveFragranceDescription = ({
  fragrance,
  fragrance_description,
  fragrance_smells_like,
  type: formulaType = undefined,
  fragranceNotesOnly = false,
}: Pick<RawOrderItem['formula'], 'fragrance' | 'fragrance_description' | 'fragrance_smells_like'> &
  Partial<Pick<RawOrderItem['formula'], 'type'>> & { fragranceNotesOnly?: boolean }) => {
  if (!fragrance_description) {
    return '';
  }
  if (
    !fragrance_smells_like &&
    (formulaType === productsSlugs.SUPPLEMENT_CORE ||
      formulaType === productsSlugs.SUPPLEMENTS_POUCH)
  ) {
    return '';
  }

  if (typeof formulaType === 'undefined' && fragrance === FRAGRANCE_FREE) {
    return 'You may notice a light scent due to your custom ingredient blend. Some of our ingredients have naturally occurring scents that are not removed.';
  }

  if (fragrance_smells_like) {
    return `Smells like: ${fragrance_smells_like} — ${fragrance_description}`;
  }

  if (fragranceNotesOnly) {
    return capitalizeFirstLetter(deriveFragranceNotes(fragrance));
  }

  return `${fragrance === NO_ADDED_FRAGRANCE ? 'No added fragrance' : 'Your fragrance'} — ${
    [NO_ADDED_FRAGRANCE, FRAGRANCE_FREE].includes(fragrance)
      ? fragrance_description
      : `${deriveName(fragrance)}, with notes of ${deriveFragranceNotes(fragrance)}`
  }`;
};

export const enrichOrderItem = (rawOrderItem: RawOrderItem) =>
  createNextState(rawOrderItem as EnrichedOrderItem, (draftItem) => {
    draftItem.formula.displayedIngredients =
      draftItem.formula.key_active_ingredients?.slice(0, 4) ??
      draftItem.formula.ingredients?.slice(0, 4) ??
      [];
    draftItem.formula.descriptionString = draftItem.formula.description.join(' ');
    draftItem.formula.derivedFragranceDescription = deriveFragranceDescription(draftItem.formula);

    const productType = draftItem.formula.type;

    draftItem.isSupplement = [
      productsSlugs.SUPPLEMENT_CORE,
      productsSlugs.SUPPLEMENT_BOOSTER,
      productsSlugs.SUPPLEMENTS_POUCH,
    ].includes(productType);

    draftItem.incisListRequiresDisclaimer =
      /* hide disclaimer if item has been produced, or if it's Supplements */
      !draftItem.isSupplement && !draftItem.formula.produced_at;

    /* attach supplements facts images */
    if (draftItem.isSupplement) {
      const coreImageKey = `${draftItem.formula.slug}_facts`;
      if (isKey(supplementsFactsImages, coreImageKey)) {
        draftItem.factsImages = {
          core: supplementsFactsImages[coreImageKey]!,
          booster: supplementsFactsImages.supplementSoftgelFacts,
        };
      }
    }

    const additionalContentKey = isKey(productsAdditionalContent, productType)
      ? productType
      : 'default';

    if (additionalContentKey === 'default') {
      logSentryError(
        '[digitalLeaflet/enrichOrderItem]',
        new Error(
          `No matching additional content for product type (item.formula.type): ${productType}`,
        ),
      );
    }

    draftItem.productImage = productsAdditionalContent[additionalContentKey].productImage;
    draftItem.navLabel = productsAdditionalContent[additionalContentKey].navLabel;
    draftItem.howToImage = productsAdditionalContent[additionalContentKey].howToImage;

    /* derive fragrance-related values */
    if (productHasShowcasedFragrance(draftItem)) {
      const itemFragrance = draftItem.formula.fragrance;

      draftItem.showcasedFragrance = {
        name: isKey(fragrances, itemFragrance) ? fragrances[itemFragrance].name : itemFragrance,
        description: deriveFragranceDescription({
          fragrance: itemFragrance,
          fragrance_description: draftItem.formula.fragrance_description,
          fragrance_smells_like: draftItem.formula.fragrance_smells_like,
          fragranceNotesOnly: true,
        }),
        imageSrc: isKey(fragranceImages, itemFragrance)
          ? fragranceImages[itemFragrance]
          : fragranceImages.default,
      };
    }
  });

const deriveEnrichedItems = (rawOrderItems: Array<OrderItem>) =>
  createNextState(rawOrderItems as Array<EnrichedOrderItem>, (draftItems) => {
    /* partition items */
    const [supplementItems, otherItems] = partition(
      (item) => item.formula.type.includes('supplement'),
      draftItems as Array<OrderItem>,
    );

    if (supplementItems.length === 0) return otherItems.map(enrichOrderItem);

    /* merge supplement items into one */
    const supplementItem = supplementItems.reduce((acc, currItem) => {
      return { ...acc, ...currItem };
    }, {} as OrderItem);

    return [enrichOrderItem(supplementItem), ...otherItems.map(enrichOrderItem)];
  });

const sortItemsByRank = <T extends { formula: { rank: number } }>(unorderedItems: Array<T>) =>
  [...unorderedItems].sort((itemA, itemB) => itemB.formula.rank - itemA.formula.rank);

export const enrichDigitalLeaflet = (rawData: DigitalLeafletRawData) =>
  createNextState(rawData as EnrichedData, (draft) => {
    /* --------------------------------------------- */
    /* --- enrich with category-specific content --- */
    /* --------------------------------------------- */
    const orderCategory =
      draft.order.items[0]!.formula.category === productsCategories.HAIRCARE
        ? productsCategories.HAIRCARE
        : productsCategories.SKINCARE;
    draft.heroData = {
      images: heroImages[orderCategory],
      headingText: headingTexts[orderCategory],
      bodyText: heroBodyText,
    };
    draft.productsSectionTitle =
      draft.order.items.length > 1
        ? 'Custom products in your order'
        : 'Custom product in your order';

    /* ---------------------------------------------------------------------------------- */
    /* --- enrich items with F-E assets/content + derived data + merge relevant items --- */
    /* ---------------------------------------------------------------------------------- */
    draft.order.displayedItems = sortItemsByRank(deriveEnrichedItems(draft.order.items));
  });
