import PropTypes from 'prop-types';
import { forbidExtraProps } from 'airbnb-prop-types';

import ReactDOM from 'react-dom';

const ToastPortal = ({ children }) =>
  ReactDOM.createPortal(children, document.getElementById('toast'));

ToastPortal.propTypes = forbidExtraProps({
  children: PropTypes.node.isRequired,
});

export default ToastPortal;
