export const AntibrassModal = 'AntibrassModal';
export const DefaultQuestion = 'DefaultQuestion';
export const TextureContainer = 'TextureContainer';
export const FragranceContainer = 'FragranceContainer';
export const FragranceSatisfactionIntensity = 'FragranceSatisfactionIntensity';
export const PublicReview = 'PublicReview';
export const Rating = 'Rating';
export const TextArea = 'TextArea';
export const HaircareFeedbackResults = 'HaircareFeedbackResults';
export const HaircareTransitionScreen = 'HaircareTransitionScreen';
export const SupplementsFeedbackResults = 'SupplementsFeedbackResults';
export const SupplementsTransitionScreen = 'SupplementsTransitionScreen';
export const SkincareFeedbackResults = 'SkincareFeedbackResults';
export const SkincareFragrance = 'SkincareFragrance';
export const SkincareTransitionScreen = 'SkincareTransitionScreen';
