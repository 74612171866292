import { HAIR } from 'Apps/Feedback/constants/feedbackSections';
import {
  haircareHairShine,
  haircareImprovements,
  haircareNoConcernInImprovements,
  haircareNoImprovements,
  haircareSomeImprovements,
} from 'Apps/Feedback/constants/feedbackSlugs';

import { productsSlugs } from 'constants/products';

const hairShine = {
  name: haircareHairShine,
  route: `/feedback/haircare/${haircareHairShine}`,
  category: HAIR,
  shouldBeIncluded: ({ formulas }) =>
    formulas?.some(f =>
      [productsSlugs.HAIR_MASK, productsSlugs.SHAMPOO, productsSlugs.CONDITIONER].includes(
        f.variant.product.type
      )
    ),
  title: "Did you see improvements in your hair's shine?",
  subtitle: 'Light should reflect off your hair',
  options: [
    { value: haircareImprovements, label: 'Yes, I love the shine' },
    { value: haircareSomeImprovements, label: 'Some, but could be better' },
    { value: haircareNoImprovements, label: "No, it's looking dull" },
    { value: haircareNoConcernInImprovements, label: "It wasn't a concern" },
  ],
  getInitialValue: answers => answers?.hair_shine,
  getAnswers: ({ selected }) => ({ hair_shine: selected }),
};

export default hairShine;
