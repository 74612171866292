import { SKIN } from 'Apps/Feedback/constants/feedbackSections';
import {
  skincareAntiShine,
  skincareImprovements,
  skincareNoConcernInImprovements,
  skincareNoImprovements,
  skincareSomeImprovements,
} from 'Apps/Feedback/constants/feedbackSlugs';

import { feedbackCategories } from 'constants/feedback';

const skinAntiShine = {
  name: skincareAntiShine,
  route: '/feedback/skincare/skin-anti-shine',
  category: SKIN,
  feedbackCategory: feedbackCategories.SKINCARE,
  shouldBeIncluded: () => true,
  title: 'Is your skin less oily or shiny?',
  options: [
    { value: skincareImprovements, label: "Yes, it's balanced now" },
    { value: skincareSomeImprovements, label: 'Some, but could be better' },
    { value: skincareNoImprovements, label: 'No, I still have too many oily zones' },
    { value: skincareNoConcernInImprovements, label: "It wasn't a concern" },
  ],
  getInitialValue: answers => answers?.skin_anti_shine,
  getAnswers: ({ selected }) => ({ skin_anti_shine: selected }),
};

export default skinAntiShine;
