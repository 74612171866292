import { FRAGRANCE } from 'Apps/Feedback/constants/feedbackSections';
import { moisturizerFragranceSatisfactionIntensity } from 'Apps/Feedback/constants/feedbackSlugs';

import { feedbackCategories } from 'constants/feedback';
import { productsSlugs } from 'constants/products';

const moisturizerFragranceIntensitySatisfaction = {
  name: moisturizerFragranceSatisfactionIntensity,
  route: `/feedback/skincare/fragrance-moisturizer`,
  category: FRAGRANCE,
  feedbackCategory: feedbackCategories.SKINCARE,
  shouldBeIncluded: ({ formulas, skinFragrance, consultationAnswers }) =>
    !consultationAnswers?.pref_fragrance_free_skin &&
    formulas?.some(
      f => [productsSlugs.MOISTURIZER].includes(f.variant.product.type) && skinFragrance !== null
    ),
  component: 'FragranceSatisfactionIntensity',
  surtitle: {
    showFormulas: 'moisturizer',
    showFragrance: true,
  },
  title: 'How satisfied are you with the scent of your moisturizer?',
  getInitialValue: answers => ({
    fragrance_satisfaction: answers?.fragrance_moisturizer_satisfaction,
    fragrance_intensity: answers?.fragrance_moisturizer_intensity,
  }),
  getAnswers: ({ selected }) => ({
    fragrance_moisturizer_satisfaction: selected?.fragrance_satisfaction,
    fragrance_moisturizer_intensity: selected?.fragrance_intensity,
  }),
};

export default moisturizerFragranceIntensitySatisfaction;
