import { PRODUCTS } from 'Apps/Feedback/constants/feedbackSections';
import {
  haircareDryShampooCleansing,
  haircareImprovements,
  haircareNoConcernInImprovements,
  haircareNoImprovements,
  haircareSomeImprovements,
} from 'Apps/Feedback/constants/feedbackSlugs';

import { productsSlugs } from 'constants/products';

const dryShampooCleansing = {
  name: haircareDryShampooCleansing,
  route: `/feedback/haircare/${haircareDryShampooCleansing}`,
  category: PRODUCTS,
  shouldBeIncluded: ({ formulas, feedbackAnswers }) =>
    formulas?.find(f => f.variant.product.type === productsSlugs.DRY_SHAMPOO) &&
    feedbackAnswers?.product_dry_shampoo_satisfaction,
  title: 'Did you see a gentle cleansing effect?',
  subtitle: "Ideally, you'll have less noticeable oil & more days between washes",
  options: [
    { value: haircareImprovements, label: 'Yes, it works well ' },
    { value: haircareSomeImprovements, label: 'Sort of, but not enough cleansing' },
    { value: haircareNoImprovements, label: 'No, it’s just not clean' },
    { value: haircareNoConcernInImprovements, label: "It wasn't a concern" },
  ],
  getInitialValue: answers => answers?.product_dry_shampoo_cleansing,
  getAnswers: ({ selected }) => ({ product_dry_shampoo_cleansing: selected }),
};

export default dryShampooCleansing;
