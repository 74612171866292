import * as APIClient from './APIClient';

export const fetchProducts = async addressPubKey => {
  const response = await APIClient.get(
    `/v1/products${addressPubKey ? `?shipping_address=${addressPubKey}` : ''}`
  );
  return response.json();
};

export const fetchGiftsSets = async () => {
  const response = await APIClient.get('/v1/products/digital_gift');
  return response.json();
};
