import { Link } from 'react-router-dom';

import { styled } from '@prose-ui/legacy';

import Button from 'Components/LegacyButton';
import Title from 'Components/Title';

import BaseScene from '../../Consultation/Scenes/BaseScene';

const MessageContainer = styled.div`
  max-width: 592px;
  text-align: center;
`;

const AccessoriesConsultationRedirection = () => {
  return (
    <BaseScene justifyContent="center">
      <MessageContainer>
        <Title>
          You’re almost there — just complete your haircare consultation to purchase your accessory.
        </Title>

        <Button Component={Link} to="/consultation/haircare" variant="vert">
          Continue
        </Button>
      </MessageContainer>
    </BaseScene>
  );
};

export default AccessoriesConsultationRedirection;
