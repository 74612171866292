import { fragranceNewChoice as productFragranceNewChoice } from 'Apps/Feedback/constants/feedbackSlugs';

import { feedbackCategories } from 'constants/feedback';

const skincareFragranceNewChoice = {
  name: productFragranceNewChoice,
  category: 'fragrance',
  route: `/feedback/skincare/fragrance-new-choice`,
  feedbackCategory: feedbackCategories.SKINCARE,
  shouldBeIncluded: () => true,
  component: 'SkincareFragrance',
  title: 'Which fragrance would you like in your next order?',
  surtitle: {
    showFormulas: 'skincareWithSurveyFragrance',
    showFragrance: true,
  },
  getInitialValue: answers => answers?.fragrance_new_choice,
  getAnswers: ({ selected }) => ({
    new_fragrance: selected.skincareFragranceFromOrder !== selected.fragranceChoice,
    fragrance_new_choice:
      selected.fragranceChoice === 'NONE' ? 'frag_free' : selected.fragranceChoice,
  }),
};

export default skincareFragranceNewChoice;
