import {
  supplementsFromThreeMonths,
  supplementsSatisfaction,
} from 'Apps/Feedback/constants/feedbackSlugs';

import { productsSlugs } from 'constants/products';

const supplementsSatisfactionScreen = {
  name: supplementsSatisfaction,
  route: `/feedback/supplements/${supplementsSatisfaction}`,
  shouldBeIncluded: ({ feedbackAnswers }) =>
    feedbackAnswers &&
    supplementsFromThreeMonths.includes(feedbackAnswers.product_supplements_usage_duration),
  component: 'Rating',
  title: (
    <>
      How satisfied are you with your Root Source
      <sup>®</sup>Hair Supplements?
    </>
  ),
  options: [
    { value: 1, label: 'Very Unsatisfied' },
    { value: 2, label: 'Unsatisfied' },
    { value: 3, label: 'Neutral' },
    { value: 4, label: 'Satisfied' },
    { value: 5, label: 'Very Satisfied' },
  ],
  hasOpenText: true,
  getInitialValue: answers => answers?.product_supplements_satisfaction,
  getInitialProductFeedback: productsAnswers => {
    return productsAnswers.find(answer => answer.product === productsSlugs.SUPPLEMENT_CORE)
      ?.open_text_feedback;
  },
  getAnswers: ({ selected }) => ({ product_supplements_satisfaction: selected }),
  getFeedbackAnswer: ({ openText }) => ({
    product: productsSlugs.SUPPLEMENT_CORE,
    open_text_feedback: openText?.feedback_text,
  }),
};

export default supplementsSatisfactionScreen;
