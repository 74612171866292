import { Component } from 'react';
import PropTypes from 'prop-types';

class Delay extends Component {
  state = {
    pastDelay: false,
  };

  componentDidMount() {
    this._delay = setTimeout(() => this.setState({ pastDelay: true }), 1000);
  }

  componentWillUnmount() {
    clearTimeout(this._delay);
  }

  render() {
    const { children } = this.props;
    const { pastDelay } = this.state;
    return children({ pastDelay });
  }
}

Delay.propTypes = {
  children: PropTypes.func.isRequired,
};

export default Delay;
