import { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'dux/app/hooks';

import { SUCCESS } from 'constants/statuses';
import {
  UNIVERSAL_CART_PRODUCT_ADDED_FROM_RECO,
  UNIVERSAL_CART_PRODUCT_ADDED_PAGE,
  UNIVERSAL_CART_PRODUCT_REMOVED,
  UNIVERSAL_CART_VISITED,
} from 'constants/tracking';

import { trackPubsub } from 'dux/tracking/actions';
import { getIsAuthenticated, getIsAuthenticating } from 'dux/auth/selectors';
import { getIsGuestUserAuthenticated, getIsGuestUserAuthenticating } from 'dux/guestAuth/selectors';

import {
  getCartRecommendationStatusV2,
  getCartV2Data,
  getCartV2Status,
  getTransactionId,
  getUpsaleSectionRecommendationV2,
} from './selectors';
import { getCartV2 } from './thunks';

export const useGetCartV2 = () => {
  const dispatch = useAppDispatch();
  const status = useAppSelector(getCartV2Status);
  const isGuestUserAuthenticated = useAppSelector(getIsGuestUserAuthenticated);
  const isGuestUserAuthenticating = useAppSelector(getIsGuestUserAuthenticating);
  const isAuthenticated = useAppSelector(getIsAuthenticated);
  const isAuthenticating = useAppSelector(getIsAuthenticating);

  useEffect(() => {
    if (
      !isGuestUserAuthenticating &&
      !isAuthenticating &&
      (isAuthenticated || isGuestUserAuthenticated)
    ) {
      dispatch(getCartV2());
    }
  }, [isGuestUserAuthenticated, isAuthenticated, isGuestUserAuthenticating, isAuthenticating]);

  return { status };
};

export const useTrackingCartVisited = () => {
  const [isTrackingSent, setIsTrackingSent] = useState(false);
  const dispatch = useAppDispatch();
  const status = useAppSelector(getCartV2Status);
  const cartData = useAppSelector(getCartV2Data);
  const cartRecoStatus = useAppSelector(getCartRecommendationStatusV2);
  const cartRecoProducts = useAppSelector(getUpsaleSectionRecommendationV2);
  const transactionId = useAppSelector(getTransactionId);

  useEffect(() => {
    if (transactionId && status === SUCCESS && cartRecoStatus === SUCCESS && !isTrackingSent) {
      dispatch(
        trackPubsub({
          eventName: UNIVERSAL_CART_VISITED,
          data: {
            transaction_id: transactionId,
            url: '/checkout/accessories',
            cart_products: cartData.cartitems.map(
              product => product.variant_slug || product.product_type
            ),
            upsell_products: cartRecoProducts.map(product => product.product_slug || product.slug),
          },
        })
      );
      setIsTrackingSent(true);
    }
  }, [status, cartRecoStatus, isTrackingSent, transactionId]);
};

export const useTrackCartV2OnAddFromPageProduct = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const status = useAppSelector(getCartV2Status);
  const cartData = useAppSelector(getCartV2Data);
  const transactionId = useAppSelector(getTransactionId);

  const onAdd = productSlug => {
    if (status === SUCCESS) {
      dispatch(
        trackPubsub({
          eventName: UNIVERSAL_CART_PRODUCT_ADDED_PAGE,
          data: {
            added_products: [productSlug],
            url: pathname,
            transaction_id: transactionId,
            cart_products: cartData.cartitems.map(
              product => product.variant_slug || product.product_type
            ),
          },
        })
      );
    }
  };

  return { onAdd };
};

export const useTrackCartV2OnAddFromRecommendedSection = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const status = useAppSelector(getCartV2Status);
  const cartData = useAppSelector(getCartV2Data);
  const transactionId = useAppSelector(getTransactionId);
  const cartRecoProducts = useAppSelector(getUpsaleSectionRecommendationV2);

  const onAdd = productSlug => {
    if (status === SUCCESS && transactionId) {
      dispatch(
        trackPubsub({
          eventName: UNIVERSAL_CART_PRODUCT_ADDED_FROM_RECO,
          data: {
            added_products: [productSlug],
            url: pathname,
            transaction_id: transactionId,
            cart_products: cartData.cartitems.map(
              product => product.variant_slug || product.product_type
            ),
            upsell_products: cartRecoProducts.map(product => product.product_slug || product.slug),
          },
        })
      );
    }
  };

  return { onAdd };
};

export const useTrackCartV2OnRemoveFromCart = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const status = useAppSelector(getCartV2Status);
  const cartData = useAppSelector(getCartV2Data);
  const transactionId = useAppSelector(getTransactionId);
  const cartRecoProducts = useAppSelector(getUpsaleSectionRecommendationV2);

  const onRemove = productSlug => {
    if (status === SUCCESS && transactionId) {
      dispatch(
        trackPubsub({
          eventName: UNIVERSAL_CART_PRODUCT_REMOVED,
          data: {
            removed_products: [productSlug],
            url: pathname,
            transaction_id: transactionId,
            cart_products: cartData.cartitems.map(
              product => product.variant_slug || product.product_type
            ),
            upsell_products: cartRecoProducts.map(product => product.product_slug || product.slug),
          },
        })
      );
    }
  };

  return { onRemove };
};
