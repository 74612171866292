import * as APIClient from './APIClient';

export const fetch = async pubkey => {
  const response = await APIClient.get(`/v1/surveys/${pubkey}`);
  return response.json();
};

export const fetchFeedbackEndOfTheRoad = async pubkey => {
  const response = await APIClient.post(`/v1/supplements_feedback/${pubkey}/end_of_road`);
  return response.json();
};
