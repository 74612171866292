export const UPSELL_ACCOUNT_CHECKOUT_PROCEED_EVENT =
  'cta_click.upsell_in_account.add_to_cart.button_clicked.redirected';
export const UPSELL_ACCOUNT_OPEN_SUBSCRIPTION_MODAL_EVENT =
  'cta_click.upsell_in_account.add_to_subscription.button_clicked.modal_opened';
export const UPSELL_ACCOUNT_PAGE_VIEWED = 'pageview.upsell_in_account';

export const UNIVERSAL_CART_VISITED = 'universal_cart.visited';
export const UNIVERSAL_CART_PRODUCT_ADDED_PAGE = 'universal_cart.product_added_page';
export const UNIVERSAL_CART_PRODUCT_ADDED_FROM_RECO = 'universal_cart.product_added_recommender';
export const UNIVERSAL_CART_PRODUCT_REMOVED = 'universal_cart.product_removed';
