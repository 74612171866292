import { PRODUCTS } from 'Apps/Feedback/constants/feedbackSections';
import {
  haircareCurlCreamDefinition,
  haircareImprovements,
  haircareNoConcernInImprovements,
  haircareNoImprovements,
  haircareSomeImprovements,
  haircareTooCrunchy,
} from 'Apps/Feedback/constants/feedbackSlugs';

import { productsSlugs } from 'constants/products';

const curlCreamDefinition = {
  name: haircareCurlCreamDefinition,
  route: `/feedback/haircare/${haircareCurlCreamDefinition}`,
  category: PRODUCTS,
  shouldBeIncluded: ({ formulas, feedbackAnswers }) =>
    formulas?.find(f => f.variant.product.type === productsSlugs.CURL_CREAM) &&
    feedbackAnswers?.product_curl_cream_satisfaction,
  title: 'Were your curls defined after using curl cream?',
  options: [
    { value: haircareImprovements, label: 'Yes, I saw good curl definition and sufficient hold' },
    { value: haircareTooCrunchy, label: 'My hair is too crunchy' },
    { value: haircareSomeImprovements, label: 'Some, but could be better' },
    { value: haircareNoImprovements, label: 'No, not enough hold & definition' },
    { value: haircareNoConcernInImprovements, label: "It wasn't a concern" },
  ],
  getInitialValue: answers => answers?.product_curl_cream_definition,
  getAnswers: ({ selected }) => ({ product_curl_cream_definition: selected }),
};

export default curlCreamDefinition;
