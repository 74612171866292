import { userSignout } from 'dux/auth/actions';
import { STYLIST_FAILURE, STYLIST_REQUEST, STYLIST_SUCCESS } from 'dux/stylist/actionTypes';

import initialState from './initialState';

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case userSignout.type:
      return initialState;

    case STYLIST_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: true,
      };

    case STYLIST_SUCCESS:
      return {
        ...state,
        error: null,
        isLoading: false,
        stylist: action.stylist,
      };

    case STYLIST_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
        stylist: null,
      };

    default:
      return state;
  }
}
