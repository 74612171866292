import { createAsyncThunk } from '@reduxjs/toolkit';

import isEmpty from 'lodash/fp/isEmpty';

import * as Sentry from '@sentry/nextjs';

import { supplementsLessThanTwoMonths } from 'Apps/Feedback/constants/feedbackSlugs';

import * as OrderService from 'Services/OrderService';
import * as SurveysService from 'Services/SurveysService';

import { feedbackCategories, feedbackSubCategories } from 'constants/feedback';

import logSentryError from 'utils/logSentry';

import {
  trackFeedbackConfirmationViewed,
  trackGtmEvent,
  trackReviewAndRefineAccountViewed,
  trackReviewAndRefineCompleted,
  trackReviewAndRefineStarted,
} from 'dux/tracking/actions';
import {
  getConsultationSurveyDetails,
  getConsultationSurveyIsSoldoutFragrance,
  getConsultationSurveyPubkeyFromOrder,
  getFeedbackCompletionStatus,
  getFeedbackData,
  getFeedbackEligibleProducts,
  getFeedbackPubkey,
  getFeedbackQuestionByIndex,
  getFeedbackQuestionSet,
  getOrderFormulas,
  getOrderHasSkincareMinis,
  getOrderSkincare,
  isHaircareFeedbackCompleted,
  isSupplementsFeedbackCompleted,
} from 'dux/feedback/selectors';
import { getFirstSupplementsOrder } from 'dux/orders/selectors';
import {
  getHaircareFeedbackOnOrder,
  getSkincareFeedbackOnOrder,
  getSupplementsFeedbackOnOrder,
  getUserSkincareFragrance,
} from 'dux/user/selectors';

/**
 * Fetch end-of-the-road message (POST)
 */
export const fetchFeedbackEndOfTheRoad = createAsyncThunk(
  'feedback/fetchFeedbackEndOfTheRoad',
  async (_, { getState }) => {
    const state = getState();
    const feedbackPubkey = getFeedbackPubkey(state);

    const endOfTheRoadMessage = await SurveysService.fetchFeedbackEndOfTheRoad(feedbackPubkey);
    return endOfTheRoadMessage;
  },
  {
    condition: (_, { getState }) => {
      const feedbackPubkey = getFeedbackPubkey(getState());
      return Boolean(feedbackPubkey);
    },
  }
);

/**
 * Fetch orders eligible for feedback (GET)
 */
export const fetchFeedbackHaircareOrder = createAsyncThunk(
  'feedback/fetchFeedbackHaircareOrder',
  async ({ haircareOrderPubkey, supplementsOrderPubkey, withFeedback = false }) => {
    try {
      const order = await OrderService.fetch(haircareOrderPubkey);

      return { order, haircareOrderPubkey, supplementsOrderPubkey, withFeedback }; // passing pubkeys for conditional assignation
    } catch (error) {
      logSentryError('[dux/feedback] fetchFeedbackHaircareOrder', error);

      throw error;
    }
  }
);
export const fetchFeedbackSupplementsOrder = createAsyncThunk(
  'feedback/fetchFeedbackSupplementsOrder',
  async ({ pubkey, withFeedback = false }) => {
    try {
      const order = await OrderService.fetch(pubkey);
      return { order, withFeedback };
    } catch (error) {
      logSentryError('[dux/feedback] fetchFeedbackSupplementsOrder', error);

      throw error;
    }
  }
);
export const fetchFeedbackSkincareOrder = createAsyncThunk(
  'feedback/fetchFeedbackSkincareOrder',
  async ({ pubkey, withFeedback = false }) => {
    try {
      const order = await OrderService.fetch(pubkey);
      return { order, withFeedback };
    } catch (error) {
      logSentryError('[dux/feedback] fetchFeedbackSkincareOrder', error);

      throw error;
    }
  }
);
export const fetchFeedbackOrder = createAsyncThunk(
  'feedback/fetchFeedbackOrder',
  async (_, { dispatch, getState }) => {
    const state = getState();
    const haircareOrderPubkey = getHaircareFeedbackOnOrder(state);
    const supplementsOrderPubkey = getSupplementsFeedbackOnOrder(state);
    const skincareOrderPubkey = getSkincareFeedbackOnOrder(state);

    // NOTE: currently fetching all 3 in waterfall, but seems like it could be simultaneous ?
    // (or maybe the conditions should be exclusive)
    if (haircareOrderPubkey) {
      await dispatch(fetchFeedbackHaircareOrder({ haircareOrderPubkey, supplementsOrderPubkey })); // sending both pubkeys in order to compare them in reducer
    }
    if (supplementsOrderPubkey && haircareOrderPubkey !== supplementsOrderPubkey) {
      // if supplements order is a separate order than haircare (topicals)
      await dispatch(fetchFeedbackSupplementsOrder({ pubkey: supplementsOrderPubkey }));
    }
    if (skincareOrderPubkey) {
      await dispatch(fetchFeedbackSkincareOrder({ pubkey: skincareOrderPubkey }));
    }
  }
);

/**
 * Load feedback to init survey's answers already answered (GET)
 */
export const fetchConsultationSurvey = createAsyncThunk(
  'feedback/fetchConsultationSurvey',
  async consultationSurveyPubkey => {
    try {
      const consultationSurvey = await SurveysService.fetch(consultationSurveyPubkey);
      return consultationSurvey;
    } catch (error) {
      logSentryError(`[dux/feedback] fetchConsultationSurvey`, error);

      throw error;
    }
  }
);
export const patchHaircareFeedback = createAsyncThunk(
  'feedback/patchHaircareFeedback',
  async ({ pubkey, data }) => {
    const feedback = await OrderService.patchFeedback(pubkey, data);
    return feedback;
  }
);
export const patchSupplementsFeedback = createAsyncThunk(
  'feedback/patchSupplementsFeedback',
  async ({ pubkey, data }) => {
    const feedback = await OrderService.patchSupplementsFeedback(pubkey, data);
    return feedback;
  }
);
export const patchSkincareFeedback = createAsyncThunk(
  'feedback/patchSkincareFeedback',
  async ({ pubkey, data }) => {
    const feedback = await OrderService.patchSkincareFeedback(pubkey, data);
    return feedback;
  }
);
export const patchProductsFeedback = createAsyncThunk(
  'feedback/patchProductsFeedback',
  async ({ pubkey, data }) => {
    try {
      const productSatisfaction = await OrderService.patchProductsFeedback(pubkey, data);
      return productSatisfaction;
    } catch (error) {
      Sentry.withScope(scope => {
        scope.setLevel('error');
        Sentry.captureMessage(`[Patch product feedback] ${error.toString()}`);
      });
      throw error;
    }
  }
);

export const getProductsFeedback = createAsyncThunk(
  'feedback/getProductsFeedback',
  async ({ pubkey }) => {
    try {
      const productSatisfaction = await OrderService.getProductsFeedback(pubkey);
      return await productSatisfaction.json();
    } catch (error) {
      Sentry.withScope(scope => {
        scope.setLevel('error');
        Sentry.captureMessage(`[Get product feedback] ${error.toString()}`);
      });
      throw error;
    }
  }
);

export const loadFeedback = createAsyncThunk(
  'feedback/loadFeedback',
  async ({ feedbackCategory, origin, hasOpenText }, { dispatch, getState }) => {
    const state = getState();
    const haircareOrderPubkey = getHaircareFeedbackOnOrder(state);
    const supplementsOrderPubkey = getSupplementsFeedbackOnOrder(state);
    const skincareOrderPubkey = getSkincareFeedbackOnOrder(state);

    /**
     * Fetch order details on which to give feedback
     * Set the feedback answers if existing
     */
    try {
      if (feedbackCategory === feedbackCategories.HAIRCARE) {
        await dispatch(
          fetchFeedbackHaircareOrder({
            haircareOrderPubkey,
            supplementsOrderPubkey,
            withFeedback: true,
          })
        ); // sending both pubkeys in order to compare them in reducer
        if (hasOpenText) {
          await dispatch(getProductsFeedback({ pubkey: haircareOrderPubkey }));
        }
      }
      if (feedbackCategory === feedbackSubCategories.SUPPLEMENTS) {
        // TODO: Shouldn't it be `fetchFeedbackSupplementsOrder` ?
        await dispatch(
          fetchFeedbackHaircareOrder({
            haircareOrderPubkey: supplementsOrderPubkey,
            supplementsOrderPubkey,
            withFeedback: true,
          })
        );
        if (hasOpenText) {
          await dispatch(getProductsFeedback({ pubkey: supplementsOrderPubkey }));
        }
      }
      if (feedbackCategory === feedbackCategories.SKINCARE) {
        await dispatch(
          fetchFeedbackSkincareOrder({ pubkey: skincareOrderPubkey, withFeedback: true })
        );
        if (hasOpenText) {
          await dispatch(getProductsFeedback({ pubkey: skincareOrderPubkey }));
        }
      }
    } catch (error) {
      Sentry.withScope(scope => {
        scope.setLevel('error');
        Sentry.captureMessage(`[OrderService fetch] ${error.toString()}`);
      });
      throw error;
    }

    // Fetch the consultation survey
    const consultationSurveyPubkey =
      feedbackCategory === feedbackCategories.SKINCARE
        ? getConsultationSurveyPubkeyFromOrder(getState())[feedbackCategories.SKINCARE]
        : getConsultationSurveyPubkeyFromOrder(getState())[feedbackCategories.HAIRCARE];
    if (consultationSurveyPubkey) {
      await dispatch(fetchConsultationSurvey(consultationSurveyPubkey));
    }

    // If no feedback stored yet, init it
    if (isEmpty(getFeedbackData(getState()))) {
      /** INFO: if origin is null when we init feedback it's sign of a regression somewhere
       */
      if (origin === null) {
        logSentryError('[dux/feedback] loadFeedback', `Invalid feedback origin value ${origin}`);
      }
      try {
        if (feedbackCategory === feedbackCategories.HAIRCARE) {
          await dispatch(patchHaircareFeedback({ pubkey: haircareOrderPubkey, data: { origin } }));
        }
        if (feedbackCategory === feedbackSubCategories.SUPPLEMENTS) {
          await dispatch(
            patchSupplementsFeedback({ pubkey: supplementsOrderPubkey, data: { origin } })
          );
        }
        if (feedbackCategory === feedbackCategories.SKINCARE) {
          await dispatch(patchSkincareFeedback({ pubkey: skincareOrderPubkey, data: { origin } }));
        }
      } catch (error) {
        Sentry.withScope(scope => {
          scope.setLevel('error');
          Sentry.captureMessage(`[OrderService Patch feedback] ${error.toString()}`);
        });
        throw error;
      }
    }
  }
);

/**
 * Save feedback answer (PATCH)
 */
export const saveFeedbackAnswers = createAsyncThunk(
  'feedback/saveFeedbackAnswers',
  async ({ selected, openText, questionIndex, feedbackCategory }, { dispatch, getState }) => {
    const state = getState();
    const answers = getFeedbackData(state);
    const question = getFeedbackQuestionByIndex(state, { feedbackCategory, questionIndex });
    const haircareOrderPubkey = getHaircareFeedbackOnOrder(state);
    const supplementsOrderPubkey = getSupplementsFeedbackOnOrder(state);
    const skincareOrderPubkey = getSkincareFeedbackOnOrder(state);

    if (openText?.feedback_text.length > 0) {
      try {
        if (feedbackCategory === feedbackCategories.HAIRCARE) {
          const data = question.getFeedbackAnswer({ openText });
          await dispatch(patchProductsFeedback({ pubkey: haircareOrderPubkey, data }));
        }
        if (feedbackCategory === feedbackSubCategories.SUPPLEMENTS) {
          const data = question.getFeedbackAnswer({ openText });
          await dispatch(patchProductsFeedback({ pubkey: supplementsOrderPubkey, data }));
        }
        if (feedbackCategory === feedbackCategories.SKINCARE) {
          const data = question.getFeedbackAnswer({ openText });
          await dispatch(patchProductsFeedback({ pubkey: skincareOrderPubkey, data }));
        }
      } catch (error) {
        Sentry.withScope(scope => {
          scope.setLevel('error');
          Sentry.captureMessage(`[OrderService Post feedback] ${error.toString()}`);
        });
        throw error;
      }
    }

    // Attempt fetching the existing feedback data or init it
    try {
      const patchData = question.getAnswers({ selected, answers, question });
      if (feedbackCategory === feedbackCategories.HAIRCARE) {
        await dispatch(patchHaircareFeedback({ pubkey: haircareOrderPubkey, data: patchData }));
      }
      if (feedbackCategory === feedbackSubCategories.SUPPLEMENTS) {
        await dispatch(
          patchSupplementsFeedback({ pubkey: supplementsOrderPubkey, data: patchData })
        );
      }
      if (feedbackCategory === feedbackCategories.SKINCARE) {
        await dispatch(patchSkincareFeedback({ pubkey: skincareOrderPubkey, data: patchData }));
      }
    } catch (error) {
      Sentry.withScope(scope => {
        scope.setLevel('error');
        Sentry.captureMessage(`[OrderService Patch feedback] ${error.toString()}`);
      });
      throw error;
    }
  }
);

export const onNext =
  ({ selected, openText, currentQuestionIndex, feedbackCategory, antiBrassIngredients }) =>
  async (dispatch, getState) => {
    const state = getState();
    const currentQuestion = getFeedbackQuestionByIndex(state, {
      feedbackCategory,
      questionIndex: currentQuestionIndex,
    });

    try {
      if (!currentQuestion.skipSave) {
        // This question's answers will be saved
        await dispatch(
          saveFeedbackAnswers({
            selected,
            openText,
            questionIndex: currentQuestionIndex,
            feedbackCategory,
          })
        );
      }

      const completionStatus = getFeedbackCompletionStatus(state);

      const updatedState = getState();
      const consultationAnswers = getConsultationSurveyDetails(updatedState);
      const feedbackAnswers = getFeedbackData(updatedState);
      const skinFragrance = getUserSkincareFragrance(updatedState);
      const formulas =
        feedbackCategory === feedbackCategories.HAIRCARE
          ? getOrderFormulas(updatedState)
          : getOrderSkincare(updatedState);
      const isSoldoutFragrance = getConsultationSurveyIsSoldoutFragrance(updatedState);
      const questions = getFeedbackQuestionSet(updatedState, { feedbackCategory });
      const nextQuestion = questions.find(
        (question, questionIndex) =>
          questionIndex > currentQuestionIndex &&
          question.shouldBeIncluded({
            consultationAnswers,
            feedbackAnswers,
            formulas,
            skinFragrance,
            isSoldoutFragrance,
            questions,
            antiBrassIngredients,
          })
      );

      if (nextQuestion?.route) {
        dispatch(
          trackGtmEvent('eventGA', {
            feedback_name: currentQuestion?.name,
            feedback_question_topic: currentQuestion?.category,
            feedback_question_num: currentQuestionIndex,
            feedback_question_answer: currentQuestion.skipSave ? null : selected,
          })
        );
      }

      // Redirect to WhatToExpect page if user has not been using supplements for at least 2 months
      if (supplementsLessThanTwoMonths.includes(selected)) {
        return { nextRoute: '/feedback/supplements/what-to-expect', options: {} };
      }

      // Redirect to FeedbackConfirmation page if there is no more question
      if (completionStatus === 'completed' && !nextQuestion) {
        return {
          nextRoute: `/feedback/confirmation${
            selected.category && `?data-from=${selected.category}`
          }`,
          options: {},
        };
      }

      // Redirect to next question if there's one
      if (nextQuestion?.route) {
        return { nextRoute: nextQuestion.route, options: {} };
      }

      // Otherwise redirect to Feedback in account
      return { nextRoute: '/account/feedback', options: {} };
    } catch (error) {
      Sentry.withScope(scope => {
        scope.setLevel('error');
        Sentry.captureMessage(`[Consultation Action] onNext : ${error.toString()}`);
      });
      return { nextRoute: '/account/feedback', options: {} };
    }
  };

/**
 * Tracking data
 */
export const trackAccountFeedbackViewed = () => async (dispatch, getState) => {
  const state = getState();
  const eligibleCategory = [];
  const isHaircareEligible = getHaircareFeedbackOnOrder(state);
  const isSupplementsEligible = getSupplementsFeedbackOnOrder(state);
  const isSkincareEligible = getSkincareFeedbackOnOrder(state);

  if (isHaircareEligible) {
    eligibleCategory.push(feedbackCategories.HAIRCARE);
  }
  if (isSupplementsEligible) {
    eligibleCategory.push(feedbackSubCategories.SUPPLEMENTS);
  }
  if (isSkincareEligible) {
    eligibleCategory.push(feedbackCategories.HAIRCARE);
  }

  await dispatch(
    trackReviewAndRefineAccountViewed({
      'Feedback eligible category': eligibleCategory.length > 0 ? eligibleCategory : null,
      'Feedback eligible products': getFeedbackEligibleProducts(state),
      'First supps order months': getFirstSupplementsOrder(state),
    })
  );
};

export const trackFeedbackConfirmation =
  hasDoneSupplementsFeedback => async (dispatch, getState) => {
    const state = getState();

    const isSkincareStarterSet = getOrderHasSkincareMinis(state);

    await dispatch(
      trackReviewAndRefineCompleted({
        'Feedback category': hasDoneSupplementsFeedback
          ? feedbackSubCategories.SUPPLEMENTS
          : feedbackCategories.HAIRCARE,
        isSkincareStarterSet,
        'Feedback answers': getFeedbackData(state),
      })
    );

    const haircareFeedbackCompleted = isHaircareFeedbackCompleted(state);
    const isHaircareEligible = getHaircareFeedbackOnOrder(state);
    const isSupplementsEligible = getSupplementsFeedbackOnOrder(state);
    const supplementsFeedbackCompleted = isSupplementsFeedbackCompleted(state);

    await dispatch(
      trackFeedbackConfirmationViewed({
        'Feedback category': hasDoneSupplementsFeedback
          ? feedbackSubCategories.SUPPLEMENTS
          : feedbackCategories.HAIRCARE,
        isSkincareStarterSet,
        'Other feedback push':
          hasDoneSupplementsFeedback && isHaircareEligible && !haircareFeedbackCompleted
            ? feedbackCategories.HAIRCARE
            : !hasDoneSupplementsFeedback && isSupplementsEligible && !supplementsFeedbackCompleted
            ? feedbackSubCategories.SUPPLEMENTS
            : null,
      })
    );
  };

export const trackFeedbackStartedViewed = feedbackCategory => async (dispatch, getState) => {
  const state = getState();

  await dispatch(
    trackReviewAndRefineStarted({
      'Feedback category': feedbackCategory,
      isSkincareStarterSet: getOrderHasSkincareMinis(state),
      'Feedback answers': getFeedbackData(state),
    })
  );
};
