import { createAsyncThunk } from '@reduxjs/toolkit';

import * as Sentry from '@sentry/nextjs';

import * as OrderService from 'Services/OrderService';

import { productsCategories } from 'constants/products';

import { orderLoadSuccessFromPaymentSuccess } from 'dux/checkoutOrder/actions';
import { trackOrderSuccess, trackPurchaseCompleted } from 'dux/tracking/actions';

export const saveStripePayment = createAsyncThunk(
  'checkoutPayment/saveStripePayment',
  async ({ nameOnCard, stripe, cardElement }, { rejectWithValue }) => {
    try {
      const { token, error } = await stripe.createToken(cardElement, {
        name: nameOnCard,
      });
      if (error) {
        return rejectWithValue({ error, status: 'error' });
      }
      return { token, status: 'success' };
    } catch (error) {
      return rejectWithValue({ error, status: 'failure' });
    }
  }
);

export const savePayment = createAsyncThunk(
  'checkoutPayment/savePayment',
  async ({ values, stripe, cardElement }, { rejectWithValue }) => {
    try {
      if (values.cardPubkey && values.cardPubkey !== 'newCard') {
        return { cardPubkey: values.cardPubkey, status: 'success' };
      }
      const { token, error } = await stripe.createToken(cardElement, {
        name: values.nameOnCard,
      });
      if (error) {
        return rejectWithValue({ error, status: 'error' });
      }
      return { token, status: 'success' };
    } catch (error) {
      return rejectWithValue({ error, status: 'failure' });
    }
  }
);

export const payWithButton = createAsyncThunk(
  'checkoutPayment/payWithButton',
  async ({ paymentType, saveCard, token }, { dispatch, rejectWithValue }) => {
    try {
      const order = await OrderService.post(token.id, saveCard);
      dispatch(orderLoadSuccessFromPaymentSuccess(order));
      dispatch(trackOrderSuccess(order));
      dispatch(
        trackPurchaseCompleted({
          express_checkout: false,
          payment_location: 'cart',
          payment_type: paymentType,
        })
      );
      return { status: 'success', order };
    } catch (error) {
      return rejectWithValue({ error, status: 'failure' });
    }
  }
);

export const payWithButtonV2 = createAsyncThunk(
  'checkoutPayment/payWithButtonV2',
  async ({ paymentType, saveCard, token }, { dispatch, rejectWithValue }) => {
    try {
      const order = await OrderService.postV2(token.id, saveCard);
      dispatch(orderLoadSuccessFromPaymentSuccess(order));
      dispatch(
        trackPurchaseCompleted({
          express_checkout: false,
          payment_location: 'cart',
          payment_type: paymentType,
        })
      );
      return { status: 'success', order };
    } catch (error) {
      return rejectWithValue({ error, status: 'failure' });
    }
  }
);

export const payWithPayPalButton = createAsyncThunk(
  'checkoutPayment/payWithPayPalButton',
  async ({ checkoutCategory, error, payload }, { rejectWithValue }) => {
    // If an error occurs at this stage, it's related to PayPal payment
    if (error) {
      console.error('PayPal: error creating token:', error);
      Sentry.withScope(scope => {
        scope.setLevel('error');
        Sentry.captureMessage(`[PayPal token] error: ${error.toString()}`);
      });
      return rejectWithValue({ error, status: 'failure' });
    }

    // Create order with Prose API
    try {
      const isCartV2 = checkoutCategory === productsCategories.ACCESSORIES;
      const createOrder = isCartV2 ? OrderService.postWithPayPalV2 : OrderService.postWithPayPal;
      const order = await createOrder({
        payment: {
          payment_mode: 'braintree',
          token: payload?.nonce,
          save_card: 'true',
        },
      });
      return { status: 'success', order };
    } catch (err) {
      console.error('PayPal: error creating order with Prose API:', err);
      Sentry.withScope(scope => {
        scope.setLevel('error');
        Sentry.captureMessage(`[PayPal - order creation with Prose API] error: ${err.toString()}`);
      });
      return rejectWithValue({ error: err, status: 'failure' });
    }
  }
);
