import * as feedbackComponents from 'Apps/Feedback/constants/feedbackComponents';
import { OVERALL } from 'Apps/Feedback/constants/feedbackSections';
import { skincareOverallExperience } from 'Apps/Feedback/constants/feedbackSlugs';

import { feedbackCategories } from 'constants/feedback';

const overallExperience = {
  name: skincareOverallExperience,
  route: `/feedback/${feedbackCategories.SKINCARE}/${skincareOverallExperience}`,
  category: OVERALL,
  feedbackCategory: feedbackCategories.SKINCARE,
  shouldBeIncluded: () => true,
  component: feedbackComponents.Rating,
  title: 'How would you rate your overall Prose experience?',
  subtitle: "We'll only use this rating internally.",
  options: [
    { value: 1, label: 'Very Unsatisfied' },
    { value: 2, label: 'Unsatisfied' },
    { value: 3, label: 'Neutral' },
    { value: 4, label: 'Satisfied' },
    { value: 5, label: 'Very Satisfied' },
  ],
  getInitialValue: answers => answers?.overall_experience,
  getAnswers: ({ selected }) => ({ overall_experience: selected }),
};

export default overallExperience;
