import supplementsTransitionImgDesktop from 'assets/images/reviewAndRefine/supplements-transition_desktop.jpg';
import supplementsTransitionImgMobile from 'assets/images/reviewAndRefine/supplements-transition_mobile.png';
import supplementsTransitionImgTablet from 'assets/images/reviewAndRefine/supplements-transition_tablet.jpg';

const supplementsTransitionScreen = {
  name: '',
  route: '/feedback/supplements',
  shouldBeIncluded: () => true,
  component: 'SupplementsTransitionScreen',
  skipSave: true,
  title: (
    <>
      Check your Root Source<sup>®</sup> progress
    </>
  ),
  subtitle: "Tell us what results you're seeing so far on your hair supplement journey.",
  paragraphs: [
    <>
      <b>For the first 90 days</b>, we’ll help you track key signs of growth as they emerge.
    </>,
    <>
      <b>After 90 days</b>, we’ll determine if you could benefit from any new ingredients in your
      formulas, or if you’re all set to keep growing better hair.
    </>,
  ],
  legalNotice:
    'Unless noted otherwise, collected reviews and ratings may be made public to share with the community.',
  ctaNextText: "Let's get started",
  style: {
    backgroundImages: {
      desktop: supplementsTransitionImgDesktop,
      mobile: supplementsTransitionImgMobile,
      tablet: supplementsTransitionImgTablet,
    },
  },
};

export default supplementsTransitionScreen;
