import { createAsyncThunk } from '@reduxjs/toolkit';

import * as CartService from 'Services/CartService';

export const createCartV2 = createAsyncThunk('cartV2/create', async payload => {
  const data = await CartService.postCartV2(payload);
  return data;
});

export const getCartV2 = createAsyncThunk('cartV2/get', async () => {
  const data = await CartService.fetchCartV2();
  return data;
});

export const getCartCustomizationV2 = createAsyncThunk('cartV2/getCustomization', async () => {
  const data = await CartService.fetchCartCustomizationV2();
  return data;
});

// This thunk update an item, if there is no customization yet for this item it creates it.
export const getCartRecommendationV2 = createAsyncThunk(
  'cartV2/getCartRecommendation',
  async () => {
    const data = await CartService.fetchCartRecommendationV2();
    return data;
  }
);

export const updateCartCustomizationV2 = createAsyncThunk(
  'cartV2/updateCustomization',
  async (payload, { dispatch }) => {
    const customizationPubkey = payload.item?.customization?.pubkey;
    if (customizationPubkey) {
      const data = await CartService.patchCartCustomizationV2({
        pubkey: customizationPubkey,
        data: payload?.data,
      });
      return data;
    }
    const data = await CartService.postCartCustomizationV2({
      cart_item: payload.item.pubkey,
      data: payload?.data,
    });
    // We need to refresh the customization object to get the pubkey of the customization object.
    dispatch(getCartCustomizationV2());
    return data;
  }
);

export const patchCartV2 = createAsyncThunk('cartV2/patch', async payload => {
  const data = await CartService.patchCartV2(payload);
  return data;
});

export const updateCartV2ItemsQuantity = payload => async (dispatch, getState) =>
  patchCartV2({ cartitems: [payload] })(dispatch, getState);
