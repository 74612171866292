import { HAIR } from 'Apps/Feedback/constants/feedbackSections';
import {
  haircareHairVolume,
  haircareImprovements,
  haircareNoConcernInImprovements,
  haircareNoImprovements,
  haircareSomeImprovements,
} from 'Apps/Feedback/constants/feedbackSlugs';

import { productsSlugs } from 'constants/products';

const hairVolume = {
  name: haircareHairVolume,
  route: `/feedback/haircare/${haircareHairVolume}`,
  category: HAIR,
  shouldBeIncluded: ({ formulas }) =>
    formulas?.some(f =>
      [productsSlugs.HAIR_MASK, productsSlugs.SHAMPOO, productsSlugs.CONDITIONER].includes(
        f.variant.product.type
      )
    ),
  title: "Did you see improvements in your hair's volume?",
  subtitle: 'Fullness and body from root to tip',
  options: [
    { value: haircareImprovements, label: 'Yes, I love the volume' },
    { value: haircareSomeImprovements, label: 'Some, but could be better' },
    { value: haircareNoImprovements, label: 'No, it still feels flat' },
    { value: haircareNoConcernInImprovements, label: "It wasn't a concern" },
  ],
  getInitialValue: answers => answers?.hair_volume,
  getAnswers: ({ selected }) => ({ hair_volume: selected }),
};

export default hairVolume;
