import { useAppDispatch, useAppSelector } from 'dux/app/hooks';

import ErrorContainer from 'Containers/ErrorContainer';

import { loadAddresses } from 'dux/checkoutAddresses/actions';
import { getIsAuthenticated } from 'dux/auth/selectors';
import { getCartV2Error } from 'dux/cartV2/selectors';
import { fetchAccountCards } from 'dux/accountCards/thunks';
import { getCartV2 } from 'dux/cartV2/thunks';

const CheckoutErrorSceneV2 = () => {
  const isAuthenticated = useAppSelector(getIsAuthenticated);

  const dispatch = useAppDispatch();
  const error = useAppSelector(getCartV2Error);

  const onRetry = () => {
    dispatch(getCartV2());
    if (isAuthenticated) {
      dispatch(loadAddresses());
      dispatch(fetchAccountCards());
    }
  };

  return (
    <ErrorContainer
      error={error}
      retry={() => onRetry()}
      sentryMessage="Invalid state on CheckoutContainerV2"
    />
  );
};

export default CheckoutErrorSceneV2;
