import * as feedbackComponents from 'Apps/Feedback/constants/feedbackComponents';
import { PRODUCTS } from 'Apps/Feedback/constants/feedbackSections';
import { skincareCleanserTexture } from 'Apps/Feedback/constants/feedbackSlugs';

import { feedbackCategories } from 'constants/feedback';
import { productsSlugs } from 'constants/products';

const cleanserTexture = {
  name: skincareCleanserTexture,
  route: '/feedback/skincare/cleanser-texture',
  category: PRODUCTS,
  productSlug: productsSlugs.CLEANSER,
  feedbackCategory: feedbackCategories.SKINCARE,
  shouldBeIncluded: ({ formulas, feedbackAnswers }) =>
    formulas?.find(f => f.variant.product.type === productsSlugs.CLEANSER) &&
    feedbackAnswers?.cleanser_satisfaction,
  title: 'Which cleanser texture do you want for your next order?',
  getInitialValue: answers => ({
    pref_texture_cleanser: answers?.pref_texture_cleanser,
  }),
  getAnswers: ({ selected }) => ({
    pref_texture_cleanser: selected,
  }),
  component: feedbackComponents.TextureContainer,
};

export default cleanserTexture;
