import { createSlice } from '@reduxjs/toolkit';

import * as RequestStatuses from 'constants/statuses';

import { fetchDigitalLeaflet } from './thunks';
import { type EnrichedData } from './types';

type EndpointState = {
  data: EnrichedData | undefined;
  error: object | Error | undefined;
  status: keyof typeof RequestStatuses;
};

const initialState: EndpointState = {
  data: undefined,
  error: undefined,
  status: RequestStatuses.IDLE,
};

const digitalLeafletSlice = createSlice({
  name: 'digitalLeaflet',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDigitalLeaflet.pending, (draft) => {
      draft.status = RequestStatuses.LOADING;
      draft.error = undefined;
    });
    builder.addCase(fetchDigitalLeaflet.fulfilled, (draft, action) => {
      draft.status = RequestStatuses.SUCCESS;
      draft.data = action.payload;
    });
    builder.addCase(fetchDigitalLeaflet.rejected, (draft, action) => {
      draft.status = RequestStatuses.ERROR;
      draft.error = action.error;
    });
  },
});

export const { reducer } = digitalLeafletSlice;
