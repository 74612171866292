import urlJoin from 'proper-url-join';

import getEnv from 'utils/getEnv';

const ROOT_URL = getEnv('REACT_APP_ROOT_URL');

// generates an instance of FormData from object
export const formDataFromObject = data =>
  Object.entries(data).reduce((formData, [key, value]) => {
    formData.append(key, value);
    return formData;
  }, new FormData());

export const getApiUrlFromRoute = route => {
  if (/^http/.test(route)) {
    throw new Error(`Can't get API route for absolute route ${route}`);
  }

  return urlJoin(ROOT_URL, route, { trailingSlash: true });
};
