import PropTypes from 'prop-types';

import { useAppDispatch, useAppSelector } from 'dux/app/hooks';

import { styled } from '@prose-ui/legacy';
import { PaymentRequestButtonElement } from '@stripe/react-stripe-js';

import Spacer from 'Components/Spacer';
import Typography from 'Components/Typography';

import { APPLE_PAY, GOOGLE_PAY } from 'constants/paymentMethods';

import useResponsiveVariant from 'utils/useResponsiveVariant';

import { trackEvent } from 'dux/tracking/actions';
import { shouldShowExpressCheckout } from 'dux/featureFlags/selectors';
import { useStripePayment } from 'dux/checkoutPayment/hooks';

import CheckoutBlock from './CheckoutBlock';

const ExpressCheckoutBorder = styled.div`
  display: flex;
  align-items: center;
`;

const ExpressCheckoutBorderLine = styled.div`
  width: 100%;
  border-bottom: 1px solid rgba(40, 51, 51, 0.2);
`;

const ChildrenWrapper = styled.div`
  padding: 12px 0;
`;

const DesktopChildrenWrapper = styled.div`
  max-width: 300px;
`;

const ExpressCheckoutBorderText = styled(Typography)`
  padding: 0 20px;
`;

const MobileOrDesktopWrapper = ({ children, isLastSection }) => {
  const { isMobile } = useResponsiveVariant();

  return (
    <>
      {isMobile && (
        <div dataTestId="expresspayment-block-checkout-redesign">
          {isLastSection && <ExpressCheckoutBorderLine />}
          <Spacer size={22} />
          <Typography align="center" color="noir" variant="mono3">
            Express Checkout
          </Typography>
          <ChildrenWrapper>{children}</ChildrenWrapper>
        </div>
      )}

      {!isMobile && (
        <CheckoutBlock
          border={false}
          dataTestId="expresspayment-block"
          id="checkout-express-payment-block"
          title="Express checkout"
        >
          <DesktopChildrenWrapper>{children}</DesktopChildrenWrapper>
        </CheckoutBlock>
      )}

      <ExpressCheckoutBorder>
        <ExpressCheckoutBorderLine />
        <ExpressCheckoutBorderText color="noir" variant="mono3">
          OR
        </ExpressCheckoutBorderText>
        <ExpressCheckoutBorderLine />
      </ExpressCheckoutBorder>
    </>
  );
};

MobileOrDesktopWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  isLastSection: PropTypes.bool.isRequired,
};

const CheckoutExpressPayment = ({ isLastSection }) => {
  const dispatch = useAppDispatch();

  const showExpressCheckout = useAppSelector(shouldShowExpressCheckout);

  const { canMakePayment, walletType, paymentRequest } = useStripePayment({
    expressCheckout: true,
    saveCard: false,
  });

  // Allowed to see the Apple/Google Pay button
  const canSeeAppleOrGooglePayButton =
    canMakePayment && (walletType === APPLE_PAY || walletType === GOOGLE_PAY);

  if (!canSeeAppleOrGooglePayButton || !showExpressCheckout) {
    return null;
  }

  return (
    <>
      <MobileOrDesktopWrapper isLastSection={isLastSection}>
        <PaymentRequestButtonElement
          onClick={() =>
            dispatch(
              trackEvent('payment_button_click', {
                payment_button_type: walletType,
              })
            )
          }
          options={{
            paymentRequest,
          }}
        />
      </MobileOrDesktopWrapper>
      {isLastSection && <Spacer size={20} />}
    </>
  );
};

CheckoutExpressPayment.propTypes = {
  isLastSection: PropTypes.bool,
};

CheckoutExpressPayment.defaultProps = {
  isLastSection: false,
};

export default CheckoutExpressPayment;
