import { publicReview } from 'Apps/Feedback/constants/feedbackSlugs';

const supplementsPublicReview = {
  name: publicReview,
  route: `/feedback/supplements/${publicReview}`,
  shouldBeIncluded: () => true,
  component: 'PublicReview',
  title: 'Would you like to leave a review?',
  subtitle: 'We may post your review publicly to share your experience with the Prose community.',
  getInitialValue: answers => ({
    open_text_public: answers?.supplements_open_text_public,
    open_text_internal: answers?.supplements_open_text_internal,
  }),
  getAnswers: ({ selected }) => ({
    supplements_open_text_public: selected?.open_text_public,
    supplements_open_text_internal: selected?.open_text_internal,
  }),
};

export default supplementsPublicReview;
