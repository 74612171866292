import isEmpty from 'lodash/fp/isEmpty';

import dayjs from 'dayjs';

import { PRODUCTS } from 'Apps/Feedback/constants/feedbackSections';
import {
  haircareConditionerAntibrass,
  haircareImprovements,
  haircareNoConcernInImprovements,
  haircareNoImprovements,
  haircareSomeImprovements,
  haircareTooMuch,
} from 'Apps/Feedback/constants/feedbackSlugs';

import { productsSlugs } from 'constants/products';

const conditionerAntiBrass = {
  name: haircareConditionerAntibrass,
  route: `/feedback/haircare/${haircareConditionerAntibrass}`,
  category: PRODUCTS,
  shouldBeIncluded: ({ formulas, feedbackAnswers, antiBrassIngredients }) => {
    return (
      formulas?.find(
        f =>
          f.variant.product.type === productsSlugs.CONDITIONER &&
          !isEmpty(f.item_object.ingredients?.find(i => antiBrassIngredients.includes(i.slug))) &&
          dayjs().diff(f.created_at, 'day') > 21
      ) && feedbackAnswers?.product_conditioner_satisfaction
    );
  },
  title: 'Did your hair get less brassy?',
  options: [
    { value: haircareImprovements, label: 'Yes, it’s less brassy' },
    { value: haircareSomeImprovements, label: 'A little, but not enough' },
    { value: haircareNoImprovements, label: 'No, it’s the same' },
    { value: haircareTooMuch, label: 'No, my color turned an unwanted color' },
    { value: haircareNoConcernInImprovements, label: 'It wasn’t a concern' },
  ],
  getInitialValue: answers => answers?.product_conditioner_antibrass,
  getAnswers: ({ selected }) => ({ product_conditioner_antibrass: selected }),
};

export default conditionerAntiBrass;
