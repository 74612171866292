import { results as supplementsFeedbackResults } from 'Apps/Feedback/constants/feedbackSlugs';

const supplementsResults = {
  name: supplementsFeedbackResults,
  route: `/feedback/supplements/${supplementsFeedbackResults}`,
  shouldBeIncluded: () => true,
  component: 'SupplementsFeedbackResults',
  skipSave: true,
  getAnswers: () => ({ status: 'completed' }),
};

export default supplementsResults;
