import type { TypedStartListening } from '@reduxjs/toolkit';
import { createListenerMiddleware } from '@reduxjs/toolkit';

import * as Sentry from '@sentry/nextjs';
import type { AppDispatch, RootState } from 'store';

import { getEnvOrThrow } from 'utils/getEnv';

import { addAuthListeners } from 'dux/auth/middlewares';
import { addTrackingListeners } from 'dux/tracking/middleware';

import { addAppListeners } from './middlewares';

// check if we are in test environment
const testEnv = getEnvOrThrow('REACT_APP_ENV') === 'test';
const developmentEnv = getEnvOrThrow('REACT_APP_ENV') === 'development';

const onError = (err: unknown) => {
  if (testEnv) {
    throw err;
  } else if (developmentEnv) {
    console.error(err);
  } else {
    Sentry.withScope((scope) => {
      scope.setLevel('error');
      if (err instanceof Error) {
        Sentry.captureMessage(`[Listener Middleware]: ${err.toString()}`);
      }
    });
  }
};
export const listenerMiddleware = createListenerMiddleware({ onError });

export type AppStartListening = TypedStartListening<RootState, AppDispatch>;

const startAppListening = listenerMiddleware.startListening as AppStartListening;

/*
 * NOTE: follows strategy #3 of organizing middleware listners
 * https://redux-toolkit.js.org/api/createListenerMiddleware#organizing-listeners-in-files
 */
addAppListeners(startAppListening);
addAuthListeners(startAppListening);
addTrackingListeners(startAppListening);
