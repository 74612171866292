export const title = {
  request: "You're so close",
  success: 'You’re all set!',
  magicLinkSent: 'Check your email!',
};

export const modal = {
  dataFrom: {
    request: 'consultation-exit-modal',
    success: 'consultation-exit-confirmation-modal',
  },
};

export const body = {
  request:
    "Just a few more steps to your perfectly customized routine. Save your progress so you don't miss a beat when you come back.",
  success:
    'We just sent you an email with a link to finish your consultation. Just click through when you’re ready — your progress (and your custom formulas) will be waiting.',
  magicLinkSent: 'You just received a link to log you in.',
};

export const firstCTA = {
  label: {
    request: 'Save my progress',
    success: 'Explore PROSE products',
    magicLinkSent: 'Explore PROSE products',
  },
  link: category => ({
    success: `/products/${category}`,
    magicLinkSent: `/products/${category}`,
  }),
  dataFrom: {
    request: 'consultation-exit-modal',
    success: 'consultation-exit-confirmation-modal',
  },
  dataClick: {
    request: 'save',
    success: 'products',
  },
};

export const secondCTA = {
  dataClick: {
    request: 'close',
    success: 'account',
  },
  dataFrom: {
    request: 'consultation-exit-modal',
    success: 'consultation-exit-confirmation-modal',
  },
  label: {
    request: 'Nope, I’m gone',
    success: 'Thanks, see you later',
    magicLinkSent: 'Thanks, see you later',
  },
  link: {
    request: '/',
    success: '/account',
    magicLinkSent: '/',
  },
};
