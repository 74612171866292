import { apiReducer, initialApiState } from 'utils/reduxUtils';

import { userSignout } from 'dux/auth/actions';
import { CLEAR_CHECKOUT } from 'dux/checkout/actionTypes';
import {
  LOAD_ADDRESSES_FAILURE,
  LOAD_ADDRESSES_REQUEST,
  LOAD_ADDRESSES_SUCCESS,
  SET_NEW_ADDRESS,
} from 'dux/checkoutAddresses/actionTypes';

export const addresses = (state = initialApiState, action) => {
  switch (action.type) {
    case userSignout.type:
    // fallthrough
    case CLEAR_CHECKOUT:
      return initialApiState;
    case SET_NEW_ADDRESS:
      return { ...state, data: [...state.data, action.data] };
    default:
      return apiReducer(
        [LOAD_ADDRESSES_REQUEST, LOAD_ADDRESSES_SUCCESS, LOAD_ADDRESSES_FAILURE],
        state,
        action
      );
  }
};
