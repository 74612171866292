import PropTypes from 'prop-types';

import { Link, useSearchParams } from 'react-router-dom';

import { legacyTheme, styled } from '@prose-ui/legacy';
import { withStyles } from 'legacyStyles';

import Button from 'Components/LegacyButton';
import TextField from 'Components/TextField';
import Typography from 'Components/Typography';

import { productsCategories } from 'constants/products';

import BaseScene from 'Scenes/LegacyBaseScene';

const styles = {
  emailField: {
    width: '100%',
    maxWidth: 400,
  },
};

const StyledTypographyTitle = styled(Typography)`
  padding-bottom: ${legacyTheme.spacing.s24};
`;
const StyledTypographySubCta = styled(Typography)`
  max-width: 435px;
  padding-bottom: ${legacyTheme.spacing.s24};
  padding-top: ${legacyTheme.spacing.s48};
`;
const Signin = ({
  classes,
  email,
  error,
  handleBlur,
  handleChange,
  handleSubmit,
  isSubmitting,
  isValidLogin,
}) => {
  const [SearchParams] = useSearchParams();
  const fromUrlParam = SearchParams.get('from') ?? null;
  const isFromOthersCategory = fromUrlParam === productsCategories.OTHERS;
  return (
    <form onSubmit={handleSubmit}>
      <BaseScene background="beige" header padding>
        <StyledTypographyTitle align="center" markupName="h2" variant="h1">
          Sign in as an existing customer
        </StyledTypographyTitle>

        <TextField
          className={classes.emailField}
          error={error}
          id="email"
          label="Email address"
          margin
          name="email"
          onBlur={handleBlur}
          onChange={handleChange}
          type="email"
          value={email}
        />

        <Button disabled={isSubmitting || !isValidLogin} isLoading={isSubmitting} type="submit">
          Sign in
        </Button>

        <StyledTypographySubCta align="center" color="noir" variant="p1">
          New to Prose?{' '}
          {fromUrlParam === productsCategories.OTHERS &&
            'For now, you need to complete your haircare consultation before you can purchase Prose accessories.'}
        </StyledTypographySubCta>

        <Button
          Component={Link}
          to={isFromOthersCategory ? '/consultation/haircare' : '/consultation'}
          variant="underlined"
        >
          Start {isFromOthersCategory ? 'haircare' : 'a'} consultation
        </Button>
      </BaseScene>
    </form>
  );
};

Signin.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  email: PropTypes.string.isRequired,
  error: PropTypes.string,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isValidLogin: PropTypes.bool.isRequired,
};

export default withStyles(styles)(Signin);
