import { OVERALL } from 'Apps/Feedback/constants/feedbackSections';

import haircareTransitionImgDesktop from 'assets/images/reviewAndRefine/haircare-transition_desktop.jpg';
import haircareTransitionImgMobile from 'assets/images/reviewAndRefine/haircare-transition_mobile.jpg';

const overallTransitionScreen = {
  name: '',
  route: '/feedback/haircare',
  category: OVERALL,
  shouldBeIncluded: () => true,
  component: 'HaircareTransitionScreen',
  skipSave: true,
  title: 'Give feedback to make your next order the best one yet',
  subtitle:
    "Review your recent products, rate your overall experience, and keep tabs on your hair goals with just a few clicks. We'll continue to tailor your formulas as your needs evolve.",
  legalNotice: 'Your review may be made public to share your experience with our community.',
  ctaNextText: "Let's get started",
  style: {
    theme: 'white',
    backgroundImages: {
      desktop: haircareTransitionImgDesktop,
      mobile: haircareTransitionImgMobile,
    },
  },
};

export default overallTransitionScreen;
