import { useEffect, useMemo, useState } from 'react';

import { Navigate, useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'dux/app/hooks';

import Loading from 'Blocks/Loading';

import { fetchOrdersIfNeeded } from 'dux/orders/actions';
import * as ordersSelectors from 'dux/orders/selectors';

const LastOrderDigital = () => {
  const [isUninitialized, setIsUninitialized] = useState(true);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setIsUninitialized(false);
    dispatch(fetchOrdersIfNeeded());
  }, [fetchOrdersIfNeeded]);

  const isLoadingOrders = useAppSelector(ordersSelectors.getIsLoading);
  const errorOrders = useAppSelector(ordersSelectors.getError);

  const [params] = useSearchParams();
  const categoryParam = useMemo(() => params.get('category'), [params]);

  const requestedOrder = useAppSelector((state) =>
    ordersSelectors.getLastOrderByCategoryWithFallback(state, categoryParam),
  );

  if (isUninitialized || isLoadingOrders) {
    return <Loading />;
  }

  if (
    errorOrders ||
    /* No data found */ (!isUninitialized && !isLoadingOrders && !requestedOrder)
  ) {
    return <Navigate replace to="/account/history" />; // immediately redirect to order history page in case of error
  }

  return <Navigate replace to={`/digital-leaflet/${requestedOrder.pubkey}`} />;
};

export default LastOrderDigital;
