import * as APIClient from './APIClient';
import HTTPError from './HTTPError';

export const fetch = async pubkey => {
  const response = await APIClient.get(`/v1/stylists/${pubkey}`);
  return response.json();
};

export const searchBySlug = async slug => {
  const response = await APIClient.get(`/v1/stylists/?public_slug=${slug}`);
  const stylists = await response.json();
  if (!stylists || stylists.length === 0) {
    throw new HTTPError({
      code: 'invalid_stylist_slug',
      detail: 'Stylist not found',
    });
  }
  return stylists[0];
};
