import { HAIR } from 'Apps/Feedback/constants/feedbackSections';
import {
  haircareHairCurlDefinition,
  haircareImprovements,
  haircareNoConcernInImprovements,
  haircareNoImprovements,
  haircareSomeImprovements,
} from 'Apps/Feedback/constants/feedbackSlugs';

import { productsSlugs } from 'constants/products';

const hairCurlDefinition = {
  name: haircareHairCurlDefinition,
  route: `/feedback/haircare/${haircareHairCurlDefinition}`,
  category: HAIR,
  shouldBeIncluded: ({ formulas, consultationAnswers }) =>
    formulas?.some(f =>
      [productsSlugs.HAIR_MASK, productsSlugs.SHAMPOO, productsSlugs.CONDITIONER].includes(
        f.variant.product.type
      )
    ) &&
    (consultationAnswers?.goal_curl ||
      (['curly', 'type_3a', 'type_3b', 'type_3c', 'very_curly', 'frizzy', 'zigzag'].includes(
        consultationAnswers?.diag_hair_texture
      ) &&
        !consultationAnswers?.diag_other_treatments.includes('relaxer'))),
  title: "Did you see improvements in your hair's curl definition?",
  subtitle: 'This means frizz-free curls with life and bounce ',
  options: [
    { value: haircareImprovements, label: 'Yes, my curls are thriving' },
    { value: haircareSomeImprovements, label: 'Some, but could be better' },
    { value: haircareNoImprovements, label: 'No, they lack definition' },
    { value: haircareNoConcernInImprovements, label: "It wasn't a concern" },
  ],
  getInitialValue: answers => answers?.hair_curl_definition,
  getAnswers: ({ selected }) => ({ hair_curl_definition: selected }),
};

export default hairCurlDefinition;
