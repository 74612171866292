import { createSelector } from '@reduxjs/toolkit';

const getCheckoutState = state => state.checkout;

export const getAddressChoiceAfterValidation = createSelector(
  getCheckoutState,
  state => state.addressValidation.addressChoice
);

export const getAddressValidationInvalidFields = createSelector(
  getCheckoutState,
  state => state.addressValidation.addressInvalidFields
);

export const getAddressValidationRecommendedFields = createSelector(
  getCheckoutState,
  state => state.addressValidation.addressRecommendedFields
);
