import { matchPath, useLocation } from 'react-router-dom';

import includes from 'lodash/fp/includes';

import { productsCategories } from 'constants/products';
import { consultationProductsCategories } from '../constants/consultationProductsCategories';

const useConsultationCategory = () => {
  const { pathname } = useLocation();
  const { category } = matchPath('/consultation/:category/*', pathname)?.params || {};
  return includes(category, consultationProductsCategories)
    ? category
    : productsCategories.AGNOSTIC;
};

export default useConsultationCategory;
