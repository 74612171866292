import { createSlice } from '@reduxjs/toolkit';

import { ERROR, IDLE, LOADING, SUCCESS } from 'constants/statuses';

import { userSignout } from 'dux/auth/actions';

import { fetchQuestionSets } from './thunks';

const initialState = {
  error: null,
  status: IDLE,
  data: {},
};

const questionSetsSlice = createSlice({
  name: 'questionSets',
  initialState,
  extraReducers: builder => {
    builder
      // reinitialize state upon signout
      .addCase(userSignout, () => initialState)

      .addCase(fetchQuestionSets.pending, draft => {
        draft.status = LOADING;
      })
      .addCase(fetchQuestionSets.fulfilled, (draft, action) => {
        draft.status = SUCCESS;
        draft.data[action.meta.arg.consultationCategory] = action.payload;
      })
      .addCase(fetchQuestionSets.rejected, (draft, action) => {
        draft.status = ERROR;
        draft.error = action.payload;
      });
  },
});

export const { reducer } = questionSetsSlice;
