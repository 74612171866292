import { createAsyncThunk } from '@reduxjs/toolkit';

import * as Sentry from '@sentry/nextjs';

import * as SelfiesService from 'Services/SelfiesService';

export const saveSelfies = createAsyncThunk('selfies/save', async files => {
  try {
    await SelfiesService.upload(files);
  } catch (err) {
    Sentry.captureMessage('API action (Selfies.saveSelfies)');
    throw err;
  }
});
