import { fetchFeatureFlags } from 'dux/featureFlags/actions';
import { getIsAuthenticated, getIsAuthenticating, getIsNotAuthenticated } from 'dux/auth/selectors';
import { fetchGiftWithPurchaseList } from 'dux/giftWithPurchase/thunks';
/*
 * NOTE: follows strategy #3 of organizing middleware listners
 * https://redux-toolkit.js.org/api/createListenerMiddleware#organizing-listeners-in-files
 */
export const addAppListeners = startAppListening => {
  startAppListening({
    predicate(_, currentState, previousState) {
      const wasAuthenticating = getIsAuthenticating(previousState);
      const wasAuthenticated = getIsAuthenticated(previousState);

      const isDoneAuthenticating = !getIsAuthenticating(currentState);
      const isNotAuthenticated = getIsNotAuthenticated(currentState);

      return (
        // Refetch on log-in because user context changed
        (wasAuthenticating && isDoneAuthenticating) ||
        // Refetch on log-out because user context changed
        (wasAuthenticated && isNotAuthenticated)
      );
    },
    async effect(_, { dispatch }) {
      dispatch(fetchFeatureFlags());
      dispatch(fetchGiftWithPurchaseList());
    },
  });
};
