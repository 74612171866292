import { useState } from 'react';

import { Link } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'dux/app/hooks';

import { Box, Modal, theme } from '@prose-ui';
import { legacyTheme } from '@prose-ui/legacy';
import { useTrialOfferPromotionModalContent } from 'hooks/useRedeemCouponForTrialOffer';
import { styled } from 'legacyStyles';
import Image from 'next/image';

import { useSssShippingFeeAbTest } from 'abTesting/sssShippingFee/hook';

import { type Currency } from 'constants/currencies';

import trialOfferGWPMixedWithSkincareMinisModalContent from 'assets/content/trialOfferGWPMixedWithSkincareMinisModal';
import trialOfferMixedWithSkincareMinisModalContent from 'assets/content/trialOfferMixedWithSkincareMinisModal';
import trialOfferMixedWithSkincareMinisWithShippingFeesModalModalContent from 'assets/content/trialOfferMixedWithSkincareMinisWithShippingFeesModal';
import { ReactComponent as Cross } from 'assets/images/cross_icon.svg';

import useResponsiveVariant from 'utils/useResponsiveVariant';

import { trackHeapEvent } from 'dux/tracking/actions';
import { trialOfferEvergreen } from 'dux/featureFlags/selectors';
import { getUserCurrency } from 'dux/user/selectors';
import { VARIANT1 } from 'dux/featureFlags/constants/flagVariants';

import Button from './LegacyButton';
import Typography from './Typography';

const Header = styled.div`
  height: 35px;
  padding: 10px 10px 5px 17px;
  background-color: ${theme.colors.tertiary[200]};
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

const CloseModalButton = styled.button`
  cursor: pointer;
  appearance: none;
  background: none;
  border: none;
  padding: 0;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -4px;
`;

const StyledModal = styled(Modal)`
  padding: 0;
  min-width: 330px;

  ${legacyTheme.breakpoints.up('sm')} {
    max-width: 550px;
  }
`;

const Content = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: ${theme.colors.neutral[200]};
`;

const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
`;

const HeaderLabel = styled(Typography)`
  font-size: 14px;
  font-weight: 600;
`;

const Description = styled(Typography)`
  font-size: 14px;
  color: ${theme.colors.primary[400]};

  ${legacyTheme.breakpoints.up('sm')} {
    max-width: 365px;
    font-size: 16px;
  }
`;

const Conditions = styled(Typography)`
  font-size: 10px;
  padding: 0 8px;
`;

type TrialOfferMixedWithSkincareMinisModalProps = {
  onClose: () => void;
  isOpen: boolean;
};

const TrialOfferMixedWithSkincareMinisModal = ({
  isOpen = false,
  onClose,
}: TrialOfferMixedWithSkincareMinisModalProps) => {
  const dispatch = useAppDispatch();
  const currency = useAppSelector(getUserCurrency) as Currency;
  const { isMobile } = useResponsiveVariant();

  const { variantName: sssShippingFeeAbTestVariantName } = useSssShippingFeeAbTest();

  const { variant } = useTrialOfferPromotionModalContent();
  const content =
    variant !== trialOfferEvergreen
      ? trialOfferGWPMixedWithSkincareMinisModalContent
      : sssShippingFeeAbTestVariantName === VARIANT1
      ? trialOfferMixedWithSkincareMinisWithShippingFeesModalModalContent
      : trialOfferMixedWithSkincareMinisModalContent;

  return (
    <StyledModal
      events={{
        onModalClose: () =>
          dispatch(trackHeapEvent(content.popUpModal[currency].tracking.modalClosing)),
        onModalOpen: () =>
          dispatch(trackHeapEvent(content.popUpModal[currency].tracking.modalOpening)),
        timer: {
          onModalOpenAfterTime: () =>
            dispatch(trackHeapEvent(content.popUpModal[currency].tracking.modalOpening2sec)),
        },
      }}
      hideClose
      isOpen={isOpen}
      onClose={onClose}
      zIndexValue={10000}
    >
      <Header>
        {/* @ts-ignore Legacy Typography  */}
        <HeaderLabel medium variant="mono1">
          {content.popUpModal[currency].headerTitle}
        </HeaderLabel>
        <CloseModalButton
          data-click="exit"
          data-from="skincare-minis-hc-trial-popup"
          data-testid="skincare-minis-close-modal"
          id="modal-close-button"
          onClick={onClose}
        >
          <Cross height="13" width="13" />
        </CloseModalButton>
      </Header>
      <Content>
        <StyledImage
          alt="list of skincare minis products"
          src={content.popUpModal[currency].image}
        />
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          padding={{ mobile: '4x', tablet: '8x', desktop: '8x' }}
        >
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            gap={{ mobile: '2x', tablet: '3x', desktop: '3x' }}
          >
            {/* @ts-expect-error Legacy Typography  */}
            <Description align="center" id={isOpen ? 'modal-description' : ''} variant="p1">
              {content.popUpModal[currency].description[isMobile ? 'mobile' : 'desktop']}
            </Description>
          </Box>
          <Box paddingY={{ mobile: '5x', tablet: '6x', desktop: '6x' }}>
            {/* @ts-expect-error Legacy Button  */}
            <Button
              Component={Link}
              data-click="offer-cta"
              data-from="skincare-minis-hc-trial-popup"
              fullWidth
              noMargin
              onClick={onClose}
              variant="vert"
            >
              {content.popUpModal[currency].cta.ctaLabel}
            </Button>
          </Box>
          {/* @ts-expect-error Legacy Typography  */}
          <Conditions align="center" id={isOpen ? 'modal-conditions' : ''} variant="p3">
            {content.popUpModal[currency].conditions}
          </Conditions>
        </Box>
      </Content>
    </StyledModal>
  );
};

type TrialOfferMixedWithSkincareMinisModalWithStateLogicProps = {
  isOpen: boolean;
};

export const TrialOfferMixedWithSkincareMinisModalWithStateLogic = ({
  isOpen,
}: TrialOfferMixedWithSkincareMinisModalWithStateLogicProps) => {
  const [open, setOpen] = useState(isOpen);
  const onClose = () => setOpen(false);

  return <TrialOfferMixedWithSkincareMinisModal isOpen={open} onClose={onClose} />;
};
