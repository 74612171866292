import {
  supplementsFromTwoMonths,
  supplementsHairGrowth,
  supplementsLittleImprovements,
  supplementsLotsOfImprovements,
  supplementsNoConcernInImprovements,
  supplementsNoImprovements,
  supplementsSomeImprovements,
} from 'Apps/Feedback/constants/feedbackSlugs';

const supplementsHairGrowthScreen = {
  name: supplementsHairGrowth,
  route: `/feedback/supplements/${supplementsHairGrowth}`,
  shouldBeIncluded: ({ feedbackAnswers }) =>
    feedbackAnswers &&
    supplementsFromTwoMonths.includes(feedbackAnswers.product_supplements_usage_duration),
  title: 'Have you noticed faster hair growth?',
  subtitle: 'Does it seem like the lengths & ends of your hair have grown out quicker than usual?',
  options: [
    { value: supplementsLotsOfImprovements, label: 'Yes, lots of improvement' },
    { value: supplementsSomeImprovements, label: 'Yes, some improvement' },
    { value: supplementsLittleImprovements, label: 'Yes, maybe a little' },
    { value: supplementsNoImprovements, label: 'Not yet' },
    { value: supplementsNoConcernInImprovements, label: 'No, but it wasn’t a concern' },
  ],
  getInitialValue: answers => answers?.product_supplements_hair_growth,
  getAnswers: ({ selected }) => ({ product_supplements_hair_growth: selected }),
};

export default supplementsHairGrowthScreen;
