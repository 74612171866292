import PropTypes from 'prop-types';

import isNil from 'lodash/fp/isNil';

import { theme } from '@prose-ui';
import { legacyTheme } from '@prose-ui/legacy';
import { useStyles, withStyles } from 'legacyStyles';

import Typography from 'Components/Typography';

import { ReactComponent as NoIcon } from 'assets/images/no-icon.svg';
import { ReactComponent as YesIcon } from 'assets/images/yes-icon.svg';

const styles = (_legacyTheme, _props, classes) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.primary[400],
  },
  check: {
    position: 'absolute',
    backgroundColor: theme.colors.neutral[100],
    boxShadow: `2px 2px 3px 0 ${theme.colors.neutral[600]}`,
    transition: 'background-color 100ms ease-in',
  },
  input: {
    appearance: 'none',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    flexShrink: 0,
    position: 'relative',
    zIndex: 1,
  },
  label: {
    marginLeft: legacyTheme.spacing.s16,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  selected: {
    [`& .${classes.check}`]: {
      backgroundColor: theme.colors.primary[300],
    },
  },
  disabled: {
    [`& .${classes.check}`]: {
      backgroundColor: theme.colors.neutral['400'],
      boxShadow: 'none',
    },
    [`& .${classes.label}`]: {
      color: theme.colors.neutral[700],
    },
    [`& .${classes.input}`]: {
      cursor: 'not-allowed',
    },
  },
  displayMode: {
    [`& .${classes.check}`]: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    [`& .${classes.label}`]: {
      color: theme.colors.neutral[800],
    },
  },
  size: {},
  smallSize: {
    [`& .${classes.size}`]: {
      width: legacyTheme.props.checkboxSize.small,
      height: legacyTheme.props.checkboxSize.small,
    },
  },
  mediumSize: {
    [`& .${classes.size}`]: {
      width: legacyTheme.props.checkboxSize.medium,
      height: legacyTheme.props.checkboxSize.medium,
    },
  },
  largeSize: {
    [`& .${classes.size}`]: {
      width: legacyTheme.props.checkboxSize.large,
      height: legacyTheme.props.checkboxSize.large,
    },
  },
});

const CheckYes = ({ className, color, dataPrefix }) => (
  <YesIcon
    aria-hidden
    className={className}
    data-testid={`${dataPrefix}-enabled-icon`}
    focusable={false}
    role="presentation"
    stroke={color}
    viewBox="-5 -6 28 28"
  />
);

CheckYes.propTypes = {
  className: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  dataPrefix: PropTypes.string.isRequired,
};

const CheckNo = ({ className, color, dataPrefix }) => (
  <NoIcon
    aria-hidden
    className={className}
    data-testid={`${dataPrefix}-disabled-icon`}
    focusable={false}
    role="presentation"
    stroke={color}
    viewBox="-7 -7 28 28"
  />
);

CheckNo.propTypes = {
  className: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  dataPrefix: PropTypes.string.isRequired,
};

const Checkbox = ({
  classes,
  disabled,
  id,
  label,
  labelColor,
  labelVariant,
  name,
  onBlur,
  onChange,
  size,
  value,
  dataPrefix,
  displayMode,
  dataTestId,
}) => {
  const { cx } = useStyles();
  return (
    <div
      className={cx(classes.root, {
        [classes.selected]: value,
        [classes.disabled]: disabled,
        [classes.smallSize]: size === 'small',
        [classes.mediumSize]: size === 'medium',
        [classes.largeSize]: size === 'large',
        [classes.displayMode]: displayMode,
      })}
      data-testid={dataTestId}
    >
      {value ? (
        <CheckYes
          className={cx(classes.check, classes.size)}
          color={
            displayMode
              ? theme.colors.primary[400]
              : disabled
              ? theme.colors.neutral[700]
              : theme.colors.neutral[100]
          }
          dataPrefix={dataPrefix}
        />
      ) : (
        <CheckNo
          className={cx(classes.check, classes.size)}
          color={
            displayMode
              ? theme.colors.primary[400]
              : disabled
              ? theme.colors.neutral[700]
              : theme.colors.neutral[100]
          }
          dataPrefix={dataPrefix}
        />
      )}
      <input
        checked={isNil(value) ? false : value}
        className={cx(classes.input, classes.size)}
        disabled={disabled}
        id={id}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        type="checkbox"
      />
      <Typography
        className={classes.label}
        color={labelColor}
        htmlFor={id}
        markupName="label"
        variant={labelVariant}
      >
        {label}
      </Typography>
    </div>
  );
};

Checkbox.defaultProps = {
  disabled: false,
  labelColor: 'inherit',
  labelVariant: 'p1',
  onBlur: null,
  onChange: null,
  size: 'medium',
  value: null,
  dataPrefix: 'checkbox',
  displayMode: false,
  dataTestId: null,
};

Checkbox.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  labelColor: PropTypes.string,
  labelVariant: PropTypes.string,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  value: PropTypes.bool,
  dataPrefix: PropTypes.string,
  displayMode: PropTypes.bool,
  dataTestId: PropTypes.string,
};

export default withStyles(styles)(Checkbox);
