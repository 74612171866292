import * as APIClient from './APIClient';

export const fetch = async () => {
  const response = await APIClient.get('/v1/coupon_customers/');
  return response.json();
};

export const create = ({ code, source }: { code?: string; source?: string }) =>
  APIClient.post('v1/coupon_customers/', {
    referral_code: code,
    coupon_source: source,
  });

export const couponCodes = {
  skincareWelcomeKit: '062023_WKFreeSkinTrio',
};

/* to get statuses of specific coupons */
export const getCouponsStatuses = async () => {
  const response = await APIClient.post('/v1/coupon_customers/status/', {
    coupons: Object.values(couponCodes),
  });
  return response.json();
};
