import { rem } from '../utils/units';

const base = 0.25;

const Spacing = {
  none: '0',
  auto: 'auto',
  '1x': rem(base), // 0.25rem / 4px
  '2x': rem(2 * base), // 0.5rem
  '3x': rem(3 * base), // 0.75rem
  '4x': rem(4 * base), // 1rem / 16px
  '5x': rem(5 * base), // 1.25rem
  '6x': rem(6 * base), // 1.5rem
  '8x': rem(8 * base), // 2rem / 32px
  '10x': rem(10 * base), // 2.5rem
  '12x': rem(12 * base), // 3rem / 48px
  '14x': rem(14 * base), // 3.5rem / 56px
  '15x': rem(15 * base), // 3.75rem
  '20x': rem(20 * base), // 5rem
  '25x': rem(25 * base), // 6.25rem
  '30x': rem(30 * base), // 7.5rem / 120px
} as const;

// Global useful constants
export const bannerHeight = '40px';
export const navbarHeight = '55px';
export const headerHeight = `${String(parseInt(bannerHeight, 10) + parseInt(navbarHeight, 10))}px`;

export type SpacingToken = keyof typeof Spacing;

export default Spacing;
