import { createAsyncThunk } from '@reduxjs/toolkit';

import * as Sentry from '@sentry/nextjs';

import * as ProductsService from 'Services/ProductsService';

import { getProductsStatusMap } from 'dux/products/selectors';

export const fetchProducts = createAsyncThunk(
  'products/fetch',
  async addressPubKey => {
    try {
      return await ProductsService.fetchProducts(addressPubKey);
    } catch (error) {
      Sentry.captureMessage(`Fetch products: ${error.toString()}`);
      throw error;
    }
  },
  {
    condition(_, { getState }) {
      const state = getState();
      const productsStatusMap = getProductsStatusMap(state);

      return !productsStatusMap.isLoading;
    },
  }
);

export const fetchGiftsSets = createAsyncThunk('products/fetchGiftsSets', async () => {
  try {
    return await ProductsService.fetchGiftsSets();
  } catch (error) {
    Sentry.captureMessage(`Fetch gifts sets: ${error.toString()}`);
    throw error;
  }
});
