import { useState } from 'react';
import PropTypes from 'prop-types';
import { forbidExtraProps } from 'airbnb-prop-types';
import classNames from 'classnames';

import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'dux/app/hooks';

import isEmpty from 'lodash/fp/isEmpty';

import { theme } from '@prose-ui';
import { legacyTheme, styled } from '@prose-ui/legacy';
import { CardElement, PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import { FastField } from 'formik';
import { makeStyles } from 'legacyStyles';

import Checkbox from 'Components/Checkbox';
import HorizontalDivider from 'Components/HorizontalDivider';
import Spacer from 'Components/Spacer';
import Typography from 'Components/Typography';

import PayPalPayment from '../Blocks/PayPalPayment';

import { trackEvent } from 'dux/tracking/actions';
import { shouldShowPaypal } from 'dux/featureFlags/selectors';
import { useStripePayment } from 'dux/checkoutPayment/hooks';
import { paymentActions } from 'dux/checkoutPayment/slice';

import useCreateCreditCardInfosByCartType from '../hooks/useCreateCreditCardInfosByCartType';
import usePayPalCheckoutButton from '../hooks/usePayPalCheckoutButton';
import CardInfo from './CardInfo';
import PaymentTextField from './PaymentTextField';

const Wrapper = styled.div`
  // Display none instead of removing from DOM so we can show it again server error after confirmation step
  display: ${({ cardPubkey }) => (cardPubkey === 'newCard' ? 'block' : 'none')};
  ${legacyTheme.breakpoints.up('sm')} {
    max-width: 300px;
  }
`;

const CreditCardSaveContainer = styled.div`
  padding: 18px 0;
`;

const PaymentRequestButtonContainer = styled.div`
  margin-bottom: 20px;
`;

const CardErrorMessage = styled(Typography)`
  margin-left: 20px;
`;
const CardErrorMessageIntent = styled(Typography)`
  margin-left: 4px;
`;

const useStyles = makeStyles({
  stripeElements: {
    position: 'relative',

    width: '100%',
    maxWidth: 400,
    marginTop: legacyTheme.spacing.s8,
    marginBottom: legacyTheme.spacing.s8,
    padding: `${legacyTheme.spacing.s16} 20px`,

    background: theme.colors.neutral[100],

    fontSize: 16,

    '&:before': {
      position: 'absolute',
      top: 0,
      left: 0,

      width: '100%',
      height: '100%',

      boxShadow: `2px 2px 3px 0 ${theme.colors.neutral[600]}`,
      border: `solid 0.5px ${theme.colors.neutral[600]}`,
      mixBlendMode: 'multiply',

      content: '""',
      pointerEvents: 'none',
    },
  },
  stripeElementsError: {
    borderBottom: `2px solid ${theme.colors.error[200]}`,
  },
});

const CreateCreditCard = ({ cardPubkey, showExpressPayment, saveCard, paymentError }) => {
  const dispatch = useDispatch();
  const showPayPal = useSelector(shouldShowPaypal);
  const [cardErrorMessage, setCardErrorMessage] = useState(null);

  const { classes } = useStyles();
  const { cartHasSubscription, currency, shippingAddress, totalAmount } =
    useCreateCreditCardInfosByCartType();

  usePayPalCheckoutButton({ currency, shippingAddress, totalAmount });

  const { canMakePayment, paymentRequest, walletType } = useStripePayment({
    expressCheckout: false,
    saveCard,
  });
  const PaymentRequestButtonElementProps = {
    onClick: () =>
      dispatch(
        trackEvent('payment_button_click', {
          payment_button_type: walletType,
        })
      ),
    paymentRequest,
  };

  const onCardElementChange = event => {
    if (event.error?.message) {
      setCardErrorMessage(event.error.message);
    } else {
      setCardErrorMessage(null);
    }
  };
  return (
    <Wrapper cardPubkey={cardPubkey}>
      {/*
       * showExpressPayment is used to hide the button while the ExpressCheckout is proposed,
       * Stripe does not allow two paymentRequestButton in the DOM
       * */}
      {showExpressPayment && canMakePayment && (
        <PaymentRequestButtonContainer>
          {/*
           * Native Stripe element to display Apple Pay or Google Pay button depending on the device and browser
           * The paymentRequestButton element automatically shows the correct wallet branding
           * If no option available, no button displayed
           * For more info, see https://stripe.com/docs/stripe-js/elements/payment-request-button?html-or-react=react
           * Or https://stripe.com/docs/js/payment_request/can_make_payment
           */}
          <PaymentRequestButtonElement
            onClick={PaymentRequestButtonElementProps.onClick}
            options={{
              paymentRequest: PaymentRequestButtonElementProps.paymentRequest,
            }}
          />
        </PaymentRequestButtonContainer>
      )}

      {showPayPal && <PayPalPayment />}

      {((showExpressPayment && canMakePayment) || showPayPal) && (
        <HorizontalDivider textSize="14px" />
      )}

      <CardInfo />

      <Spacer size={18} />

      <CardElement
        className={classNames(classes.stripeElements, {
          [classes.stripeElementsError]: !isEmpty(cardErrorMessage) === true,
        })}
        onChange={onCardElementChange}
        onReady={() => dispatch(paymentActions.setIsPaymentReady(true))}
        options={{
          style: {
            base: {
              color: theme.colors.neutral[800],
              fontFamily: legacyTheme.simplonNorm,
              fontSize: '16px',
            },
            invalid: {
              // For some reason, the rouge medium variable isn't recognized, so passing the hexa value
              color: theme.colors.error[200],
              iconColor: theme.colors.error[200],
            },
          },
        }}
      />

      {cardErrorMessage && (
        <>
          <CardErrorMessage align="left" color="rouge" markupName="p" variant="p3">
            {cardErrorMessage}
          </CardErrorMessage>
          <Spacer size={6} />
        </>
      )}
      {paymentError && (
        <>
          <CardErrorMessageIntent align="left" color="rouge" markupName="p" variant="p3">
            {paymentError}
          </CardErrorMessageIntent>
          <Spacer size={6} />
        </>
      )}

      <PaymentTextField
        ariaRequired
        autoCorrect={false}
        data-testid="name-on-card-field"
        label="Name on card*"
        name="nameOnCard"
      />

      <FastField name="cardError">
        {({ field: { value } }) =>
          value ? (
            <Typography align="left" color="rouge" variant="p3">
              {value}
            </Typography>
          ) : null
        }
      </FastField>

      <CreditCardSaveContainer data-testid="save-card-checkbox">
        <FastField name="saveCard">
          {({ field }) => (
            <Checkbox
              disabled={cartHasSubscription}
              id="saveCard"
              label={
                cartHasSubscription
                  ? 'Your payment method is automatically saved for your subscription.'
                  : 'Save this payment method'
              }
              labelColor="noirDark"
              labelVariant="p3"
              name="saveCard"
              onBlur={field.onBlur}
              onChange={field.onChange}
              value={field.value}
            />
          )}
        </FastField>
      </CreditCardSaveContainer>
    </Wrapper>
  );
};

CreateCreditCard.propTypes = forbidExtraProps({
  cardPubkey: PropTypes.string.isRequired,
  paymentError: PropTypes.string,
  showExpressPayment: PropTypes.bool,
  saveCard: PropTypes.bool,
});

CreateCreditCard.defaultProps = {
  paymentError: null,
  showExpressPayment: false,
  saveCard: false,
};

export default CreateCreditCard;
