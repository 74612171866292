import PropTypes from 'prop-types';

import ScaleLoader from 'Components/ScaleLoader';

import BaseScene from 'Scenes/LegacyBaseScene';

const LoadingScene = ({ pastDelay, header, ...props }) => (
  <BaseScene header={header} {...props}>
    {pastDelay && <ScaleLoader />}
  </BaseScene>
);

LoadingScene.propTypes = {
  header: PropTypes.bool,
  pastDelay: PropTypes.bool,
};

LoadingScene.defaultProps = {
  header: true,
  pastDelay: true,
};

export default LoadingScene;
