import supplementsBabyHairs from './questions/supplements/supplementsBabyHairs';
import supplementsHairGrowthScreen from './questions/supplements/supplementsHairGrowth';
import supplementsLessShedding from './questions/supplements/supplementsLessShedding';
import supplementsOverallImprovementScreen from './questions/supplements/supplementsOverallImprovement';
import supplementsPublicReview from './questions/supplements/supplementsPublicReview';
import supplementsResults from './questions/supplements/supplementsResults';
import supplementsSatisfaction from './questions/supplements/supplementsSatisfaction';
import supplementsScalpComfort from './questions/supplements/supplementsScalpComfort';
import supplementsScalpFlakes from './questions/supplements/supplementsScalpFlakes';
import supplementsScalpOil from './questions/supplements/supplementsScalpOil';
import supplementsShine from './questions/supplements/supplementsShine';
import supplementsSoftness from './questions/supplements/supplementsSoftness';
import supplementsStrength from './questions/supplements/supplementsStrength';
import supplementsThickness from './questions/supplements/supplementsThickness';
import supplementsTransitionScreen from './questions/supplements/supplementsTransitionScreen';
import supplementsUsageDuration from './questions/supplements/supplementsUsageDuration';
import supplementsUsageFrequency from './questions/supplements/supplementsUsageFrequency';

export default [
  // INTRO
  supplementsTransitionScreen,

  // QUESTIONS
  supplementsUsageDuration,
  supplementsUsageFrequency,
  supplementsSatisfaction,
  supplementsOverallImprovementScreen,
  supplementsHairGrowthScreen,
  supplementsBabyHairs,
  supplementsLessShedding,
  supplementsShine,
  supplementsSoftness,
  supplementsStrength,
  supplementsThickness,
  supplementsScalpComfort,
  supplementsScalpFlakes,
  supplementsScalpOil,

  // RESULTS
  supplementsResults,

  // PUBLIC REVIEW
  supplementsPublicReview,
];
