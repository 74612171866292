import {
  supplementsDaily,
  supplementsEveryTwoThreeDays,
  supplementsFromTwoMonths,
  supplementsOnceTwiceAWeek,
  supplementsStoppedUse,
  supplementsUsageFrequency,
} from 'Apps/Feedback/constants/feedbackSlugs';

const supplementsUsageFrequencyScreen = {
  name: supplementsUsageFrequency,
  route: `/feedback/supplements/${supplementsUsageFrequency}`,
  shouldBeIncluded: ({ feedbackAnswers }) =>
    feedbackAnswers &&
    supplementsFromTwoMonths.includes(feedbackAnswers.product_supplements_usage_duration),
  title: 'How frequently do you take your supplements?',
  options: [
    { value: supplementsDaily, label: 'Daily as recommended' },
    { value: supplementsEveryTwoThreeDays, label: 'Every 2-3 days' },
    { value: supplementsOnceTwiceAWeek, label: 'Once or twice a week' },
    { value: supplementsStoppedUse, label: 'I stopped' },
  ],
  getInitialValue: answers => answers?.product_supplements_usage_frequency,
  getAnswers: ({ selected }) => ({ product_supplements_usage_frequency: selected }),
};

export default supplementsUsageFrequencyScreen;
