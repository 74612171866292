import flow from 'lodash/fp/flow';
import set from 'lodash/fp/set';

import { createReducer } from 'utils/reduxUtils';

import { userSignout } from 'dux/auth/actions';

import {
  FETCH_ORDERS_FAILURE,
  FETCH_ORDERS_REQUEST,
  FETCH_ORDERS_SUCCESS,
  FETCH_PUMPS_CUSTOMER_PREFERENCES_FAILURE,
  FETCH_PUMPS_CUSTOMER_PREFERENCES_REQUEST,
  FETCH_PUMPS_CUSTOMER_PREFERENCES_SUCCESS,
} from './actionTypes';
import initialState from './initialState';

const reducerToActionTypeMap = {
  [userSignout.type]: () => initialState,
  [FETCH_ORDERS_REQUEST]: flow(set('isLoading', true), set('error', false)),
  [FETCH_ORDERS_SUCCESS]: (state, { orders }) =>
    flow(set('isLoading', false), set('error', false), set('orders', orders))(state),
  [FETCH_ORDERS_FAILURE]: flow(set('isLoading', false), set('error', true)),
  [FETCH_PUMPS_CUSTOMER_PREFERENCES_REQUEST]: flow(set('isLoading', true), set('error', false)),
  [FETCH_PUMPS_CUSTOMER_PREFERENCES_SUCCESS]: (state, { pumpsCustomerPreferences }) =>
    flow(
      set('isLoading', false),
      set('error', false),
      set('pumpsCustomerPreferences', pumpsCustomerPreferences)
    )(state),
  [FETCH_PUMPS_CUSTOMER_PREFERENCES_FAILURE]: flow(set('isLoading', false), set('error', true)),
};

export default createReducer(reducerToActionTypeMap, initialState);
