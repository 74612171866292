import { styled } from '@prose-ui/legacy';
import Image from 'next/image';

import Typography from 'Components/Typography';

import creditCardsImg from 'assets/images/credit_cards.svg';

const CardInfoWrapper = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
  font-size: 0.6em;
`;

const SecurePaymentInfo = styled.div`
  display: flex;
  align-items: center;
`;

const CardInfo = () => {
  return (
    <CardInfoWrapper>
      <Image
        alt="Visa | Mastercard | Maestro - Credit card icon - vector"
        height={15}
        src={creditCardsImg}
        width={160}
      />
      <SecurePaymentInfo>
        {/* @ts-expect-error - Typography has no TS types for now */}
        <Typography variant="p3">Secure and Encrypted</Typography>
      </SecurePaymentInfo>
    </CardInfoWrapper>
  );
};

export default CardInfo;
