import * as feedbackComponents from 'Apps/Feedback/constants/feedbackComponents';
import { SKIN } from 'Apps/Feedback/constants/feedbackSections';
import { skincareSatisfaction } from 'Apps/Feedback/constants/feedbackSlugs';

import { feedbackCategories } from 'constants/feedback';

const skinSatisfaction = {
  name: skincareSatisfaction,
  route: '/feedback/skincare/skin-satisfaction',
  category: SKIN,
  feedbackCategory: feedbackCategories.SKINCARE,
  shouldBeIncluded: () => true,
  component: feedbackComponents.Rating,
  title: 'Overall, how satisfied are you with the current state of your skin?',
  subtitle: 'Consider how it looks, feels, and behaves on a daily basis.',
  options: [
    { value: 1, label: 'Very Unsatisfied' },
    { value: 2, label: 'Unsatisfied' },
    { value: 3, label: 'Neutral' },
    { value: 4, label: 'Satisfied' },
    { value: 5, label: 'Very Satisfied' },
  ],
  getInitialValue: answers => answers?.skin_satisfaction,
  getAnswers: ({ selected }) => ({ skin_satisfaction: selected }),
};

export default skinSatisfaction;
