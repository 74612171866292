export const setCookie = (name: string, value: string, days?: number, path = '/') => {
  const date = new Date();
  if (days) {
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  }
  const expires = days ? `; expires=${date.toUTCString()}` : '';
  document.cookie = `${name}=${value}${expires}; path=${path}`;
};

export const getCookie = (name: string): string | null => {
  // Retrieve cookies and trim each of them
  const cookies = document.cookie.split('; ').map((cookie) => cookie.trim());
  // Locate cookie with desired name
  const match = cookies.find((cookie) => cookie.startsWith(`${name}=`));
  return match && typeof match !== 'undefined' ? match.split('=')[1]! : null;
};
