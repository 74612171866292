import { SCALP } from 'Apps/Feedback/constants/feedbackSections';
import {
  haircareImprovements,
  haircareNoConcernInImprovements,
  haircareNoImprovements,
  haircareScalpSensitivity,
  haircareSomeImprovements,
} from 'Apps/Feedback/constants/feedbackSlugs';

import { productsSlugs } from 'constants/products';

const scalpSensitivity = {
  name: haircareScalpSensitivity,
  route: `/feedback/haircare/${haircareScalpSensitivity}`,
  category: SCALP,
  shouldBeIncluded: ({ formulas }) =>
    formulas?.some(f =>
      [productsSlugs.SCALP_MASK, productsSlugs.SHAMPOO].includes(f.variant.product.type)
    ),
  title: "Did you see improvements in your scalp's sensitivity?",
  subtitle: 'This is how dry, tight, or itchy your scalp feels',
  options: [
    { value: haircareImprovements, label: "Yes, it's soothed now" },
    { value: haircareSomeImprovements, label: 'Some, but could be better' },
    { value: haircareNoImprovements, label: 'No, I still have sensitivity' },
    { value: haircareNoConcernInImprovements, label: "It wasn't a concern" },
  ],
  getInitialValue: answers => answers?.scalp_sensitivity,
  getAnswers: ({ selected }) => ({ scalp_sensitivity: selected }),
};

export default scalpSensitivity;
