import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import isEmpty from 'lodash/fp/isEmpty';

import { type AppThunk } from 'store';

import * as Auth from 'Services/Auth';
import * as AuthService from 'Services/AuthService';
import { isFetchError } from 'Services/HTTPError';

import logSentryError from 'utils/logSentry';

import { trackUserSignOut, trackUtmSource } from 'dux/tracking/actions';
import { getIsIdle } from 'dux/auth/selectors';
import { fetchAddresses, fetchUser } from 'dux/user/thunks';

export const fetchAuth = createAsyncThunk<undefined, undefined, { rejectValue: 'signout' }>(
  'auth/fetch',
  async (_, { dispatch, fulfillWithValue, rejectWithValue }) => {
    // synchronously define if not authenticated
    if (!Auth.isCookied()) {
      return rejectWithValue('signout');
    }

    try {
      const auth = await AuthService.isAuthenticated();

      if (auth) {
        await Promise.all([dispatch(fetchUser()), dispatch(fetchAddresses())]);
      } else {
        Auth.clearTokens();
        return rejectWithValue('signout');
      }
    } catch (error) {
      if (!isFetchError(error)) {
        logSentryError('[dux/auth] fetchAuth', error);
        Auth.clearTokens();
      }

      throw error;
    }

    return fulfillWithValue(undefined);
  },
);

export const fetchAuthIfNeeded = (): AppThunk => (dispatch, getState) => {
  if (typeof window === 'undefined') return undefined;

  const searchParams = new URLSearchParams(window.location.search);
  // WARNING this is a hack
  // a token param means the authentication is done with a magic link
  if (searchParams.get('token')) {
    return undefined;
  }

  // if machine isn't on idle state we can't proceed
  if (!getIsIdle(getState())) {
    return undefined;
  }

  if (!isEmpty(searchParams?.get('utm_source'))) {
    dispatch(trackUtmSource({ source: searchParams?.get('utm_source') }));
  }

  return dispatch(fetchAuth());
};

// For backward compat with previous userSignout implementation
export const userSignout = createAction('auth/signout');
export const signoutUser = (): AppThunk => (dispatch) => {
  Auth.clearTokens();
  dispatch(trackUserSignOut());
  dispatch(userSignout());
};
