import { useEffect } from 'react';

import { Navigate, useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'dux/app/hooks';

import { SUCCESS } from 'constants/statuses';

import ErrorContainer from 'Containers/ErrorContainer';

import LoadingScene from 'Scenes/LoadingScene';

import { trackHeapEvent } from 'dux/tracking/actions';
import * as digitalLeafletSelectors from 'dux/digitalLeaflet/selectors';
import { fetchDigitalLeaflet } from 'dux/digitalLeaflet/thunks';

const DigitalLeafletViaQRCode = () => {
  const { orderBoxPubKey } = useParams();

  const dispatch = useAppDispatch();

  const data = useAppSelector(digitalLeafletSelectors.getData);
  const status = useAppSelector(digitalLeafletSelectors.getStatus);
  const error = useAppSelector(digitalLeafletSelectors.getError);

  useEffect(() => {
    if (orderBoxPubKey) {
      dispatch(fetchDigitalLeaflet({ orderBoxPubKey }));
    }
  }, [orderBoxPubKey]);

  useEffect(() => {
    if (data || status === SUCCESS) {
      dispatch(trackHeapEvent('QR Scan of digital collateral')); // send tracking event before redirection
    }
  }, [data]);

  if (error) return <ErrorContainer error={error} />;

  // @ts-expect-error - LoadingScene to be typed
  if (!data || status !== SUCCESS) return <LoadingScene background="beige" />;

  return <Navigate replace to={`/digital-leaflet/${data.order.pubkey}`} />;
};

export default DigitalLeafletViaQRCode;
