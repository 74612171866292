import type { ReactNode } from 'react';

import { Box, theme } from '@prose-ui';
import { styled } from '@prose-ui/legacy';
import { mediaMinWidth } from '@prose-ui/utils/media';

import Typography from 'Components/Typography';

const LabelText = styled(Typography)`
  position: relative;
  font-size: ${theme.typography.fontSize.xs};

  --label-inline-padding: 0.7em;
  --label-block-padding: 0.7em;

  padding-inline: var(--label-inline-padding);
  padding-top: var(--label-block-padding);
  padding-bottom: calc(
    var(--label-block-padding) - 0.4em
  ); /* smaller bottom padding because   the font's baseline is offsetted */

  ${mediaMinWidth('sm')} {
    font-size: ${theme.typography.fontSize.sm};
  }
`;

const ImageLabel = ({ children }: { children: ReactNode }) => {
  return (
    <Box
      left="50%"
      position="absolute"
      top={{ mobile: '3x', desktop: '5x' }}
      transform="translateX(-50%)"
      width="max"
    >
      {/* required to reduce opacity of the background without reducing opacity on text */}
      <Box
        backgroundColor="neutral.100"
        height="full"
        opacity="0.8"
        position="absolute"
        width="full"
      />
      {/* @ts-expect-error - Typography component to be typed */}
      <LabelText variant="mono3">{children}</LabelText>
    </Box>
  );
};

export default ImageLabel;
