import {
  supplementsBabyHairs,
  supplementsFromTwoMonths,
  supplementsLittleImprovements,
  supplementsLotsOfImprovements,
  supplementsNoConcernInImprovements,
  supplementsNoImprovements,
  supplementsSomeImprovements,
} from 'Apps/Feedback/constants/feedbackSlugs';

const supplementsBabyHairsScreen = {
  name: supplementsBabyHairs,
  route: `/feedback/supplements/${supplementsBabyHairs}`,
  shouldBeIncluded: ({ feedbackAnswers }) =>
    feedbackAnswers &&
    supplementsFromTwoMonths.includes(feedbackAnswers.product_supplements_usage_duration),
  title: 'Have you noticed any new baby hairs?',
  subtitle: 'Check around your temples and your part for new growth.',
  options: [
    { value: supplementsLotsOfImprovements, label: 'Yes, lots of improvement' },
    { value: supplementsSomeImprovements, label: 'Yes, some improvement' },
    { value: supplementsLittleImprovements, label: 'Yes, maybe a little' },
    { value: supplementsNoImprovements, label: 'Not yet' },
    { value: supplementsNoConcernInImprovements, label: 'No, but it wasn’t a concern' },
  ],
  getInitialValue: answers => answers?.product_supplements_baby_hairs,
  getAnswers: ({ selected }) => ({ product_supplements_baby_hairs: selected }),
};

export default supplementsBabyHairsScreen;
