import { useEffect } from 'react';

import { Route, Routes, useLocation, useParams } from 'react-router-dom';

import { useAppDispatch } from 'dux/app/hooks';

import { MetaData } from 'Components/MetaData';

import NotFound from 'Scenes/NotFound';

import createLoadablePage from 'utils/createLoadablePage';

import { trackBreadcrumb } from 'dux/tracking/actions';

const BrushesLanding = createLoadablePage(() =>
  import(/* webpackChunkName: "landing-brush" */ './Scenes/BrushesLanding')
);
const CurlCreamLanding = createLoadablePage(() =>
  import(/* webpackChunkName: "landing-curl-cream" */ './Scenes/CurlCreamLanding')
);
const OilLanding = createLoadablePage(() =>
  import(/* webpackChunkName: "landing-oil" */ './Scenes/OilLanding')
);
const DryShampooLanding = createLoadablePage(
  () => import(/* webpackChunkName: "landing-dry-shampoo" */ './Scenes/DryShampooLanding'),
  {
    LoadingSceneProps: { banner: false },
  }
);
const SupplementsLanding = createLoadablePage(() =>
  import(/* webpackChunkName: "landing-supplements" */ './Scenes/Supplements')
);
const StylistContentCall = createLoadablePage(() =>
  import(/* webpackChunkName: "landing-stylist-content-call" */ './Scenes/StylistContentCall')
);

const StylingGelLanding = createLoadablePage(() =>
  import(/* webpackChunkName: "landing-styling-gel" */ './Scenes/StylingGelLanding')
);

const SkincareLanding = createLoadablePage(() =>
  import(/* webpackChunkName: "landing-skincare" */ './Scenes/SkincareLanding')
);
const ValidateSupplementsVariantLanding = () => {
  const params = useParams();
  const isFound = [
    'supplements-search',
    'supplements-rootsource1',
    'supplements-rootsource2',
    'supplements-before-after',
    'supplements-video-straight',
    'supplements-video-curly',
    'supplements-video-3',
    'supplements-before-after-search',
    'supplements-pp',
  ].includes(params?.supplementsLandingVariant);
  if (!isFound) {
    return <NotFound />;
  }

  let variant;
  switch (params.supplementsLandingVariant) {
    case 'supplements-before-after':
      variant = 'before-after';
      break;
    case 'supplements-rootsource1':
      variant = 'rootsource1';
      break;
    case 'supplements-rootsource2':
      variant = 'rootsource2';
      break;
    case 'supplements-video-straight':
      variant = 'videostraight';
      break;
    case 'supplements-video-curly':
      variant = 'videocurly';
      break;
    case 'supplements-video-3':
      variant = 'video3';
      break;
    case 'supplements-before-after-search':
      variant = 'before-after-search';
      break;
    case 'supplements-pp':
      variant = 'pp';
      break;
    default:
      variant = null;
  }
  return <SupplementsLanding variant={variant} />;
};

const LandingPagesRoutes = () => {
  const dispatch = useAppDispatch();
  const { pathname, search } = useLocation();

  useEffect(() => {
    dispatch(trackBreadcrumb(`${pathname}${search}`));
  }, [pathname, search]);

  return (
    <Routes>
      <Route element={<BrushesLanding />} path="brushes" />
      <Route element={<CurlCreamLanding />} path="curl-cream" />
      <Route element={<MetaData noIndex outlet />}>
        <Route element={<OilLanding />} path="oil" />
      </Route>
      <Route element={<DryShampooLanding />} path="dry-shampoo" />
      <Route element={<StylingGelLanding />} path="styling-gel" />
      <Route element={<StylingGelLanding variant="curl" />} path="styling-gel-curl" />
      <Route element={<StylingGelLanding variant="coily" />} path="styling-gel-coily" />
      <Route element={<SkincareLanding />} path="skincare" />
      <Route element={<MetaData canonicalURL="/pages/supplements" outlet />}>
        <Route element={<SupplementsLanding />} path="supplements" />
        <Route element={<MetaData inheritParentCanonicalURL noIndex outlet />}>
          <Route
            element={<ValidateSupplementsVariantLanding />}
            path=":supplementsLandingVariant"
          />
        </Route>
      </Route>
      <Route element={<MetaData noIndex outlet />}>
        <Route element={<StylistContentCall />} path="stylist-content-call" />
      </Route>
      <Route element={<NotFound />} path="*" />
    </Routes>
  );
};

export default LandingPagesRoutes;
