import PropTypes from 'prop-types';

import { theme } from '@prose-ui';
import { keyframes } from '@prose-ui/legacy';
import { withStyles } from 'legacyStyles';

import range from '../utils/range';

const scaleLoaderKeyframes = keyframes`
    0% {
      transform: scaley(1.0);
    }

    100% {
      transform: scaley(0.4);
    }
`;

const styles = {
  animation: scaleLoaderKeyframes,
  bar: {
    backgroundColor: theme.colors.primary[400],
    height: '35px',
    width: '4px',
    margin: '5px',
    borderRadius: '2px',
    display: 'inline-block',
    animationName: scaleLoaderKeyframes.name,
    animationDirection: 'alternate',
    animationDuration: '500ms',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'cubic-bezier(.74,.04,.65,.99)',
  },
};

const ScaleLoader = ({ className, classes, id }) => (
  <div className={className} data-testid="scale-loader" id={id}>
    {range(5).map(i => (
      <div key={i} className={classes.bar} style={{ animationDelay: `${i * 100}ms` }} />
    ))}
  </div>
);

ScaleLoader.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.string,
  id: PropTypes.string,
  margin: PropTypes.string,
  radius: PropTypes.string,
  width: PropTypes.string,
};

ScaleLoader.defaultProps = {
  color: theme.colors.neutral['900'],
  height: '35px',
  id: null,
  margin: '5px',
  radius: '2px',
  width: '4px',
};

export default withStyles(styles)(ScaleLoader);
