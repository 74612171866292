import { type ReactNode, useState } from 'react';

import { theme } from '@prose-ui';
import { styled } from '@prose-ui/legacy';

import { BottomModal } from 'Components/BottomModal';
import Spacer from 'Components/Spacer';
import Typography from 'Components/Typography';

import { handleKeyPress } from 'utils/a11y';

const ContentContainer = styled.div<{ padding?: PaddingString; backgroundColor: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  width: 100%;
  max-width: 400px;
  min-width: 310px;
  margin: 0 auto;
  padding: ${({ padding }) => padding ?? '65px 55px'};

  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const Title = styled(Typography)`
  font-size: 12px;
`;

const Terms = styled(Typography)`
  cursor: pointer;
`;

type PaddingString = `${number}px ${number}px`;

type OfferCheckoutCommunicationProps = {
  modalContent: {
    title: string | ReactNode;
    description: string | ReactNode;
    conditions?: string | ReactNode;
  };
  checkoutCommunicationContent: {
    title: string;
    description: string | ReactNode;
    terms: string;
  };
  backgroundColor?: string;
  padding?: PaddingString;
  testPrefix: string;
  dataTestId?: string;
};

const OfferCheckoutCommunication = ({
  modalContent,
  checkoutCommunicationContent,
  padding = '65px 55px',
  testPrefix,
  dataTestId = 'cart-offer-block-message',
  backgroundColor = theme.colors.tertiary[200],
}: OfferCheckoutCommunicationProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <BottomModal
        backgroundColor={backgroundColor}
        content={modalContent}
        dataFrom="membership-modal"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        testPrefix={testPrefix}
      />
      <ContentContainer
        backgroundColor={backgroundColor}
        data-testid={dataTestId}
        padding={padding}
      >
        {/* @ts-ignore Legacy Typography  */}
        <Title variant="mono3">{checkoutCommunicationContent.title}</Title>
        <Spacer axis="vertical" size={16} />
        {/* @ts-ignore Legacy Typography  */}
        <Typography variant="p1">{checkoutCommunicationContent.description}</Typography>
        <Spacer axis="vertical" size={16} />
        {/* @ts-ignore Legacy Typography  */}
        <Terms
          aria-label={`${checkoutCommunicationContent.terms} - Open checkout communication modal`}
          onClick={() => setIsOpen(true)}
          onKeyPress={handleKeyPress(() => setIsOpen(true))}
          role="button"
          tabIndex="0"
          underline
          variant="p3"
        >
          {checkoutCommunicationContent.terms}
        </Terms>
      </ContentContainer>
    </>
  );
};

export default OfferCheckoutCommunication;
