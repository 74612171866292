import { FRAGRANCE } from 'Apps/Feedback/constants/feedbackSections';
import { haircareFragranceSatisfactionScalpMask } from 'Apps/Feedback/constants/feedbackSlugs';

import { productsSlugs } from 'constants/products';

const scalpMaskFragranceSatisfaction = {
  name: haircareFragranceSatisfactionScalpMask,
  route: `/feedback/haircare/${haircareFragranceSatisfactionScalpMask}`,
  category: FRAGRANCE,
  shouldBeIncluded: ({ formulas }) =>
    formulas?.some(
      f =>
        [productsSlugs.SCALP_MASK].includes(f.variant.product.type) &&
        f.item_object.smells_like !== null
    ),
  component: 'Rating',
  surtitle: {
    showFormulas: 'scalp_mask',
    showFragrance: true,
  },
  title: 'How satisfied are you with the scent of your scalp mask?',
  options: [
    { value: 1, label: 'Very Unsatisfied' },
    { value: 2, label: 'Unsatisfied' },
    { value: 3, label: 'Neutral' },
    { value: 4, label: 'Satisfied' },
    { value: 5, label: 'Very Satisfied' },
  ],
  getInitialValue: answers => answers?.fragrance_satisfaction_scalp_mask,
  getAnswers: ({ selected }) => ({ fragrance_satisfaction_scalp_mask: selected }),
};

export default scalpMaskFragranceSatisfaction;
