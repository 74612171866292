import { Navigate, useParams } from 'react-router-dom';

import find from 'lodash/fp/find';
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';

const validVariantPubKeys = [
  { variantPubKey: '717a9229-ed61-4690-bef2-e9d4dc60b506', linkTo: '/the-salon' },
  { variantPubKey: 'd823e60e-dc05-4e11-9628-1b288ea0ed56', linkTo: '/the-salon' },
  { variantPubKey: 'dee1f736-c435-499e-b9e3-d730b5e54369', linkTo: '/pages/supplements' },
  { variantPubKey: '5654d778-f8ce-46f4-921b-13251f837dfc', linkTo: '/pages/supplements' },
  { variantPubKey: '8b43c1da-611c-4a0c-8f69-08ea46feb6a5', linkTo: '/pages/supplements' },
  { variantPubKey: '6891212c-e9d3-49a2-8df0-712bb8c65685', linkTo: '/pages/supplements' },
  { variantPubKey: '5257e8da-2a09-4537-b030-79264ef8e872', linkTo: '/pages/supplements' },
  { variantPubKey: '7d74af4e-4f6e-48a6-a1f7-c994f63f5ebd', linkTo: '/pages/supplements' },
  { variantPubKey: '2d640213-7ac2-4499-8de9-0d76b674e5b0', linkTo: '/pages/supplements' },
  { variantPubKey: 'd7ba7e1f-e464-4aa1-b4f8-b39b350bac7d', linkTo: '/pages/supplements' },
  { variantPubKey: 'ee24fc75-e23c-4999-8cea-49331b9437dc', linkTo: '/pages/supplements' },
  { variantPubKey: '401efd6d-ea46-44c8-89c7-4006967c1b30', linkTo: '/pages/supplements' },
  { variantPubKey: '0ec2fe27-5b06-42fb-aa8e-c8e1914abd57', linkTo: '/pages/supplements' },
  { variantPubKey: '1eaf86e3-42a6-4a81-a422-57aa53639383', linkTo: '/pages/supplements' },
];

const getLinkToVariant = variantPubKey =>
  flow(find({ variantPubKey }), get('linkTo'))(validVariantPubKeys);

const Variant = () => {
  const { variantPubKey } = useParams();

  return <Navigate replace to={getLinkToVariant(variantPubKey) ?? '/products/haircare'} />;
};

export default Variant;
