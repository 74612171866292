import { useState } from 'react';

import { Text, theme } from '@prose-ui';
import { legacyTheme, styled } from '@prose-ui/legacy';

const Container = styled.div`
  z-index: 10000;
  height: ${legacyTheme.props.bannerHeight};
  width: 100%;
  position: fixed;
  top: 0;
  background-color: ${theme.colors.error[200]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${legacyTheme.spacing.s8};
`;

const StyledButton = styled.button`
  color: ${theme.colors.neutral[100]};
  cursor: pointer;
  width: ${legacyTheme.props.bannerHeight};
  height: ${legacyTheme.props.bannerHeight};
  background-color: transparent;
  border: none;
`;

type VersionProps = {
  initiallyVisible: boolean;
  versionAlgo?: string;
  versionApi?: string;
  versionSite?: string;
};

export const Version = ({
  initiallyVisible,
  versionAlgo,
  versionApi,
  versionSite,
}: VersionProps) => {
  const [visible, setVisible] = useState(initiallyVisible);

  if (!visible) {
    return null;
  }

  return (
    <Container>
      <Text color="neutral.100" variant="bodySm">
        www={versionSite}
      </Text>
      <Text color="neutral.100" variant="bodySm">
        api={versionApi}
      </Text>
      <Text color="neutral.100" variant="bodySm">
        algo={versionAlgo}
      </Text>
      <StyledButton onClick={() => setVisible(false)}>x</StyledButton>
    </Container>
  );
};
