import { type ReactNode } from 'react';

import { theme } from '@prose-ui';
import { styled } from '@prose-ui/legacy';
import { mediaMinWidth } from '@prose-ui/utils/media';
import { cssHeight, type HeightCSSValue, type HeightProp } from '@prose-ui/utils/props';
import type { OptionalKeysOf } from 'type-fest';
import { isKey } from 'types/predicates';

type OrderTwo = '12' | '21';

type SpaceDistributionProp = 'equal' | 'contents' | 'two-thirds';

const cssReorder = (ofTypeSelector: string, orderString: string) =>
  orderString.split('').reduce(
    (acc, curr, i) =>
      `${acc}
${ofTypeSelector}:nth-of-type(${curr}) {
  order: ${i};
}`,
    '',
  );

const Root = styled.div<{
  desktopOrder: OrderTwo;
  mobileMinHeight: HeightCSSValue;
  desktopMinHeight: HeightCSSValue;
  spaceDistribution: ResponsiveProp<SpaceDistributionProp>;
  borders: NonNullable<TwoUpProps['borders']>;
}>`
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: ${({ spaceDistribution }) =>
    spaceDistribution.mobile === 'equal'
      ? '1fr 1fr'
      : spaceDistribution.mobile === 'two-thirds'
      ? '2fr 1fr'
      : 'min-content min-content'};
  width: 100%;

  --with-border: 1px solid;
  --with-radius: ${theme.radius.xs};

  border-color: ${theme.colors.neutral[900]};
  border-bottom: ${({ borders }) => (borders.bottom ? 'var(--with-border)' : 'none')};
  border-top: ${({ borders }) => (borders.top ? 'var(--with-border)' : 'none')};
  border-inline: ${({ borders }) => (borders.sides ? 'var(--with-border)' : 'none')};
  border-radius: ${({ borders }) => (borders.radius ? 'var(--with-radius)' : 'none')};

  min-height: ${({ mobileMinHeight }) => mobileMinHeight};

  overflow: hidden;

  ${mediaMinWidth('sm')} {
    grid-auto-flow: column;
    grid-template-columns: ${({ spaceDistribution }) =>
      spaceDistribution.desktop === 'equal'
        ? '1fr 1fr'
        : spaceDistribution.desktop === 'two-thirds'
        ? '2fr 1fr'
        : 'fit-content fit-content'};
    grid-template-rows: 1fr;
    min-height: ${({ desktopMinHeight }) => desktopMinHeight};

    ${({ desktopOrder }) => cssReorder('& > div', desktopOrder)};
  }

  & > div {
    display: flex;
    place-content: center center;
    width: 100%;
    height: 100%;
  }
`;

type ResponsiveProp<T> = { mobile: T; desktop: T };

type Defaults<T extends object> = Required<Pick<T, OptionalKeysOf<T>>>;

type TwoUpProps = {
  children: [ReactNode, ReactNode];
  desktopOrder?: OrderTwo;
  minHeight?: HeightProp | ResponsiveProp<HeightProp>;
  spaceDistribution?: SpaceDistributionProp | ResponsiveProp<SpaceDistributionProp>;
  borders?: {
    top?: boolean;
    bottom?: boolean;
    sides?: boolean;
    radius?: boolean;
  };
  dataTestId?: string | null;
  dataFrom?: string | null;
};

const defaultProps = {
  desktopOrder: '12',
  spaceDistribution: { mobile: 'contents', desktop: 'equal' },
  minHeight: { mobile: 'fit-content', desktop: 'fit-content' },
  borders: { top: false, bottom: false, sides: false, radius: false },
  dataTestId: null,
  dataFrom: null,
} satisfies Defaults<TwoUpProps>;

type FlattenResponsive<T> = T extends ResponsiveProp<infer K> ? K : T;
type DeviceType = keyof ResponsiveProp<any>;

function getResponsiveProp<T>(value: T, deviceType: DeviceType): Required<FlattenResponsive<T>> {
  return typeof value === 'object' && value !== null && isKey(value, deviceType)
    ? (value[deviceType] as Required<FlattenResponsive<T>>)
    : (value as Required<FlattenResponsive<T>>);
}

const TwoUp = ({
  desktopOrder,
  children,
  minHeight,
  spaceDistribution,
  borders,
  dataTestId,
  dataFrom,
}: TwoUpProps) => {
  const minHeightMobile = getResponsiveProp(minHeight, 'mobile') ?? defaultProps.minHeight.mobile;
  const minHeightDestkop =
    getResponsiveProp(minHeight, 'desktop') ?? defaultProps.minHeight.desktop;

  const spaceDistributionMobile =
    getResponsiveProp(spaceDistribution, 'mobile') ?? defaultProps.spaceDistribution.mobile;
  const spaceDistributionDesktop =
    getResponsiveProp(spaceDistribution, 'desktop') ?? defaultProps.spaceDistribution.desktop;

  const BorderTop = borders?.top ?? defaultProps.borders.top;
  const BorderBottom = borders?.bottom ?? defaultProps.borders.bottom;
  const BorderSides = borders?.sides ?? defaultProps.borders.sides;
  const BorderRadius = borders?.radius ?? defaultProps.borders.radius;

  return (
    <Root
      borders={{
        top: BorderTop,
        bottom: BorderBottom,
        sides: BorderSides,
        radius: BorderRadius,
      }}
      data-from={dataFrom || undefined}
      data-testid={dataTestId || undefined}
      desktopMinHeight={cssHeight(minHeightDestkop)}
      desktopOrder={desktopOrder ?? defaultProps.desktopOrder}
      mobileMinHeight={cssHeight(minHeightMobile)}
      spaceDistribution={{ mobile: spaceDistributionMobile, desktop: spaceDistributionDesktop }}
    >
      <div>{children[0]}</div>
      <div>{children[1]}</div>
    </Root>
  );
};

export default TwoUp;
