import type { Middleware } from '@reduxjs/toolkit';

import * as Sentry from '@sentry/nextjs';
import type { RootState } from 'store';

import logSentryError from 'utils/logSentry';

// if a reducer fail, ignore the action and send data to sentry

const errorMiddleware: Middleware<unknown, RootState> = () => (next) => (action) => {
  try {
    return next(action);
  } catch (error) {
    Sentry.addBreadcrumb({ message: 'Reducer failed', category: 'info', level: 'warning' });
    logSentryError('[Reducer error]', error);

    return action;
  }
};

export default errorMiddleware;
