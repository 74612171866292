import { PRODUCTS } from 'Apps/Feedback/constants/feedbackSections';
import {
  skincareCleanserFeel,
  skincareLittleDrying,
  skincareNicelyHydrating,
  skincareTooDrying,
  skincareTooOily,
} from 'Apps/Feedback/constants/feedbackSlugs';

import { feedbackCategories } from 'constants/feedback';
import { productsSlugs } from 'constants/products';

const cleanserFeel = {
  name: skincareCleanserFeel,
  route: `/feedback/${feedbackCategories.SKINCARE}/cleanser-product-feel`,
  category: PRODUCTS,
  feedbackCategory: feedbackCategories.SKINCARE,
  shouldBeIncluded: ({ formulas, feedbackAnswers }) =>
    formulas?.some(f => f.variant.product.type === productsSlugs.CLEANSER_MINI) &&
    feedbackAnswers?.cleanser_satisfaction,
  title: 'How does the cleanser feel on your skin?',
  options: [
    { value: skincareTooOily, label: 'Too oily' },
    { value: skincareNicelyHydrating, label: 'Nicely hydrating' },
    { value: skincareLittleDrying, label: 'A little drying' },
    { value: skincareTooDrying, label: 'Too drying' },
  ],
  getInitialValue: answers => answers?.cleanser_product_feel,
  getAnswers: ({ selected }) => ({ cleanser_product_feel: selected }),
};

export default cleanserFeel;
