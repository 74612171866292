import { Link } from 'react-router-dom';

import Button from 'Components/LegacyButton';
import Typography from 'Components/Typography';

import BaseScene from 'Scenes/LegacyBaseScene';

import getEnv from 'utils/getEnv';

const UserFraud = () => (
  <BaseScene header justifyContent="space-between" padding>
    <Typography align="center" variant="h1">
      Sorry!
    </Typography>

    <Typography align="center" variant="p1">
      <strong>We’ve detected that you have clicked your own referral link.</strong>
      <br />
      As an existing customer, you are only able to earn credit by referring new friends to Prose.
      <br />
      Get ${getEnv('REACT_APP_FRIENDBUY_ADVOCATE_AMOUNT')} for every friend you refer. They’ll get{' '}
      {getEnv('REACT_APP_FRIENDBUY_REFERREE_AMOUNT')} off and you’ll get $
      {getEnv('REACT_APP_FRIENDBUY_ADVOCATE_AMOUNT')} off after their first purchase.
    </Typography>

    <Button Component={Link} to="/account/refer-a-friend" variant="underlined">
      Refer your friends now
    </Button>
  </BaseScene>
);

export default UserFraud;
