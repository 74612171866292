import { supplementsVariantsSlugs } from 'constants/products';

import supplementsFacts1Img from 'assets/images/supplement_core_0001_facts.png';
import supplementsFacts2Img from 'assets/images/supplement_core_0002_facts.png';
import supplementsFacts3Img from 'assets/images/supplement_core_0003_facts.png';
import supplementsFacts4Img from 'assets/images/supplement_core_0004_facts.png';
import supplementsFacts6Img from 'assets/images/supplement_core_0006_facts.png';
import supplementsFactsSoftgelImg from 'assets/images/supplement_softgel_facts.png';

export const titles = {
  core: 'Bottle 1',
  softGel: 'Bottle 2',
};

export const supplementsFactsImages = {
  [`${supplementsVariantsSlugs.SUPPLEMENT_0001}_facts`]: supplementsFacts1Img,
  [`${supplementsVariantsSlugs.SUPPLEMENT_0002}_facts`]: supplementsFacts2Img,
  [`${supplementsVariantsSlugs.SUPPLEMENT_0003}_facts`]: supplementsFacts3Img,
  [`${supplementsVariantsSlugs.SUPPLEMENT_0004}_facts`]: supplementsFacts4Img,
  [`${supplementsVariantsSlugs.SUPPLEMENT_0006}_facts`]: supplementsFacts6Img,
  [`${supplementsVariantsSlugs.SUPPLEMENT_POUCH_0001}_facts`]: supplementsFacts1Img,
  [`${supplementsVariantsSlugs.SUPPLEMENT_POUCH_0002}_facts`]: supplementsFacts2Img,
  [`${supplementsVariantsSlugs.SUPPLEMENT_POUCH_0003}_facts`]: supplementsFacts3Img,
  [`${supplementsVariantsSlugs.SUPPLEMENT_POUCH_0004}_facts`]: supplementsFacts4Img,
  [`${supplementsVariantsSlugs.SUPPLEMENT_POUCH_0006}_facts`]: supplementsFacts6Img,
  supplementSoftgelFacts: supplementsFactsSoftgelImg,
};
