import { createAsyncThunk } from '@reduxjs/toolkit';

import * as Sentry from '@sentry/nextjs';

import * as APIClient from 'Services/APIClient';

const KB_ROOT = '/v1/knowledge_base';

export const fetchCategories = createAsyncThunk('support/fetchCategories', async () => {
  try {
    const result = await APIClient.getJson(`${KB_ROOT}/categories?page=1&pageSize=100`);
    return { categories: result };
  } catch (err) {
    Sentry.captureMessage('Kustomer API action (Support.fetchCategories)');
    throw err;
  }
});

export const fetchArticles = createAsyncThunk('support/fetchArticles', async () => {
  try {
    const result = await APIClient.getJson(
      `${KB_ROOT}/articles?page=1&pageSize=250&includeLang=en_us`
    );
    return { articles: result };
  } catch (err) {
    Sentry.captureMessage('Kustomer API action (Support.fetchArticles)');
    throw err;
  }
});

export const fetchSearch = createAsyncThunk('support/fetchSearch', async query => {
  try {
    const result = await APIClient.getJson(
      `${KB_ROOT}/search?term=${encodeURIComponent(query)}&scope=public`
    );
    return { search: result };
  } catch (err) {
    Sentry.captureMessage('Kustomer API action (Support.search)');
    throw err;
  }
});

export const fetchTags = createAsyncThunk('support/fetchTags', async () => {
  try {
    const result = await APIClient.getJson(`${KB_ROOT}/tags?page=1&pageSize=100`);
    return { fetchedTags: result };
  } catch (err) {
    Sentry.captureMessage('Kustomer API action (Support.fetchTags)');
    throw err;
  }
});
