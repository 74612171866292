import {
  supplementsFromTwoMonths,
  supplementsLittleImprovements,
  supplementsLotsOfImprovements,
  supplementsNoConcernInImprovements,
  supplementsNoImprovements,
  supplementsScalpComfort,
  supplementsSomeImprovements,
} from 'Apps/Feedback/constants/feedbackSlugs';

const supplementsScalpComfortScreen = {
  name: supplementsScalpComfort,
  route: `/feedback/supplements/${supplementsScalpComfort}`,
  shouldBeIncluded: ({ feedbackAnswers, consultationAnswers }) =>
    feedbackAnswers &&
    supplementsFromTwoMonths.includes(feedbackAnswers.product_supplements_usage_duration) &&
    consultationAnswers?.diag_sensitivity_level >= 2,
  title: 'Does your scalp feel more comfortable?',
  subtitle: 'Are you getting relief from itchiness, tightness, or sensitivity?',
  options: [
    { value: supplementsLotsOfImprovements, label: 'Yes, lots of improvement' },
    { value: supplementsSomeImprovements, label: 'Yes, some improvement' },
    { value: supplementsLittleImprovements, label: 'Yes, maybe a little' },
    { value: supplementsNoImprovements, label: 'Not yet' },
    { value: supplementsNoConcernInImprovements, label: 'No, but it wasn’t a concern' },
  ],
  getInitialValue: answers => answers?.product_supplements_scalp_comfort,
  getAnswers: ({ selected }) => ({ product_supplements_scalp_comfort: selected }),
};

export default supplementsScalpComfortScreen;
